.sortby {
    padding: 24px 0 8px;
    &-ttl {
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        color: #111827;
        margin-right: 16px;

    }

    &-inner {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &-btn {
        border: none;
        outline: none;
        background-color: transparent;
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        margin-right: 16px;
        cursor:pointer;
        padding: 0;
        color: #6B7280;
    }

    &-btn.active {
        color: #2563EB;
    }

    &-btn.desc .sortby-icon{
        -moz-transform: scale(-1, -1);
        -o-transform: scale(-1, -1);
        -webkit-transform: scale(-1, -1);
        transform: scale(-1, -1);

    }
    &-btn-inner {
        display: flex;
        align-items: center;
    }
    &-icon {
        margin-left: 2px;
    }
}