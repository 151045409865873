@charset "UTF-8";

*,
*::before,
*::after, {
    box-sizing: border-box;
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
}

html{
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 16px;
}

body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-weight: normal;
    font-family: $font-basic;
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-display: swap;
    font-size: 16px;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    &.is-hidden {
        overflow: hidden;
    }
}

ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}

a,
a:visited {
    text-decoration: none;
}

button {
    border: none;
    padding: 0;
}

img {
    display: block;
}

.container {
    width: 100%;
    max-width: 1072px;
    padding: 0 16px;
    margin: 0 auto;
    &-small {
        max-width: 672px !important;
        @extend .container;
    }
    &-medium {
        max-width: 708px !important;
        @extend .container;
    }
    &-large {
        max-width: 1170px !important;
        @extend .container;
    }
}

@media screen and (min-width: 1001px) {
    .visible-1000 {
        display: none !important;
    }
}

@media screen and (min-width: 768px) {
    .visible-767 {
        display: none !important;
    }
}

@media screen and (min-width: 1281px) {
    body.is-hidden {
        padding-right: 17px;
    }
}

@media screen and (max-width: 767px) {
    .hidden-767 {
        display: none !important;
    }
}