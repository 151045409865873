.popup-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 5;
    display: none;

    &.active {
        display: flex;
    }

    &-wrap {
        margin: 300px 24px 0 auto;
        position: relative;
        width: 100%;
        max-width: 512px;
        height: 384px;
        background-color: $white;
        border-radius: 8px;
        overflow: auto;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);

        scrollbar-color: #D1D5DB transparent;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            border: solid 6px transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #D1D5DB;
            border-radius: 18px;
            border: solid 6px transparent;
        }
    }

    &-header {
        position: sticky;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 12px 16px;
        border-bottom: 1px solid $gray-light;
        background-color: #fff;
        z-index: 5;
    }

    &-ttl {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $dark-blue;
    }

    &-buttons {
        display: flex;
        align-items: center;
    }

    &-delete {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $dark-blue;
        padding: 4px 16px;
        cursor: pointer;
    }

    &-input {
        position: relative;
        width: 103.2px;
        height: 34px;

        input {
            width: 0.01px;
            height: 0;
            opacity: 0;
            visibility: hidden;
        }

        label {
            position: absolute;
            top: 0;
            left: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            cursor: pointer;
            padding: 4px 16px;
        }
        
    }

    &-close {
        display: none;
    }

    &-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto;
        grid-column-gap: 4px;
        grid-row-gap: 4px;
        padding: 16px 16px 0;

        &-item {
            height: 64px;
            cursor: pointer;

            &__input {
                display: none;
                position: absolute;
                left: -9999px;
                visibility: hidden;
            }

            &__inner {
                height: inherit;
                @extend .img-cover;
                border-radius: 4px;
                transition: all .4s;
                overflow: hidden;

                &:after {
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.3283 5.33836C16.6017 5.61173 16.6017 6.05494 16.3283 6.32831L7.99497 14.6616C7.72161 14.935 7.27839 14.935 7.00503 14.6616L3.67169 11.3283C3.39832 11.0549 3.39832 10.6117 3.67169 10.3384C3.94506 10.065 4.38827 10.065 4.66164 10.3384L7.5 13.1767L15.3384 5.33836C15.6117 5.06499 16.0549 5.06499 16.3283 5.33836Z' fill='%23ffffff'/%3E%3C/svg%3E");
                    background-size: 40px;
                    background-position: 50%;
                    background-repeat: no-repeat;
                    background-color: rgba($green3, .6);
                    width: 100%;
                    height: 100%;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: all .4s;
                    transition-property: opacity, visibility;
                }
            }

            &__input:checked ~ &__inner:after {
                opacity: 1;
                visibility: visible;
            }
            
            &:hover &__input:not(:checked) ~ &__inner:after {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &-footer {
        display: none;
    }
} 

@media screen and (max-width: 1000px) {
    .popup-cover {
        position: fixed;
        align-items: center;
        justify-content: center;
        background-color: rgba(17, 24, 39, 0.5);

        &-wrap {
            margin: 0;
            height: 85%;
        }
 
    }
}

@media screen and (max-width: 767px) {
    .popup-cover {

        &-wrap {
            height: 75%;
        }

    }
}

@media screen and (max-width: 480px) {
    .popup-cover {
        display: flex;
        transition: .3s;
        transform: translateX(-100%);
        
        &.active {
            transform: translateX(0);
        }

        &-wrap {
            display: flex;
            flex-direction: column;
            border-radius: 0;
            height: 100vh;
        }

        &-header {
            .popup-cover-buttons {
                display: none;
            }
        }        

        &-close {
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-left: auto;
        }

        &-grid {
            padding: 16px;
            flex: 1;
            grid-template-rows: none;
            grid-auto-rows: min-content;
        }

        &-footer {
            position: sticky;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 12px 16px;
            border-top: 1px solid $gray-light;
            background-color: #fff;
            z-index: 5;
        }

        &-delete {
            padding: 0;
        }
    }
}