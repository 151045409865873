.u-icon {
    width: 1em;
    height: 1em;
    display: inline-block;
    color: inherit;
    fill: currentColor;
}

.row {
    margin: 0 -25px;
    display: flex;
    flex-wrap: wrap;

    &__col {
        max-width: 100%;
        min-height: 1px;
        padding: 0 24px;
        flex: 1;

        &--4 {
            max-width: 33.333333333%;
            flex-basis: 33.333333333%;
        }

        &--6 {
            max-width: 50%;
            flex-basis: 50%;
        }
    }
}

.text-accent {
    color: $green3;
}

.text-blue {
    color: $blue;
}

.img-cover {
    display: block;
    position: relative;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
    }
}

.text-overflow {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.text-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    &--12 {
        -webkit-line-clamp: 12;
    }
}

.card-box-text {
    margin: 0;
    line-height: 1.7;
    color: $dark-blue;
    & > :first-child {
        margin-top: 0;
    }
    & > :last-child {
        margin-bottom: 0;
    }
}

.title {
    &-3 {
        margin: 0;
        font-weight: 600;
        font-size: $fs-3;
        line-height: 1.35;
    }
    &-5 {
        margin: 0;
        font-weight: 600;
        font-size: $fs-5;
        line-height: 1.4;
    }
}

.notification-card {
    background: $white;
    font-size: 14px;
    line-height: 1.57;
    color: $darknes;
    text-align: initial;
    border-radius: 4px;
    position: relative;
    transition: all .4s;
    transition-property: background;
    &--unread {
        background: $blue-light;
    }
    &--size-full {
        border-radius: 0;
    }
    &:hover {
        @media screen and (min-width: 1200px) {
            background: #F3F4F6;
        }
    }
    &__inner {
        padding: 12px;
        display: flex;
        align-items: flex-start;
    }
    &--size-full &__inner {
        padding-top: 28px;
        padding-bottom: 28px;
        
    }
    &__avatar {
        width: 40px;
        height: 40px;
        margin: 0 12px 0 0;
        flex: 0 0 40px;
        overflow: visible;
        img {
            border-radius: 50%;
        }
    }
    &__avatar-link {
        &:before {
            width: 100%;
            height: 100%;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &__content {
        overflow: hidden;
    }
    &__top {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    &__name {
        margin: 0 .4em 0 0;
        font-weight: 500;
        @extend .text-overflow;
        a {
            color: inherit;
            &:before {
                width: 100%;
                height: 100%;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
    &__donate-wrapper &__name {
        width: 100%;
    }
    &__donate-wrapper {
        display: flex;
        align-items: flex-start;
    }
    &__action {
        color: $gray-1;
        a,
        span {
            color: $blue;
        }
        a {
            box-shadow: inset 0 -1px 0 transparent;
            position: relative;
            transition: all .4s;
            transition-property: box-shadow;
            z-index: 1;
            &:hover {
                @media screen and (min-width: 1200px) {
                    box-shadow: inset 0 -1px 0 currentColor;
                }
            }
        }
    }
    &__donate {
        margin-left: auto;
        padding-left: 5px;
        flex-shrink: 0;
        font-weight: 500;
        font-size: 14px;
        color: $green3;
        white-space: nowrap;
    }
    &__text {
        margin: 2px 0 0;
        a,
        span {
            color: $blue;
        }
        a {
            box-shadow: inset 0 -1px 0 transparent;
            position: relative;
            transition: all .4s;
            transition-property: box-shadow;
            z-index: 1;
            &:hover {
                @media screen and (min-width: 1200px) {
                    box-shadow: inset 0 -1px 0 currentColor;
                }
            }
        }
    }
    &--size-full &__text {
        display: block;
        -webkit-line-clamp: initial;
        -webkit-box-orient: initial;
        overflow: initial;
        
    }
    &__view-all {
        margin-top: 2px;
        display: inline-block;
        line-height: 1.2;
        color: $blue;
        position: relative;
        z-index: 1;
        box-shadow: inset 0 -1px 0 transparent;
        transition: all .4s;
        transition-property: box-shadow;
        &:hover {
            @media screen and (min-width: 1200px) {
                box-shadow: inset 0 -1px 0 currentColor;
            }
        }
    }
    &--size-full &__view-all {
        display: none;
        
    }
    &__footer {
        margin-top: 2px;
        color: $gray-1;
        @extend .text-clamp;
    }
    &__time {
        display: inline;
    }
    &__footer-subtitle {
        display: inline;
        a,
        span {
            color: $blue;
        }
        a {
            box-shadow: inset 0 -1px 0 transparent;
            position: relative;
            transition: all .4s;
            transition-property: box-shadow;
            z-index: 1;
            &:hover {
                @media screen and (min-width: 1200px) {
                    box-shadow: inset 0 -1px 0 currentColor;
                }
            }
        }
    }
    &__group-btns {
        padding-top: 8px;
        position: relative;
        z-index: 0;
    }
}

.notification-board {
    display: none !important;
    &__main {
        height: calc(100vh - 140px);
        max-height: 624px;
        min-height: 280px;
        margin: 4px;
        scrollbar-width: none;
        scrollbar-color: #D1D5DB transparent;
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #D1D5DB;
            border-radius: 3px;
            border: 3px solid #D1D5DB;
        }
    }
    &__list {
        margin: -4px 0 -4px -4px;
        padding: 0 4px;
    }
    &__list-card {
        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }
    &__footer {
        border-top: 1px solid #EEF0F3;
    }
    &__view-all {
        padding: 14px;
        display: block;
        font-weight: normal;
        font-size: 14px;
        line-height: 1.3;
        color: $blue;
        text-align: center;
        border-radius: 0 0 8px 8px;
        transition: all .4s;
        transition-property: background;
        &:hover {
            @media screen and (min-width: 1200px) {
                background: rgba(#EEF0F3, .65);
            }
        }
    }
}

.btn-reset {
    background: transparent;
    margin: 0;
    padding: 0;
    font-size: 0;
    outline: none;
    border: none;
    border-radius: 0;
}

.btn-action {
    background: transparent;
    width: 24px;
    height: 24px;
    font-size: 0;
    color: #9CA3AF;
    outline: none;
    border: none;
    border-radius: 0;
    cursor: pointer;
    position: relative;
    transition: all .4s;
    transition-property: color;
    &:hover {
        text-decoration: none;
    }
    &:before {
        content: '';
        position: absolute;
        inset: -4px;
        border-radius: 8px;
        transition: all .4s;
        transition-property: background;
    }
    &:hover:before {
        background: rgba($gray, .1);
    }
    &__icon {
        width: 100%;
        height: 100%;
        display: block;
    }
    &__notification {
        background: $white;
        width: 448px;
        position: absolute;
        top: 100%;
        right: 0;
        box-shadow: 0 10px 15px -3px rgba(0,0,0,.1),
                    0 4px 6px -4px rgba(0,0,0,.1);
        border-radius: 8px;
    }
}

.btn-close {
    background: transparent;
    width: 24px;
    height: 24px;
    display: inline-block;
    font-size: 0;
    color: #9CA3AF;
    position: relative;
    border: none;
    border-radius: 0;
    outline: none;
    cursor: pointer;
    transition: all .4s;
    transition-property: color;
    &:hover {
        color: $darknes;
    }
    &:before {
        content: '';
        position: absolute;
        top: -6px;
        right: -6px;
        bottom: -6px;
        left: -6px;
    }
    svg {
        width: 100%;
        height: 100%;
    }
}

.media-responsive {
    padding: 0 0 56.25%;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    > * {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.video-link-block {
    &__link-wrap {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 1.4;
        overflow: hidden;
    }
    &__link {
        margin-right: 24px;
        color: #9CA3AF;
    }
    &__btn-change {
        flex-shrink: 0;
        font-size: 14px;
        line-height: 1.4;
        color: $blue;
        position: relative;
        cursor: pointer;
        transition: all .4s;
        transition-property: color;
        &:before {
            content: '';
            position: absolute;
            top: -6px;
            right: -6px;
            bottom: -6px;
            left: -6px;
        }
    }
    &__el-wrap {
        margin: 16px 0 0;
    }
    &__link-edit {
        margin-bottom: 32px;
        &-input {
            width: 100%;
            margin: 0 0 16px;
        }
        &-group-btns {
            .btn {
                min-height: 36px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.input-donate {
    width: 100%;
    padding: 2px 0;
    display: block;
    font-weight: 600;
    font-size: 48px;
    line-height: 1.4;
    color: #000;
    border: solid $gray-light;
    border-width: 0 0 1px;
    border-radius: 0;
    outline: none;
    -moz-appearance: textfield;
    transition: all .4s;
    transition-property: border-color;

    &:hover,
    &:focus {
        outline: none;
    }

    &:hover {

    }

    &:focus {
        
    }

    &::placeholder {
        color: $gray-light;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.input-el {
    background: #fff;
    width: 100%;
    min-height: 44px;
    padding: 10px 11px;
    display: block;
    font-size: 14px;
    line-height: 1.55;
    color: $darknes;
    border: 1px solid $gray-light;
    border-radius: 8px;
    outline: none;
    transition: all .4s;
    transition-property: border-color;
    &:hover,
    &:focus {
        outline: none;
        border-color: $gray-1;
    }
    &:hover {
    }
    &:focus {
    }
    &:disabled {
        background: #F9FAFB !important;
        border-color: $gray-light !important;
        user-select: none !important;
    }
    &::placeholder {
        color: #9CA3AF;
    }
    &--textarea {
        max-width: 100%;
        min-width: 100%;
        resize: vertical;
        &-size-md {
            min-height: 110px;
        }
        &-size-lg {
            min-height: 260px;
        }
    }
    &--select {
        background-position: right 11px center;
        background-size: 24px;
        background-repeat: no-repeat;
        padding-right: 46px;
        appearance: none;

        &::-ms-expand {
            display: none;
        }
    }
}

.input-link {
    &__label {
        display: block;
    }
    &__label-title {
        margin: 0 0 7px;
        display: block;
        font-weight: 500;
        font-size: 12px;
        line-height: 1;
        color: #4B5563;
    }
    &__label-input {
        width: 100%;
    }
}

.link-add {
    &__inner {
        display: flex;
    }
    &__name {
        max-width: 32%;
        margin-right: 12px;
        flex: 0 0 32%;
    }
    &__url {
        margin-right: 8px;
        flex-grow: 1;
    }
    &__remove {
        width: 24px;
        max-width: 24px;
        height: 24px;
        margin-bottom: 10px;
        flex: 0 0 24px;
        align-self: flex-end;
    }
}

.input-file {
    display: block;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    &--picture {
        width: 100%;
        padding-bottom: 56.25%;
        border-radius: 8px;
        overflow: hidden;
    }
    &--logo {
        width: 160px;
        height: 160px;
        border-radius: 50%;
    }
    &__input {
        display: none;
        position: absolute;
        left: -9999px;
    }
    &__inner {
        width: 100%;
        height: 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 1.7;
        color: #9CA3AF;
        text-align: center;
        border: 2px dashed #D1D5DB;
        border-radius: inherit;
        position: absolute;
        inset: 0;
    }
    &__icon {
        width: 40px;
        height: 40px;
        margin: 0 0 4px;
        display: block;
    }
    &__description {
        margin: 0 0 8px;
        display: block;
    }
    &__title {
        margin: 0;
        display: block;
        color: $blue;
    }
    &__load {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: inherit;
        transition: all .4s;
        transition-property: opacity, visibility;
        overflow: hidden;
        z-index: 1;
        img[src=''] {
           display: none;
        }
    }
    &:hover &__load {
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
    }
}

.input-block {
    line-height: 1.5;
    &__label {
        display: block;
    }
    &__label-name {
        margin: 0 0 12px;
        display: block;
        font-weight: 500;
        font-size: 16px;
        span {
            color: $gray;
        }
        strong {
            font-weight: 500;
        }
    }
    &--label-size-md &__label-name {
        font-weight: normal;
        font-size: 14px;
    }
    &--label-size-sm &__label-name {
        margin-bottom: 4px;
        font-weight: normal;
        font-size: 12px;
        color: #4B5563;
    }
    &__label-description {
        margin: -8px 0 12px;
        display: block;
        font-size: 14px;
        color: $gray;
    }
    &__add-link {
        margin: 12px 0 0;
    }
    &__el-container {
        display: block;
        position: relative;
    }
    &__el-icon-wrapper {
        width: 48px;
        height: 100%;
        color: #D1D5DB;
        position: absolute;
        top: 0;
        cursor: pointer;
        z-index: 1;
        transition: all .4s;
        transition-property: color;
        &--position-left {
            left: 0;
        }
        &--position-right {
            right: 0;
        }
        &:hover {
            @media screen and (min-width: 1200px) {
                color: $darknes;
            }
        }
    }
    &__el-icon {
        width: 24px;
        height: 24px;
        margin: -12px 0 0 -12px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transition: all .4s;
        transition-property: opacity, visibility;
    }
    &__el-icon-wrapper.is-password-show &__el-icon:first-child,
    &__el-icon-wrapper:not(.is-password-show) &__el-icon:last-child {
        opacity: 0;
        visibility: hidden;
    }
    &__el {
    }
    &__el-icon-wrapper ~ &__el {
        padding-right: 50px;
    }
    &__textarea-wrapper {
        position: relative;
    }
    &__textarea-counter {
        font-weight: 400;
        font-size: 12px;
        line-height: 1.35;
        color: #D1D5DB;
        position: absolute;
        right: 8px;
        bottom: 8px;
        pointer-events: none;
    }
    &__file {
    }
    &__label-radio {
        margin: 0 0 12px;
    }
    &__label ~ .input-file--logo {
        margin-top: 24px;
    }
    &__template-cards {
        margin: 16px -8px;
        padding-bottom: 16px;
        display: flex;
        flex-wrap: wrap;
        &-item {
            max-width: 100%;
            padding: 8px;
            flex: 1;
            &--size-4 {
                max-width: 33.3333333%;
                flex-basis: 33.3333333%;
            }
        }
    }
    &__editor {
        img {
            max-width: 100%;
            height: auto;
            display: block;
        }
    }
    &__video-link-block {
        padding-top: 4px;
    }
    &__link-add {
        margin: 24px 0;
    }
    &__label-tooltip {
        margin-left: 7px;
    }
}

.btn {
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    transition: all .4s;
    transition-property: background, color, border-color;

    &-white {
        background-color: $white;
        font-weight: 400;
        color: $dark-blue;
        border-color: #D1D5DB;
    }

    &-trp {
        background-color: transparent;
        font-weight: 400;
        color: $dark-blue;
        border-color: #D1D5DB;
        &:hover {
            border-color: $gray-light;
        }
        &:focus,
        &:active {
            background: #F3F4F6;
            border-color: #D1D5DB;
        }
    }

    &-simple {
        background-color: transparent;
        font-weight: 400;
        color: $blue;
        &:hover {
            background: rgba($gray-light, .4);
        }
        &:focus,
        &:active {
            background: rgba($gray-light, .7);
        }
    }

    &-cancel {
        background-color: transparent;
        font-weight: 400;
        color: $gray;
        &:hover {
            color: $darknes;
        }
    }

    &-green {
        font-weight: 500;
        color: $white;
        background-color: $green2;
        &:hover {
            background: $green1;
        }
        &:focus,
        &:active {
            background: $green4;
        }
    }

    &-green-accent {
        font-weight: 500;
        color: $white;
        background-color: $green3;
        &:hover {
            background: $green1;
        }
        &:focus,
        &:active {
            background: $green4;
        }
    }

    &-dark {
        font-weight: 500;
        color: $white;
        background-color: $darknes;
        &:hover {
            background: $green1;
        }
        &:focus,
        &:active {
            background: $green4;
        }
    }

    &--action {
        padding: 8px;
    }

    &--size-lt {
        padding: 3px 16px;
    }

    &--size-sm {
        padding: 9px 20px;
    }

    &--size-md {
        padding: 11px 28px;
    }

    &__icon {
        width: 20px;
        height: 20px;
        margin: -10px 0 0 -10px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
    }
}

.city-label {
    display: inline-flex;
    align-items: center;
    &--theme-dark {
    }
    &__icon {
        width: 20px;
        height: 20px;
        margin-right: 4px;
        flex: 0 0 20px;
    }
}

.filter-item {
    font-size: 14px;
    line-height: 1;
    position: relative;
    &--dotted {
    }
    &__btn {
        background: transparent;
        padding: 5px 0;
        display: flex;
        align-items: center;
        font-size: inherit;
        line-height: inherit;
        color: $blue;
        position: relative;
        border: none;
        border-radius: 0;
        outline: none;
        cursor: pointer;
        transition: all .4s;
        transition-property: color;
        &:before {
            content: '';
            position: absolute;
            inset: -6px;
        }
        &:after {
            width: 0;
            height: 0;
            margin: .2em 0 0 .3em;
            content: '';
            display: inline-block;
            flex-shrink: 0;
            border-style: solid;
            border-width: 3px 3px 0 3px;
            border-color: $blue transparent transparent transparent;
            vertical-align: middle;
            transition: all .4s;
            transition-property: transform;
        }
    }
    &.is-open &__btn:after {
        transform: scaleY(-1);
    }
    &--dotted &__btn {
        font-size: 0;
        color: $gray-1;
        &:after {
            display: none;
        }
    }
    &__btn-dotted {
        width: 20px;
        height: 20px;
        display: block;
    }
    &__dropdown {
        background: $white;
        padding: 4px;
        box-shadow: 0 10px 15px -3px rgba(0,0,0,.1),
                    0 4px 6px -4px rgba(0,0,0,.1);
        border-radius: 8px;
        position: absolute;
        top: 100%;
        right: 0;
        transition: all .4s;
        transition-property: transform, visibility, opacity;
        z-index: 2;
        &-action-list {
        }
        &-action {
            width: 100%;
            padding: 10px 12px;
            display: block;
            font-size: 14px;
            line-height: 1.2;
            color: $dark-blue;
            text-align: left;
            border-radius: 4px;
            user-select: none;
            cursor: pointer;
            white-space: nowrap;
            transition: all .24s;
            transition-property: background;
            @extend .btn-reset;
            &:hover {
                background: #F3F4F6;
                text-decoration: none;
            }
        }
    }
    &:not(.is-open) &__dropdown {
        transform: translateY(4px);
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
    }
}

.filter-group {
    display: flex;

    &__item {

        &:not(:last-child) {
            margin-right: 32px;
        }
    }
}

.widgets-btn {
    cursor: pointer;
    background: $green3;
    min-height: 36px;
    padding: 7px 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    color: $white;
    text-align: center;
    border-radius: 8px;
    @extend .btn-reset;
    &--size-md {
        min-height: 44px;
        padding: 10px 24px;
    }
    &__icon-wrap {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        flex: 0 0 20px;
    }
    &__icon {
        width: 100%;
        height: 100%;
        display: block;
        fill: currentColor;
    }
    &__title {
    }
}

.code-area {
    margin: 0;
    display: block;
    &__body {
        background: $blue-light;
        margin: 0;
        padding: 16px;
        display: block;
        font-size: 14px;
        line-height: 1.7;
        color: #242B33;
        white-space: pre-wrap;
        word-break: break-all;
        border-radius: 8px;
    }
}

.widgets-card {
    font-size: 14px;
    line-height: 1.3;
    color: #111827;
    &__inner {
        padding: 16px;
        border: 1px solid $gray-light;
        border-radius: 12px;
    }
    &__header {
        margin: 0 0 12px;
        display: flex;
        align-items: center;
    }
    &__picture {
        width: 40px;
        height: 40px;
        margin-right: 12px;
        border-radius: 50%;
        flex: 0 0 40px;
        position: relative;
        overflow: hidden;
    }
    &__img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
    }
    &__poster {
        margin: 0 0 12px;
        padding-bottom: 56.25%;
        display: block;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
    }
    &__header-content {
        overflow: hidden;
    }
    &__header-title {
        margin: 0 0 3px;
        font-weight: 500;
    }
    &__header-subtitle {
        font-size: 12px;
        color: #9CA3AF;
    }
    &__text {
        font-size: 14px;
        line-height: 1.7;
        color: $dark-blue;
        &:not(:last-child) {
            margin-bottom: 12px;
        }
        > * {
            margin: 0;
        }
    }
    &__btn {
        width: 100%;
    }
}

.widgets-item {
    line-height: 1.3;
    &--btn {
    }
    &--card-small {
    }
    &--card-big {
    }
    &__title {
        margin: 0 0 32px;
        font-weight: 600;
        font-size: 20px;
    }
    &__view {
    }
    &__subtitle {
        margin: 0 0 10px;
        font-size: 14px;
        color: $gray;
    }
    &__view-body {
        margin: 0 0 32px;
    }
    &--card-small &__view-body {
        max-width: 360px;
    }
    &--card-big &__view-body {
        max-width: 480px;
    }
    &__code-wrap {
    }
    &__code {
    }
}

.subscriber-card {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1.3;
    &__content {
        padding-right: 15px;
        display: flex;
        align-items: center;
        overflow: hidden;
    }
    &__picture {
        width: 40px;
        height: 40px;
        margin-right: 12px;
        flex: 0 0 40px;
        border-radius: 50%;
        overflow: hidden;
    }
    &__text {
        overflow: hidden;
    }
    &__name {
        font-weight: 500;
        font-size: 16px;
        &:not(:last-child) {
            margin-bottom: 2px;
        }
    }
    &__position {
        color: #8A9199;
    }
    &__actions {
        margin-left: auto;
        flex-shrink: 0;
    }
}

.subscribers-list {
    &__item {
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }
}

.language-site {
    font-size: 14px;
    line-height: 1;
    color: $darknes;
    white-space: nowrap;
    &__current {
        padding: 14px 0;
        display: flex;
        align-items: center;
    }
    &__current-title {
    }
    &__current-icon {
        width: 20px;
        height: 20px;
        margin-left: 4px;
        flex: 0 0 20px;
        transition: all .4s;
        transition-property: transform;
    }
    &.is-active &__current-icon {
        transform: scaleY(-1);
    }
    &__dropdown {
    }
    &:not(.is-active) &__dropdown {
        display: none;
    }
    &__link {
        margin-top: 4px;
        padding: 14px 0;
        display: block;
        color: inherit;
        text-decoration: none;
    }
}

.profile-card {
    display: flex;
    align-items: flex-end;
    line-height: 1.3;
    &__avatar {
        width: 128px;
        height: 128px;
        margin-right: 12px;
        flex: 0 0 128px;
        border-radius: 50%;
        box-shadow: 0 0 0 2px $white;
        overflow: hidden;
    }
    &__content {
    }
    &__name {
        margin: 0 0 8px;
        font-weight: 600;
        font-size: 24px;
        line-height: 1;
    }
    &__subscribes {
        margin: 0 0 2px;
        font-size: 14px;
        line-height: 1;
        color: #9CA3AF;
    }
}

.user-profile {
    padding: 10px 12px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $darknes;
    position: relative;
    z-index: 0;
    transition: all .4s;
    &--is-active {
    }
    &__avatar {
        width: 32px;
        height: 32px;
        margin-right: 12px;
        flex: 0 0 32px;
        border-radius: 50%;
    }
    &__content {
        margin-right: 10px;
        flex-grow: 1;
    }
    &__content-name {
        margin: 0 0 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 1;
        color: $darknes !important;
        &:before {
            width: 100%;
            height: 100%;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &__content-email {
        font-size: 12px;
        line-height: 1;
        color: #8A9199;
    }
    &__icon-wrap {
        width: 20px;
        height: 20px;
        margin: 0 -4px 0 auto;
        flex: 0 0 20px;
        position: relative;
        z-index: -1;
    }
    &__icon {
        width: 100%;
        height: 100%;
        display: none;
        &--active {
        }
        &--arrow {
        }
    }
    &--is-active &__icon--active {
        display: block;
    }
}

.profile-dropdown {
    background: #fff;
    padding: 4px;
    border-radius: 8px;
    box-shadow: 0 10px 15px -3px rgba(0,0,0,.1),
                0 4px 6px -4px rgba(0,0,0,.1);
    transition: all .4s;
    transition-property: transform, visibility, opacity;
    &:not(.is-show) {
        transform: translateY(6px);
        opacity: 0;
        visibility: hidden;
    }
    &__user-list {
        &-item {
        }
    }
    &__nav-wrap {
    }
    &__nav {
        margin: 0;
        padding: 0;
        list-style-type: none;
        &:before {
            background: $gray-light;
            height: 1px;
            margin: 4px -4px;
            content: '';
            display: block;
        }
        &-item {
            margin: 0;
            padding: 0;
        }
        &-link {
            padding: 11px 12px;
            font-size: 14px;
            line-height: 1;
            display: block;
            color: $dark-blue !important;
            border-radius: 4px;
            white-space: nowrap;
            transition: all .4s;
            transition-property: background;
            &:hover,
            &.is-active {
                background: #F3F4F6;
                text-decoration: none;
            }
        }
    }
    &__overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
    }
}

.list-content {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
        margin: 0;
        padding: 0;
        &:not(:last-child) {
            margin-bottom: 16px;
        }
        &:before {
            padding-right: .15em;
            content: '\2022';
        }
    }
    &--theme-dots-accent li {
        &:before {
            color: $green3;
        }
    }
    a {
        color: inherit;
        box-shadow: 0 1px 0 transparent;
        transition: all .4s;
        transition-property: box-shadow;
        &:hover {
            box-shadow: 0 1px 0 currentColor;
        }
        &:visited {
            color: inherit;
        }
    }
}

.scroll-up {
    background: $blue-light;
    padding: 10px 16px;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 1;
    color: $blue!important;
    user-select: none;
    border-radius: 8px;
    position: relative;
    white-space: nowrap;
    transition: all .34s;
    transition-property: background, transform, opacity, visibility;
    z-index: 100;
    &:hover {
        background: #eef1f4;
    }
    &:not(.is-show) {
        transform: translateY(120px);
        opacity: 0;
        visibility: hidden;
    }
    &--position-right-bottom {
        position: fixed;
        right: 40px;
        bottom: 41px;
    }
    &__icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        stroke: currentColor;
        color: inherit;
        flex: 0 0 24px;
    }
    &__title {
    }
}

.verification-status {
    display: flex;
    align-items: flex-start;
    line-height: 24px;
    &--hold {
        color: $gray;
    }
    &--passed {
        color: $green3;
    }
    &--failed {
        color: $red;
    }
    &__icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        flex: 0 0 24px;
    }
    &__text {
        font-weight: 500;
        font-size: 18px;
    }
}

.list-counter {
    margin: 0;
    padding: 0;
    list-style-type: none;
    counter-reset: list-counter-1;
    li {
        margin: 0;
        padding: 0 0 0 30px;
        counter-increment: list-counter-1;
        position: relative;
        &:before {
            content: counter(list-counter-1) '. ';
            position: absolute;
            top: 0;
            left: 0;
        }
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }
    strong {
        font-weight: 600;
    }
    p {
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    a:not([class]) {
        color: $blue !important;
        box-shadow: 0 1px 0 currentColor;
        transition: all .4s;
        transition-property: box-shadow;
        &:hover {
            box-shadow: 0 1px 0 transparent;
        }
    }
    .btn {
        display: inline-block;
    }
}

.list-text {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
        margin: 0;
        padding: 0;
        position: relative;
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }
    strong {
        font-weight: 600;
    }
    p {
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    a:not([class]) {
        color: $blue !important;
        box-shadow: 0 1px 0 currentColor;
        transition: all .4s;
        transition-property: box-shadow;
        &:hover {
            box-shadow: 0 1px 0 transparent;
        }
    }
    .btn {
        display: inline-block;
    }
}

.attach-file {
    display: flex;
    align-items: center;
    &__name {
        min-width: 108px;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.4;
        color: $blue !important;
        box-shadow: 0 1px 0 transparent;
        transition: all .4s;
        transition-property: box-shadow;
        &:hover {
            box-shadow: 0 1px 0 currentColor;
        }
    }
    &__btn-remove {
        width: 20px;
        height: 20px;
        margin: 0 0 0 8px;
        flex: 0 0 20px;
        color: $gray-1;
        cursor: pointer;
        @extend .btn-reset;
        transition: all .4s;
        transition-property: transform;
        position: relative;
        &:hover {
            transform: rotate(180deg);
        }
        &:before {
            content: '';
            position: absolute;
            top: -6px;
            right: -6px;
            bottom: -6px;
            left: -6px;
        }
        &-icon {
            width: 100%;
            height: 100%;
            display: block;
        }
    }
}

.phone-binding {
    background: $blue-light;
    padding: 24px;
    color: $darknes;
    border-radius: 8px;
    &__text {
        margin: 0 0 16px;
        font-size: 14px;
        line-height: 1.7;
    }
    &__btn {
        display: inline-block;
    }
}

.group-btns {
    margin: -6px;
    display: flex;
    align-items: center;
    &__item {
        margin: 6px;
    }
}

.btn-vote {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
    @extend .btn-reset;
    &:before {
        content: '';
        position: absolute;
        top: -3px;
        right: -3px;
        bottom: -3px;
        left: -3px;
    }
    &--like {
    }
    &--dislike {
    }
    &__icon {
        width: 100%;
        height: 100%;
        fill: transparent;
        stroke: $gray-1;
        transition: all .4s;
        transition-property: fill, stroke;
    }
    &--like &__icon {
    }
    &--dislike &__icon {
        transform: scaleY(-1);
    }
    &--like:hover &__icon {
        fill: $green3;
        stroke: $green3;
    }
    &--dislike:hover &__icon {
        fill: $red;
        stroke: $red;
        transform: scaleY(-1);
    }
}

.rating-vote {
    display: flex;
    align-items: center;
    &__btn {
        flex: 0 0 20px;
    }
    &__count {
        margin: 0 6px;
        flex-shrink: 0;
        font-weight: normal;
        font-size: 12px;
        line-height: 1;
        color: $gray;
        text-align: center;
    }
}

.user-project-donate-card {
    font-size: 14px;
    color: $darknes;
    border: 1px solid $gray-light;
    border-radius: 8px;
    &__inner {
        padding: 24px;
    }
    &__header {
        margin: -4px 0 24px;
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $gray-light;
        &-logo {
            width: 32px;
            height: 32px;
            margin-right: 12px;
            flex: 0 0 32px;
            border-radius: 50%;
            user-select: none;
            overflow: hidden;
        }
        &-title {
            margin: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 1.4;
            overflow: hidden;
        }
        &-link {
            display: block;
            color: $darknes !important;
            @extend .text-overflow;
        }
    }
    &__body {
    }
    &__user {
        margin: 0 0 12px;
        display: flex;
        align-items: center;
        &-avatar {
            width: 40px;
            height: 40px;
            margin-right: 12px;
            flex: 0 0 40px;
            border-radius: 50%;
            user-select: none;
            overflow: hidden;
        }
        &-content {
            overflow: hidden;
        }
        &-name {
            margin: 0 0 2px;
            display: block;
            font-weight: 500;
            font-size: 14px;
            line-height: 1.4;
            color: $darknes !important;
            @extend .text-overflow;
        }
        &-info {
            display: flex;
            align-items: center;
            line-height: 1.2;
            flex-wrap: wrap;
        }
        &-date {
            margin-right: 6px;
            font-size: 12px;
            line-height: 1;
            color: $gray;
        }
        &-amount {
            background: #F3F4F6;
            padding: 4px 6px;
            flex-shrink: 0;
            font-weight: 500;
            font-size: 12px;
            line-height: 1;
            color: #111827;
            align-self: center;
            border-radius: 4px;
            white-space: nowrap;
        }
    }
    &__text {
        margin: 0 0 12px;
        font-size: 14px;
        line-height: 1.7;
    }
    &__rating {
    }
}

.verification-checked {
    &:after {
        background: url(/img/verification-checked.svg) 50% no-repeat;
        background-size: contain;
        width: 1em;
        height: 1em;
        margin-left: .22em;
        content: '';
        display: inline-block;
        flex-shrink: 0;
        vertical-align: middle;
    }
}

.donate-btn {
    padding: 8px 15px;
    display: inline-block;
    font-weight: normal;
    font-size: 14px;
    line-height: 1;
    color: $dark-blue;
    border: 1px solid #D1D5DB;
    border-radius: 20px;
    cursor: pointer;
    transition: all .4s;
    transition-property: background, color, border-color;
    @extend .btn-reset;
    &.is-active {
        background: $green3;
        color: #fff;
        border-color: $green3;
    }
    &:hover {
        border-color: $green3;
    }
}

.donate-btn-list {
    &__inner {
        margin: -4px;
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        margin: 4px;
    }
}

.tooltip-donate {
    display: inline-block;
    font-size: 0;
    color: $gray-1;
    vertical-align: middle;
    position: relative;
    transition: all .4s;
    transition-property: color;
    &:hover,
    &:active,
    &.is-active {
        color: $darknes;

        .tooltip-donate__dropdown {
            pointer-events: auto;
        opacity: 1;
        visibility: visible;
            transform: translateY(-50%);
            transition: all .4s;
            transition-property: transform, opacity, visibility;
        }
    }
    &__icon {
        width: 20px;
        height: 20px;
        display: block;
        cursor: pointer;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: -3px;
            right: -3px;
            bottom: -3px;
            left: -3px;
        }
    }
    &__dropdown {
        background: #fff;
        width: 320px;
        min-height: 186px;
        margin-left: 16px;
        padding: 16px;
        font-size: 14px;
        position: absolute;
        top: 50%;
        left: 100%;
        //transform: translateY(-50%);
        border-radius: 8px;
        box-shadow: 0 10px 15px rgba(0,0,0,.1),
                    0 4px 6px rgba(0,0,0,.1);
        cursor: initial;
        z-index: 2;
        transition: all .4s;
        transition-property: transform, opacity, visibility;
        transform: translateX(6px) translateY(-50%);
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
        &:before {
            margin: -7px -1px 0 0;
            content: '';
            border-style: solid;
            border-width: 7px 10px 7px 0;
            border-color: transparent #fff transparent transparent;
            position: absolute;
            top: 50%;
            right: 100%;
            z-index: -1;
        }
        &-close {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 12px;
            right: 12px;
        }
        &-text {
            margin: 0 0 12px;
            font-size: 14px;
            line-height: 1.7;
            color: $gray;
        }
        &-amount {
            margin: 0 0 12px;
            padding-bottom: 8px;
            font-weight: 600;
            font-size: 36px;
            line-height: 1.1;
            color: $darknes;
            border-bottom: 1px solid $gray-light;
        }
        //&-donate-list {
        //}
    }
  /*   &:not(.is-active) &__dropdown,
    &:not:hover &__dropdown  {
        transform: translateX(6px) translateY(-50%);
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
    } */
}

.btn-action-simple {
    background: transparent;
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 1;
    color: $gray;
    border: none;
    border-radius: 0;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: -6px;
        right: -6px;
        bottom: -6px;
        left: -6px;
    }
    &__icon {
        width: 20px;
        height: 20px;
        margin-right: 6px;
        flex: 0 0 20px;
    }
    &__title {
    }
}

.share-el {
    font-weight: normal;
    font-size: 14px;
    color: $dark-blue;
    position: relative;
    &__dropdown {
        background: #fff;
        margin-top: 24px;
        font-size: 14px;
        position: absolute;
        top: 100%;
        right: 0;
        border-radius: 8px;
        box-shadow: 0 10px 15px -3px rgba(0,0,0,.1),
                      0 4px 6px -4px rgba(0,0,0,.1);
        transition: all .4s;
        transition-property: transform, opacity, visibility;
        z-index: 2;
        &-body {
            width: 256px;
            padding: 4px;
        }
        &.is-qrcode &-body {
            display: none;
        }
        &-cancel {
            width: 100%;
            margin-top: 8px;
            padding: 13px;
            display: none;
            font-size: 14px;
            text-align: center;
            border: 1px solid #D1D5DB;
            border-radius: 8px;
            cursor: pointer;
            @extend .btn-reset;
        }
        &-qrcode {
            width: 240px;
            padding: 16px;
            &-title {
                margin: -4px 0 12px;
                font-weight: 600;
                font-size: 16px;
                text-align: center;
            }
            &-body {
                margin: 0 0 16px;
                display: block;
                font-size: 0;
                > * {
                    width: 100%;
                    height: auto;
                    display: block;
                }
            }
            &-btn {
                width: 100%;
                text-align: center;
            }
        }
        &:not(.is-qrcode) &-qrcode {
            display: none;
        }
    }
    &:not(.is-active) &__dropdown {
        transform: translateY(6px);
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
    }
    &__item {
        width: 100%;
        padding: 8px 12px;
        display: flex;
        align-items: center;
        font-size: 14px;
        border-radius: 4px;
        cursor: pointer;
        transition: all .4s;
        transition-property: background;
        @extend .btn-reset;
        &:hover {
            background: #F3F4F6;
        }
        &--vk {
        }
        &--telegram {
        }
        &--copy-link {
        }
        &--qrcode {
        }
        &-icon {
            width: 20px;
            height: 20px;
            margin-right: 12px;
            flex: 0 0 20px;
        }
        &--vk &-icon {
            color: $telegram-social;
        }
        &--telegram &-icon {
            color: $vk-social;
        }
        &-title {
        }
    }
}

.input-mask-el {
    &__inner {
        background: #fff;
        width: 100%;
        min-height: 44px;
        padding: 10px 11px;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 1.55;
        color: $darknes;
        border: 1px solid $gray-light;
        border-radius: 8px;
        user-select: none;
        outline: none;
        transition: all .4s;
        transition-property: border-color;
        &:hover,
        &:focus,
        &:focus-within {
            outline: none;
            border-color: $gray-1;
        }
    }
    &__title {
        color: $gray-1;
        flex-shrink: 0;
        white-space: nowrap;
    }
    &__input {
        background: transparent;
        margin: 0;
        padding: 0;
        display: block;
        line-height: inherit;
        color: inherit;
        flex-grow: 1;
        border: none;
        border-radius: 0;
        outline: none;
    }
}

.project-status {
    background: #F3F4F6;
    padding: 5px 6px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1;
    color: $gray;
    border-radius: 4px;
    user-select: none;
    &__icon {
        width: 1.14285714286em;
        height: 1.14285714286em;
        margin-right: 0;
        flex: 0 0 1.14285714286em;
    }
    &__title {
        white-space: nowrap;
    }
}

.tag {
    font-size: 14px;
    line-height: 1.3;
    color: $blue;
    transition: all .4s;
    transition-property: box-shadow;
    &:hover {
        @media screen and (min-width: 1200px) {
            box-shadow: inset 0 -1px 0 currentColor;
        }
    }
}

.btn-video {
    background: transparent;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    color: $blue;
    text-align: center;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    transition: all .4s;
    transition-property: color;
    &:before {
        content: '';
        position: absolute;
        top: -8px;
        right: -8px;
        bottom: -8px;
        left: -8px;
    }
    &__title {
        margin-right: 8px;
        box-shadow: 0 1px 0 transparent;
    }
    &:hover &__title {
        @media screen and (min-width: 1200px) {
        }
    }
    &__icon {
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
        transition: all .34s;
        transition-property: transform;
    }
    &:hover &__icon {
        @media screen and (min-width: 1200px) {
            transform: scale(1.1);
        }
    }
}

.user-item {
    text-align: center;
    position: relative;
    &__picture {
        margin: 0 0 12px;
        padding-bottom: 100%;
        display: block;
        border-radius: 50%;
        overflow: hidden;
        @extend .img-cover;
    }
    &__avatar {
    }
    &__name {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.28;
        a {
            color: inherit;
            &:hover {
                @media screen and (min-width: 1200px) {
                }
            }
            &:before {
                width: 100%;
                height: 100%;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}

.header-block-info {
    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__title {
        margin: 0;
        font-weight: 600;
        font-size: 20px;
        line-height: 1.4;
    }
    &__link {
        flex-shrink: 0;
    }
}

.search-users {
    &__header {
        margin: 0 0 24px;
    }
    &__list {
        margin: 0 -16px -32px;
        display: flex;
        flex-wrap: wrap;
        &-col {
            max-width: 11.1111111111%;
            margin-bottom: 32px;
            padding: 0 16px;
            flex: 0 0 11.1111111111%;
        }
    }
}

.support-social {
    &__inner {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    &__description {
        margin: 0;
        flex-shrink: 0;
        font-size: 16px;
        line-height: 1.7;
        color: $gray;
        @media screen and (max-width: 767px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 4px;
            font-size: 14px;
            text-align: center;
        }
    }
    &__list {
        margin: 0 0 0 18px;
        padding: 0;
        display: flex;
        list-style-type: none;
        @media screen and (max-width: 767px) {
            width: 100%;
            justify-content: center;
        }
        &-item {
            margin: 9px 18px 9px 0;
            padding: 0;
        }
        &-link {
            width: 24px;
            height: 24px;
            display: block;
            text-align: center;
            transition: all .4s;
            transition-property: opacity;
            position: relative;
            &:hover {
                text-decoration: none;
                @media screen and (min-width: 1200px) {
                    opacity: .5;
                }
            }
            &:before {
                content: '';
                position: absolute;
                top: -5px;
                right: -5px;
                bottom: -5px;
                left: -5px;
            }
        }
        &-icon {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
        }
    }
}

.doc-el {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.25;
    color: $gray-1;
    transition: all .4s;
    transition-property: color;
    &:hover {
        @media screen and (min-width: 1200px) {
            color: $blue;
        }
    }
    &__inner {
        padding-left: 32px;
        position: relative;
    }
    &__icon {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
    }
    &__link {
        display: block;
        color: $blue;
        &:hover {
            text-decoration: none;
        }
        &:before {
            content: '';
            position: absolute;
            top: -8px;
            right: -6px;
            bottom: -8px;
            left: -6px;
            border-radius: 6px;
            transition: all .4s;
            transition-property: background;
        }
    }
    &:hover &__link:before {
        @media screen and (min-width: 1200px) {
            background: rgba($gray, .08);
        }
    }
    &__description {
        margin: 2px 0 0;
        font-size: 14px;
        line-height: 1.6;
    }
}

.login-social {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    list-style-type: none;
    &__item {
        margin: 8px;
        padding: 0;
    }
    &__link {
        width: 56px;
        height: 56px;
        display: block;
        font-size: 0;
        line-height: 56px;
        text-align: center;
        border: 1px solid $gray-light;
        border-radius: 50%;
        transition: all .4s;
        transition-property: background;
        position: relative;
        &:hover {
            text-decoration: none;
            @media screen and (min-width: 1200px) {
                background: rgba($gray-light, .5);
            }
        }
        &:before {
            content: '';
            position: absolute;
            top: -5px;
            right: -5px;
            bottom: -5px;
            left: -5px;
        }
    }
    &__icon {
        width: 26px;
        height: 26px;
        display: inline-block;
        object-fit: contain;
        vertical-align: middle;
    }
}

.login-el {
    &__reset {
        @media screen and (max-width: 480px) {
            padding-top: 16px;
        }
    }
    &__header {
        display: block;
    }
    &__title {
        margin: 0 0 6px;
        font-weight: 600;
        font-size: 30px;
        line-height: 1.1;
        text-align: center;
    }
    &__subtitle {
        margin: 0 0 32px;
        font-size: 14px;
        line-height: 1.42;
        color: $gray;
        text-align: center;
        @media screen and (max-width: 480px) {
            margin-bottom: 24px;
        }
        a {
            color: $blue;
            @media screen and (min-width: 1200px) {
                box-shadow: 0 1px 0 transparent;
                transition: all .4s;
                transition-property: box-shadow;
            }
            &:hover {
                @media screen and (min-width: 1200px) {
                    box-shadow: 0 1px 0 currentColor;
                }
            }
        }
        strong {
            font-weight: 500;
            color: $darknes;
        }
    }
    &__reset-text {
        max-width: 360px;
        margin: 30px auto 40px;
        font-size: 14px;
        line-height: 1.42;
        color: $gray;
        text-align: center;
        @media screen and (max-width: 480px) {
            margin-bottom: 30px;
        }
        strong {
            font-weight: 500;
            color: $darknes;
        }
    }
    &__form {
        &-line {
            margin-bottom: 20px;
        }
        &-btn {
            width: 100%;
            margin: 20px 0 44px;
        }
        &-another {
            padding-top: 40px;
            text-align: center;
            border-top: 1px solid $gray-light;
            font-size: 14px;
            @media screen and (max-width: 480px) {
                padding-top: 32px;
            }
            a {
                color: $blue;
                @media screen and (min-width: 1200px) {
                    box-shadow: 0 1px 0 transparent;
                    transition: all .4s;
                    transition-property: box-shadow;
                }
                &:hover {
                    @media screen and (min-width: 1200px) {
                        box-shadow: 0 1px 0 currentColor;
                    }
                }
            }
        }
    }
    &__social {
        &-title {
            margin: 0 0 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            color: $gray;
            text-align: center;
            &:before,
            &:after {
                background: $gray-light;
                height: 1px;
                content: '';
                flex-grow: 1;
            }
            span {
                margin: 0 8px;
                display: block;
                flex-shrink: 0;
                white-space: nowrap;
            }
        }
        &-list {
            margin-bottom: -8px;
        }
    }
}

@media screen and (max-width: 1169px) {
    .user-profile {
        &--is-visible {
            display: flex !important;
        }
        &--is-active &__icon--arrow {
            display: block;
        }
        &--is-toggle &__icon--arrow {
            transform: scaleY(-1);
        }
        &--is-active &__icon--active {
            display: none;
        }
    }
    .widgets-item {
        &__title {
            margin-bottom: 20px;
        }
    }
    .scroll-up {
        padding: 12px;
        &--position-right-bottom {
            right: 24px;
            bottom: 24px;
        }
        &__icon {
            margin-right: 0;
        }
        &__title {
            display: none;
        }
    }
}

@media screen and (max-width: 767px) {
    .link-add {
        &__inner {
            flex-wrap: wrap;
            position: relative;
        }
        &__name {
            max-width: calc(100% - 36px);
            flex: 0 0 calc(100% - 36px);
            order: 1;
        }
        &__url {
            max-width: calc(100% - 36px);
            margin-top: 12px;
            flex: 0 0 calc(100% - 36px);
            order: 3;
        }
        &__remove {
            order: 2;
        }
    }
    .widgets-item {
    }
    .group-btns {
        margin: -10px;
        flex-wrap: wrap;
        &__item {
            margin: 10px;
        }
    }
}