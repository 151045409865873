.p-error {
    padding: 32px 0;
    text-align: center;
    color: $darknes;
    overflow: clip;
    &__container {
    }
    &__inner {
        display: inline-block;
        text-align: center;
        vertical-align: top;
    }
    &__title-wrapper {
    }
    &__title {
        margin: 0 0 35px;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        font-size: 148px;
        line-height: 1;
        color: #D1D5DB;
        animation: .5s linear 2s normal forwards running errorTextColor;
        user-select: none;
        pointer-events: none;
        @media screen and (max-width: 767px) {
            margin-bottom: 5px;
            font-size: 100px;
        }
        span {
            width: 1.01351351351em;
            height: .72972972973em;
            margin: 0 .10135135135em;
            display: block;
            font-size: inherit;
            color: #D1D5DB;
            border: .1554054054em solid currentColor;
            border-radius: .36486486486em;
            animation: .5s linear 2s normal forwards running errorDotColor;
            position: relative;
            &:before {
                background: currentColor;
                width: .24324324324em;
                height: .24324324324em;
                margin-top: -.12162162162em;
                content: '';
                display: block;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: .1081081081em;
                animation: 1s ease-in 1s normal forwards running errorDotSlide;
            }
        }
    }
    &__content {
        max-width: 400px;
        display: inline-block;
        vertical-align: top;
        &-subtitle {
            margin: 0 0 8px;
            font-weight: 600;
            font-size: 24px;
        }
        &-text {
            margin: 0 0 20px;
            font-size: 14px;
        }
        &-prev-link {
        }
    }
}

@keyframes errorTextColor {
    0% {
    }
    100% {
        color: $darknes;
    }
}

@keyframes errorDotColor {
    0% {
    }
    100% {
        color: $green3;
    }
}

@keyframes errorDotSlide {
    0% {
    }
    100% {
        transform: translateX(.24324324324em);
    }
}