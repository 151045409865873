.header-nav {
    &__list {
        margin: 0;
        padding: 0;
        display: flex;
        list-style-type: none;
    }
    &__item {
        margin: 0;
        padding: 0;

        &:not(:last-child) {
            margin-right: 40px;
        }
    }
    &__link {
        font-size: 14px;
        line-height: 1.35;
        display: block;
        color: $dark-blue;
        position: relative;
        transition: all .4s;
        transition-property: color;
        &:hover {
            color: $green3;
            text-decoration: none;
        }
        &:focus {
            color: $dark-blue;
        }
        &:before {
            content: '';
            position: absolute;
            top: -10px;
            right: -10px;
            bottom: -10px;
            left: -10px;
        }
    }
}

.header-search {
    display: block;
    position: relative;
    overflow: hidden;
    &__input {
        padding-right: 44px;
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            -webkit-appearance:none;
        }
    }
    &__close {
        height: 100%;
        width: 44px;
        padding: 0 10px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
    }
}

.header-menu-mobile {
    font-size: 14px;
    line-height: 1.35;
    color: $dark-blue;
    &__inner {
        min-height: 100%;
        padding: 16px;
        display: flex;
        flex-direction: column;
    }
    &__nav-list {
    }
    &__nav {
        margin: 0 -16px;
        padding: 0;
        list-style-type: none;
    }
    &__nav + &__nav:before,
    &__nav:first-child:not(:last-child):before,
    &__nav:last-child:not(:first-child):after {
        margin: 5px 0;
        content: '';
        display: block;
        border-top: 1px solid $gray-light;
    }
    &__nav-item {
        margin: 0;
        padding: 0;
        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }
    &__nav-link {
        min-height: 48px;
        padding: 10px 16px;
        display: flex;
        align-items: center;
        color: $dark-blue;
        &--accent {
            font-weight: 500;
            color: $green3;
        }
    }
    &__nav-link-plus {
        width: 20px;
        height: 20px;
        flex: 0 0 20px;
        margin-left: 4px;
    }
    &__btn-list {
        margin-top: 22px;
    }
    &__btn {
        width: 100%;
        display: block;
        &:not(:last-child) {
            margin-bottom: 12px;
        }
        &--register {
        }
        &--login {
        }
    }
    &__user-list {
        margin: -4px -12px 7px;
    }
    &__user-list-item {
        display: none;
        &.user-profile--is-active {
            display: flex;
        }
    }
    &__footer {
        margin-top: auto;
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 12px;
        &-link {
            margin: 12px 0 0;
            color: $gray !important;
        }
        &-copyright {
            margin: 12px 0 0;
            color: #9CA3AF;
        }
    }
}

.header {
    background: #fff;
    height: 60px;
    color: $dark-blue;
    box-shadow: 0 1px 3px rgba(0,0,0,.1),
                0 1px 2px -1px rgba(0,0,0,.1);
    position: relative;
    &__container {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__toggle {
        background: transparent;
        width: 24px;
        height: 24px;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        border: none;
        outline: none;
        position: relative;
        flex-wrap: wrap;
        &:before {
            content: '';
            position: absolute;
            inset: -5px;
        }
    }
    &__toggle-line {
        background: $darknes;
        width: 18px;
        height: 2px;
        border-radius: 1px;
        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }
    &__logo {
        margin: 2px 32px 0 0;
        display: flex;
        align-items: flex-start;
        flex-shrink: 0;
    }
    &__logo-link {
        width: 100px;
        display: block;
        transition: all .4s;
        transition-property: opacity;
        &:hover {
            text-decoration: none;
            opacity: .6;
        }
        &--russia {
            width: 108px;
        }
        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
    &__logo-beta {
        background: #F1F5F9;
        margin: -4px 0 0 6px;
        padding: 2px 6px;
        font-size: 10px;
        color: $gray;
        line-height: 1;
        text-transform: uppercase;
        border-radius: 4px;
        transition: all .4s;
        transition-property: opacity;
        &:hover {
            text-decoration: none;
            opacity: .6;
        }
    }
    &__menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        position: relative;
    }
    &__nav {
        margin-right: 16px;
    }
    &__btn-search {
        margin-left: auto;
        flex: 0 0 24px;
    }
    &__search {
        margin-top: -22px;
        position: absolute;
        top: 50%;
        right: -4px;
        left: 0;
        transition: all .24s;
        transition-property: visibility, opacity;
        &:not(.is-show) {
            opacity: 0;
            visibility: hidden;
        }
    }
    &__btn {
        &-login-mobile {
            display: none;
        }
        &-register {
            margin-left: 16px;
            flex-shrink: 0;
        }
        &-login {
            margin-left: 8px;
            flex-shrink: 0;
        }
        &-notification {
            margin-left: 24px;
        }
        &-create-project {
            margin-left: 24px;
        }
    }
    &__menu-mobile {
        background: #fff;
        min-width: 256px;
        display: none;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        overflow-y: auto;
        transition: all .4s;
        transition-property: transform, visibility, opacity;
        z-index: 1001;
    }
    &__overlay-mobile {
        background: rgba($darknes, .5);
        width: 100%;
        display: none;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;
        transition: all .4s;
        transition-property: visibility, opacity;
    }
    &__profile {
        margin-left: 24px;
        flex-shrink: 0;
        position: relative;
        &-header {
            height: 60px;
            display: flex;
            align-items: center;
        }
        &-avatar {
            width: 32px;
            height: 32px;
            display: block;
            border-radius: 50%;
            cursor: pointer;
            position: relative;
            z-index: 3;
            transition: all .4s;
            transition-property: opacity;
            &:hover {
                opacity: .5;
            }
        }
        &-dropdown {
            min-width: 256px;
            margin-top: 1px;
            position: absolute;
            right: 0;
            top: 100%;
            z-index: 2;
        }
    }
}

.header-middle {
    position: relative;
    width: 100%;
    height: 240px;
    background-color: $gray-light;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &-edit {
        position: absolute;
        right: 24px;
        bottom: 16px;
        width: 32px;
        border-radius: 8px;
        height: 32px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        background-color: $dark-blue;
        transition: .3s;
        opacity: 0;
        cursor: pointer;

        img {
            width: initial;
            height: initial;
            object-fit: unset;
        }
    }
}

.header-bottom {
    margin-bottom: 32px;

    &-wrap {
        width: 100%;
        padding-top: 16px;
        display: flex;
        justify-content: space-between;
    }
}

.header-info {
    position: relative;

    &-photo {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 132px;
        height: 132px;
        border-radius: 50%;
        border: 2px solid $white;
        overflow: hidden;
        background-color: $gray;
        filter: none !important;
        cursor: pointer;

        img {
            width: 100%;
        }
    }

    &-block {
        margin-left: 140px;
    }

    &-name {
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        color: $darknes;            
    }

    &-subs {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #9CA3AF;
        cursor: pointer;
    }
}

.header-subscribe {
    padding: 6px 24px;
    height: min-content;
}

@media screen and (max-width: 1169px) {
    .header {
        height: 48px;
        &__container {
        }
        &__toggle {
            display: flex;
        }
        &__logo {
            width: 134px;
            margin: 2px 0 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            will-change: transform;
            z-index: 1;
        }
        &__logo-link {
            width: 90px;
        }
        &__logo-beta {
        }
        &__menu {
            margin-left: 16px;
        }
        &__nav {
            display: none;
        }
        &__btn {
            &-login-mobile {
                margin-left: 12px;
                display: block;
            }
            &-register {
                display: none;
            }
            &-login {
                display: none;
            }
            &-notification {
                margin-left: 8px;
            }
            &-create-project {
                display: none;
            }
        }
        &__search {
            z-index: 2;
        }
        &__menu-mobile {
            display: block;
            &:not(.is-show) {
                pointer-events: none;
                transform: translateX(-100%);
                opacity: 0;
                visibility: hidden;
            }
        }
        &__overlay-mobile {
            display: block;
            &:not(.is-show) {
                pointer-events: none;
                opacity: 0;
                visibility: hidden;
            }
        }
        &__profile {
            margin-left: 0;
            order: -1;
            &-header {
                height: 48px;
            }
            &-avatar {
                width: 28px;
                height: 28px;
            }
            &-dropdown {
                display: none !important;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .header {
        &__container {
        }
        &__logo {
        }
        &__menu {
        }
        &__nav {
        }
        &__btn-search {
        }
        &__btn-register {
        }
        &__btn-login {
        }
    }

    .header-subscribe {
        padding: 6px 24px;
        height: min-content;
    }

    .header-middle {
        height: 144px;
        &-edit {
            right: 16px;
        }
    }

    .header-bottom {
        &-wrap {
            padding-top: 10px;
            align-items: center;
        }
    }

    .header-info {

        &-block {
            margin-left: 124px;
        }

        &-photo {
            width: 112px;
            height: 112px;
        }

        &-name {
            font-size: 20px;
            line-height: 28px;
        }
    }
}

@media screen and (max-width: 767px) {
    .header-info {

        &-block {
            margin-left: 0;
        }

        &-photo {
            bottom: auto;
            top: -66px;
        }

        &-name {
            padding-top: 50px;
        }
    }

    .header-middle {
        &-edit {
            opacity: 1;
        }
    }

    .header-bottom {
        &-wrap {
            align-items: flex-start;
        } 
    }
}

@media screen and (max-width: 480px) {

    .header-middle {
        height: 128px;
    }

    .header-info {

        &-photo {
            top: -48px;
            width: 80px;
            height: 80px;
        }

        &-name {
            padding-top: 40px;
            font-size: 20px;
            line-height: 28px;
        }
    }

    .header-subscribe {
        padding: 4px 12px;
    }
}