.loader {
 position: fixed;
 left: 50%;
 top: 50%;
 z-index: 1;
 width: 120px;
 height: 120px;
 margin: -76px 0 0 -76px;
 border: 16px solid #f3f3f3;
 border-radius: 50%;
 border-top: 16px solid #3498db;
 -webkit-animation: spin 2s linear infinite;
 animation: spin 2s linear infinite;
}

@keyframes spin {
 0% { transform: rotate(0deg); }
 100% { transform: rotate(360deg); }
} 