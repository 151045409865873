.faq-nav {
    background: $blue-light;
    line-height: 1.75;
    color: #4B5563;
    border-radius: 4px;
    &__inner {
        padding: 24px;
    }
    &__item {
        & + & {
            border-top: 1px solid $gray-light;
        }
    }
    &__btn {
        width: 100%;
        margin: 0;
        padding: 20px 0;
        display: block;
        text-align: left;
        cursor: pointer;
        position: relative;
        @extend .btn-reset;
        &:after {
            background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.50503 7.42299C4.77839 7.14963 5.22161 7.14963 5.49497 7.42299L9.99992 11.9279L14.5049 7.42299C14.7782 7.14963 15.2214 7.14963 15.4948 7.42299C15.7672 7.69636 15.7672 8.13958 15.4948 8.41294L10.4949 13.4129C10.2215 13.6862 9.77831 13.6862 9.50494 13.4129L4.50503 8.41294C4.23166 8.13958 4.23166 7.69636 4.50503 7.42299Z'/%3E%3C/svg%3E");
            background-size: contain;
            width: 20px;
            height: 20px;
            margin-top: -10px;
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            transition: all .4s;
            transition-property: transform;
        }
    }
    &__item.is-show &__btn:after {
        transform: rotate(-180deg);
    }
    &__title {
        margin: 0;
        padding-right: 28px;
        font-weight: normal;
        font-size: 16px;
    }
    &__list {
        margin: 0 0 20px;
        font-size: 16px;
        color: $blue;
    }
    &__item:not(.is-show) &__list {
        display: none;
    }
    &__footer {
        padding: 24px 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid $gray-light;
    }
    &__content {
        padding-right: 45px;
        display: flex;
        align-items: flex-start;
        font-size: 14px;
    }
    &__content-icon {
        width: 20px;
        height: 20px;
        margin-top: .15em;
        margin-right: 8px;
        object-fit: contain;
        flex: 0 0 20px;
        color: $gray-1;
    }
    &__content-text {
    }
    &__content-btn {
        flex-shrink: 0;
    }
}

.p-faq {
    padding: 32px 0;
    overflow: clip;
    &__container {
    }
    &__title {
        margin: 0 0 32px;
        @extend .title-3;
    }
    &__header {
        margin-bottom: 60px;
    }
    &__content-list {
    }
    &__content-item {
        padding: 48px 0;
        font-size: 16px;
        color: $darknes;
        position: relative;
        z-index: 0;
        &:not(:last-child) {
            border-bottom: 1px solid $gray-light;
        }
        &:before {
            background: $blue-light;
            content: '';
            position: absolute;
            top: 0;
            right: -24px;
            bottom: 0;
            left: -24px;
            border-radius: 4px;
            transition: all .3s;
            transition-property: opacity, visibility;
            transition-delay: .2s;
            z-index: -1;
        }
        &:not(.is-flash):before {
            opacity: 0;
            visibility: hidden;
        }
    }
}

@media screen and (max-width: 1169px) {
    .faq-nav {
        &__content {
            padding-right: 24px;
        }
    }
    .p-faq {
        padding: 24px 0;
    }
}

@media screen and (max-width: 1000px) {
}

@media screen and (max-width: 767px) {
    .faq-nav {
        &__inner {
            padding-right: 16px;
            padding-left: 16px;
        }
        &__footer {
            padding-top: 16px;
            flex-direction: column;
            align-items: flex-start;
        }
        &__content {
            width: 100%;
            max-width: 100%;
            margin-bottom: 16px;
            padding-right: 0;
        }
        &__content-icon {
            display: none;
        }
    }
    .p-faq {
        &__container {
            width: initial;
            max-width: initial;
        }
        &__title {
            margin-bottom: 16px;
            font-size: 20px;
        }
        &__header {
            margin: 0 -16px;
            border-radius: 0;
        }
        &__content-item {
            margin: 0 -16px;
            padding: 32px 16px;
            h2 {
                margin-bottom: 1.25em;
                font-size: 1.25em;
            }
            h3 {
                margin: 1.25em 0 .5em;
                font-size: 1em;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .faq-nav {
        &__content-btn {
            width: 100%;
        }
    }
}