.popup-avatar {
    position: absolute;
    top: -120px;
    right: -20px;
    z-index: 5;
    display: none;

    &.active {
        display: flex;
    }

    &-wrap {
        position: relative;
        width: 208px;
        height: 280px;
        padding: 4px;
        border-radius: 8px;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
        background-color: $white;

        &::after {
            content: '';
            position: absolute;
            top: 95px;
            left: -10px;
            width: 20px;
            height: 20px;
            background-color: $white;
            transform: rotate(45deg);
            z-index: -1;
            box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
        }
    }

    &-header {
        display: none;
    }

    &-close {

        &-desctop {
            position: absolute;
            top: 4px;
            right: 4px;
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            z-index: 10;
            cursor: pointer;
        }         
    }

    &-img {
        width: 100%;
        height: 228px;
        border-radius: 8px;
        overflow: hidden;
        img {
            width: 100%;
        }
    }

    &-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
    }

    &-input {
        position: relative;

        input {
            width: 0.01px;
            height: 0;
            opacity: 0;
            visibility: hidden;
        }

        label {
            position: absolute;
            top: 0;
            left: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #2563EB;
            cursor: pointer;
        }
    }

    &-btn {
        border: none;
        background-color: transparent;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #2563EB;
        cursor: pointer;
    }
}

@media screen and (max-width: 1000px) {
    .popup-avatar {
        top: -115px;
        right: -50px;
    }
}

@media screen and (max-width: 1000px) {
    .popup-avatar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        right: auto;

        &-wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            width: 100%;
            height: 100%;
            border: none;
            padding: 0;
        }

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 12px 16px;
            border-bottom: 1px solid $gray-light;
            background-color: #fff;
            z-index: 5;
        }

        &-ttl {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $dark-blue;
        }

        &-close {
            &-desctop {
                display: none;
            }
        }

        &-img {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            height: auto;
            background-color: $gray-light;
            padding: 16px;

            img {
                width: initial;
                max-width: 100%;
                height: initial;
                max-height: 100%;
            }
        }
    }
}