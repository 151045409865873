.login-form {
  @apply bg-white sm:shadow lg:login-width  md:w-1/2 w-full p-5 sm:p-8 mt-10;
}


.or-text {
 height: 1px;
 @apply w-full bg-gray-300 mt-3;
}


.login-text-p {
  @apply focus:outline-none focus:underline hover:underline text-sm font-medium text-blue-500 cursor-pointer;
}

.login-input {
  @apply border rounded-lg  text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:border-transparent;
}

.apply-test {
  @apply focus:ring-2 rounded-lg focus:ring-offset-2 focus:ring-indigo-700 text-sm font-semibold leading-none text-white focus:outline-none bg-rose-600 border hover:bg-indigo-600 py-4 w-full;
}

.login-form-container {
  height: 100%;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (max-width: 320px) { 
  .login-form-container {
    height: 100%;
    width: 100%;
    --tw-bg-opacity: 1;
    background-color: white;
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }
  
}


.form-button {
  @apply focus:ring-2 rounded-lg focus:ring-offset-2 focus:ring-indigo-700 text-sm font-semibold leading-none text-white focus:outline-none border hover:bg-green-700 py-4 w-full;
}

.login-form-row {
  @apply flex flex-col items-center justify-center;
}


.form-p-sign {
  @apply focus:outline-none leading-6 text-3xl font-semibold text-center text-gray-900;
}

.form-p-login {
   @apply focus:outline-none text-gray-500 text-sm mt-4 font-normal text-center leading-none;
}

.form-label {
   @apply text-sm font-normal leading-none text-gray-800;
}

.form-social-container {
  @apply flex flex-row justify-center items-center space-x-3;
}

.form-social-icon {
  @apply w-11 h-11 ml-3 items-center justify-center inline-flex rounded-full font-bold text-lg  text-white hover:shadow-lg cursor-pointer transition ease-in duration-300;
}

.eye-logn-pass {
  @apply absolute right-0 mt-2 mr-3 cursor-pointer;
}

