@keyframes notyf-fadeinup{
    0%{
      opacity: 0;
      transform: translateY(25%);
    }
    100%{
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes notyf-fadeinleft{
    0%{
      opacity: 0;
      transform: translateX(25%);
    }
    100%{
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes notyf-fadeoutright{
    0%{
      opacity: 1;
      transform: translateX(0);
    }
    100%{
      opacity: 0;
      transform: translateX(25%);
    }
  }
  
  @keyframes notyf-fadeoutdown{
    0%{
      opacity: 1;
      transform: translateY(0);
    }
    100%{
      opacity: 0;
      transform: translateY(25%);
    }
  }
  
  @keyframes ripple {
    0% {
      transform: scale(0) translateY(-45%) translateX(13%);
    }
  
    100% {
      transform: scale(1) translateY(-45%) translateX(13%);
    }
  }
  
  $toast-padding: 15px;
  
  .notyf {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: #ffffff;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    pointer-events: none;
    box-sizing: border-box;
    padding: 20px;
  
    &__icon {
      &--error, &--success {
        height: 21px;
        width: 21px;
        background: white;
        border-radius: 50%;
        display: block;
        margin: 0 auto;
        position: relative;
      }
  
      &--error {
        &:after, &:before{
          content: "";
          background: currentColor;
          display: block;
          position: absolute;
          width: 3px;
          border-radius: 3px;
          left: 9px;
          height: 12px;
          top: 5px;
        }
    
        &:after{
          transform: rotate(-45deg);
        }
  
        &:before{
          transform: rotate(45deg);
        }
      }
  
      &--success {
        &:after, &:before{
          content: "";
          background: currentColor;
          display: block;
          position: absolute;
          width: 3px;
          border-radius: 3px;
        }
        &:after{
          height: 6px;
          transform: rotate(-45deg);
          top: 9px;
          left: 6px;
        }
        &:before{
          height: 11px;
          transform: rotate(45deg);
          top: 5px;
          left: 10px;
        }
      }
    }
  
    &__toast {
      display: block;
      overflow: hidden;
      pointer-events: auto;
      animation: notyf-fadeinup 0.3s ease-in forwards;
      box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.25);
      position: relative;
      padding: 0 $toast-padding;
      border-radius: 2px;
      max-width: 300px;
      transform: translateY(25%);
      box-sizing: border-box;
      flex-shrink: 0;
  
      &--disappear{
        transform: translateY(0);
        animation: notyf-fadeoutdown 0.3s forwards;
        animation-delay: 0.25s;
  
        .notyf {
          &__message, &__icon {
            animation: notyf-fadeoutdown 0.3s forwards;
            opacity: 1;
            transform: translateY(0);
          }
  
          &__dismiss {
            animation: notyf-fadeoutright 0.3s forwards;
            opacity: 1;
            transform: translateX(0);
          }
        }
  
        .notyf__message{
          animation-delay: 0.05s;
        }
      }
  
      &--upper {
        margin-bottom: 20px;
      }
  
      &--lower {
        margin-top: 20px;
      }
  
      &--dismissible {
  
        .notyf__wrapper {
          padding-right: 30px;
        }
      }
    }
  
    &__ripple {
      height: 400px;
      width: 400px;
      position: absolute;
      transform-origin: bottom right;
      right: 0;
      top: 0;
      border-radius: 50%;
      transform: scale(0) translateY(-51%) translateX(13%);
      z-index: 5;
      animation: ripple 0.4s ease-out forwards;
    }
  
    &__wrapper {
      display: flex;
      align-items: center;
      padding-top: 17px;
      padding-bottom: 17px;
      padding-right: 15px;
      border-radius: 3px;
      position: relative;
      z-index: 10;
    }
  
    &__icon {
      width: 22px;
      text-align: center;
      font-size: 1.3em;
      opacity: 0;
      animation: notyf-fadeinup 0.3s forwards;
      animation-delay: 0.3s;
      margin-right: 13px;
    }
  
    &__dismiss {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 26px;
      margin-right: -$toast-padding;
      animation: notyf-fadeinleft 0.3s forwards;
      animation-delay: 0.35s;
      opacity: 0;
    }
  
    &__dismiss-btn {
      background-color: rgba(#000, 0.25);
      border: none;
      cursor: pointer;
      transition: opacity 0.2s ease, background-color 0.2s ease;
      outline: none;
      opacity: 0.35;
      height: 100%;
      width: 100%;
  
      &:after, &:before {
        content: "";
        background: white;
        height: 12px;
        width: 2px;
        border-radius: 3px;
        position: absolute;
        left: calc(50% - 1px);
        top: calc(50% - 5px);
      }
  
      &:after {
        transform: rotate(-45deg);
      }
  
      &:before {
        transform: rotate(45deg);
      }
      
      &:hover {
        opacity: 0.7;
        background-color: rgba(#000, 0.15);
      }
  
      &:active {
        opacity: 0.8;
      }
    }
  
    &__message {
      vertical-align: middle;
      position: relative;
      opacity: 0;
      animation: notyf-fadeinup 0.3s forwards;
      animation-delay: 0.25s;
      line-height: 1.5em;
    }
  }
  
  /* Small screens */
  @media only screen and (max-width: 480px){
    .notyf {
      padding: 0;
  
      &__ripple {
        height: 600px;
        width: 600px;
        animation-duration: 0.5s;
      }
  
      &__toast {
        max-width: initial;
        border-radius: 0;
        box-shadow: 0px -2px 7px 0px rgba(0, 0, 0, 0.13);
        width: 100%;
      }
  
      &__dismiss {
        width: 56px;
      }
    }
  }