* {
  box-sizing: border-box;
  outline: none !important;
}

html {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Inter", arial, sans-serif;
  font-smooth: antialiased;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-display: swap;
  margin: 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

.popup-avatar-img .cropper-face,
.popup-avatar-img .cropper-view-box {
  border-radius: 50%;
}

ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

a,
a:visited {
  text-decoration: none;
}

img {
  display: block;
}

.container {
  width: 100%;
  max-width: 1072px;
  padding: 0 16px;
  margin: 0 auto;
}

@media screen and (min-width: 769px) {
  a:hover {
    text-decoration: none;
    outline: 0;
  }

  .header-middle:hover .header-middle-edit {
    opacity: 1;
  }
}

.header-top {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  padding: 13px 24px;
  position: relative;
}

.header-top-left,
.header-top-right {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.header-logo {
  width: 122px;
  height: 28px;
  margin-right: 40px;
}

.header-logo img {
  width: 100%;
}

.header-about {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #374151;
  text-decoration: none;
}

.header-new {
  padding: 4px 16px;
  margin-right: 24px;
}

.header-alerts {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 24px;
  cursor: pointer;
}

.header-alerts.header-alerts-mobile {
  display: none;
}

.header-profile {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background-color: #e5e7eb;
}

.header-profile img {
  width: 100%;
}

.header-middle {
  position: relative;
  width: 100%;
  height: 240px;
  background-color: #e5e7eb;
}

.header-middle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-middle-edit {
  position: absolute;
  right: 24px;
  bottom: 16px;
  width: 32px;
  border-radius: 8px;
  height: 32px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #374151;
  transition: 0.3s;
  opacity: 0;
  cursor: pointer;
}

.header-middle-edit img {
  width: initial;
  height: initial;
  object-fit: unset;
}

.header-bottom {
  margin-bottom: 32px;
}

.header-bottom-wrap {
  width: 100%;
  padding-top: 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header-info {
  position: relative;
}

.header-info-photo {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  overflow: hidden;
  background-color: #6b7280;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
}

.header-info-photo img {
  width: 100%;
}

.header-info-block {
  margin-left: 140px;
}

.header-info-name {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #111827;
}

.header-info-subs {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9CA3AF;
  cursor: pointer;
}

.header-subscribe {
  padding: 6px 24px;
  height: -webkit-min-content;
  height: min-content;
}

@media screen and (max-width: 1000px) {

  .header-about,
  .header-new,
  .header-alerts {
    display: none;
  }

  .header-alerts.header-alerts-mobile {
    display: -ms-flexbox;
    display: flex;
    margin-right: 0;
  }

  .header-top {
    padding: 8px 16px;
  }

  .header-top-right {
    -ms-flex-order: -1;
    order: -1;
  }

  .header-profile {
    width: 28px;
    height: 28px;
  }

  .header-logo {
    width: 104.6px;
    height: 24px;
    margin-right: 0;
  }

  .header-middle {
    height: 144px;
  }

  .header-middle-edit {
    right: 16px;
  }

  .header-bottom-wrap {
    padding-top: 10px;
    -ms-flex-align: center;
    align-items: center;
  }

  .header-info-block {
    margin-left: 124px;
  }

  .header-info-photo {
    width: 112px;
    height: 112px;
  }

  .header-info-name {
    font-size: 20px;
    line-height: 28px;
  }
}

@media screen and (max-width: 768px) {
  .header-info-block {
    margin-left: 0;
  }

  .header-info-photo {
    bottom: auto;
    top: -66px;
  }

  .header-info-name {
    padding-top: 50px;
  }

  .header-middle-edit {
    opacity: 1;
  }

  .header-bottom-wrap {
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

@media screen and (max-width: 480px) {
  .header-middle {
    height: 128px;
  }

  .header-info-photo {
    top: -48px;
    width: 80px;
    height: 80px;
  }

  .header-info-name {
    padding-top: 40px;
    font-size: 20px;
    line-height: 28px;
  }

  .header-subscribe {
    padding: 4px 12px;
  }
}

.footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 12px 24px;
  border-top: 1px solid #e5e7eb;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b7280;
}

.footer-block {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.footer-divider {
  width: 1px;
  height: 16px;
  background-color: #e5e7eb;
  margin: 0 24px;
}

.footer-copyrights {
  color: #9CA3AF;
}

.footer-about,
.footer-docs {
  color: #6b7280;
}

@media screen and (max-width: 1000px) {
  .footer {
    display: none;
  }
}

.popup-profile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 5;
  padding: 62px 24px 0 0;
  display: none;
}

.popup-profile-wrap {
  width: 265px;
  margin-left: auto;
  background: #FFFFFF;
  overflow: auto;
  border-radius: 8px;
  padding: 4px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.popup-profile-section {
  position: relative;
}

.popup-profile-section::after {
  position: absolute;
  left: -4px;
  bottom: 0;
  content: "";
  width: calc(100% + 8px);
  height: 1px;
  background-color: #e5e7eb;
}

.popup-profile-section:nth-of-type(3)::after {
  display: none;
}

.popup-profile-section-bottom {
  display: none;
}

.popup-profile-user {
  display: -ms-flexbox;
  display: flex;
  padding: 8px 14px;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.popup-profile-user-img {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  min-width: 32px;
  border-radius: 50%;
  overflow: hidden;
}

.popup-profile-user-img img {
  width: 100%;
}

.popup-profile-user-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #111827;
}

.popup-profile-user-email {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8A9199;
}

.popup-profile-link,
.popup-profile-copyrights,
.popup-profile-docs,
.popup-profile-lang {
  display: block;
  padding: 6px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #111827;
  transition: 0.3s;
}

.popup-profile-link:hover,
.popup-profile-copyrights:hover,
.popup-profile-docs:hover,
.popup-profile-lang:hover {
  background-color: #F3F4F6;
}

.popup-profile-link {
  border-radius: 4px;
  cursor: pointer;
}

.popup-profile-link.mobile {
  display: none;
}

.popup-profile-link.green {
  color: #166534;
}

@media screen and (max-width: 1000px) {
  .popup-profile {
    position: fixed;
    padding: 0;
    background-color: rgba(17, 24, 39, 0.5);
  }

  .popup-profile-wrap {
    margin-left: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: inherit;
    border-radius: 0;
    width: 320px;
  }

  .popup-profile-section {
    padding: 6px 0;
  }

  .popup-profile-section:nth-of-type(3)::after {
    display: block;
  }

  .popup-profile-section:nth-of-type(4)::after {
    display: none;
  }

  .popup-profile-section-bottom {
    -ms-flex: 1;
    flex: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .popup-profile-link.mobile {
    display: block;
  }

  .popup-profile-docs {
    color: #6b7280;
    font-size: 12px;
    line-height: 16px;
  }

  .popup-profile-copyrights {
    font-size: 12px;
    line-height: 16px;
    color: #9CA3AF;
  }
}

@media screen and (max-width: 480px) {
  .popup-profile-wrap {
    width: 256px;
  }
}

.popup-subs {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(17, 24, 39, 0.5);
  z-index: 5;
  display: none;
}

.popup-subs.active {
  display: -ms-flexbox;
  display: flex;
}

.popup-subs-wrap {
  position: relative;
  width: 100%;
  max-width: 576px;
  height: 75%;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: auto;
  scrollbar-color: #D1D5DB transparent;
  scrollbar-width: thin;
}

.popup-subs-wrap::-webkit-scrollbar {
  width: 4px;
}

.popup-subs-wrap::-webkit-scrollbar-track {
  border: solid 6px transparent;
}

.popup-subs-wrap::-webkit-scrollbar-thumb {
  background-color: #D1D5DB;
  border-radius: 18px;
  border: solid 6px transparent;
}

.popup-subs-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 20px 24px;
  border-bottom: 1px solid #e5e7eb;
  background-color: #fff;
  z-index: 5;
}

.popup-subs-back {
  display: none;
}

.popup-subs-count {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #374151;
}

.popup-subs-close {
  width: 28px;
  height: 28px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
}

.popup-subs-list {
  padding: 24px;
}

.popup-subs-user {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}

.popup-subs-user:last-of-type {
  margin-bottom: 0;
}

.popup-subs-user-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.popup-subs-user-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.popup-subs-user-photo img {
  width: 100%;
}

.popup-subs-user-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #374151;
  margin-left: 16px;
}

.popup-subs-user-btn {
  width: 124px;
  padding: 4px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  margin-left: auto;
}

@media screen and (max-width: 1000px) {
  .popup-subs-wrap {
    height: 85%;
  }
}

@media screen and (max-width: 480px) {
  .popup-subs {
    display: -ms-flexbox;
    display: flex;
    transition: 0.3s;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  .popup-subs.active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .popup-subs-wrap {
    height: 100%;
    border-radius: 0;
  }

  .popup-subs-header {
    padding: 0;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .popup-subs-back {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 52px;
    height: 56px;
  }

  .popup-subs-close {
    display: none;
  }

  .popup-subs-list {
    padding: 16px;
  }

  .popup-subs-user {
    margin-bottom: 16px;
  }

  .popup-subs-user-btn {
    display: none;
  }
}

.popup-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 5;
  display: none;
}

.popup-cover.active {
  display: -ms-flexbox;
  display: flex;
}

.popup-cover-wrap {
  margin: 300px 24px 0 auto;
  position: relative;
  width: 100%;
  max-width: 512px;
  height: 384px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: auto;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  scrollbar-color: #D1D5DB transparent;
  scrollbar-width: thin;
}

.popup-cover-wrap::-webkit-scrollbar {
  width: 4px;
}

.popup-cover-wrap::-webkit-scrollbar-track {
  border: solid 6px transparent;
}

.popup-cover-wrap::-webkit-scrollbar-thumb {
  background-color: #D1D5DB;
  border-radius: 18px;
  border: solid 6px transparent;
}

.popup-cover-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  border-bottom: 1px solid #e5e7eb;
  background-color: #fff;
  z-index: 5;
}

.popup-cover-ttl {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #374151;
}

.popup-cover-buttons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.popup-cover-delete {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  padding: 4px 16px;
  cursor: pointer;
}

.popup-cover-input {
  position: relative;
  width: 103.2px;
  height: 34px;
}

.popup-cover-input input {
  width: 0.01px;
  height: 0;
  opacity: 0;
  visibility: hidden;
}

.popup-cover-input label {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  padding: 4px 16px;
}

.popup-cover-close {
  display: none;
}

.popup-cover-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[4];
  grid-template-columns: repeat(4, 1fr);
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  padding: 16px 16px 0;
}

.popup-cover-grid-item {
  height: 64px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
}

.popup-cover-grid-item img {
  display: block;
  width: 100%;
  height: 100%;
}

.popup-cover-footer {
  display: none;
}

@media screen and (max-width: 1000px) {
  .popup-cover {
    position: fixed;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: rgba(17, 24, 39, 0.5);
  }

  .popup-cover-wrap {
    margin: 0;
    height: 85%;
  }
}

@media screen and (max-width: 768px) {
  .popup-cover-wrap {
    height: 75%;
  }
}

@media screen and (max-width: 480px) {
  .popup-cover {
    display: -ms-flexbox;
    display: flex;
    transition: 0.3s;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  .popup-cover.active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .popup-cover-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 0;
    height: 100vh;
  }

  .popup-cover-header .popup-cover-buttons {
    display: none;
  }

  .popup-cover-close {
    width: 28px;
    height: 28px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    margin-left: auto;
  }

  .popup-cover-grid {
    padding: 16px;
    -ms-flex: 1;
    flex: 1;
    -ms-grid-rows: none;
    grid-template-rows: none;
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }

  .popup-cover-footer {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    left: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
    border-top: 1px solid #e5e7eb;
    background-color: #fff;
    z-index: 5;
  }

  .popup-cover-delete {
    padding: 0;
  }
}

.popup-avatar {
  position: absolute;
  top: -120px;
  right: -50%;
  z-index: 5;
  display: none;
}

.popup-avatar.active {
  display: -ms-flexbox;
  display: flex;
}

.popup-avatar-wrap {
  position: relative;
  width: 208px;
  height: 280px;
  padding: 4px;
  border-radius: 8px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.popup-avatar-wrap::after {
  content: "";
  position: absolute;
  top: 95px;
  left: -10px;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: -1;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
}

.popup-avatar-header {
  display: none;
}

.popup-avatar-close-desctop {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 28px;
  height: 28px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;
}

.popup-avatar-img {
  width: 100%;
  height: 228px;
  border-radius: 8px;
  overflow: hidden;
}

.popup-avatar-img img {
  width: 100%;
}

.popup-avatar-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 12px;
}

.popup-avatar-input {
  position: relative;
}

.popup-avatar-input input {
  width: 0.01px;
  height: 0;
  opacity: 0;
  visibility: hidden;
}

.popup-avatar-input label {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2563EB;
  cursor: pointer;
}

.popup-avatar-btn {
  border: none;
  background-color: transparent;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2563EB;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .popup-avatar {
    top: -115px;
    right: -50px;
  }
}

@media screen and (max-width: 1000px) {
  .popup-avatar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    right: auto;
  }

  .popup-avatar-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;
  }

  .popup-avatar-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
    border-bottom: 1px solid #e5e7eb;
    background-color: #fff;
    z-index: 5;
  }

  .popup-avatar-ttl {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
  }

  .popup-avatar-close-desctop {
    display: none;
  }

  .popup-avatar-img {
    -ms-flex: 1;
    flex: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: auto;
    background-color: #e5e7eb;
    padding: 16px;
  }

  .popup-avatar-img img {
    width: initial;
    max-width: 100%;
    height: initial;
    max-height: 100%;
  }
}

.btn {
  font-size: 14px;
  line-height: 24px;
  border-radius: 8px;
  cursor: pointer;
}

.btn-white {
  background-color: #ffffff;
  font-weight: 400;
  color: #374151;
  border: 1px solid #D1D5DB;
}

.btn-green {
  font-weight: 500;
  color: #ffffff;
  background-color: #15803D;
}

.post {
  width: 100%;
  padding: 24px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  margin-bottom: 24px;
}

.post:last-of-type {
  margin-bottom: 0;
}

.post-header {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
}

.post-header-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
  overflow: hidden;
}

.post-header-img img {
  width: 100%;
}

.post-header-ttl {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #111827;
  margin-bottom: 4px;
}

.post-header-date {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b7280;
}

.post-header-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: auto;
}

.post-header-btn img {
  width: 100%;
}

.post-img {
  margin-bottom: 24px;
  border-radius: 4px;
  overflow: hidden;
}

.post-img img {
  width: 100%;
}

.post-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  margin-bottom: 24px;
}

.post-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.post-count {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
  margin: 0 8px;
}

.post-like,
.post-dislike {
  background: none;
  border: none;
  width: 32px;
  height: 32px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.post-dislike img {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media screen and (max-width: 768px) {
  .post {
    border: none;
    margin-bottom: 0;
    position: relative;
    padding: 24px 0;
  }

  .post::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: -16px;
    width: calc(100% + 32px);
    height: 1px;
    background-color: #e5e7eb;
  }
}

@media screen and (max-width: 480px) {
  .post {
    padding: 16px 0;
  }

  .post-header,
  .post-img,
  .post-text {
    margin-bottom: 16px;
  }

  .post-header-ttl {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.profile {
  padding-bottom: 32px;
  -ms-flex: 1;
  flex: 1;
}

.profile-wrap {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 672px auto;
  grid-template-columns: 672px auto;
  grid-column-gap: 48px;
  grid-row-gap: 32px;
}

.profile-tabs {
  position: relative;
  -ms-grid-column-span: 2;
  grid-column: span 2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 12px;
}

.profile-tabs::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #e5e7eb;
}

.profile-tab {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
  margin-right: 40px;
  cursor: pointer;
}

.profile-tab.active {
  position: relative;
  color: #166534;
}

.profile-tab.active::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -13px;
  width: 100%;
  height: 3px;
  background-color: #166534;
  z-index: 2;
}

.profile-posts {
  width: 100%;
  margin-right: 48px;
}

.profile-aside-text,
.profile-aside-adress {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
  margin-bottom: 12px;
}

.profile-aside-link {
  display: block;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2563EB;
  margin-bottom: 4px;
}

@media screen and (max-width: 1000px) {
  .profile-wrap {
    -ms-grid-columns: 64.8% auto;
    grid-template-columns: 64.8% auto;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
  }
}

@media screen and (max-width: 768px) {
  .profile-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-row-gap: 0;
    grid-column-gap: 0;
  }

  .profile-aside {
    -ms-flex-order: -2;
    order: -2;
    margin-bottom: 24px;
  }

  .profile-tabs::after {
    left: -16px;
    width: calc(100% + 32px);
    height: 1px;
    background-color: #e5e7eb;
  }

  .profile-posts {
    margin: 0;
  }
}

/*# sourceMappingURL=styles.css.map */

.btn-group {
  padding: 7px;
  position: absolute;
  right: 24px;
  bottom: 16px;
  border-radius: 8px;
  height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #374151;
  transition: 0.3s;
  cursor: pointer;
}

.btn-group button {
  background-color: #374151;
  /* Green background */
  border: 1px solid #374151;
  ;
  /* Green border */
  color: white;
  /* White text */
  padding: 5px;
  /* Some padding */
  cursor: pointer;
  /* Pointer/hand icon */
  float: left;
  /* Float the buttons side by side */
}

.btn-group button:not(:last-child) {
  border-right: none;
  /* Prevent double borders */
}

/* Clear floats (clearfix hack) */
.btn-group:after {
  content: "";
  clear: both;
  display: table;
}

/* Add a background color on hover */
.btn-group button:hover {
  background-color: #374151;
}

.profile-posts-content-empty {
  font-size: 14px;
  line-height: 1.55;
  color: #6b7280;
  margin-top: 25px;
}

div.scrollmenu {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.phoneTimer {
  display: none;
  margin-bottom: 10px;
}

.phone_text {
  margin-right: 5px;
}


.button-role {
  background-color: #fff;
  cursor: pointer;
}

.index-post-news {
  display: flex;
  justify-content: end;
}

.qr-code {
  height: 30%;
  width: 30%;
}


/* Класс visually-hidden для скрытия элементов */

.visually-hidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

/* User profile */

.user-profile__content-name {
  line-height: 24px;
  overflow: visible;
}

.user-profile__content-email {
  line-height: 14px;
  overflow: visible;
}

.notyf__toast {
  padding: 0;
  border-radius: 8px;
  background: #334155;
}

.notyf__wrapper {
  padding: 12px 30px 12px 16px;
  background: #334155;
  min-height: 72px;

}

.notyf__ripple {
  background: #334155;
}

.notyf__icon {
  display: none;
}

.notyf__message {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  min-width: 280px;
}

.notyf__dismiss {
  margin-right: 0;
}

.notyf__dismiss-btn {
  opacity: 1;
  background-color: #334155;
}

.notyf__dismiss-btn:before,
.notyf__dismiss-btn:after {
  top: 16px;
}

@media screen and (max-width: 480px) {
  .notyf__message {
    min-width: 254px;
  }
}

/* Dropdown Поделиться */


.ya-share2__link,
.share-el__item-title {
  color: #374151;
  font-family: 'Inter';
  font-size: 14px;
  line-height: 24px;
}

.share-el__item--telegram .share-el__item-icon {
  color: #26A5E4;
}

.share-el__item--vk .share-el__item-icon {
  color: #0077FF;
}

@media screen and (max-width: 768px) {
  .share-el {
    position: static;
  }

  .share-el__item {
    padding: 12px;
  }

  .share-el__dropdown {
    top: auto;
    left: 8px;
    right: 8px;
    bottom: 8px;
  }

  .share-el__dropdown-body {
    width: 100%;
  }

  .share-el__dropdown-cancel {
    display: block;
    color: #374151;
  }

  .ya-share2__link,
  .share-el__item-title {

    font-size: 16px;
    line-height: 28px;

  }
}


/* Страница проекта - Город */


.project-main-info-city {
  font-size: 14px;
  line-height: 24px;
  color: #9CA3AF;
}


/* Чекбоксы */
.label-checkbox__inner {
  color: #374151;
}


.label-checkbox {
  position: relative;
  display: block;
  color: #374151;
  font-size: 14px;
  line-height: 24px;
  padding-left: 30px;
}


.label-checkbox__input {
  display: none;
}


.label-checkbox__input+label::before {
  content: '';
  position: absolute;
  left: 0;
  //top: 50%;
  //transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: white;
  border: 1px solid #D1D5DB;
  border-radius: 4px;
}

.label-checkbox__input:checked+label::before {
  background-color: #16A34A;
  border: none;
}

.label-checkbox__input:checked+label::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 1px;
  width: 18px;
  height: 18px;
  background-image: url("/assets/checked.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
}

/* Демо проект */
.aside-links-ttl {
  font-weight: 500;
  margin-bottom: 16px;
}

/* Демо проект - Управление - Донаты */

.balance-alert__description {
  line-height: 24px;
  margin-bottom: 0;
}

/* Демо проект - Управление - Основное */


.input-block__label-description {
  line-height: 24px;
}

.u-icon.tooltip-donate__icon {
  fill: #9CA3AF;
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 768px) {
  .tooltip-donate {
    position: static;
  }

  .tooltip-donate .tooltip-donate__dropdown {
    max-width: 304px;

    transform: translate(-50%, -55%);
  }

  .tooltip-donate .tooltip-donate__dropdown::before {
    display: none;
  }

  .tooltip-donate:hover .tooltip-donate__dropdown,
  .tooltip-donate:active .tooltip-donate__dropdown,
  .tooltip-donate.is-active .tooltip-donate__dropdown {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}


/* Демо-проект - Управление - Описание */

.n-new-project__main-form {
  max-width: 648px;
}

.input-el--textarea {
  font-family: "Inter", arial, sans-serif;
}


/* Демо проект - Управление - Изображения */

.video-link-block__link-wrap {
  margin-bottom: 34px;
}


/* Блог проекта - Страница поста */

.project-detail-header {
  margin-bottom: 16px;
  cursor: pointer;
}

.project-detail-ttl-wrapper {

  margin-bottom: 24px;

}

.project-detail-ttl-wrapper.add {
  margin-bottom: 40px;
}

.project-detail-ttl-info {
  display: flex;
  justify-content: space-between;
}

.project-detail-ttl {
  line-height: 36px;
  margin-right: 12px;
  margin-bottom: 0;
}

.project-detail-ttl-btn {
  margin: 6px 0 30px;
}

.project-detail-ttl-label {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  padding: 2px 6px;
  color: #374151;
  background-color: #F3F4F6;
  border-radius: 4px;
  margin-top: 8px;
}

.project-detail-text p {
  margin: 0 0 12px;
}

.project-detail-img {
  max-width: 100%;
  margin: 0;
  border-radius: 0;
}

.project-detail-img img {
  max-width: 100%;
  margin-bottom: 24px;
}

.project-detail-header-img {
  border-radius: 50%;
  overflow: hidden;
}

.project-detail-text .media,
.project-detail-text .image,
.project-detail-text figure {
  width: 100%;
  margin: 24px 0;
}

.project-detail-text figure img {
  width: 100%;
}

.project-detail-text h1,
.project-detail-text h2,
.project-detail-text h3 {
  margin: 0;
  margin-top: 32px;
  font-weight: 600px;
  font-size: 20px;
  line-height: 32px;
}

.project-detail-text ul,
.project-detail-text ol {
  list-style: initial;
}

.project-detail-text ul li,
.project-detail-text ol li {
  margin-bottom: 12px;
}

.project-detail ul li::marker {
  color: #000000;
}

.project-add-text-ttl.extended {
  margin-bottom: 4px;
}

@media screen and (max-width: 480px) {
  .project-detail-ttl {
    line-height: 28px;
  }
  .project-detail-ttl-wrapper.add {
    margin-bottom: 32px;
  }
}


/* Пост */

.post-img img {

  height: 378px;
  object-fit: cover;

}

@media screen and (max-width: 1000px) {
  .post-img img {
    height: 414px;
  }
}

@media screen and (max-width: 768px) {
  .post-img img {
    height: 252px;
  }
}

@media screen and (max-width: 480px) {
  .post-img img {
    height: 162px;
  }
}

/* Отчеты в проекте */

.project-blog-noreports {
  display: flex;
  justify-content: flex-start;
  padding: 16px;
}



.u-icon.project-blog-noreports__icon {
  flex-shrink: 0;
  fill: #9CA3AF;
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.project-blog-noreports-msg {
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
}

.project-blog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-blog-header-btn {
  margin-left: 12px;
  width: 20px;
  height: 26px;
}

/* Проект - Управление - Донаты */

.project-settings__main-balance-alert {
  margin: 0 0 32px;
}

.balance-alert__content {
  margin-bottom: 16px;
}

.balance-alert__header {
  margin-bottom: 16px;
}

.balance-alert__description p {
  margin: 0;
  padding: 0;
  margin-bottom: 8px;

}

.balance-alert__description-date {
  color: #111827;
  font-weight: 500;
}

.balance-alert__support {
  display: inline-block;
  margin-left: 0.5em;
  color: #2563EB;
  transition: all 0.4s;
  transition-property: all;
  transition-property: color;

}


/* Проект - Добавить отчет */

.project-add-field-subttl,
.project-add-img-subttl {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  margin-bottom: 12px;
}


/* Инпут города - select2 */

.select2__region{
  color: #9CA3AF;
  display: inline-block;
  margin-left: 4px;
}

.select2-selection__arrow {
  display: none;
}

.select2-dropdown.select2-dropdown--below {
  top: -38px;
}

.select2-container--default .select2-selection--single{
  border: 1px solid #E5E7EB;
 }

.select2-search__field {
  border: none;
}
.select2-search.select2-search--dropdown {
  padding: 0;
}


.select2-container--default .select2-results__option.select2-results__option--highlighted.select2-results__option--selectable,
.select2-container--default .select2-results__option.select2-results__option--highlighted,
.select2-container--default .select2-results__option[aria-selected="true"]{
  background-color: #F3F4F6;
  color: #374151;
 }

 .select2-container--default .select2-results__option {
  font-size: 14px;
  line-height: 24px;
  
 }

 .select2-container--default .select2-search--dropdown .select2-search__field {
  padding: 4px 14px;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  font-family: "Inter", arial, sans-serif
 }

 .select2-container--default .select2-search--dropdown .select2-search__field {
  border: none;
 }

 .select2-dropdown{
  border: 1px solid #E5E7EB;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
 
 }

 .select2-container--default.select2-container--open.select2-container--below .select2-selection--single:hover,
 .select2-container--default.select2-container--open.select2-container--below .select2-selection--single:focus,
 .select2-container--default .select2-selection--single:hover, .select2-container--default .select2-selection--single:focus {
  border: 1px solid #E5E7EB;
 }


 /* Комментарии, сабкомменты */

 .subcomments {
  padding-left: 56px;
 }

 .subcomments .comment-block-item-person {
  margin-right: 12px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  flex: 0 0 24px;

 }


 


 

 
