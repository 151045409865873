@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "styles";

@import "users";

@import "login";

@import "actiontext";

@import "spiner";


/*

.select2-container--default .select2-selection--single .select2-selection__arrow{
 top: 10px !important;
}

.select2-container--default .select2-selection--single{
 border: 1px solid #E5E7EB !important;
}

.select2-container--default .select2-search--dropdown .select2-search__field{
 border: 1px solid #E5E7EB !important;

}

.select2-dropdown{
 border: 1px solid #E5E7EB !important;

}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable{
 background-color: #16A34A !important;
}


.select2-container{
 width: 100% !important;
}
 


.select2-container--default .select2-selection--single .select2-selection__arrow b {
 background-color: transparent;
 border: none !important;
 height: 20px !important;
 width: 20px !important;
 margin: auto !important;
 top: auto !important;
 left: -45% !important;
}

*/