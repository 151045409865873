.modal-title {
    margin: 0;
    font-weight: 600;
    line-height: 1.35;
    &--size-md {
        font-size: 24px;
    }
}

.modal-body {
    line-height: 1.35;
    font-size: 0;
    color: $darknes;
    text-align: center;
    &:after {
        width: 0;
        height: 100%;
        content: '';
        display: inline-block;
        vertical-align: middle;
    }
    &__center {
        background: #fff;
        width: 100%;
        padding: 40px;
        display: inline-block;
        font-size: 14px;
        border-radius: 8px;
        vertical-align: middle;
        text-align: initial;
        position: relative;
    }
    &__center--size-sm {
        max-width: 448px;
    }
    &__center--size-md {
        max-width: 576px;
    }
    &__title {
        margin: 0 0 24px;
    }
    &__close {
        position: absolute;
        top: 24px;
        right: 24px;
    }
    &__text {
        margin: 0 0 16px;
        font-size: 14px;
        line-height: 1.7;
        color: $gray;
    }
    &__card-form {
    }
    &__card-form-input {
        margin: 0 0 48px;
    }
    &__card-form-group-btns {
        padding-top: 16px;
    }
    &__card-form-input-block {
        margin: 0 0 32px;
    }
    &__binding-phone {
        padding-top: 8px;
        &-label {
            margin: 0;
            padding: 0 0 4px;
            display: block;
            font-weight: 500;
            font-size: 14px;
            line-height: 1.7;
            color: #334155;
        }
        &-input {
            max-width: 288px;
            flex-grow: 1;
        }
        &-group-btns {
            margin-top: 32px;
        }
        &-input-wrapper {
            margin-bottom: 24px;
            display: flex;
            align-items: center;
        }
        &-btn-change {
            margin-left: 4px;
            flex-shrink: 0;
        }
    }
    &__tell-project {
        &-textarea {
            min-height: 168px;
            margin-bottom: 42px;
        }
    }
}

.modal {
    background: rgba($darknes, .5);
    width: 100%;
    height: 100%;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 1002;
    &.is-show {
        display: block;
    }
    &__body {
        width: 100%;
        height: 100%;
        padding: 8px;
        overflow-y: auto;
    }
}

@media screen and (max-width: 767px) {
    .modal-title {
        &--size-md {
            font-size: 20px;
        }
    }
    .modal-body {
        &__close {
            top: 12px;
            right: 12px;
        }
        &__center {
            padding: 16px;
        }
        &__card-form-input {
            margin-bottom: 32px;
        }
    }
}