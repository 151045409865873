.profile {
    padding-bottom: 32px;
    flex: 1;

    &-wrap {
        display: grid;
        grid-template-columns: 672px auto;
        grid-column-gap: 48px;
        grid-row-gap: 32px;
    }

    &-tabs {
        position: relative;
        grid-column: span 2;
        display: flex;
        align-items: center;
        padding-bottom: 12px;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $gray-light;
        }
    }

    &-tab {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $gray;
        margin-right: 40px;
        cursor: pointer;

        &.active {
            position: relative;
            color: $green1;
            
            &::after {
                position: absolute;
                content: '';
                left: 0;
                bottom: -13px;
                width: 100%;
                height: 3px;
                background-color: #166534;
                z-index: 2;
            }
        }
    }

    &-posts {
        width: 100%;
        margin-right: 48px;
        &-content-empty {
            font-size: 14px;
            line-height: 1.55;
            color: $gray;
            a {
                box-shadow: none;
            }
        }
        &__list-item {
            & + & {
                margin-top: 24px;
            }
        }
    }

    &-aside {
        
        &-text,
        &-adress {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: $gray;
            margin-bottom: 12px;
        }

        &-link {
            display: block;
            text-decoration: none;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #2563EB;
            margin-bottom: 4px;
        }
    }
}

.profile-header {
    overflow: hidden;
    &__poster {
        padding-bottom: 18.75%;
        user-select: none;
    }
    &__container {
    }
    &__inner {
        margin: -64px 0 40px;
        display: flex;
        align-items: flex-end;
    }
    &__card {
        margin-right: 20px;
    }
    &__btn-subscribe {
        margin: 0 0 3px auto;
        display: inline-block;
        flex-shrink: 0;
    }
}

@media screen and (max-width: 1000px) {
    .profile {

        &-wrap {
            grid-template-columns: 64.8% auto;
            grid-column-gap: 32px;
            grid-row-gap: 32px;
        }
    }
}

@media screen and (max-width: 767px) {
    .profile {

        &-wrap {
            display: flex;
            flex-direction: column;
            grid-row-gap: 0;
            grid-column-gap: 0;
        }

        &-aside {
            order: -2;
            margin-bottom: 24px;
        }

        &-tabs::after {
            left: -16px;
            width: calc(100% + 32px);
            height: 1px;
            background-color: $gray-light;
        }

        &-posts {
            margin: 0;
        }
    }
}