.home {
    padding-bottom: 80px;
    flex: 1;

    &-main {
        padding: 136px 0 48px;
        background-color: #F8FAFC;

        &-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-auto-rows: auto;
            grid-row-gap: 70px;
        }

        &-info {
            padding-right: 60px;

            &-ttl {
                font-weight: 500;
                font-size: 42px;
                line-height: 52px;
                color: $darknes;
                margin-bottom: 16px;
            }

            &-subttl {
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                color: $dark-blue;
                margin-bottom: 24px;
            }
        }
        
        &-buttons {
            display: flex;
            align-items: center;
        }

        &-registration {
            padding: 12px 32px;
            margin-right: 32px;
        }

        &-about {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #2563EB;
        }

        &-video {
            position: relative;
            background-color: $white;
            box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.08);
            border-radius: 8px;
            padding-top: 56%;
            height: min-content;
            overflow: hidden;
            
            &-wrap {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;                

                iframe {
                    width: 100%;
                    display: block;
                    height: 100%;
                }
            }  
        }

        &-bank {
            display: flex;
            align-items: center;
            padding-right: 60px;

            &-logo {
                width: 110px;
                min-width: 110px;
                margin-right: 30px;
                overflow: hidden;

                img {
                    display: block;
                    width: 100%;
                }
            }

            &-desc {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: $gray;
            }
        }
    }

    &-section {
        background-color: $white;

        &-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 40px;
        }

        &-ttl {
            font-weight: 500;
            font-size: 30px;
            line-height: 36px;
            color: $darknes;
        }

        &-link {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #2563EB;
        }
    }
    
    &-easy {
        padding: 60px 0 80px;

        &-wrap {
            display: grid;
            grid-template-columns: repeat(3, 364px);
            grid-column-gap: 23px;
            max-width: 1138px;
            margin: 0 auto;
            overflow: scroll;
            scrollbar-width: none;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
        }

        &-item {
            padding: 32px;
            background-color: #F8FAFC;
            border-radius: 12px;
            overflow: hidden;

            &-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                width: 56px;
                height: 56px;
                background-color: $white;
                border-radius: 50%;
                margin-bottom: 24px;
            }

            &-ttl {
                font-weight: 500;
                font-size: 21px;
                line-height: 30px;
                color: $darknes;
                margin-bottom: 8px;
            }

            &-text {
                font-weight: 400;
                font-size: 15px;
                line-height: 24px;
                color: $dark-blue;
            }
        }
        
    }

    &-how {
        padding: 128px 0;
        background-color: #F8FAFC;

        &-wrap {
            display: flex;
        }

        &-img {
            width: 61.5%;
            min-width: 61.5%;
            background-color: $white;
            border-radius: 8px;
            box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.08);
            margin-right: 6.85%;
            overflow: hidden;

            img {
                display: block;               
                object-fit: cover;
                object-position: center center;
            }
        }

        &-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &-block {
                &-ttl {
                    font-weight: 500;
                    font-size: 21px;
                    line-height: 30px;
                    color: $darknes;
                    margin-bottom: 8px;
                }

                &-text {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 24px;
                    color: $dark-blue;
                }
            }
        }
    }

    &-projects {
        padding: 60px 0 132px;

        &-slider-container {
            position: relative;
            max-width: 1138px;
            margin: 0 auto;
            padding-bottom: 1px;

            & .swiper-slide {
                height: initial;
            }

            & .swiper-pagination {
                position: absolute;
                left: 0px;
                bottom: -60px;
            }

            & .home-projects-button-prev {
                position: absolute;
                left: -56px;
                top: 50%;
                transform: translateY(-50%);
                width: 44px;
                height: 44px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
                cursor: pointer;
            }

            & .home-projects-button-next {
                position: absolute;
                right: -56px;
                top: 50%;
                transform: translateY(-50%);
                width: 44px;
                height: 44px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
                cursor: pointer;
            }

            & .swiper-pagination-bullet {
                width: 4px;
                height: 4px;
                margin: 0 2px!important;
                background-color: #D1D5DB;
            }

            & .swiper-pagination-bullet-active {
                background-color: $green3;
            }
        }

        &-item {
            border-radius: 8px;
            border: 1px solid #E5E7EB;
            max-width: 364px;
            height: auto;
            display: flex;
            flex-direction: column;

            &-head {
                display: flex;
                align-items: center;
                padding: 20px;

                &-img {
                   width: 36px;
                   min-width: 36px;
                   height: 36px;
                   margin-right: 12px;
                   border-radius: 50%;
                   overflow: hidden;
                   
                   img {
                       width: 100%;
                   }
                }

                &-ttl {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 21px;
                    color: $darknes;
                }
            }

            &-img {
                margin-bottom: 20px;

                img {
                    width: 100%;
                }
            }

            &-amount {
                font-weight: 500;
                font-size: 21px;
                line-height: 30px;
                color: $darknes;
                margin-bottom: 4px;
                padding: 0 20px;

                span:last-of-type {
                    font-size: 14px;
                }
            }

            &-text {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: $dark-blue;
                margin-bottom: 20px;
                padding: 0 20px;
            }

            &-info {
                margin-top: auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #9CA3AF;
                padding: 0 20px 20px;
            }

            &-city {
                position: relative;
                padding-left: 22px;
            }

            &-city-mark {
                background-size: contain;
                background-position: 50%;
                background-repeat: no-repeat;
                position: absolute;
                top: 50%;
                left: 0;
                width: 20px;
                height: 20px;
                transform: translateY(-50%);
            }
        }
    }

    &-interest {

        &-inner {
            display: flex;
            justify-content: space-between;
            padding: 40px;
            background: #F8FAFC;
            background-repeat: no-repeat;
            background-position: top right;
            border-radius: 12px;
        }

        &-info {

            &-ttl {
                font-weight: 500;
                font-size: 30px;
                line-height: 36px;
                color: $darknes;
                margin-bottom: 8px;
            }

            &-subttl {
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                color: $dark-blue;
                margin-bottom: 24px;
            }
        }

        &-buttons {
            display: flex;
            align-items: center;
        }

        &-create {
            padding: 10px 24px;
            margin-right: 32px; 
        }

        &-about {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #2563EB; 
        }

        &-img {
            width: 288px;
            min-width: 288px;
            margin-left: 40px;

            img {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .home-main-info-ttl {
        font-size: 3.35vw;
    }

    .home-projects-slider-container {
        & .home-projects-button-prev,
        & .home-projects-button-next {
            display: none;
        }
    }

    .home-projects-pagination {
        display: none;
    }

    .home-projects {
        padding: 60px 0;
    }
}

@media screen and (max-width: 1169px) {
    .home {
        
        &-easy-wrap {
            margin: 0;
            padding: 0 16px;
            grid-column-gap: 16px;
        }
        
        &-projects-slider {
            padding: 0 16px;
            overflow: scroll;
            scrollbar-width: none;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
            
        }
    }


}

@media screen and (max-width: 1000px) {
	.home {
		padding-bottom: 72px;
		
		&-main {
            padding: 48px 0 32px;

            &-grid {
                display: block;
                text-align: center;
            }

            &-info {
                margin-bottom: 48px;
                padding-right: 0;

                &-ttl {
                    font-size: 42px;
                }
            }

            &-buttons {
                justify-content: center;
            }

            &-video {
                margin-bottom: 24px;
            }

            &-bank {
                justify-content: center;
                padding-right: 0;
            }
		}

        &-how {
            padding: 48px 0 60px;
            
            &-wrap {
                display: block;
            }

            &-img {
                min-width: 0;
                width: 100%;
                margin-bottom: 48px;

                img {
                    width: 100%;

                }
            }

            &-info {
                flex-direction: row;
                gap: 32px;
            }
        }

        &-interest {

            &-inner {
                padding: 32px;
            }

            &-img {
                width: 200px;
                min-width: 220px;
            }
        }
	}
}

@media screen and (max-width: 767px) {
	.home {
        padding-bottom: 48px;
		
		&-main {

            &-info {
                
                &-ttl {
                    font-size: 32px;
                    line-height: 46px;
                }
            }

            &-bank {
                flex-direction: column;

                &-logo {
                    margin: 0 0 8px;
                }
            }
		}

        &-section {
            &-head {
                margin-bottom: 32px;
            }
        }

        &-easy {
            padding: 40px 0 60px;
        }

        &-how {
            padding: 40px 0;

            &-img {
                margin-bottom: 32px;
            }

            &-info {
                flex-direction: column;
                gap: 16px;
            }
        }
        
        &-interest {

            &-inner {
                padding: 24px;
            }

            &-img {
                display: none;
            }
        }
	}
}

@media screen and (max-width: 480px) {
	.home {
        padding-bottom: 40px;
		
		&-main {
            padding: 24px 0;

            &-info {
                margin-bottom: 28px;
                
                &-ttl {
                    font-size: 24px;
                    line-height: 34px;
                    margin-bottom: 12px;
                }
                
                &-subttl {
                    font-size: 15px;
                    line-height: 24px;
                    margin-bottom: 20px;
                }
            }

            &-buttons {
                flex-direction: column;
            }

            &-registration {
                width: 100%;
                margin: 0 0 24px;
            }

            &-video {
                margin-bottom: 20px;
            }
		}

        &-section {

            &-head {
                margin-bottom: 32px;
            }

            &-ttl {
                font-size: 24px;
                line-height: 28px;
            }
        }

        &-easy {
            padding: 40px 0;

            &-wrap {
                grid-template-columns: repeat(3, 284px);
                grid-column-gap: 8px;
            }

            &-item {
                padding: 24px;

                &-icon {
                    margin-bottom: 16px;
                }

                &-ttl {
                    font-size: 18px;
                    line-height: 28px;
                }

            }
        }

        &-how {
            padding: 24px 0;

            &-info {

                &-block {

                    &-ttl {
                        font-size: 18px;
                        line-height: 28px;
                        margin-bottom: 4px; 
                    }
                }
            }
        }

        &-projects {
            padding: 40px 0;

            &-item {
                max-width: 287px;
            }
        }

        &-interest {

            &-inner {
                background-image: none;
            }

            &-buttons {
                width: 100%;
                flex-direction: column;
            }

            &-create {
                width: 100%;
                margin: 0 0 28px;
                text-align: center;
            }
        }
	}
}