.l-login {
    background: $blue-light;
    overflow: clip;
    @media screen and (max-width: 480px) {
        background: transparent;
    }
    &__container {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 480px) {
            align-items: flex-start;
        }
    }
    &__inner {
        width: 100%;
        max-width: 448px;
        margin: 0 auto;
        padding: 48px 0;
        @media screen and (max-width: 767px) {
            padding: 32px 0;
        }
        @media screen and (max-width: 480px) {
            max-width: 100%;
        }
    }
    &__logo {
        width: 120px;
        margin: 0 auto 24px;
        display: block;
        @media screen and (min-width: 1200px) {
            transition: all .4s;
            transition-property: opacity;
        }
        &:hover {
            @media screen and (min-width: 1200px) {
                opacity: .6;
            }
        }
        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
    &__block {
        background: $white;
        margin: 0 0 16px;
        padding: 48px 40px;
        border-radius: 16px;
        box-shadow: 0 1px 3px rgba(0,0,0,.1),
               0 1px 2px -1px rgba(0,0,0,.1);
        @media screen and (max-width: 480px) {
            background: transparent;
            margin-bottom: 26px;
            padding: 0;
            box-shadow: initial;
        }
    }
    &__footer {
        display: block;
        font-size: 12px;
        line-height: 1.35;
        color: $gray;
        text-align: center;
        a {
            color: $blue;
            @media screen and (min-width: 1200px) {
                box-shadow: 0 1px 0 transparent;
                transition: all .4s;
                transition-property: box-shadow;
            }
            &:hover {
                @media screen and (min-width: 1200px) {
                    box-shadow: 0 1px 0 currentColor;
                }
            }
        }
    }
}