.comments {
    padding-top: 40px;

    &-ttl {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: $darknes;
        padding-bottom: 16px;
        border-bottom: 1px solid $gray-light;
        margin-bottom: 32px;
    }

    &-tree {
        &__child {
            padding-left: 50px;
        }
        &__child &__child {
            padding-left: 0 !important;
        }
    }

    &-item {
        padding: 24px 0;
        border-bottom: 1px solid $gray-light;

        &-header {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
        }

        &-person {
            width: 40px;
            min-width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 12px;
            flex: 0 0 40px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
            }
        }

        &-info {
        }

        &-name {
            max-width: 100%;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $dark-blue;
            margin-bottom: 2px;
            flex: 0 0 100%;

            &.topic-starter {
                color: $white;
                background-color: $green3;
                padding: 0 8px;
                border-radius: 80px;
            }
        }

        &-info-bottom {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
        }

        &-time {
            margin-right: 4px;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $gray;
            align-self: center;
        }

        &-donate-amount {
            background: #F3F4F6;
            padding: 4px 6px;
            flex-shrink: 0;
            font-weight: 500;
            font-size: 12px;
            line-height: 1;
            color: $darknes;
            align-self: center;
            border-radius: 4px;
            white-space: nowrap;
        }

        &-donate-hidden {
            font-size: 12px;
            line-height: 1;
            color: $gray;
            flex-shrink: 0;
            align-self: center;
        }

        &-text {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: $dark-blue;
            margin-bottom: 12px;
        }
        &-btn {
            width: 20px;
            height: 20px;
            margin: 2px 0 0 auto;
            flex: 0 0 20px;
            align-self: flex-start;
        }
    }

    &-input {
        width: 100%;
        margin-bottom: 20px;
        
        &-wrap {
            display: flex;
            align-items: center;
        }

        &-buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 24px;
            /* &:not(.is-show) {
                display: none;
            } */
        }

        &-cancel {
            padding: 6px 20px;
            font-size: 14px;
            line-height: 24px;
            color: $gray;
            cursor: pointer;
        }

        &-public {
            padding: 6px 16px;
            font-size: 14px;
            line-height: 24px;
            cursor: pointer;
            margin-left: 8px;
        }
    }
}

@media screen and (min-width: 1200px) {
    .comments {
        &-item {
            &-btn {
                transition: all .18s;
                transition-property: opacity, visibility;
            }
            &:not(:hover) &-btn {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .comments {

        &-ttl {
            margin-bottom: 24px;
        }

        &-input {
            
            &-wrap > .comments-item-person {
                display: none;
            }
        }

        
    }
}