.popup-profile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 5;
    padding: 62px 24px 0 0;    
    display: none;

    &-wrap{
        width: 265px;
        margin-left: auto;
        background: #FFFFFF;
        overflow: auto;
        border-radius: 8px;
        padding: 4px;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
        overflow: hidden;
    }

    &-section{
        position: relative;

        &::after {
            position: absolute;
            left: -4px;
            bottom: 0;
            content: '';
            width: calc(100% + 8px);
            height: 1px;
            background-color: $gray-light;
        }

        &:nth-of-type(3)::after {
            display: none;
        }

        &-bottom {
            display: none;
        }
    }

    &-user {
        display: flex;
        padding: 8px 14px;
        align-items: center;
        position: relative;

        &-img{
            width: 32px;
            height: 32px;
            margin-right: 12px;
            min-width: 32px;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        &-name{
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $darknes;
        }

        &-email{
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #8A9199;
        }
    }

    &-link,
    &-copyrights,
    &-docs,
    &-lang {
        display: block;
        padding: 6px 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: $darknes;
        transition: .3s;

        &:hover {
            background-color: #F3F4F6;
        }
    }

    &-link {
        border-radius: 4px;
        cursor: pointer;

        &.mobile {
            display: none;
        }

        &.green {
            color: $green1;
        }
    }
}

@media screen and (max-width: 1000px) {
    .popup-profile {
        position: fixed;
        padding: 0;
        background-color: rgba(17, 24, 39, 0.5);

        &-wrap {
            margin-left: 0;
            display: flex;
            flex-direction: column;
            height: inherit;
            border-radius: 0;
            width: 320px;
        }

        &-section {
            padding: 6px 0;

            &:nth-of-type(3)::after {
                display: block;
            }

            &:nth-of-type(4)::after {
                display: none;
            }

            &-bottom {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                
            }
        }

        &-link {
            &.mobile {
                display: block;
            }
        }

        &-docs {
            color: $gray;
            font-size: 12px;
            line-height: 16px;
        }

        &-copyrights {
            font-size: 12px;
            line-height: 16px;
            color: #9CA3AF;
        }
    }
}

@media screen and (max-width: 480px) {
    .popup-profile {
        &-wrap {
            width: 256px;
        }
    }
}