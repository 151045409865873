.likes {

    &-group {
        display: flex;
        align-items: center;

        &.active {
            margin-bottom: 24px;
        }
    }

    &-like,
    &-dislike {
        width: 18px;
        height: 12px;
        cursor: pointer;
        overflow: hidden;

        svg {
            width: 100%;            

            path {
                stroke: #9CA3AF;
                transition: .3s;
            }
        }
    }

    &-like.active {
        svg path {
            fill: $green3;
            stroke: $green3;
        }
    }

    &-dislike.active {
        svg path {
            fill: $red;
            stroke: $red;
        }
    }

    &-dislike.active {
        svg {
            fill: $red;
            stroke: $red;
        }
    }

    &-count {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $gray;
        margin: 0 6px;
    }

    &-answer,
    &-expand {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #2563EB;
        //text-transform: uppercase;
        margin-left: 16px;
        cursor: pointer;
    }

    &-expand-inner {
        display: flex;
        align-items: center;
    }
    &-expand-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &-expand.active svg {
        transform: rotate(180deg);
    }
}