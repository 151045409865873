.settings {
    flex: 1;
    padding: 40px 0 80px;

    &-wrap {
        display: flex;
        align-items: flex-start;
    }

    &-tabs {
        width: 240px;
        min-width: 240px;
        margin-right: 40px;
        position: sticky;
        top: 42px;
        
        &-head {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
        }

        &-img {
            width: 32px;
            min-width: 32px;
            height: 32px;
            margin-right: 12px;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        &-info {

            &-top {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: $darknes; 
            }

            &-bottom {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #8A9199;
            }
        }

        &-tab {
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            margin-bottom: 24px;
            color: $gray;
            cursor: pointer;

            &:last-of-type {
                margin-bottom: 0;
            }

            &.active {
                font-weight: 500;
                color: $green1;
            }
        }

        &-head-nav-step {
            @media screen and (max-width: 1000px) {
                padding: 0 16px;
            }
        }
    }

    &-inner {
        flex: 1;

        &-ttl {
            margin: 0;
            font-weight: 600;
            font-size: 30px;
            line-height: 40px;
            color: $darknes;
            margin-bottom: 8px;
        }

        &-subttl {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: $darknes;
        }

        &-desc {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: $gray;
            margin-bottom: 60px;
        }

        &-form {

            &-group {
                margin-bottom: 40px;

                &-wrap {
                    display: none;
                }
            }

            &-label {
                display: inline-block;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: $darknes;
                margin-bottom: 12px;
            }

            &-text {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: $gray;
                margin: 0 0 12px;
            }

            &-email {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: $dark-blue;
                margin: 0 0 12px;
            }

            &-changepass {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: $dark-blue;

                &-link {
                    color: #2563EB;
                    transition: all .4s;
                    transition-property: box-shadow;
                    cursor: pointer;

                    @media screen and (min-width: 1200px) {
                        &:hover {
                            box-shadow: inset 0 -1px 0 currentColor;
                        }
                    }
                }
            }

            &-add {
                display: flex;
                align-items: center;
                cursor: pointer;

                img {
                    margin-right: 8px; 
                }

                span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: #2563EB;
                }
            }

            &-block {
                max-width: 90%;

                .settings-inner-form-text {
                    margin-bottom: 0;
                }

                &-accounts {
                    max-width: 70%;

                    & .settings-inner-form-text {
                        margin-bottom: 0;
                    }
                }
            }

            &-buttons {
                display: flex;
                align-items: center;
            }

            &-save {
                padding: 10px 24px;
                margin-right: 32px;
            }

            &-preview {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #2563EB;
            }

            &-cancel {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: $gray;
                cursor: pointer; 
            }

            &-account {
                width: 142px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                border: 1px solid #D1D5DB;
                transition: all .4s;
                transition-property: background;

                @media screen and (min-width: 1200px) {
                    &:hover {
                        background: rgba($gray-light, .6);
                    }
                }

                &-logo {
                    width: 20px;
                    min-width: 20px;
                    height: 20px;
                    margin-right: 4px;
                    img {
                        width: 100%;
                    }

                }                

                span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: $dark-blue;
                }

                &-connected {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &-block {
                        text-align: right;
                    }

                    &-name {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        color: $darknes;
                    }

                    &-cancel {
                        margin-left: auto;
                        display: inline-flex;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        color: #2563EB;
                        transition: all .4s;
                        transition-property: box-shadow;
                        cursor: pointer;

                        @media screen and (min-width: 1200px) {
                            &:hover {
                                box-shadow: inset 0 -1px 0 currentColor;
                            }
                        }
                    }

                    &-logo {
                        width: 32px;
                        min-width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-left: 12px;
                    }
                }
            }

            &.notice {
                .settings-inner-subttl {
                    margin-bottom: 24px;
                } 

                .input-group {
                    margin-bottom: 32px;
                }
            }

            &-group-btns {
                margin-top: -10px;
            }
        }

        &-card {
            display: flex;
            align-items: flex-end;

            &-logo {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 64px;
                height: 44px;
                border-radius: 8px;
                border: 1px solid #D1D5DB;
                overflow: hidden;
                margin-right: 16px;

                img {
                    width: 100%;
                }
            }

            &-data {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
            }

            &-number {
                color: $darknes;
            }

            &-date {
                color: $gray;
            }

            &-close {
                width: 48px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }
    }
}

.user-verification {
    font-size: 14px;
    line-height: 1.7;
    color: $darknes;
    &__phone-wrapper {
        padding-top: 40px;
    }
    &__paragraph {
        margin: 0 0 1.7em;
    }
    &__phone-binding {
    }
    &__subtitle {
        margin: 32px 0 8px;
    }
    &__radio {
    }
    &__block {
        margin: 0 0 48px;
    }
    &__radio:not(:checked) ~ &__block {
        display: none;
    }
    &__choose-item {
        margin: 0 0 20px;
    }
    &__link {
    }
    &__attach-file {
        & + & {
            margin-top: 12px;
        }
    }
    &__status-wrap {
        padding-top: 24px;
    }
    &__status {
        margin: 0 0 16px;
    }
    &__status-description {
        margin: 0 0 20px;
        font-size: 14px;
        line-height: 1.7;
        color: $dark-blue;
    }
    &__status-btn {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 1000px) {
    .settings {
        padding: 0 0 48px;

        &-wrap {
            display: block;
        }

        &-tabs {
            position: relative;
            width: calc(100% + 32px);
            min-width: 0;
            margin: 0 -16px 32px;
            position: static;
            top: 0;
            overflow-x: scroll;
            scrollbar-width: none;
            -ms-overflow-style: none;            

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }

            
            &-head {
                display: none;
            }
            
            &-list {
                width: max-content;
                min-width: 100%;
                position: relative;
                display: flex;
                align-items: center;
                padding: 6px 16px;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0px;
                    width: 100%;
                    height: 1px;
                    background-color: $gray-light;
                }
                                
            }

            &-tab {
                position: relative;
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 0;
                margin-right: 48px;
                white-space: nowrap;

                &:last-of-type {
                    margin-right: 0;
                }

                &.active::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -6px;
                    width: 100%;
                    height: 3px;
                    background-color: $green1;
                    z-index: 2;
                }
            }
        }

        &-inner {

            &-desc {
                margin-bottom: 48px;
            }

            &-form {

                &-block {

                    &-accounts {
                        max-width: 55%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .settings {
        padding: 0 0 40px;

        &-inner {

            &-ttl {
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 12px;
            }

            &-desc {
                margin-bottom: 32px;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .settings {

        &-inner {

            &-desc {
                margin-bottom: 24px;
            }

            &-form {

                &-block {
                    max-width: none;

                    &-accounts {
                        max-width: none;

                        .settings-inner-form-text {
                            margin-bottom: 12px;
                        }
                    }
                }

                &-buttons {
                    flex-direction: column;                    
                }

                &-save {
                    width: 100%;
                    margin: 0 0 32px;
                    text-align: center;
                }
            }
        }
    }
}