.comment-block {
    //padding-top: 24px;

    &-ttl {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: $darknes;
        padding-bottom: 16px;
        border-bottom: 1px solid $gray-light;
        margin-bottom: 32px;
    }

    &-tree {
        &__child {
            padding-left: 50px;
        }

        &__child &__child {
            padding-left: 0 !important;
        }
    }

    &-nocomments {
        font-size: 14px;
        padding: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gray;
    }

    &-item {
        padding: 16px 0;
        border-bottom: 1px solid $gray-light;
        display: flex;
        justify-content: flex-start;

        &-content {
            flex-grow: 1;
        }

        &-header {
            display: flex;
            justify-content: space-between;
            //align-items: center;
            //margin-bottom: 12px;
        }

        &-amount {
            color: #16A34A;
            font-size: 14px;
            line-height: 24px;
            font-weight: 500;
        }

        &-person {
            width: 40px;
            min-width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 16px;
            flex: 0 0 40px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
            }
        }

        &-donates {
            display: inline-block;
            font-size: 12px;
            line-height: 16px;
            padding: 2px 6px;
            background-color: #F3F4F6;
            color: $gray;
            border-radius: 90px;
        }

        &-name {
            max-width: 100%;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $dark-blue;
            margin-bottom: 2px;
            flex: 0 0 100%;
            margin-right: 8px;

            &.topic-starter {
                //color: $white;
                background-color: #D4FADB;
                padding: 0 8px;
                border-radius: 80px;
            }
        }

        &-info-bottom {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
        }

        &-time {
            margin-right: 4px;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $gray;
            align-self: center;
        }

        &-donate-amount {
            background: #F3F4F6;
            padding: 4px 6px;
            flex-shrink: 0;
            font-weight: 500;
            font-size: 12px;
            line-height: 1;
            color: $darknes;
            align-self: center;
            border-radius: 4px;
            white-space: nowrap;
        }

        &-donate-hidden {
            font-size: 12px;
            line-height: 1;
            color: $gray;
            flex-shrink: 0;
            align-self: center;
        }

        &-text {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: $dark-blue;
            margin-bottom: 12px;
        }

        &-btn {
            width: 20px;
            height: 20px;
            margin: 2px 0 0 auto;
            flex: 0 0 20px;
            align-self: flex-start;
        }

        &-votebtn {
            background-color: transparent;
            padding: 0;
            border: none;
            outline: none;
            cursor: pointer;
        }

        &-actionbtn {
            padding: 6px 20px;

            color: #6b7280;
            background-color: transparent;

            border: none;
            outline: none;
            cursor: pointer;
        }
    }

    &-input {
        width: 100%;
        margin-bottom: 20px;

        &-wrap {
            display: flex;
            align-items: center;
        }

        &-buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 24px;
            /* &:not(.is-show) {
                display: none;
            } */
        }

        &-cancel {
            padding: 6px 20px;
            font-size: 14px;
            line-height: 24px;
            color: $gray;
            cursor: pointer;
        }

        &-public {
            padding: 6px 16px;
            font-size: 14px;
            line-height: 24px;
            cursor: pointer;
            margin-left: 8px;
        }
    }

    & .comment-block-textarea {
        width: 100%;
        font-size: 14px;
        line-height: 24px;
        min-height: 44px;
        resize: vertical;
        font-family: "Inter", arial, sans-serif;
        padding: 9px 12px;

        color: #111827;
        border-radius: 8px;
        border: 1px solid #e5e7eb;
    }

    & .comment-block-textarea::placeholder {
        font-size: 14px;
    }
}

@media screen and (min-width: 1200px) {
    .comment-block {
        &-item {
            &-btn {
                transition: all .18s;
                transition-property: opacity, visibility;
            }

            &:not(:hover) &-btn {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .comment-block {

        &-ttl {
            margin-bottom: 24px;
        }

        &-input {

            &-wrap>.comment-block-item-person {
                display: none;
            }
        }


    }
}