@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}

html {
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-weight: normal;
  font-family: "Inter", arial, sans-serif;
  font-smooth: antialiased;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-display: swap;
  font-size: 16px;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
body.is-hidden {
  overflow: hidden;
}

ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

a,
a:visited {
  text-decoration: none;
}

button {
  border: none;
  padding: 0;
}

img {
  display: block;
}

.container, .container-large, .container-medium, .container-small {
  width: 100%;
  max-width: 1072px;
  padding: 0 16px;
  margin: 0 auto;
}
.container-small {
  max-width: 672px !important;
}
.container-medium {
  max-width: 708px !important;
}
.container-large {
  max-width: 1170px !important;
}

@media screen and (min-width: 1001px) {
  .visible-1000 {
    display: none !important;
  }
}
@media screen and (min-width: 768px) {
  .visible-767 {
    display: none !important;
  }
}
@media screen and (min-width: 1281px) {
  body.is-hidden {
    padding-right: 17px;
  }
}
@media screen and (max-width: 767px) {
  .hidden-767 {
    display: none !important;
  }
}
@media screen and (min-width: 769px) {
  a:hover {
    text-decoration: none;
    outline: 0;
  }

  .header-middle:hover .header-middle-edit {
    opacity: 1;
  }

  .likes-like:hover svg path {
    fill: #16A34A;
    stroke: #16A34A;
  }

  .likes-dislike:hover svg path {
    fill: #EF4444;
    stroke: #EF4444;
  }
}
@keyframes notyf-fadeinup {
  0% {
    opacity: 0;
    transform: translateY(25%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes notyf-fadeinleft {
  0% {
    opacity: 0;
    transform: translateX(25%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes notyf-fadeoutright {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(25%);
  }
}
@keyframes notyf-fadeoutdown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(25%);
  }
}
@keyframes ripple {
  0% {
    transform: scale(0) translateY(-45%) translateX(13%);
  }
  100% {
    transform: scale(1) translateY(-45%) translateX(13%);
  }
}
.notyf {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: #ffffff;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  pointer-events: none;
  box-sizing: border-box;
  padding: 20px;
}
.notyf__icon--error, .notyf__icon--success {
  height: 21px;
  width: 21px;
  background: white;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  position: relative;
}
.notyf__icon--error:after, .notyf__icon--error:before {
  content: "";
  background: currentColor;
  display: block;
  position: absolute;
  width: 3px;
  border-radius: 3px;
  left: 9px;
  height: 12px;
  top: 5px;
}
.notyf__icon--error:after {
  transform: rotate(-45deg);
}
.notyf__icon--error:before {
  transform: rotate(45deg);
}
.notyf__icon--success:after, .notyf__icon--success:before {
  content: "";
  background: currentColor;
  display: block;
  position: absolute;
  width: 3px;
  border-radius: 3px;
}
.notyf__icon--success:after {
  height: 6px;
  transform: rotate(-45deg);
  top: 9px;
  left: 6px;
}
.notyf__icon--success:before {
  height: 11px;
  transform: rotate(45deg);
  top: 5px;
  left: 10px;
}
.notyf__toast {
  display: block;
  overflow: hidden;
  pointer-events: auto;
  animation: notyf-fadeinup 0.3s ease-in forwards;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  padding: 0 15px;
  border-radius: 2px;
  max-width: 300px;
  transform: translateY(25%);
  box-sizing: border-box;
  flex-shrink: 0;
}
.notyf__toast--disappear {
  transform: translateY(0);
  animation: notyf-fadeoutdown 0.3s forwards;
  animation-delay: 0.25s;
}
.notyf__toast--disappear .notyf__message, .notyf__toast--disappear .notyf__icon {
  animation: notyf-fadeoutdown 0.3s forwards;
  opacity: 1;
  transform: translateY(0);
}
.notyf__toast--disappear .notyf__dismiss {
  animation: notyf-fadeoutright 0.3s forwards;
  opacity: 1;
  transform: translateX(0);
}
.notyf__toast--disappear .notyf__message {
  animation-delay: 0.05s;
}
.notyf__toast--upper {
  margin-bottom: 20px;
}
.notyf__toast--lower {
  margin-top: 20px;
}
.notyf__toast--dismissible .notyf__wrapper {
  padding-right: 30px;
}
.notyf__ripple {
  height: 400px;
  width: 400px;
  position: absolute;
  transform-origin: bottom right;
  right: 0;
  top: 0;
  border-radius: 50%;
  transform: scale(0) translateY(-51%) translateX(13%);
  z-index: 5;
  animation: ripple 0.4s ease-out forwards;
}
.notyf__wrapper {
  display: flex;
  align-items: center;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-right: 15px;
  border-radius: 3px;
  position: relative;
  z-index: 10;
}
.notyf__icon {
  width: 22px;
  text-align: center;
  font-size: 1.3em;
  opacity: 0;
  animation: notyf-fadeinup 0.3s forwards;
  animation-delay: 0.3s;
  margin-right: 13px;
}
.notyf__dismiss {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 26px;
  margin-right: -15px;
  animation: notyf-fadeinleft 0.3s forwards;
  animation-delay: 0.35s;
  opacity: 0;
}
.notyf__dismiss-btn {
  background-color: rgba(0, 0, 0, 0.25);
  border: none;
  cursor: pointer;
  transition: opacity 0.2s ease, background-color 0.2s ease;
  outline: none;
  opacity: 0.35;
  height: 100%;
  width: 100%;
}
.notyf__dismiss-btn:after, .notyf__dismiss-btn:before {
  content: "";
  background: white;
  height: 12px;
  width: 2px;
  border-radius: 3px;
  position: absolute;
  left: calc(50% - 1px);
  top: calc(50% - 5px);
}
.notyf__dismiss-btn:after {
  transform: rotate(-45deg);
}
.notyf__dismiss-btn:before {
  transform: rotate(45deg);
}
.notyf__dismiss-btn:hover {
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.15);
}
.notyf__dismiss-btn:active {
  opacity: 0.8;
}
.notyf__message {
  vertical-align: middle;
  position: relative;
  opacity: 0;
  animation: notyf-fadeinup 0.3s forwards;
  animation-delay: 0.25s;
  line-height: 1.5em;
}

/* Small screens */
@media only screen and (max-width: 480px) {
  .notyf {
    padding: 0;
  }
  .notyf__ripple {
    height: 600px;
    width: 600px;
    animation-duration: 0.5s;
  }
  .notyf__toast {
    max-width: initial;
    border-radius: 0;
    box-shadow: 0px -2px 7px 0px rgba(0, 0, 0, 0.13);
    width: 100%;
  }
  .notyf__dismiss {
    width: 56px;
  }
}
.post-status {
  background: transparent;
  padding: 5px 8px;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  outline: none;
  user-select: none;
  white-space: nowrap;
}
.post-status--accept {
  background: #16A34A;
}
.post-status--rejected {
  background: #EF4444;
}

.post {
  width: 100%;
  padding: 24px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  margin-bottom: 24px;
}
.post:last-of-type {
  margin-bottom: 0;
}
.post-header {
  display: flex;
  margin-bottom: 24px;
}
.post-header-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
  flex: 0 0 48px;
  overflow: hidden;
}
.post-header-img img {
  width: 100%;
}
.post-header-ttl {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #111827;
  margin-bottom: 4px;
}
.post-header-date {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b7280;
}
.post-header-btn {
  width: 20px;
  height: 26px;
  margin: 0 0 0 auto;
}
.post-img {
  margin-bottom: 24px;
  border-radius: 4px;
  overflow: hidden;
}
.post-img img {
  width: 100%;
}
.post-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
}
.post-text:not(:last-child) {
  margin: 0 0 24px;
}
.post-info {
  display: flex;
  align-items: center;
}
.post-count {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
  margin: 0 8px;
}
.post-like, .post-dislike {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.post-dislike img {
  transform: rotate(180deg);
}
.post-status {
  margin-top: 12px;
}
.post-status__btn {
  vertical-align: top;
}

@media screen and (max-width: 767px) {
  .post {
    border: none;
    margin-bottom: 0;
    position: relative;
    padding: 24px 0;
  }
  .post::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: -16px;
    width: calc(100% + 32px);
    height: 1px;
    background-color: #e5e7eb;
  }
}
@media screen and (max-width: 480px) {
  .post {
    padding: 16px 0;
  }
  .post-header, .post-img, .post-text {
    margin-bottom: 16px;
  }
  .post-header-ttl {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.comments {
  padding-top: 40px;
}
.comments-ttl {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #111827;
  padding-bottom: 16px;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 32px;
}
.comments-tree__child {
  padding-left: 50px;
}
.comments-tree__child .comments-tree__child {
  padding-left: 0 !important;
}
.comments-item {
  padding: 24px 0;
  border-bottom: 1px solid #e5e7eb;
}
.comments-item-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.comments-item-person {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  flex: 0 0 40px;
  overflow: hidden;
}
.comments-item-person img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.comments-item-name {
  max-width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  margin-bottom: 2px;
  flex: 0 0 100%;
}
.comments-item-name.topic-starter {
  color: #ffffff;
  background-color: #16A34A;
  padding: 0 8px;
  border-radius: 80px;
}
.comments-item-info-bottom {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.comments-item-time {
  margin-right: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b7280;
  align-self: center;
}
.comments-item-donate-amount {
  background: #F3F4F6;
  padding: 4px 6px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: #111827;
  align-self: center;
  border-radius: 4px;
  white-space: nowrap;
}
.comments-item-donate-hidden {
  font-size: 12px;
  line-height: 1;
  color: #6b7280;
  flex-shrink: 0;
  align-self: center;
}
.comments-item-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  margin-bottom: 12px;
}
.comments-item-btn {
  width: 20px;
  height: 20px;
  margin: 2px 0 0 auto;
  flex: 0 0 20px;
  align-self: flex-start;
}
.comments-input {
  width: 100%;
  margin-bottom: 20px;
}
.comments-input-wrap {
  display: flex;
  align-items: center;
}
.comments-input-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  /* &:not(.is-show) {
      display: none;
  } */
}
.comments-input-cancel {
  padding: 6px 20px;
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
  cursor: pointer;
}
.comments-input-public {
  padding: 6px 16px;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  margin-left: 8px;
}

@media screen and (min-width: 1200px) {
  .comments-item-btn {
    transition: all 0.18s;
    transition-property: opacity, visibility;
  }
  .comments-item:not(:hover) .comments-item-btn {
    opacity: 0;
    visibility: hidden;
  }
}
@media screen and (max-width: 480px) {
  .comments-ttl {
    margin-bottom: 24px;
  }
  .comments-input-wrap > .comments-item-person {
    display: none;
  }
}
.comment-form {
  padding-top: 40px;
}
.comment-form-ttl {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #111827;
  padding-bottom: 16px;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 32px;
}
.comment-form-tree__child {
  padding-left: 50px;
}
.comment-form-tree__child .comment-form-tree__child {
  padding-left: 0 !important;
}
.comment-form-item {
  padding: 24px 0;
  border-bottom: 1px solid #e5e7eb;
}
.comment-form-item-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.comment-form-item-person {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  flex: 0 0 40px;
  overflow: hidden;
}
.comment-form-item-person img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.comment-form-item-name {
  max-width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  margin-bottom: 2px;
  flex: 0 0 100%;
}
.comment-form-item-name.topic-starter {
  color: #ffffff;
  background-color: #16A34A;
  padding: 0 8px;
  border-radius: 80px;
}
.comment-form-item-info-bottom {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.comment-form-item-time {
  margin-right: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b7280;
  align-self: center;
}
.comment-form-item-donate-amount {
  background: #F3F4F6;
  padding: 4px 6px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: #111827;
  align-self: center;
  border-radius: 4px;
  white-space: nowrap;
}
.comment-form-item-donate-hidden {
  font-size: 12px;
  line-height: 1;
  color: #6b7280;
  flex-shrink: 0;
  align-self: center;
}
.comment-form-item-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  margin-bottom: 12px;
}
.comment-form-item-btn {
  width: 20px;
  height: 20px;
  margin: 2px 0 0 auto;
  flex: 0 0 20px;
  align-self: flex-start;
}
.comment-form .comment-form-textarea {
  font-size: 14px;
  min-height: 44px;
  resize: vertical;
  font-family: "Inter", arial, sans-serif;
}
.comment-form .comment-form-textarea::placeholder {
  font-size: 14px;
}
.comment-form-input {
  width: 100%;
  margin-bottom: 20px;
  /* &-cancel:hover,
  &-cancel:focus,
  &-cancel:active {
      background-color: #ffffff;

  } */
}
.comment-form-input-wrap {
  display: flex;
  align-items: center;
}
.comment-form-input-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  /* &:not(.is-show) {
      display: none;
  } */
}
.comment-form-input-cancel {
  padding: 6px 20px;
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
  background-color: #ffffff;
  cursor: pointer;
}
.comment-form-input-public {
  padding: 6px 16px;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  margin-left: 8px;
}

@media screen and (min-width: 1200px) {
  .comment-form-item-btn {
    transition: all 0.18s;
    transition-property: opacity, visibility;
  }
  .comment-form-item:not(:hover) .comment-form-item-btn {
    opacity: 0;
    visibility: hidden;
  }
}
@media screen and (max-width: 480px) {
  .comment-form-ttl {
    margin-bottom: 24px;
  }
  .comment-form-input-wrap > .comment-form-item-person {
    display: none;
  }
}
.comment-block-ttl {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #111827;
  padding-bottom: 16px;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 32px;
}
.comment-block-tree__child {
  padding-left: 50px;
}
.comment-block-tree__child .comment-block-tree__child {
  padding-left: 0 !important;
}
.comment-block-nocomments {
  font-size: 14px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6b7280;
}
.comment-block-item {
  padding: 16px 0;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: flex-start;
}
.comment-block-item-content {
  flex-grow: 1;
}
.comment-block-item-header {
  display: flex;
  justify-content: space-between;
}
.comment-block-item-amount {
  color: #16A34A;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}
.comment-block-item-person {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 16px;
  flex: 0 0 40px;
  overflow: hidden;
}
.comment-block-item-person img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.comment-block-item-donates {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  padding: 2px 6px;
  background-color: #F3F4F6;
  color: #6b7280;
  border-radius: 90px;
}
.comment-block-item-name {
  max-width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  margin-bottom: 2px;
  flex: 0 0 100%;
  margin-right: 8px;
}
.comment-block-item-name.topic-starter {
  background-color: #D4FADB;
  padding: 0 8px;
  border-radius: 80px;
}
.comment-block-item-info-bottom {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.comment-block-item-time {
  margin-right: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b7280;
  align-self: center;
}
.comment-block-item-donate-amount {
  background: #F3F4F6;
  padding: 4px 6px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: #111827;
  align-self: center;
  border-radius: 4px;
  white-space: nowrap;
}
.comment-block-item-donate-hidden {
  font-size: 12px;
  line-height: 1;
  color: #6b7280;
  flex-shrink: 0;
  align-self: center;
}
.comment-block-item-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  margin-bottom: 12px;
}
.comment-block-item-btn {
  width: 20px;
  height: 20px;
  margin: 2px 0 0 auto;
  flex: 0 0 20px;
  align-self: flex-start;
}
.comment-block-item-votebtn {
  background-color: transparent;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
}
.comment-block-item-actionbtn {
  padding: 6px 20px;
  color: #6b7280;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.comment-block-input {
  width: 100%;
  margin-bottom: 20px;
}
.comment-block-input-wrap {
  display: flex;
  align-items: center;
}
.comment-block-input-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  /* &:not(.is-show) {
      display: none;
  } */
}
.comment-block-input-cancel {
  padding: 6px 20px;
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
  cursor: pointer;
}
.comment-block-input-public {
  padding: 6px 16px;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  margin-left: 8px;
}
.comment-block .comment-block-textarea {
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  min-height: 44px;
  resize: vertical;
  font-family: "Inter", arial, sans-serif;
  padding: 9px 12px;
  color: #111827;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}
.comment-block .comment-block-textarea::placeholder {
  font-size: 14px;
}

@media screen and (min-width: 1200px) {
  .comment-block-item-btn {
    transition: all 0.18s;
    transition-property: opacity, visibility;
  }
  .comment-block-item:not(:hover) .comment-block-item-btn {
    opacity: 0;
    visibility: hidden;
  }
}
@media screen and (max-width: 480px) {
  .comment-block-ttl {
    margin-bottom: 24px;
  }
  .comment-block-input-wrap > .comment-block-item-person {
    display: none;
  }
}
.sortby {
  padding: 24px 0 8px;
}
.sortby-ttl {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #111827;
  margin-right: 16px;
}
.sortby-inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sortby-btn {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin-right: 16px;
  cursor: pointer;
  padding: 0;
  color: #6B7280;
}
.sortby-btn.active {
  color: #2563EB;
}
.sortby-btn.desc .sortby-icon {
  -moz-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}
.sortby-btn-inner {
  display: flex;
  align-items: center;
}
.sortby-icon {
  margin-left: 2px;
}

.input {
  width: 100%;
  position: relative;
}
.input > input {
  width: 100%;
  padding: 9px 12px;
  font-size: 14px;
  line-height: 24px;
  color: #111827;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}
.input > input::placeholder {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #9ca3af;
}
.input > input[type=search], .input > input[type=search]:focus {
  -webkit-appearance: none;
}
.input > input::-webkit-search-decoration, .input > input::-webkit-search-cancel-button, .input > input::-webkit-search-results-button, .input > input::-webkit-search-results-decoration {
  display: none;
}
.input > input:focus {
  border: 1px solid #6b7280;
}
.input > label {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #111827;
  margin-bottom: 12px;
}
.input > label span {
  color: #6b7280;
}
.input p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
  margin: 0 0 12px;
}
.input-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.input-close svg {
  display: block;
}
.input-view-toggle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 44px;
  height: 44px;
  cursor: pointer;
}
.input-view-toggle-icon {
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.4s;
  transition-property: opacity, visibility;
}
.input-view-toggle:not(.active) .input-view-toggle-icon ~ .input-view-toggle-icon, .input-view-toggle.active .input-view-toggle-icon:first-child {
  opacity: 0;
  visibility: hidden;
}
.input textarea {
  width: 100%;
  min-height: 288px;
  padding: 9px 12px;
  font-size: 14px;
  line-height: 24px;
  color: #111827;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}
.input textarea::placeholder {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #9ca3af;
}
.input textarea:focus {
  border: 1px solid #6b7280;
}
.settings-inner .input textarea {
  min-height: 112px;
}
.input-group {
  display: flex;
  gap: 12px;
  margin-bottom: 60px;
}
.input-group--mb-sm {
  margin-bottom: 32px;
}
.input-group:last-of-type {
  margin-bottom: 0;
}
.input-group-524 {
  max-width: 524px;
}
.input-group.input-group-choise {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.input-group-social {
  position: relative;
  display: flex;
  align-items: flex-end;
  gap: 12px;
  margin-bottom: 24px;
}
.input-group-social .input label {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #4B5563;
  margin-bottom: 4px;
}
.input-group-accounts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
}
.input-group-accounts:last-of-type {
  margin-bottom: 0;
}
.input-group-accounts.isConnected {
  align-items: flex-end;
}
.input-group-payment {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input-social-name {
  max-width: 192px;
}
.input-delete {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  cursor: pointer;
}
.input-choise {
  width: 44px;
  min-width: 44px;
  height: 24px;
}
.input-choise input {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-appearance: none;
  background-color: #e5e7eb;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 80px;
  transition: 0.3s;
  cursor: pointer;
}
.input-choise input::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}
.input-choise input:checked {
  background-color: #16A34A;
}
.input-choise input:checked::before {
  transform: translateX(20px);
}
.input-payment {
  max-width: 312px;
}
.input-payment-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #4B5563;
  margin-bottom: 4px;
}
.input-card {
  max-width: 60px;
}
.input-card-group {
  display: flex;
  align-items: center;
}
.input-card-devider {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  margin: 0 8px;
}

@media screen and (max-width: 1000px) {
  .input-group {
    margin-bottom: 48px;
  }
  .settings-inner-form-group-wrap .input-group {
    flex-direction: column;
    gap: 24px;
  }
}
@media screen and (max-width: 480px) {
  .input-group {
    display: block;
    margin-bottom: 32px;
  }
  .input-group .input {
    margin-bottom: 32px;
  }
  .input-group .input:last-of-type {
    margin-bottom: 0;
  }
  .input-group-social {
    position: relative;
    display: block;
    padding-right: 44px;
  }
  .input-group-accounts {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
  }
  .input-group-accounts.isConnected {
    align-items: flex-start;
  }
  .input-social-name {
    max-width: none;
    margin-bottom: 12px;
  }
  .input-delete {
    position: absolute;
    right: 0;
    top: 20px;
  }
  .input-choise {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.content {
  font-size: 18px;
  line-height: 1.55;
  color: #374151;
}
.content > :first-child {
  margin-top: 0;
}
.content > :last-child {
  margin-bottom: 0;
}
.content p {
  margin: 0 0 1.5em;
}

.likes-group {
  display: flex;
  align-items: center;
}
.likes-group.active {
  margin-bottom: 24px;
}
.likes-like, .likes-dislike {
  width: 18px;
  height: 12px;
  cursor: pointer;
  overflow: hidden;
}
.likes-like svg, .likes-dislike svg {
  width: 100%;
}
.likes-like svg path, .likes-dislike svg path {
  stroke: #9CA3AF;
  transition: 0.3s;
}
.likes-like.active svg path {
  fill: #16A34A;
  stroke: #16A34A;
}
.likes-dislike.active svg path {
  fill: #EF4444;
  stroke: #EF4444;
}
.likes-dislike.active svg {
  fill: #EF4444;
  stroke: #EF4444;
}
.likes-count {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b7280;
  margin: 0 6px;
}
.likes-answer, .likes-expand {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2563EB;
  margin-left: 16px;
  cursor: pointer;
}
.likes-expand-inner {
  display: flex;
  align-items: center;
}
.likes-expand-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.likes-expand.active svg {
  transform: rotate(180deg);
}

.aside {
  flex: 1;
}
.aside-section {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #e5e7eb;
}
.aside-section:last-of-type {
  padding-bottom: 0;
  border: none;
}
.aside-section-ttl {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #111827;
  margin-bottom: 12px;
}
.aside-person {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.aside-person:last-of-type {
  margin-bottom: 0;
}
.aside-person-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  flex: 0 0 32px;
  overflow: hidden;
  margin-right: 12px;
}
.aside-person-img img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.aside-person-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #111827;
}
.aside-person-role {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8A9199;
}
.aside-links-ttl {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #111827;
  margin-bottom: 8px;
}
.aside-links-block {
  margin-bottom: 24px;
}
.aside-links-block:last-of-type {
  margin-bottom: 0;
}
.aside-links-link {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2563EB;
  margin-bottom: 6px;
}
.aside-links-link:last-of-type {
  margin-bottom: 0;
}
.aside-top-person {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.aside-top-person:last-of-type {
  margin-bottom: 0;
}
.aside-top-person-img {
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  flex: 0 0 24px;
  overflow: hidden;
  margin-right: 12px;
}
.aside-top-person-img img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.aside-top-person-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
}

@media screen and (max-width: 767px) {
  .aside {
    display: none;
  }
  .aside.mobile {
    display: block;
  }
}
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}

.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline;
  list-style: none;
  padding: 0;
}

.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  font-family: sans-serif;
  overflow: hidden;
  word-break: keep-all;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option--selectable {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
  padding-right: 0px;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}

.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  padding: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
  padding-left: 20px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #999;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
  position: absolute;
  left: 0;
  top: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: #f1f1f1;
  color: #333;
  outline: none;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--group {
  padding: 0;
}

.select2-container--default .select2-results__option--disabled {
  color: #999;
}

.select2-container--default .select2-results__option--selected {
  background-color: #ddd;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: -o-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: linear-gradient(to bottom, #fff 50%, #eee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: -o-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #fff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
  padding-bottom: 5px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #888;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
  outline: none;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option--group {
  padding: 0;
}

.select2-container--classic .select2-results__option--disabled {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #3875d7;
  color: #fff;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.select2 {
  font-size: 14px;
}

.select2-container--default .select2-selection--single {
  background: #fff;
  width: 100%;
  min-height: 44px;
  padding: 9px 11px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.55;
  color: #111827;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  user-select: none;
  outline: none;
  transition: all 0.4s;
  transition-property: border-color;
}
.select2-container--default .select2-selection--single:hover, .select2-container--default .select2-selection--single:focus {
  outline: none;
  border-color: #9CA3AF;
}
.select2-container--default .select2-selection--single:disabled, .select2-container--default .select2-selection--single.disabled {
  background: #F9FAFB !important;
  border-color: #e5e7eb !important;
  user-select: none !important;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-radius: 8px;
}

.select2-container .select2-selection--single {
  height: auto;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0 15px 0 0;
  color: #111827;
  line-height: inherit;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  width: initial;
  height: initial;
  margin: 0 0 0 auto;
  position: relative;
  top: 0;
  right: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  background: 50% no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.9998 9.50146L11.9999 15.5014L6 9.50146' stroke='%23D1D5DB' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  width: 24px;
  height: 24px;
  display: block;
  margin: 0;
  border: none;
  position: relative;
  top: 0;
  left: 0;
  transition: all 0.4s;
  transition-property: transform;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  transform: scale(-1);
}

.select2-dropdown {
  background: #ffffff;
  margin-top: 4px;
  border: none;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.select2-container--default .select2-results > .select2-results__options,
.select2-results__options {
  max-height: 207px;
}

.select2-results__options {
  padding: 4px;
  scrollbar-color: #D1D5DB transparent;
  scrollbar-width: thin;
}
.select2-results__options::-webkit-scrollbar {
  width: 4px;
}
.select2-results__options::-webkit-scrollbar-track {
  border: solid 6px transparent;
}
.select2-results__options::-webkit-scrollbar-thumb {
  background-color: #D1D5DB;
  border-radius: 18px;
  border: 6px solid transparent;
}

.select2-results__option {
  background: transparent;
  width: 100%;
  padding: 10px 12px;
  display: block;
  font-size: 14px;
  line-height: 1.2;
  color: #374151;
  text-align: left;
  border: none;
  border-radius: 4px;
  outline: none;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.24s;
  transition-property: background;
}
.select2-results__option + .select2-results__option {
  margin-top: 4px;
}

.select2-container--default .select2-results__option--disabled {
  color: rgba(156, 163, 175, 0.8);
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable,
.select2-container--default .select2-results__option--selected {
  background: #F3F4F6;
  text-decoration: none;
  color: #374151;
}

.u-icon {
  width: 1em;
  height: 1em;
  display: inline-block;
  color: inherit;
  fill: currentColor;
}

.row {
  margin: 0 -25px;
  display: flex;
  flex-wrap: wrap;
}
.row__col {
  max-width: 100%;
  min-height: 1px;
  padding: 0 24px;
  flex: 1;
}
.row__col--4 {
  max-width: 33.333333333%;
  flex-basis: 33.333333333%;
}
.row__col--6 {
  max-width: 50%;
  flex-basis: 50%;
}

.text-accent {
  color: #16A34A;
}

.text-blue {
  color: #2563EB;
}

.img-cover, .l-home-intro__picture, .project-blog-img, .project-content-img, .project-main-media, .project-header-img, .popup-cover-grid-item__inner, .user-item__picture {
  display: block;
  position: relative;
  overflow: hidden;
}
.img-cover img, .l-home-intro__picture img, .project-blog-img img, .project-content-img img, .project-main-media img, .project-header-img img, .popup-cover-grid-item__inner img, .user-item__picture img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.text-overflow, .user-project-donate-card__user-name, .user-project-donate-card__header-link, .notification-card__name {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-clamp, .project-thumbnail-card__title, .feed-card__body-info-name a, .feed-card__user-top-name a, .feed-card__user-top-name, .notification-card__footer {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-clamp--12 {
  -webkit-line-clamp: 12;
}

.card-box-text {
  margin: 0;
  line-height: 1.7;
  color: #374151;
}
.card-box-text > :first-child {
  margin-top: 0;
}
.card-box-text > :last-child {
  margin-bottom: 0;
}

.title-3, .p-faq__title, .p-project__title {
  margin: 0;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.35;
}
.title-5 {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}

.notification-card {
  background: #ffffff;
  font-size: 14px;
  line-height: 1.57;
  color: #111827;
  text-align: initial;
  border-radius: 4px;
  position: relative;
  transition: all 0.4s;
  transition-property: background;
}
.notification-card--unread {
  background: #F8FAFC;
}
.notification-card--size-full {
  border-radius: 0;
}
@media screen and (min-width: 1200px) {
  .notification-card:hover {
    background: #F3F4F6;
  }
}
.notification-card__inner {
  padding: 12px;
  display: flex;
  align-items: flex-start;
}
.notification-card--size-full .notification-card__inner {
  padding-top: 28px;
  padding-bottom: 28px;
}
.notification-card__avatar {
  width: 40px;
  height: 40px;
  margin: 0 12px 0 0;
  flex: 0 0 40px;
  overflow: visible;
}
.notification-card__avatar img {
  border-radius: 50%;
}
.notification-card__avatar-link:before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}
.notification-card__content {
  overflow: hidden;
}
.notification-card__top {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.notification-card__name {
  margin: 0 0.4em 0 0;
  font-weight: 500;
}
.notification-card__name a {
  color: inherit;
}
.notification-card__name a:before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}
.notification-card__donate-wrapper .notification-card__name {
  width: 100%;
}
.notification-card__donate-wrapper {
  display: flex;
  align-items: flex-start;
}
.notification-card__action {
  color: #9CA3AF;
}
.notification-card__action a,
.notification-card__action span {
  color: #2563EB;
}
.notification-card__action a {
  box-shadow: inset 0 -1px 0 transparent;
  position: relative;
  transition: all 0.4s;
  transition-property: box-shadow;
  z-index: 1;
}
@media screen and (min-width: 1200px) {
  .notification-card__action a:hover {
    box-shadow: inset 0 -1px 0 currentColor;
  }
}
.notification-card__donate {
  margin-left: auto;
  padding-left: 5px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 14px;
  color: #16A34A;
  white-space: nowrap;
}
.notification-card__text {
  margin: 2px 0 0;
}
.notification-card__text a,
.notification-card__text span {
  color: #2563EB;
}
.notification-card__text a {
  box-shadow: inset 0 -1px 0 transparent;
  position: relative;
  transition: all 0.4s;
  transition-property: box-shadow;
  z-index: 1;
}
@media screen and (min-width: 1200px) {
  .notification-card__text a:hover {
    box-shadow: inset 0 -1px 0 currentColor;
  }
}
.notification-card--size-full .notification-card__text {
  display: block;
  -webkit-line-clamp: initial;
  -webkit-box-orient: initial;
  overflow: initial;
}
.notification-card__view-all {
  margin-top: 2px;
  display: inline-block;
  line-height: 1.2;
  color: #2563EB;
  position: relative;
  z-index: 1;
  box-shadow: inset 0 -1px 0 transparent;
  transition: all 0.4s;
  transition-property: box-shadow;
}
@media screen and (min-width: 1200px) {
  .notification-card__view-all:hover {
    box-shadow: inset 0 -1px 0 currentColor;
  }
}
.notification-card--size-full .notification-card__view-all {
  display: none;
}
.notification-card__footer {
  margin-top: 2px;
  color: #9CA3AF;
}
.notification-card__time {
  display: inline;
}
.notification-card__footer-subtitle {
  display: inline;
}
.notification-card__footer-subtitle a,
.notification-card__footer-subtitle span {
  color: #2563EB;
}
.notification-card__footer-subtitle a {
  box-shadow: inset 0 -1px 0 transparent;
  position: relative;
  transition: all 0.4s;
  transition-property: box-shadow;
  z-index: 1;
}
@media screen and (min-width: 1200px) {
  .notification-card__footer-subtitle a:hover {
    box-shadow: inset 0 -1px 0 currentColor;
  }
}
.notification-card__group-btns {
  padding-top: 8px;
  position: relative;
  z-index: 0;
}

.notification-board {
  display: none !important;
}
.notification-board__main {
  height: calc(100vh - 140px);
  max-height: 624px;
  min-height: 280px;
  margin: 4px;
  scrollbar-width: none;
  scrollbar-color: #D1D5DB transparent;
  overflow-x: hidden;
  overflow-y: auto;
}
.notification-board__main::-webkit-scrollbar {
  width: 3px;
}
.notification-board__main::-webkit-scrollbar-track {
  background: transparent;
}
.notification-board__main::-webkit-scrollbar-thumb {
  background-color: #D1D5DB;
  border-radius: 3px;
  border: 3px solid #D1D5DB;
}
.notification-board__list {
  margin: -4px 0 -4px -4px;
  padding: 0 4px;
}
.notification-board__list-card:not(:last-child) {
  margin-bottom: 4px;
}
.notification-board__footer {
  border-top: 1px solid #EEF0F3;
}
.notification-board__view-all {
  padding: 14px;
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.3;
  color: #2563EB;
  text-align: center;
  border-radius: 0 0 8px 8px;
  transition: all 0.4s;
  transition-property: background;
}
@media screen and (min-width: 1200px) {
  .notification-board__view-all:hover {
    background: rgba(238, 240, 243, 0.65);
  }
}

.btn-reset, .faq-nav__btn, .btn-reply, .comment-btn, .share-el__item, .share-el__dropdown-cancel, .donate-btn, .btn-vote, .attach-file__btn-remove, .widgets-btn, .filter-item__dropdown-action {
  background: transparent;
  margin: 0;
  padding: 0;
  font-size: 0;
  outline: none;
  border: none;
  border-radius: 0;
}

.btn-action {
  background: transparent;
  width: 24px;
  height: 24px;
  font-size: 0;
  color: #9CA3AF;
  outline: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  position: relative;
  transition: all 0.4s;
  transition-property: color;
}
.btn-action:hover {
  text-decoration: none;
}
.btn-action:before {
  content: "";
  position: absolute;
  inset: -4px;
  border-radius: 8px;
  transition: all 0.4s;
  transition-property: background;
}
.btn-action:hover:before {
  background: rgba(107, 114, 128, 0.1);
}
.btn-action__icon {
  width: 100%;
  height: 100%;
  display: block;
}
.btn-action__notification {
  background: #ffffff;
  width: 448px;
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.btn-close {
  background: transparent;
  width: 24px;
  height: 24px;
  display: inline-block;
  font-size: 0;
  color: #9CA3AF;
  position: relative;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  transition: all 0.4s;
  transition-property: color;
}
.btn-close:hover {
  color: #111827;
}
.btn-close:before {
  content: "";
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
}
.btn-close svg {
  width: 100%;
  height: 100%;
}

.media-responsive {
  padding: 0 0 56.25%;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}
.media-responsive > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video-link-block__link-wrap {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.4;
  overflow: hidden;
}
.video-link-block__link {
  margin-right: 24px;
  color: #9CA3AF;
}
.video-link-block__btn-change {
  flex-shrink: 0;
  font-size: 14px;
  line-height: 1.4;
  color: #2563EB;
  position: relative;
  cursor: pointer;
  transition: all 0.4s;
  transition-property: color;
}
.video-link-block__btn-change:before {
  content: "";
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
}
.video-link-block__el-wrap {
  margin: 16px 0 0;
}
.video-link-block__link-edit {
  margin-bottom: 32px;
}
.video-link-block__link-edit-input {
  width: 100%;
  margin: 0 0 16px;
}
.video-link-block__link-edit-group-btns .btn {
  min-height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.input-donate {
  width: 100%;
  padding: 2px 0;
  display: block;
  font-weight: 600;
  font-size: 48px;
  line-height: 1.4;
  color: #000;
  border: solid #e5e7eb;
  border-width: 0 0 1px;
  border-radius: 0;
  outline: none;
  -moz-appearance: textfield;
  transition: all 0.4s;
  transition-property: border-color;
}
.input-donate:hover, .input-donate:focus {
  outline: none;
}
.input-donate::placeholder {
  color: #e5e7eb;
}
.input-donate::-webkit-outer-spin-button, .input-donate::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-el {
  background: #fff;
  width: 100%;
  min-height: 44px;
  padding: 10px 11px;
  display: block;
  font-size: 14px;
  line-height: 1.55;
  color: #111827;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  outline: none;
  transition: all 0.4s;
  transition-property: border-color;
}
.input-el:hover, .input-el:focus {
  outline: none;
  border-color: #9CA3AF;
}
.input-el:disabled {
  background: #F9FAFB !important;
  border-color: #e5e7eb !important;
  user-select: none !important;
}
.input-el::placeholder {
  color: #9CA3AF;
}
.input-el--textarea {
  max-width: 100%;
  min-width: 100%;
  resize: vertical;
}
.input-el--textarea-size-md {
  min-height: 110px;
}
.input-el--textarea-size-lg {
  min-height: 260px;
}
.input-el--select {
  background-position: right 11px center;
  background-size: 24px;
  background-repeat: no-repeat;
  padding-right: 46px;
  appearance: none;
}
.input-el--select::-ms-expand {
  display: none;
}

.input-link__label {
  display: block;
}
.input-link__label-title {
  margin: 0 0 7px;
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: #4B5563;
}
.input-link__label-input {
  width: 100%;
}

.link-add__inner {
  display: flex;
}
.link-add__name {
  max-width: 32%;
  margin-right: 12px;
  flex: 0 0 32%;
}
.link-add__url {
  margin-right: 8px;
  flex-grow: 1;
}
.link-add__remove {
  width: 24px;
  max-width: 24px;
  height: 24px;
  margin-bottom: 10px;
  flex: 0 0 24px;
  align-self: flex-end;
}

.input-file {
  display: block;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.input-file--picture {
  width: 100%;
  padding-bottom: 56.25%;
  border-radius: 8px;
  overflow: hidden;
}
.input-file--logo {
  width: 160px;
  height: 160px;
  border-radius: 50%;
}
.input-file__input {
  display: none;
  position: absolute;
  left: -9999px;
}
.input-file__inner {
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 1.7;
  color: #9CA3AF;
  text-align: center;
  border: 2px dashed #D1D5DB;
  border-radius: inherit;
  position: absolute;
  inset: 0;
}
.input-file__icon {
  width: 40px;
  height: 40px;
  margin: 0 0 4px;
  display: block;
}
.input-file__description {
  margin: 0 0 8px;
  display: block;
}
.input-file__title {
  margin: 0;
  display: block;
  color: #2563EB;
}
.input-file__load {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  transition: all 0.4s;
  transition-property: opacity, visibility;
  overflow: hidden;
  z-index: 1;
}
.input-file__load img[src=""] {
  display: none;
}
.input-file:hover .input-file__load {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

.input-block {
  line-height: 1.5;
}
.input-block__label {
  display: block;
}
.input-block__label-name {
  margin: 0 0 12px;
  display: block;
  font-weight: 500;
  font-size: 16px;
}
.input-block__label-name span {
  color: #6b7280;
}
.input-block__label-name strong {
  font-weight: 500;
}
.input-block--label-size-md .input-block__label-name {
  font-weight: normal;
  font-size: 14px;
}
.input-block--label-size-sm .input-block__label-name {
  margin-bottom: 4px;
  font-weight: normal;
  font-size: 12px;
  color: #4B5563;
}
.input-block__label-description {
  margin: -8px 0 12px;
  display: block;
  font-size: 14px;
  color: #6b7280;
}
.input-block__add-link {
  margin: 12px 0 0;
}
.input-block__el-container {
  display: block;
  position: relative;
}
.input-block__el-icon-wrapper {
  width: 48px;
  height: 100%;
  color: #D1D5DB;
  position: absolute;
  top: 0;
  cursor: pointer;
  z-index: 1;
  transition: all 0.4s;
  transition-property: color;
}
.input-block__el-icon-wrapper--position-left {
  left: 0;
}
.input-block__el-icon-wrapper--position-right {
  right: 0;
}
@media screen and (min-width: 1200px) {
  .input-block__el-icon-wrapper:hover {
    color: #111827;
  }
}
.input-block__el-icon {
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 0.4s;
  transition-property: opacity, visibility;
}
.input-block__el-icon-wrapper.is-password-show .input-block__el-icon:first-child, .input-block__el-icon-wrapper:not(.is-password-show) .input-block__el-icon:last-child {
  opacity: 0;
  visibility: hidden;
}
.input-block__el-icon-wrapper ~ .input-block__el {
  padding-right: 50px;
}
.input-block__textarea-wrapper {
  position: relative;
}
.input-block__textarea-counter {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.35;
  color: #D1D5DB;
  position: absolute;
  right: 8px;
  bottom: 8px;
  pointer-events: none;
}
.input-block__label-radio {
  margin: 0 0 12px;
}
.input-block__label ~ .input-file--logo {
  margin-top: 24px;
}
.input-block__template-cards {
  margin: 16px -8px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
}
.input-block__template-cards-item {
  max-width: 100%;
  padding: 8px;
  flex: 1;
}
.input-block__template-cards-item--size-4 {
  max-width: 33.3333333%;
  flex-basis: 33.3333333%;
}
.input-block__editor img {
  max-width: 100%;
  height: auto;
  display: block;
}
.input-block__video-link-block {
  padding-top: 4px;
}
.input-block__link-add {
  margin: 24px 0;
}
.input-block__label-tooltip {
  margin-left: 7px;
}

.btn {
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.4s;
  transition-property: background, color, border-color;
}
.btn-white {
  background-color: #ffffff;
  font-weight: 400;
  color: #374151;
  border-color: #D1D5DB;
}
.btn-trp {
  background-color: transparent;
  font-weight: 400;
  color: #374151;
  border-color: #D1D5DB;
}
.btn-trp:hover {
  border-color: #e5e7eb;
}
.btn-trp:focus, .btn-trp:active {
  background: #F3F4F6;
  border-color: #D1D5DB;
}
.btn-simple {
  background-color: transparent;
  font-weight: 400;
  color: #2563EB;
}
.btn-simple:hover {
  background: rgba(229, 231, 235, 0.4);
}
.btn-simple:focus, .btn-simple:active {
  background: rgba(229, 231, 235, 0.7);
}
.btn-cancel {
  background-color: transparent;
  font-weight: 400;
  color: #6b7280;
}
.btn-cancel:hover {
  color: #111827;
}
.btn-green {
  font-weight: 500;
  color: #ffffff;
  background-color: #15803D;
}
.btn-green:hover {
  background: #166534;
}
.btn-green:focus, .btn-green:active {
  background: #14532D;
}
.btn-green-accent {
  font-weight: 500;
  color: #ffffff;
  background-color: #16A34A;
}
.btn-green-accent:hover {
  background: #166534;
}
.btn-green-accent:focus, .btn-green-accent:active {
  background: #14532D;
}
.btn-dark {
  font-weight: 500;
  color: #ffffff;
  background-color: #111827;
}
.btn-dark:hover {
  background: #166534;
}
.btn-dark:focus, .btn-dark:active {
  background: #14532D;
}
.btn--action {
  padding: 8px;
}
.btn--size-lt {
  padding: 3px 16px;
}
.btn--size-sm {
  padding: 9px 20px;
}
.btn--size-md {
  padding: 11px 28px;
}
.btn__icon {
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -10px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.city-label {
  display: inline-flex;
  align-items: center;
}
.city-label__icon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
  flex: 0 0 20px;
}

.filter-item {
  font-size: 14px;
  line-height: 1;
  position: relative;
}
.filter-item__btn {
  background: transparent;
  padding: 5px 0;
  display: flex;
  align-items: center;
  font-size: inherit;
  line-height: inherit;
  color: #2563EB;
  position: relative;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  transition: all 0.4s;
  transition-property: color;
}
.filter-item__btn:before {
  content: "";
  position: absolute;
  inset: -6px;
}
.filter-item__btn:after {
  width: 0;
  height: 0;
  margin: 0.2em 0 0 0.3em;
  content: "";
  display: inline-block;
  flex-shrink: 0;
  border-style: solid;
  border-width: 3px 3px 0 3px;
  border-color: #2563EB transparent transparent transparent;
  vertical-align: middle;
  transition: all 0.4s;
  transition-property: transform;
}
.filter-item.is-open .filter-item__btn:after {
  transform: scaleY(-1);
}
.filter-item--dotted .filter-item__btn {
  font-size: 0;
  color: #9CA3AF;
}
.filter-item--dotted .filter-item__btn:after {
  display: none;
}
.filter-item__btn-dotted {
  width: 20px;
  height: 20px;
  display: block;
}
.filter-item__dropdown {
  background: #ffffff;
  padding: 4px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: absolute;
  top: 100%;
  right: 0;
  transition: all 0.4s;
  transition-property: transform, visibility, opacity;
  z-index: 2;
}
.filter-item__dropdown-action {
  width: 100%;
  padding: 10px 12px;
  display: block;
  font-size: 14px;
  line-height: 1.2;
  color: #374151;
  text-align: left;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.24s;
  transition-property: background;
}
.filter-item__dropdown-action:hover {
  background: #F3F4F6;
  text-decoration: none;
}
.filter-item:not(.is-open) .filter-item__dropdown {
  transform: translateY(4px);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.filter-group {
  display: flex;
}
.filter-group__item:not(:last-child) {
  margin-right: 32px;
}

.widgets-btn {
  cursor: pointer;
  background: #16A34A;
  min-height: 36px;
  padding: 7px 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  border-radius: 8px;
}
.widgets-btn--size-md {
  min-height: 44px;
  padding: 10px 24px;
}
.widgets-btn__icon-wrap {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  flex: 0 0 20px;
}
.widgets-btn__icon {
  width: 100%;
  height: 100%;
  display: block;
  fill: currentColor;
}
.code-area {
  margin: 0;
  display: block;
}
.code-area__body {
  background: #F8FAFC;
  margin: 0;
  padding: 16px;
  display: block;
  font-size: 14px;
  line-height: 1.7;
  color: #242B33;
  white-space: pre-wrap;
  word-break: break-all;
  border-radius: 8px;
}

.widgets-card {
  font-size: 14px;
  line-height: 1.3;
  color: #111827;
}
.widgets-card__inner {
  padding: 16px;
  border: 1px solid #e5e7eb;
  border-radius: 12px;
}
.widgets-card__header {
  margin: 0 0 12px;
  display: flex;
  align-items: center;
}
.widgets-card__picture {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 50%;
  flex: 0 0 40px;
  position: relative;
  overflow: hidden;
}
.widgets-card__img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}
.widgets-card__poster {
  margin: 0 0 12px;
  padding-bottom: 56.25%;
  display: block;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.widgets-card__header-content {
  overflow: hidden;
}
.widgets-card__header-title {
  margin: 0 0 3px;
  font-weight: 500;
}
.widgets-card__header-subtitle {
  font-size: 12px;
  color: #9CA3AF;
}
.widgets-card__text {
  font-size: 14px;
  line-height: 1.7;
  color: #374151;
}
.widgets-card__text:not(:last-child) {
  margin-bottom: 12px;
}
.widgets-card__text > * {
  margin: 0;
}
.widgets-card__btn {
  width: 100%;
}

.widgets-item {
  line-height: 1.3;
}
.widgets-item__title {
  margin: 0 0 32px;
  font-weight: 600;
  font-size: 20px;
}
.widgets-item__subtitle {
  margin: 0 0 10px;
  font-size: 14px;
  color: #6b7280;
}
.widgets-item__view-body {
  margin: 0 0 32px;
}
.widgets-item--card-small .widgets-item__view-body {
  max-width: 360px;
}
.widgets-item--card-big .widgets-item__view-body {
  max-width: 480px;
}
.subscriber-card {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.3;
}
.subscriber-card__content {
  padding-right: 15px;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.subscriber-card__picture {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  flex: 0 0 40px;
  border-radius: 50%;
  overflow: hidden;
}
.subscriber-card__text {
  overflow: hidden;
}
.subscriber-card__name {
  font-weight: 500;
  font-size: 16px;
}
.subscriber-card__name:not(:last-child) {
  margin-bottom: 2px;
}
.subscriber-card__position {
  color: #8A9199;
}
.subscriber-card__actions {
  margin-left: auto;
  flex-shrink: 0;
}

.subscribers-list__item:not(:last-child) {
  margin-bottom: 24px;
}

.language-site {
  font-size: 14px;
  line-height: 1;
  color: #111827;
  white-space: nowrap;
}
.language-site__current {
  padding: 14px 0;
  display: flex;
  align-items: center;
}
.language-site__current-icon {
  width: 20px;
  height: 20px;
  margin-left: 4px;
  flex: 0 0 20px;
  transition: all 0.4s;
  transition-property: transform;
}
.language-site.is-active .language-site__current-icon {
  transform: scaleY(-1);
}
.language-site:not(.is-active) .language-site__dropdown {
  display: none;
}
.language-site__link {
  margin-top: 4px;
  padding: 14px 0;
  display: block;
  color: inherit;
  text-decoration: none;
}

.profile-card {
  display: flex;
  align-items: flex-end;
  line-height: 1.3;
}
.profile-card__avatar {
  width: 128px;
  height: 128px;
  margin-right: 12px;
  flex: 0 0 128px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #ffffff;
  overflow: hidden;
}
.profile-card__name {
  margin: 0 0 8px;
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
}
.profile-card__subscribes {
  margin: 0 0 2px;
  font-size: 14px;
  line-height: 1;
  color: #9CA3AF;
}

.user-profile {
  padding: 10px 12px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #111827;
  position: relative;
  z-index: 0;
  transition: all 0.4s;
}
.user-profile__avatar {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  flex: 0 0 32px;
  border-radius: 50%;
}
.user-profile__content {
  margin-right: 10px;
  flex-grow: 1;
}
.user-profile__content-name {
  margin: 0 0 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #111827 !important;
}
.user-profile__content-name:before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}
.user-profile__content-email {
  font-size: 12px;
  line-height: 1;
  color: #8A9199;
}
.user-profile__icon-wrap {
  width: 20px;
  height: 20px;
  margin: 0 -4px 0 auto;
  flex: 0 0 20px;
  position: relative;
  z-index: -1;
}
.user-profile__icon {
  width: 100%;
  height: 100%;
  display: none;
}
.user-profile--is-active .user-profile__icon--active {
  display: block;
}

.profile-dropdown {
  background: #fff;
  padding: 4px;
  border-radius: 8px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
  transition-property: transform, visibility, opacity;
}
.profile-dropdown:not(.is-show) {
  transform: translateY(6px);
  opacity: 0;
  visibility: hidden;
}
.profile-dropdown__nav {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.profile-dropdown__nav:before {
  background: #e5e7eb;
  height: 1px;
  margin: 4px -4px;
  content: "";
  display: block;
}
.profile-dropdown__nav-item {
  margin: 0;
  padding: 0;
}
.profile-dropdown__nav-link {
  padding: 11px 12px;
  font-size: 14px;
  line-height: 1;
  display: block;
  color: #374151 !important;
  border-radius: 4px;
  white-space: nowrap;
  transition: all 0.4s;
  transition-property: background;
}
.profile-dropdown__nav-link:hover, .profile-dropdown__nav-link.is-active {
  background: #F3F4F6;
  text-decoration: none;
}
.profile-dropdown__overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.list-content {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.list-content li {
  margin: 0;
  padding: 0;
}
.list-content li:not(:last-child) {
  margin-bottom: 16px;
}
.list-content li:before {
  padding-right: 0.15em;
  content: "•";
}
.list-content--theme-dots-accent li:before {
  color: #16A34A;
}
.list-content a {
  color: inherit;
  box-shadow: 0 1px 0 transparent;
  transition: all 0.4s;
  transition-property: box-shadow;
}
.list-content a:hover {
  box-shadow: 0 1px 0 currentColor;
}
.list-content a:visited {
  color: inherit;
}

.scroll-up {
  background: #F8FAFC;
  padding: 10px 16px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 1;
  color: #2563EB !important;
  user-select: none;
  border-radius: 8px;
  position: relative;
  white-space: nowrap;
  transition: all 0.34s;
  transition-property: background, transform, opacity, visibility;
  z-index: 100;
}
.scroll-up:hover {
  background: #eef1f4;
}
.scroll-up:not(.is-show) {
  transform: translateY(120px);
  opacity: 0;
  visibility: hidden;
}
.scroll-up--position-right-bottom {
  position: fixed;
  right: 40px;
  bottom: 41px;
}
.scroll-up__icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  stroke: currentColor;
  color: inherit;
  flex: 0 0 24px;
}
.verification-status {
  display: flex;
  align-items: flex-start;
  line-height: 24px;
}
.verification-status--hold {
  color: #6b7280;
}
.verification-status--passed {
  color: #16A34A;
}
.verification-status--failed {
  color: #EF4444;
}
.verification-status__icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  flex: 0 0 24px;
}
.verification-status__text {
  font-weight: 500;
  font-size: 18px;
}

.list-counter {
  margin: 0;
  padding: 0;
  list-style-type: none;
  counter-reset: list-counter-1;
}
.list-counter li {
  margin: 0;
  padding: 0 0 0 30px;
  counter-increment: list-counter-1;
  position: relative;
}
.list-counter li:before {
  content: counter(list-counter-1) ". ";
  position: absolute;
  top: 0;
  left: 0;
}
.list-counter li:not(:last-child) {
  margin-bottom: 16px;
}
.list-counter strong {
  font-weight: 600;
}
.list-counter p {
  margin: 0;
}
.list-counter p:not(:last-child) {
  margin-bottom: 10px;
}
.list-counter a:not([class]) {
  color: #2563EB !important;
  box-shadow: 0 1px 0 currentColor;
  transition: all 0.4s;
  transition-property: box-shadow;
}
.list-counter a:not([class]):hover {
  box-shadow: 0 1px 0 transparent;
}
.list-counter .btn {
  display: inline-block;
}

.list-text {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.list-text li {
  margin: 0;
  padding: 0;
  position: relative;
}
.list-text li:not(:last-child) {
  margin-bottom: 16px;
}
.list-text strong {
  font-weight: 600;
}
.list-text p {
  margin: 0;
}
.list-text p:not(:last-child) {
  margin-bottom: 10px;
}
.list-text a:not([class]) {
  color: #2563EB !important;
  box-shadow: 0 1px 0 currentColor;
  transition: all 0.4s;
  transition-property: box-shadow;
}
.list-text a:not([class]):hover {
  box-shadow: 0 1px 0 transparent;
}
.list-text .btn {
  display: inline-block;
}

.attach-file {
  display: flex;
  align-items: center;
}
.attach-file__name {
  min-width: 108px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: #2563EB !important;
  box-shadow: 0 1px 0 transparent;
  transition: all 0.4s;
  transition-property: box-shadow;
}
.attach-file__name:hover {
  box-shadow: 0 1px 0 currentColor;
}
.attach-file__btn-remove {
  width: 20px;
  height: 20px;
  margin: 0 0 0 8px;
  flex: 0 0 20px;
  color: #9CA3AF;
  cursor: pointer;
  transition: all 0.4s;
  transition-property: transform;
  position: relative;
}
.attach-file__btn-remove:hover {
  transform: rotate(180deg);
}
.attach-file__btn-remove:before {
  content: "";
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
}
.attach-file__btn-remove-icon {
  width: 100%;
  height: 100%;
  display: block;
}

.phone-binding {
  background: #F8FAFC;
  padding: 24px;
  color: #111827;
  border-radius: 8px;
}
.phone-binding__text {
  margin: 0 0 16px;
  font-size: 14px;
  line-height: 1.7;
}
.phone-binding__btn {
  display: inline-block;
}

.group-btns {
  margin: -6px;
  display: flex;
  align-items: center;
}
.group-btns__item {
  margin: 6px;
}

.btn-vote {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
}
.btn-vote:before {
  content: "";
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
}
.btn-vote__icon {
  width: 100%;
  height: 100%;
  fill: transparent;
  stroke: #9CA3AF;
  transition: all 0.4s;
  transition-property: fill, stroke;
}
.btn-vote--dislike .btn-vote__icon {
  transform: scaleY(-1);
}
.btn-vote--like:hover .btn-vote__icon {
  fill: #16A34A;
  stroke: #16A34A;
}
.btn-vote--dislike:hover .btn-vote__icon {
  fill: #EF4444;
  stroke: #EF4444;
  transform: scaleY(-1);
}

.rating-vote {
  display: flex;
  align-items: center;
}
.rating-vote__btn {
  flex: 0 0 20px;
}
.rating-vote__count {
  margin: 0 6px;
  flex-shrink: 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 1;
  color: #6b7280;
  text-align: center;
}

.user-project-donate-card {
  font-size: 14px;
  color: #111827;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
}
.user-project-donate-card__inner {
  padding: 24px;
}
.user-project-donate-card__header {
  margin: -4px 0 24px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e7eb;
}
.user-project-donate-card__header-logo {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  flex: 0 0 32px;
  border-radius: 50%;
  user-select: none;
  overflow: hidden;
}
.user-project-donate-card__header-title {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  overflow: hidden;
}
.user-project-donate-card__header-link {
  display: block;
  color: #111827 !important;
}
.user-project-donate-card__user {
  margin: 0 0 12px;
  display: flex;
  align-items: center;
}
.user-project-donate-card__user-avatar {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  flex: 0 0 40px;
  border-radius: 50%;
  user-select: none;
  overflow: hidden;
}
.user-project-donate-card__user-content {
  overflow: hidden;
}
.user-project-donate-card__user-name {
  margin: 0 0 2px;
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: #111827 !important;
}
.user-project-donate-card__user-info {
  display: flex;
  align-items: center;
  line-height: 1.2;
  flex-wrap: wrap;
}
.user-project-donate-card__user-date {
  margin-right: 6px;
  font-size: 12px;
  line-height: 1;
  color: #6b7280;
}
.user-project-donate-card__user-amount {
  background: #F3F4F6;
  padding: 4px 6px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: #111827;
  align-self: center;
  border-radius: 4px;
  white-space: nowrap;
}
.user-project-donate-card__text {
  margin: 0 0 12px;
  font-size: 14px;
  line-height: 1.7;
}
.verification-checked:after {
  background: url(/img/verification-checked.svg) 50% no-repeat;
  background-size: contain;
  width: 1em;
  height: 1em;
  margin-left: 0.22em;
  content: "";
  display: inline-block;
  flex-shrink: 0;
  vertical-align: middle;
}

.donate-btn {
  padding: 8px 15px;
  display: inline-block;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  color: #374151;
  border: 1px solid #D1D5DB;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.4s;
  transition-property: background, color, border-color;
}
.donate-btn.is-active {
  background: #16A34A;
  color: #fff;
  border-color: #16A34A;
}
.donate-btn:hover {
  border-color: #16A34A;
}

.donate-btn-list__inner {
  margin: -4px;
  display: flex;
  flex-wrap: wrap;
}
.donate-btn-list__item {
  margin: 4px;
}

.tooltip-donate {
  display: inline-block;
  font-size: 0;
  color: #9CA3AF;
  vertical-align: middle;
  position: relative;
  transition: all 0.4s;
  transition-property: color;
  /*   &:not(.is-active) &__dropdown,
    &:not:hover &__dropdown  {
        transform: translateX(6px) translateY(-50%);
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
    } */
}
.tooltip-donate:hover, .tooltip-donate:active, .tooltip-donate.is-active {
  color: #111827;
}
.tooltip-donate:hover .tooltip-donate__dropdown, .tooltip-donate:active .tooltip-donate__dropdown, .tooltip-donate.is-active .tooltip-donate__dropdown {
  pointer-events: auto;
  opacity: 1;
  visibility: visible;
  transform: translateY(-50%);
  transition: all 0.4s;
  transition-property: transform, opacity, visibility;
}
.tooltip-donate__icon {
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
  position: relative;
}
.tooltip-donate__icon:before {
  content: "";
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
}
.tooltip-donate__dropdown {
  background: #fff;
  width: 320px;
  min-height: 186px;
  margin-left: 16px;
  padding: 16px;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 100%;
  border-radius: 8px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: initial;
  z-index: 2;
  transition: all 0.4s;
  transition-property: transform, opacity, visibility;
  transform: translateX(6px) translateY(-50%);
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}
.tooltip-donate__dropdown:before {
  margin: -7px -1px 0 0;
  content: "";
  border-style: solid;
  border-width: 7px 10px 7px 0;
  border-color: transparent #fff transparent transparent;
  position: absolute;
  top: 50%;
  right: 100%;
  z-index: -1;
}
.tooltip-donate__dropdown-close {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 12px;
  right: 12px;
}
.tooltip-donate__dropdown-text {
  margin: 0 0 12px;
  font-size: 14px;
  line-height: 1.7;
  color: #6b7280;
}
.tooltip-donate__dropdown-amount {
  margin: 0 0 12px;
  padding-bottom: 8px;
  font-weight: 600;
  font-size: 36px;
  line-height: 1.1;
  color: #111827;
  border-bottom: 1px solid #e5e7eb;
}

.btn-action-simple {
  background: transparent;
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  color: #6b7280;
  border: none;
  border-radius: 0;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
}
.btn-action-simple:before {
  content: "";
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
}
.btn-action-simple__icon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  flex: 0 0 20px;
}
.share-el {
  font-weight: normal;
  font-size: 14px;
  color: #374151;
  position: relative;
}
.share-el__dropdown {
  background: #fff;
  margin-top: 24px;
  font-size: 14px;
  position: absolute;
  top: 100%;
  right: 0;
  border-radius: 8px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
  transition-property: transform, opacity, visibility;
  z-index: 2;
}
.share-el__dropdown-body {
  width: 256px;
  padding: 4px;
}
.share-el__dropdown.is-qrcode .share-el__dropdown-body {
  display: none;
}
.share-el__dropdown-cancel {
  width: 100%;
  margin-top: 8px;
  padding: 13px;
  display: none;
  font-size: 14px;
  text-align: center;
  border: 1px solid #D1D5DB;
  border-radius: 8px;
  cursor: pointer;
}
.share-el__dropdown-qrcode {
  width: 240px;
  padding: 16px;
}
.share-el__dropdown-qrcode-title {
  margin: -4px 0 12px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}
.share-el__dropdown-qrcode-body {
  margin: 0 0 16px;
  display: block;
  font-size: 0;
}
.share-el__dropdown-qrcode-body > * {
  width: 100%;
  height: auto;
  display: block;
}
.share-el__dropdown-qrcode-btn {
  width: 100%;
  text-align: center;
}
.share-el__dropdown:not(.is-qrcode) .share-el__dropdown-qrcode {
  display: none;
}
.share-el:not(.is-active) .share-el__dropdown {
  transform: translateY(6px);
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}
.share-el__item {
  width: 100%;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.4s;
  transition-property: background;
}
.share-el__item:hover {
  background: #F3F4F6;
}
.share-el__item-icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  flex: 0 0 20px;
}
.share-el__item--vk .share-el__item-icon {
  color: #26A5E4;
}
.share-el__item--telegram .share-el__item-icon {
  color: #0077FF;
}
.input-mask-el__inner {
  background: #fff;
  width: 100%;
  min-height: 44px;
  padding: 10px 11px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.55;
  color: #111827;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  user-select: none;
  outline: none;
  transition: all 0.4s;
  transition-property: border-color;
}
.input-mask-el__inner:hover, .input-mask-el__inner:focus, .input-mask-el__inner:focus-within {
  outline: none;
  border-color: #9CA3AF;
}
.input-mask-el__title {
  color: #9CA3AF;
  flex-shrink: 0;
  white-space: nowrap;
}
.input-mask-el__input {
  background: transparent;
  margin: 0;
  padding: 0;
  display: block;
  line-height: inherit;
  color: inherit;
  flex-grow: 1;
  border: none;
  border-radius: 0;
  outline: none;
}

.project-status {
  background: #F3F4F6;
  padding: 5px 6px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1;
  color: #6b7280;
  border-radius: 4px;
  user-select: none;
}
.project-status__icon {
  width: 1.1428571429em;
  height: 1.1428571429em;
  margin-right: 0;
  flex: 0 0 1.1428571429em;
}
.project-status__title {
  white-space: nowrap;
}

.tag {
  font-size: 14px;
  line-height: 1.3;
  color: #2563EB;
  transition: all 0.4s;
  transition-property: box-shadow;
}
@media screen and (min-width: 1200px) {
  .tag:hover {
    box-shadow: inset 0 -1px 0 currentColor;
  }
}

.btn-video {
  background: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #2563EB;
  text-align: center;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.4s;
  transition-property: color;
}
.btn-video:before {
  content: "";
  position: absolute;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
}
.btn-video__title {
  margin-right: 8px;
  box-shadow: 0 1px 0 transparent;
}
.btn-video__icon {
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  transition: all 0.34s;
  transition-property: transform;
}
@media screen and (min-width: 1200px) {
  .btn-video:hover .btn-video__icon {
    transform: scale(1.1);
  }
}

.user-item {
  text-align: center;
  position: relative;
}
.user-item__picture {
  margin: 0 0 12px;
  padding-bottom: 100%;
  display: block;
  border-radius: 50%;
  overflow: hidden;
}
.user-item__name {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.28;
}
.user-item__name a {
  color: inherit;
}
.user-item__name a:before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}

.header-block-info__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-block-info__title {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
.header-block-info__link {
  flex-shrink: 0;
}

.search-users__header {
  margin: 0 0 24px;
}
.search-users__list {
  margin: 0 -16px -32px;
  display: flex;
  flex-wrap: wrap;
}
.search-users__list-col {
  max-width: 11.1111111111%;
  margin-bottom: 32px;
  padding: 0 16px;
  flex: 0 0 11.1111111111%;
}

.support-social__inner {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.support-social__description {
  margin: 0;
  flex-shrink: 0;
  font-size: 16px;
  line-height: 1.7;
  color: #6b7280;
}
@media screen and (max-width: 767px) {
  .support-social__description {
    width: 100%;
    margin-right: 0;
    margin-bottom: 4px;
    font-size: 14px;
    text-align: center;
  }
}
.support-social__list {
  margin: 0 0 0 18px;
  padding: 0;
  display: flex;
  list-style-type: none;
}
@media screen and (max-width: 767px) {
  .support-social__list {
    width: 100%;
    justify-content: center;
  }
}
.support-social__list-item {
  margin: 9px 18px 9px 0;
  padding: 0;
}
.support-social__list-link {
  width: 24px;
  height: 24px;
  display: block;
  text-align: center;
  transition: all 0.4s;
  transition-property: opacity;
  position: relative;
}
.support-social__list-link:hover {
  text-decoration: none;
}
@media screen and (min-width: 1200px) {
  .support-social__list-link:hover {
    opacity: 0.5;
  }
}
.support-social__list-link:before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
}
.support-social__list-icon {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}

.doc-el {
  font-weight: normal;
  font-size: 16px;
  line-height: 1.25;
  color: #9CA3AF;
  transition: all 0.4s;
  transition-property: color;
}
@media screen and (min-width: 1200px) {
  .doc-el:hover {
    color: #2563EB;
  }
}
.doc-el__inner {
  padding-left: 32px;
  position: relative;
}
.doc-el__icon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}
.doc-el__link {
  display: block;
  color: #2563EB;
}
.doc-el__link:hover {
  text-decoration: none;
}
.doc-el__link:before {
  content: "";
  position: absolute;
  top: -8px;
  right: -6px;
  bottom: -8px;
  left: -6px;
  border-radius: 6px;
  transition: all 0.4s;
  transition-property: background;
}
@media screen and (min-width: 1200px) {
  .doc-el:hover .doc-el__link:before {
    background: rgba(107, 114, 128, 0.08);
  }
}
.doc-el__description {
  margin: 2px 0 0;
  font-size: 14px;
  line-height: 1.6;
}

.login-social {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  list-style-type: none;
}
.login-social__item {
  margin: 8px;
  padding: 0;
}
.login-social__link {
  width: 56px;
  height: 56px;
  display: block;
  font-size: 0;
  line-height: 56px;
  text-align: center;
  border: 1px solid #e5e7eb;
  border-radius: 50%;
  transition: all 0.4s;
  transition-property: background;
  position: relative;
}
.login-social__link:hover {
  text-decoration: none;
}
@media screen and (min-width: 1200px) {
  .login-social__link:hover {
    background: rgba(229, 231, 235, 0.5);
  }
}
.login-social__link:before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
}
.login-social__icon {
  width: 26px;
  height: 26px;
  display: inline-block;
  object-fit: contain;
  vertical-align: middle;
}

@media screen and (max-width: 480px) {
  .login-el__reset {
    padding-top: 16px;
  }
}
.login-el__header {
  display: block;
}
.login-el__title {
  margin: 0 0 6px;
  font-weight: 600;
  font-size: 30px;
  line-height: 1.1;
  text-align: center;
}
.login-el__subtitle {
  margin: 0 0 32px;
  font-size: 14px;
  line-height: 1.42;
  color: #6b7280;
  text-align: center;
}
@media screen and (max-width: 480px) {
  .login-el__subtitle {
    margin-bottom: 24px;
  }
}
.login-el__subtitle a {
  color: #2563EB;
}
@media screen and (min-width: 1200px) {
  .login-el__subtitle a {
    box-shadow: 0 1px 0 transparent;
    transition: all 0.4s;
    transition-property: box-shadow;
  }
}
@media screen and (min-width: 1200px) {
  .login-el__subtitle a:hover {
    box-shadow: 0 1px 0 currentColor;
  }
}
.login-el__subtitle strong {
  font-weight: 500;
  color: #111827;
}
.login-el__reset-text {
  max-width: 360px;
  margin: 30px auto 40px;
  font-size: 14px;
  line-height: 1.42;
  color: #6b7280;
  text-align: center;
}
@media screen and (max-width: 480px) {
  .login-el__reset-text {
    margin-bottom: 30px;
  }
}
.login-el__reset-text strong {
  font-weight: 500;
  color: #111827;
}
.login-el__form-line {
  margin-bottom: 20px;
}
.login-el__form-btn {
  width: 100%;
  margin: 20px 0 44px;
}
.login-el__form-another {
  padding-top: 40px;
  text-align: center;
  border-top: 1px solid #e5e7eb;
  font-size: 14px;
}
@media screen and (max-width: 480px) {
  .login-el__form-another {
    padding-top: 32px;
  }
}
.login-el__form-another a {
  color: #2563EB;
}
@media screen and (min-width: 1200px) {
  .login-el__form-another a {
    box-shadow: 0 1px 0 transparent;
    transition: all 0.4s;
    transition-property: box-shadow;
  }
}
@media screen and (min-width: 1200px) {
  .login-el__form-another a:hover {
    box-shadow: 0 1px 0 currentColor;
  }
}
.login-el__social-title {
  margin: 0 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #6b7280;
  text-align: center;
}
.login-el__social-title:before, .login-el__social-title:after {
  background: #e5e7eb;
  height: 1px;
  content: "";
  flex-grow: 1;
}
.login-el__social-title span {
  margin: 0 8px;
  display: block;
  flex-shrink: 0;
  white-space: nowrap;
}
.login-el__social-list {
  margin-bottom: -8px;
}

@media screen and (max-width: 1169px) {
  .user-profile--is-visible {
    display: flex !important;
  }
  .user-profile--is-active .user-profile__icon--arrow {
    display: block;
  }
  .user-profile--is-toggle .user-profile__icon--arrow {
    transform: scaleY(-1);
  }
  .user-profile--is-active .user-profile__icon--active {
    display: none;
  }

  .widgets-item__title {
    margin-bottom: 20px;
  }

  .scroll-up {
    padding: 12px;
  }
  .scroll-up--position-right-bottom {
    right: 24px;
    bottom: 24px;
  }
  .scroll-up__icon {
    margin-right: 0;
  }
  .scroll-up__title {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .link-add__inner {
    flex-wrap: wrap;
    position: relative;
  }
  .link-add__name {
    max-width: calc(100% - 36px);
    flex: 0 0 calc(100% - 36px);
    order: 1;
  }
  .link-add__url {
    max-width: calc(100% - 36px);
    margin-top: 12px;
    flex: 0 0 calc(100% - 36px);
    order: 3;
  }
  .link-add__remove {
    order: 2;
  }

  .group-btns {
    margin: -10px;
    flex-wrap: wrap;
  }
  .group-btns__item {
    margin: 10px;
  }
}
.header-nav__list {
  margin: 0;
  padding: 0;
  display: flex;
  list-style-type: none;
}
.header-nav__item {
  margin: 0;
  padding: 0;
}
.header-nav__item:not(:last-child) {
  margin-right: 40px;
}
.header-nav__link {
  font-size: 14px;
  line-height: 1.35;
  display: block;
  color: #374151;
  position: relative;
  transition: all 0.4s;
  transition-property: color;
}
.header-nav__link:hover {
  color: #16A34A;
  text-decoration: none;
}
.header-nav__link:focus {
  color: #374151;
}
.header-nav__link:before {
  content: "";
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
}

.header-search {
  display: block;
  position: relative;
  overflow: hidden;
}
.header-search__input {
  padding-right: 44px;
}
.header-search__input::-webkit-search-decoration, .header-search__input::-webkit-search-cancel-button, .header-search__input::-webkit-search-results-button, .header-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
.header-search__close {
  height: 100%;
  width: 44px;
  padding: 0 10px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.header-menu-mobile {
  font-size: 14px;
  line-height: 1.35;
  color: #374151;
}
.header-menu-mobile__inner {
  min-height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
}
.header-menu-mobile__nav {
  margin: 0 -16px;
  padding: 0;
  list-style-type: none;
}
.header-menu-mobile__nav + .header-menu-mobile__nav:before, .header-menu-mobile__nav:first-child:not(:last-child):before, .header-menu-mobile__nav:last-child:not(:first-child):after {
  margin: 5px 0;
  content: "";
  display: block;
  border-top: 1px solid #e5e7eb;
}
.header-menu-mobile__nav-item {
  margin: 0;
  padding: 0;
}
.header-menu-mobile__nav-item:not(:last-child) {
  margin-bottom: 4px;
}
.header-menu-mobile__nav-link {
  min-height: 48px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  color: #374151;
}
.header-menu-mobile__nav-link--accent {
  font-weight: 500;
  color: #16A34A;
}
.header-menu-mobile__nav-link-plus {
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  margin-left: 4px;
}
.header-menu-mobile__btn-list {
  margin-top: 22px;
}
.header-menu-mobile__btn {
  width: 100%;
  display: block;
}
.header-menu-mobile__btn:not(:last-child) {
  margin-bottom: 12px;
}
.header-menu-mobile__user-list {
  margin: -4px -12px 7px;
}
.header-menu-mobile__user-list-item {
  display: none;
}
.header-menu-mobile__user-list-item.user-profile--is-active {
  display: flex;
}
.header-menu-mobile__footer {
  margin-top: auto;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
}
.header-menu-mobile__footer-link {
  margin: 12px 0 0;
  color: #6b7280 !important;
}
.header-menu-mobile__footer-copyright {
  margin: 12px 0 0;
  color: #9CA3AF;
}

.header {
  background: #fff;
  height: 60px;
  color: #374151;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  position: relative;
}
.header__container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__toggle {
  background: transparent;
  width: 24px;
  height: 24px;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  border: none;
  outline: none;
  position: relative;
  flex-wrap: wrap;
}
.header__toggle:before {
  content: "";
  position: absolute;
  inset: -5px;
}
.header__toggle-line {
  background: #111827;
  width: 18px;
  height: 2px;
  border-radius: 1px;
}
.header__toggle-line:not(:last-child) {
  margin-bottom: 4px;
}
.header__logo {
  margin: 2px 32px 0 0;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
}
.header__logo-link {
  width: 100px;
  display: block;
  transition: all 0.4s;
  transition-property: opacity;
}
.header__logo-link:hover {
  text-decoration: none;
  opacity: 0.6;
}
.header__logo-link--russia {
  width: 108px;
}
.header__logo-link img {
  width: 100%;
  height: auto;
  display: block;
}
.header__logo-beta {
  background: #F1F5F9;
  margin: -4px 0 0 6px;
  padding: 2px 6px;
  font-size: 10px;
  color: #6b7280;
  line-height: 1;
  text-transform: uppercase;
  border-radius: 4px;
  transition: all 0.4s;
  transition-property: opacity;
}
.header__logo-beta:hover {
  text-decoration: none;
  opacity: 0.6;
}
.header__menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
.header__nav {
  margin-right: 16px;
}
.header__btn-search {
  margin-left: auto;
  flex: 0 0 24px;
}
.header__search {
  margin-top: -22px;
  position: absolute;
  top: 50%;
  right: -4px;
  left: 0;
  transition: all 0.24s;
  transition-property: visibility, opacity;
}
.header__search:not(.is-show) {
  opacity: 0;
  visibility: hidden;
}
.header__btn-login-mobile {
  display: none;
}
.header__btn-register {
  margin-left: 16px;
  flex-shrink: 0;
}
.header__btn-login {
  margin-left: 8px;
  flex-shrink: 0;
}
.header__btn-notification {
  margin-left: 24px;
}
.header__btn-create-project {
  margin-left: 24px;
}
.header__menu-mobile {
  background: #fff;
  min-width: 256px;
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  transition: all 0.4s;
  transition-property: transform, visibility, opacity;
  z-index: 1001;
}
.header__overlay-mobile {
  background: rgba(17, 24, 39, 0.5);
  width: 100%;
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.4s;
  transition-property: visibility, opacity;
}
.header__profile {
  margin-left: 24px;
  flex-shrink: 0;
  position: relative;
}
.header__profile-header {
  height: 60px;
  display: flex;
  align-items: center;
}
.header__profile-avatar {
  width: 32px;
  height: 32px;
  display: block;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  z-index: 3;
  transition: all 0.4s;
  transition-property: opacity;
}
.header__profile-avatar:hover {
  opacity: 0.5;
}
.header__profile-dropdown {
  min-width: 256px;
  margin-top: 1px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 2;
}

.header-middle {
  position: relative;
  width: 100%;
  height: 240px;
  background-color: #e5e7eb;
}
.header-middle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header-middle-edit {
  position: absolute;
  right: 24px;
  bottom: 16px;
  width: 32px;
  border-radius: 8px;
  height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #374151;
  transition: 0.3s;
  opacity: 0;
  cursor: pointer;
}
.header-middle-edit img {
  width: initial;
  height: initial;
  object-fit: unset;
}

.header-bottom {
  margin-bottom: 32px;
}
.header-bottom-wrap {
  width: 100%;
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
}

.header-info {
  position: relative;
}
.header-info-photo {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 132px;
  height: 132px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  overflow: hidden;
  background-color: #6b7280;
  filter: none !important;
  cursor: pointer;
}
.header-info-photo img {
  width: 100%;
}
.header-info-block {
  margin-left: 140px;
}
.header-info-name {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #111827;
}
.header-info-subs {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9CA3AF;
  cursor: pointer;
}

.header-subscribe {
  padding: 6px 24px;
  height: min-content;
}

@media screen and (max-width: 1169px) {
  .header {
    height: 48px;
  }
  .header__toggle {
    display: flex;
  }
  .header__logo {
    width: 134px;
    margin: 2px 0 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    will-change: transform;
    z-index: 1;
  }
  .header__logo-link {
    width: 90px;
  }
  .header__menu {
    margin-left: 16px;
  }
  .header__nav {
    display: none;
  }
  .header__btn-login-mobile {
    margin-left: 12px;
    display: block;
  }
  .header__btn-register {
    display: none;
  }
  .header__btn-login {
    display: none;
  }
  .header__btn-notification {
    margin-left: 8px;
  }
  .header__btn-create-project {
    display: none;
  }
  .header__search {
    z-index: 2;
  }
  .header__menu-mobile {
    display: block;
  }
  .header__menu-mobile:not(.is-show) {
    pointer-events: none;
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
  }
  .header__overlay-mobile {
    display: block;
  }
  .header__overlay-mobile:not(.is-show) {
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
  }
  .header__profile {
    margin-left: 0;
    order: -1;
  }
  .header__profile-header {
    height: 48px;
  }
  .header__profile-avatar {
    width: 28px;
    height: 28px;
  }
  .header__profile-dropdown {
    display: none !important;
  }
}
@media screen and (max-width: 1000px) {
  .header-subscribe {
    padding: 6px 24px;
    height: min-content;
  }

  .header-middle {
    height: 144px;
  }
  .header-middle-edit {
    right: 16px;
  }

  .header-bottom-wrap {
    padding-top: 10px;
    align-items: center;
  }

  .header-info-block {
    margin-left: 124px;
  }
  .header-info-photo {
    width: 112px;
    height: 112px;
  }
  .header-info-name {
    font-size: 20px;
    line-height: 28px;
  }
}
@media screen and (max-width: 767px) {
  .header-info-block {
    margin-left: 0;
  }
  .header-info-photo {
    bottom: auto;
    top: -66px;
  }
  .header-info-name {
    padding-top: 50px;
  }

  .header-middle-edit {
    opacity: 1;
  }

  .header-bottom-wrap {
    align-items: flex-start;
  }
}
@media screen and (max-width: 480px) {
  .header-middle {
    height: 128px;
  }

  .header-info-photo {
    top: -48px;
    width: 80px;
    height: 80px;
  }
  .header-info-name {
    padding-top: 40px;
    font-size: 20px;
    line-height: 28px;
  }

  .header-subscribe {
    padding: 4px 12px;
  }
}
.footer {
  margin-top: auto;
  padding: 12px 0;
  border-top: 1px solid #e5e7eb;
}
.footer-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b7280;
}
.footer-block {
  display: flex;
  align-items: center;
}
.footer-divider {
  width: 1px;
  height: 16px;
  background-color: #e5e7eb;
  margin: 0 24px;
}
.footer-copyrights {
  color: #9CA3AF;
}
.footer-about, .footer-docs {
  color: #6b7280;
}

@media screen and (max-width: 1000px) {
  .footer {
    display: none;
  }
}
.footer-main {
  background-color: #111827;
  margin-top: auto;
}
.footer-main-head {
  padding-top: 80px;
  margin-bottom: 12px;
}
.footer-main-logo {
  width: 100px;
}
.footer-main-logo img {
  width: 100%;
}
.footer-main-inner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;
}
.footer-main-inner-info {
  display: flex;
  flex-direction: column;
  max-width: 366px;
  margin-right: 40px;
}
.footer-main-inner-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9CA3AF;
  margin-bottom: 20px;
}
.footer-main-inner-button {
  display: flex;
  width: min-content;
  white-space: nowrap;
  align-items: center;
  padding: 10px 24px 10px 16px;
  height: min-content;
}
.footer-main-inner-button img {
  margin-right: 8px;
}
.footer-main-inner-button span {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}
.footer-main-inner-links {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 32px;
}
.footer-main-inner-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9CA3AF;
  margin-right: 32px;
}
.footer-main-inner-link:last-of-type {
  margin-right: 0;
}
.footer-main-inner-social {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer-main-inner-social-link {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #9CA3AF;
  margin-left: 8px;
}
.footer-main-inner-social-link:first-of-type {
  margin-left: 0;
}
.footer-main-bottom {
  border-top: 1px solid #374151;
  padding: 24px 0;
}
.footer-main-bottom-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-main-bottom-copyrights {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b7280;
}
.footer-main-bottom-block {
  display: flex;
  align-items: center;
}
.footer-main-bottom-lang, .footer-main-bottom-docs {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b7280;
}
.footer-main-bottom-divider {
  width: 1px;
  height: 16px;
  margin: 0 22px;
  background-color: #374151;
}

@media screen and (max-width: 767px) {
  .footer-main-head {
    padding-top: 48px;
  }
  .footer-main-inner {
    flex-direction: column;
    margin-bottom: 32px;
  }
  .footer-main-inner-info {
    flex-direction: row;
    max-width: none;
    margin-right: 0;
  }
  .footer-main-inner-text {
    margin-right: 32px;
    margin-bottom: 40px;
  }
  .footer-main-inner-links, .footer-main-inner-social {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 480px) {
  .footer-main-head {
    padding-top: 40px;
  }
  .footer-main-inner-info {
    flex-direction: column;
    margin-bottom: 40px;
  }
  .footer-main-inner-text {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .footer-main-inner-button {
    width: 100%;
    justify-content: center;
  }
  .footer-main-bottom {
    padding: 24px 0 0;
  }
  .footer-main-bottom-wrap {
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .footer-main-bottom-copyrights {
    margin-bottom: 12px;
  }
  .footer-main-bottom-block {
    margin-bottom: 24px;
  }
}
.popup-profile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 5;
  padding: 62px 24px 0 0;
  display: none;
}
.popup-profile-wrap {
  width: 265px;
  margin-left: auto;
  background: #FFFFFF;
  overflow: auto;
  border-radius: 8px;
  padding: 4px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.popup-profile-section {
  position: relative;
}
.popup-profile-section::after {
  position: absolute;
  left: -4px;
  bottom: 0;
  content: "";
  width: calc(100% + 8px);
  height: 1px;
  background-color: #e5e7eb;
}
.popup-profile-section:nth-of-type(3)::after {
  display: none;
}
.popup-profile-section-bottom {
  display: none;
}
.popup-profile-user {
  display: flex;
  padding: 8px 14px;
  align-items: center;
  position: relative;
}
.popup-profile-user-img {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  min-width: 32px;
  border-radius: 50%;
  overflow: hidden;
}
.popup-profile-user-img img {
  width: 100%;
}
.popup-profile-user-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #111827;
}
.popup-profile-user-email {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8A9199;
}
.popup-profile-link, .popup-profile-copyrights, .popup-profile-docs, .popup-profile-lang {
  display: block;
  padding: 6px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #111827;
  transition: 0.3s;
}
.popup-profile-link:hover, .popup-profile-copyrights:hover, .popup-profile-docs:hover, .popup-profile-lang:hover {
  background-color: #F3F4F6;
}
.popup-profile-link {
  border-radius: 4px;
  cursor: pointer;
}
.popup-profile-link.mobile {
  display: none;
}
.popup-profile-link.green {
  color: #166534;
}

@media screen and (max-width: 1000px) {
  .popup-profile {
    position: fixed;
    padding: 0;
    background-color: rgba(17, 24, 39, 0.5);
  }
  .popup-profile-wrap {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    height: inherit;
    border-radius: 0;
    width: 320px;
  }
  .popup-profile-section {
    padding: 6px 0;
  }
  .popup-profile-section:nth-of-type(3)::after {
    display: block;
  }
  .popup-profile-section:nth-of-type(4)::after {
    display: none;
  }
  .popup-profile-section-bottom {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .popup-profile-link.mobile {
    display: block;
  }
  .popup-profile-docs {
    color: #6b7280;
    font-size: 12px;
    line-height: 16px;
  }
  .popup-profile-copyrights {
    font-size: 12px;
    line-height: 16px;
    color: #9CA3AF;
  }
}
@media screen and (max-width: 480px) {
  .popup-profile-wrap {
    width: 256px;
  }
}
.popup-subs {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(17, 24, 39, 0.5);
  z-index: 5;
  display: none;
}
.popup-subs.active {
  display: flex;
}
.popup-subs-wrap {
  position: relative;
  width: 100%;
  max-width: 576px;
  height: 75%;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: auto;
  scrollbar-color: #D1D5DB transparent;
  scrollbar-width: thin;
}
.popup-subs-wrap::-webkit-scrollbar {
  width: 4px;
}
.popup-subs-wrap::-webkit-scrollbar-track {
  border: solid 6px transparent;
}
.popup-subs-wrap::-webkit-scrollbar-thumb {
  background-color: #D1D5DB;
  border-radius: 18px;
  border: solid 6px transparent;
}
.popup-subs-header {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 24px;
  border-bottom: 1px solid #e5e7eb;
  background-color: #fff;
  z-index: 5;
}
.popup-subs-back {
  display: none;
}
.popup-subs-count {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #374151;
}
.popup-subs-close {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
}
.popup-subs-list {
  padding: 24px;
}
.popup-subs-user {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.popup-subs-user:last-of-type {
  margin-bottom: 0;
}
.popup-subs-user-info {
  display: flex;
  align-items: center;
}
.popup-subs-user-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.popup-subs-user-photo img {
  width: 100%;
}
.popup-subs-user-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #374151;
  margin-left: 16px;
}
.popup-subs-user-btn {
  width: 124px;
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: auto;
}

@media screen and (max-width: 1000px) {
  .popup-subs-wrap {
    height: 85%;
  }
}
@media screen and (max-width: 480px) {
  .popup-subs {
    display: flex;
    transition: 0.3s;
    transform: translateX(-100%);
  }
  .popup-subs.active {
    transform: translateX(0);
  }
  .popup-subs-wrap {
    height: 100%;
    border-radius: 0;
  }
  .popup-subs-header {
    padding: 0;
    justify-content: flex-start;
  }
  .popup-subs-back {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 56px;
  }
  .popup-subs-close {
    display: none;
  }
  .popup-subs-list {
    padding: 16px;
  }
  .popup-subs-user {
    margin-bottom: 16px;
  }
  .popup-subs-user-btn {
    display: none;
  }
}
.popup-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 5;
  display: none;
}
.popup-cover.active {
  display: flex;
}
.popup-cover-wrap {
  margin: 300px 24px 0 auto;
  position: relative;
  width: 100%;
  max-width: 512px;
  height: 384px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: auto;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  scrollbar-color: #D1D5DB transparent;
  scrollbar-width: thin;
}
.popup-cover-wrap::-webkit-scrollbar {
  width: 4px;
}
.popup-cover-wrap::-webkit-scrollbar-track {
  border: solid 6px transparent;
}
.popup-cover-wrap::-webkit-scrollbar-thumb {
  background-color: #D1D5DB;
  border-radius: 18px;
  border: solid 6px transparent;
}
.popup-cover-header {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  border-bottom: 1px solid #e5e7eb;
  background-color: #fff;
  z-index: 5;
}
.popup-cover-ttl {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #374151;
}
.popup-cover-buttons {
  display: flex;
  align-items: center;
}
.popup-cover-delete {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  padding: 4px 16px;
  cursor: pointer;
}
.popup-cover-input {
  position: relative;
  width: 103.2px;
  height: 34px;
}
.popup-cover-input input {
  width: 0.01px;
  height: 0;
  opacity: 0;
  visibility: hidden;
}
.popup-cover-input label {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  padding: 4px 16px;
}
.popup-cover-close {
  display: none;
}
.popup-cover-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  padding: 16px 16px 0;
}
.popup-cover-grid-item {
  height: 64px;
  cursor: pointer;
}
.popup-cover-grid-item__input {
  display: none;
  position: absolute;
  left: -9999px;
  visibility: hidden;
}
.popup-cover-grid-item__inner {
  height: inherit;
  border-radius: 4px;
  transition: all 0.4s;
  overflow: hidden;
}
.popup-cover-grid-item__inner:after {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.3283 5.33836C16.6017 5.61173 16.6017 6.05494 16.3283 6.32831L7.99497 14.6616C7.72161 14.935 7.27839 14.935 7.00503 14.6616L3.67169 11.3283C3.39832 11.0549 3.39832 10.6117 3.67169 10.3384C3.94506 10.065 4.38827 10.065 4.66164 10.3384L7.5 13.1767L15.3384 5.33836C15.6117 5.06499 16.0549 5.06499 16.3283 5.33836Z' fill='%23ffffff'/%3E%3C/svg%3E");
  background-size: 40px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: rgba(22, 163, 74, 0.6);
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
  transition-property: opacity, visibility;
}
.popup-cover-grid-item__input:checked ~ .popup-cover-grid-item__inner:after {
  opacity: 1;
  visibility: visible;
}
.popup-cover-grid-item:hover .popup-cover-grid-item__input:not(:checked) ~ .popup-cover-grid-item__inner:after {
  opacity: 1;
  visibility: visible;
}
.popup-cover-footer {
  display: none;
}

@media screen and (max-width: 1000px) {
  .popup-cover {
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(17, 24, 39, 0.5);
  }
  .popup-cover-wrap {
    margin: 0;
    height: 85%;
  }
}
@media screen and (max-width: 767px) {
  .popup-cover-wrap {
    height: 75%;
  }
}
@media screen and (max-width: 480px) {
  .popup-cover {
    display: flex;
    transition: 0.3s;
    transform: translateX(-100%);
  }
  .popup-cover.active {
    transform: translateX(0);
  }
  .popup-cover-wrap {
    display: flex;
    flex-direction: column;
    border-radius: 0;
    height: 100vh;
  }
  .popup-cover-header .popup-cover-buttons {
    display: none;
  }
  .popup-cover-close {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: auto;
  }
  .popup-cover-grid {
    padding: 16px;
    flex: 1;
    grid-template-rows: none;
    grid-auto-rows: min-content;
  }
  .popup-cover-footer {
    position: sticky;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
    border-top: 1px solid #e5e7eb;
    background-color: #fff;
    z-index: 5;
  }
  .popup-cover-delete {
    padding: 0;
  }
}
.popup-avatar {
  position: absolute;
  top: -120px;
  right: -20px;
  z-index: 5;
  display: none;
}
.popup-avatar.active {
  display: flex;
}
.popup-avatar-wrap {
  position: relative;
  width: 208px;
  height: 280px;
  padding: 4px;
  border-radius: 8px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}
.popup-avatar-wrap::after {
  content: "";
  position: absolute;
  top: 95px;
  left: -10px;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  transform: rotate(45deg);
  z-index: -1;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
}
.popup-avatar-header {
  display: none;
}
.popup-avatar-close-desctop {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;
}
.popup-avatar-img {
  width: 100%;
  height: 228px;
  border-radius: 8px;
  overflow: hidden;
}
.popup-avatar-img img {
  width: 100%;
}
.popup-avatar-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}
.popup-avatar-input {
  position: relative;
}
.popup-avatar-input input {
  width: 0.01px;
  height: 0;
  opacity: 0;
  visibility: hidden;
}
.popup-avatar-input label {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2563EB;
  cursor: pointer;
}
.popup-avatar-btn {
  border: none;
  background-color: transparent;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2563EB;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .popup-avatar {
    top: -115px;
    right: -50px;
  }
}
@media screen and (max-width: 1000px) {
  .popup-avatar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    right: auto;
  }
  .popup-avatar-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;
  }
  .popup-avatar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
    border-bottom: 1px solid #e5e7eb;
    background-color: #fff;
    z-index: 5;
  }
  .popup-avatar-ttl {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
  }
  .popup-avatar-close-desctop {
    display: none;
  }
  .popup-avatar-img {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    background-color: #e5e7eb;
    padding: 16px;
  }
  .popup-avatar-img img {
    width: initial;
    max-width: 100%;
    height: initial;
    max-height: 100%;
  }
}
.modal-title {
  margin: 0;
  font-weight: 600;
  line-height: 1.35;
}
.modal-title--size-md {
  font-size: 24px;
}

.modal-body {
  line-height: 1.35;
  font-size: 0;
  color: #111827;
  text-align: center;
}
.modal-body:after {
  width: 0;
  height: 100%;
  content: "";
  display: inline-block;
  vertical-align: middle;
}
.modal-body__center {
  background: #fff;
  width: 100%;
  padding: 40px;
  display: inline-block;
  font-size: 14px;
  border-radius: 8px;
  vertical-align: middle;
  text-align: initial;
  position: relative;
}
.modal-body__center--size-sm {
  max-width: 448px;
}
.modal-body__center--size-md {
  max-width: 576px;
}
.modal-body__title {
  margin: 0 0 24px;
}
.modal-body__close {
  position: absolute;
  top: 24px;
  right: 24px;
}
.modal-body__text {
  margin: 0 0 16px;
  font-size: 14px;
  line-height: 1.7;
  color: #6b7280;
}
.modal-body__card-form-input {
  margin: 0 0 48px;
}
.modal-body__card-form-group-btns {
  padding-top: 16px;
}
.modal-body__card-form-input-block {
  margin: 0 0 32px;
}
.modal-body__binding-phone {
  padding-top: 8px;
}
.modal-body__binding-phone-label {
  margin: 0;
  padding: 0 0 4px;
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.7;
  color: #334155;
}
.modal-body__binding-phone-input {
  max-width: 288px;
  flex-grow: 1;
}
.modal-body__binding-phone-group-btns {
  margin-top: 32px;
}
.modal-body__binding-phone-input-wrapper {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}
.modal-body__binding-phone-btn-change {
  margin-left: 4px;
  flex-shrink: 0;
}
.modal-body__tell-project-textarea {
  min-height: 168px;
  margin-bottom: 42px;
}

.modal {
  background: rgba(17, 24, 39, 0.5);
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1002;
}
.modal.is-show {
  display: block;
}
.modal__body {
  width: 100%;
  height: 100%;
  padding: 8px;
  overflow-y: auto;
}

@media screen and (max-width: 767px) {
  .modal-title--size-md {
    font-size: 20px;
  }

  .modal-body__close {
    top: 12px;
    right: 12px;
  }
  .modal-body__center {
    padding: 16px;
  }
  .modal-body__card-form-input {
    margin-bottom: 32px;
  }
}
.profile {
  padding-bottom: 32px;
  flex: 1;
}
.profile-wrap {
  display: grid;
  grid-template-columns: 672px auto;
  grid-column-gap: 48px;
  grid-row-gap: 32px;
}
.profile-tabs {
  position: relative;
  grid-column: span 2;
  display: flex;
  align-items: center;
  padding-bottom: 12px;
}
.profile-tabs::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #e5e7eb;
}
.profile-tab {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
  margin-right: 40px;
  cursor: pointer;
}
.profile-tab.active {
  position: relative;
  color: #166534;
}
.profile-tab.active::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -13px;
  width: 100%;
  height: 3px;
  background-color: #166534;
  z-index: 2;
}
.profile-posts {
  width: 100%;
  margin-right: 48px;
}
.profile-posts-content-empty {
  font-size: 14px;
  line-height: 1.55;
  color: #6b7280;
}
.profile-posts-content-empty a {
  box-shadow: none;
}
.profile-posts__list-item + .profile-posts__list-item {
  margin-top: 24px;
}
.profile-aside-text, .profile-aside-adress {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
  margin-bottom: 12px;
}
.profile-aside-link {
  display: block;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2563EB;
  margin-bottom: 4px;
}

.profile-header {
  overflow: hidden;
}
.profile-header__poster {
  padding-bottom: 18.75%;
  user-select: none;
}
.profile-header__inner {
  margin: -64px 0 40px;
  display: flex;
  align-items: flex-end;
}
.profile-header__card {
  margin-right: 20px;
}
.profile-header__btn-subscribe {
  margin: 0 0 3px auto;
  display: inline-block;
  flex-shrink: 0;
}

@media screen and (max-width: 1000px) {
  .profile-wrap {
    grid-template-columns: 64.8% auto;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
  }
}
@media screen and (max-width: 767px) {
  .profile-wrap {
    display: flex;
    flex-direction: column;
    grid-row-gap: 0;
    grid-column-gap: 0;
  }
  .profile-aside {
    order: -2;
    margin-bottom: 24px;
  }
  .profile-tabs::after {
    left: -16px;
    width: calc(100% + 32px);
    height: 1px;
    background-color: #e5e7eb;
  }
  .profile-posts {
    margin: 0;
  }
}
.project {
  padding: 40px 0 80px;
}
.project-header {
  display: flex;
  margin-bottom: 40px;
}
.project-header-block {
  display: flex;
  flex-wrap: wrap;
}
.project-header-img {
  width: 60px;
  height: 60px;
  margin-right: 16px;
  border-radius: 50%;
  flex-shrink: 0;
  overflow: hidden;
}
.project-header-img img {
  display: block;
  width: 100%;
  height: 100%;
}
.project-header-ttl {
  max-width: 100%;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #111827;
  margin-bottom: 4px;
  flex: 0 0 100%;
}
.project-header-subs {
  margin-right: 0.7em;
  display: inline-flex;
  align-self: center;
}
.project-header-status {
  display: inline-flex;
  align-self: center;
}
.project-main {
  padding: 80px 0;
  background-color: #f8fafc;
}
.project-main-wrap {
  display: flex;
}
.project-main-media-wrap {
  max-width: 64.6153846154%;
  margin-right: 80px;
  flex: 0 0 64.6153846154%;
  align-self: center;
}
.project-main-media {
  padding-bottom: 56.25%;
  border-radius: 4px;
}
.project-main-media > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}
.project-main-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.project-main-info-amount {
  margin-bottom: 4px;
}
.project-main-info-amount-summ,
.project-main-info-amount span {
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  color: #111827;
}
.project-main-info-amount span:last-of-type {
  font-size: 14px;
}
.project-main-info-subs {
  margin: -5px 0 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9CA3AF;
}
.project-main-info-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  margin-bottom: 16px;
}
.project-main-info-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  padding: 10px 0;
}
.project-main-info-buttons {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 288px;
}
.project-main-info-settings {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 0;
}
.project-main-info-share {
  padding: 12px;
  margin-left: 8px;
}
.project-main-info-city {
  margin-top: auto;
  padding: 12px 0;
}
.project-main-info-footer {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9CA3AF;
  padding-top: 12px;
  border-top: 1px solid #e5e7eb;
}
.project-tabs {
  position: relative;
}
.project-tabs::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #e5e7eb;
}
.project-tabs-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.project-tabs-nav {
  display: flex;
  align-items: center;
}
.project-tabs-tab {
  padding-top: 22px;
  padding-bottom: 22px;
  display: block;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
  margin-right: 60px;
  cursor: pointer;
}
.project-tabs-tab:last-of-type {
  margin-right: 0;
}
.project-tabs-tab.active {
  color: #166534;
}
.project-tabs-tab.active::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #166534;
  z-index: 2;
}
.project-tabs-tab.mobile {
  display: none;
}
.project-tabs-buttons {
  display: flex;
  align-items: center;
  padding: 0 0 14px;
}
.project-tabs-subscribe {
  padding: 6px 24px;
}
.project-tabs-share {
  padding: 11px;
  margin-left: 8px;
}
.project-tabs-share img {
  width: 15px;
  height: 15px;
}
.project-content {
  padding-top: 40px;
}
.project-content-wrap {
  display: flex;
}
.project-content-inner {
  max-width: 64.6153846154%;
  flex: 0 0 64.6153846154%;
  margin-right: 80px;
}
.project-content-text {
  margin-bottom: 32px;
}
.project-content-text:last-of-type {
  margin-bottom: 0;
}
.project-content-text p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #111827;
  margin: 0 0 12px;
}
.project-content-text p:last-of-type {
  margin: 0;
}
.project-content-img {
  margin-bottom: 32px;
  padding-bottom: 56.25%;
  border-radius: 4px;
  overflow: hidden;
}
.project-content-img img {
  display: block;
  width: 100%;
}
.project-content-tags {
  margin: 0 0 16px;
  padding: 0 0 8px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #E5E7EB;
}
.project-content-tags__item {
  margin: 0 16px 10px 0;
}
.project-blog-post {
  padding-bottom: 24px;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 24px;
}
.project-blog-post:last-of-type {
  margin-bottom: 0;
}
.project-blog-img {
  margin-bottom: 24px;
  padding-bottom: 56.25%;
  border-radius: 4px;
  overflow: hidden;
}
.project-blog-img img {
  width: 100%;
  display: block;
}
.project-blog-ttl {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #111827;
  margin-bottom: 8px;
}
.project-blog-text {
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #374151;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  box-orient: vertical;
}
.project-blog-text.lines-3 {
  line-clamp: 3;
  -webkit-line-clamp: 3;
}
.project-blog-text.lines-6 {
  line-clamp: 6;
  -webkit-line-clamp: 6;
}
.project-blog-text p {
  margin: 0;
  margin-bottom: 12px;
}
.project-blog-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.project-blog-time {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
}
.project-blog-add {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  margin-bottom: 40px;
  border-radius: 8px;
  background-color: #f3f4f6;
}
.project-blog-add img {
  display: block;
  margin-right: 8px;
}
.project-detail {
  padding: 24px 0 80px;
}
.project-detail-back {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  color: #2563EB;
  font-size: 14px;
  line-height: 24px;
}
.project-detail-back img {
  display: block;
  margin-right: 12px;
}
.project-detail-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.project-detail-header-img {
  width: 32px;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
}
.project-detail-header-img img {
  display: block;
  width: 100%;
}
.project-detail-header-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #111827;
}
.project-detail-header-date {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b7280;
}
.project-detail-ttl {
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  color: #242B33;
  margin-bottom: 32px;
}
.project-detail-img {
  margin-bottom: 32px;
  border-radius: 4px;
  overflow: hidden;
}
.project-detail-img img {
  display: block;
  width: 100%;
}
.project-detail-text {
  margin-bottom: 12px;
}
.project-detail-text p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #111827;
  margin: 0 0 24px;
}
.project-detail-text p:last-of-type {
  margin: 0;
}
.project-add {
  padding: 24px 0 88px;
  flex: 1;
}
.project-add-img {
  margin-bottom: 60px;
}
.project-add-img-ttl {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #111827;
  margin-bottom: 8px;
}
.project-add-img-subttl {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  margin-bottom: 16px;
}
.project-add-img-input {
  position: relative;
  height: 44px;
}
.project-add-img-input input {
  width: 0.01px;
  height: 0.01px;
  opacity: 0;
  visibility: hidden;
}
.project-add-img-input label {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 24px;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  border-radius: 8px;
  border: 1px solid #D1D5DB;
  cursor: pointer;
}
.project-add-title {
  margin-bottom: 48px;
}
.project-add-title-ttl {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #111827;
  margin-bottom: 16px;
}
.project-add-text {
  margin-bottom: 32px;
}
.project-add-text-ttl {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #111827;
  margin-bottom: 16px;
}
.project-add-buttons {
  display: flex;
  align-items: center;
}
.project-add-buttons-submit {
  padding: 10px 24px;
  margin-right: 24px;
}
.project-add-buttons-preview {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2563EB;
}
.project-preview {
  background-color: #ffffff;
  position: sticky;
  border-top: 1px solid #e5e7eb;
  top: 0;
  left: 0;
  z-index: 5;
}
.project-preview-header {
  width: 100%;
  padding: 8px 0;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1);
}
.project-preview-header-inner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.project-preview-header-inner .project-detail-back {
  position: absolute;
  left: 0;
  margin-bottom: 0;
}
.project-preview-header-ttl {
  padding: 0 80px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  text-align: center;
}

.l-project-tabs-buttons {
  display: flex;
  align-items: center;
}
.l-project-tabs-buttons__item {
  margin-left: 20px;
}

@media screen and (max-width: 1000px) {
  .project {
    padding: 32px 0 48px;
  }
  .project-header {
    margin-bottom: 32px;
  }
  .project-main {
    padding: 32px 0 24px;
  }
  .project-main-wrap {
    display: block;
  }
  .project-main-media-wrap {
    margin-right: 0;
    margin-bottom: 36px;
    min-width: auto;
    max-width: none;
  }
  .project-main-info-btn {
    margin-bottom: 24px;
  }
  .project-main-info-buttons {
    margin-bottom: 24px;
  }
  .project-main-info-footer {
    margin-top: 0;
  }
  .project-content {
    padding-top: 32px;
  }
  .project-content-inner {
    width: 67%;
    min-width: 67%;
  }
  .project-detail {
    padding: 24px 0 44px;
  }
  .project-blog-add {
    margin-bottom: 32px;
  }
}
@media screen and (max-width: 767px) {
  .project {
    padding: 24px 0 48px;
  }
  .project-header {
    margin-bottom: 24px;
  }
  .project-header-img {
    width: 48px;
    min-width: 48px;
    height: 48px;
    margin-right: 12px;
  }
  .project-header-ttl {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 2px;
  }
  .project-tabs {
    padding-top: 0;
  }
  .project-tabs-wrap {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .project-tabs-overflow {
    width: 100%;
    overflow-x: hidden;
  }
  .project-tabs-nav {
    margin: 0 -16px;
    padding: 0 16px;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .project-tabs-nav::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .project-tabs-tab {
    white-space: nowrap;
  }
  .project-tabs-tab.mobile {
    display: block;
  }
  .project-tabs-buttons {
    position: relative;
    width: 100%;
    padding: 2px 0 32px;
    background-color: #f8fafc;
  }
  .project-tabs-buttons::before {
    position: absolute;
    content: "";
    left: 100%;
    top: 0;
    width: 16px;
    height: 100%;
    background-color: #f8fafc;
  }
  .project-tabs-buttons::after {
    position: absolute;
    content: "";
    right: 100%;
    top: 0;
    width: 16px;
    height: 100%;
    background-color: #f8fafc;
  }
  .project-tabs-subscribe {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .project-content-wrap {
    display: block;
  }
  .project-content-inner {
    width: 100%;
    margin-right: 0;
    min-width: auto;
    max-width: none;
  }
  .project-blog-text {
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 480px) {
  .project-header {
    margin-bottom: 16px;
  }
  .project-header-ttl {
    font-size: 20px;
    line-height: 28px;
  }
  .project-main {
    padding-top: 24px;
  }
  .project-main-media-wrap {
    margin-bottom: 32px;
  }
  .project-main-info-amount-summ,
.project-main-info-amount span {
    font-size: 30px;
    line-height: 36px;
  }
  .project-main-info-text {
    margin-bottom: 4px;
  }
  .project-main-info-btn {
    order: -3;
    width: 100%;
    margin-bottom: 24px;
  }
  .project-main-info-buttons {
    order: -1;
    max-width: none;
    width: 100%;
  }
  .project-main-info-footer {
    padding-top: 0;
    border: none;
  }
  .project-content-text {
    margin-bottom: 24px;
  }
  .project-content-text p {
    margin-bottom: 8px;
  }
  .project-detail {
    padding: 22px 0 32px;
  }
  .project-detail-ttl {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px;
  }
  .project-detail-img {
    margin-bottom: 24px;
  }
  .project-detail-text p {
    margin: 0 0 8px;
  }
  .project-add-img {
    margin-bottom: 40px;
  }
  .project-preview-header-ttl {
    padding-right: 0;
    padding-left: 0;
  }
  .project-preview-header-inner {
    justify-content: space-between;
  }
  .project-preview-header-inner .project-detail-back {
    position: static;
  }
  .project-tabs-share {
    margin-left: 12px;
  }
}
.home {
  padding-bottom: 80px;
  flex: 1;
}
.home-main {
  padding: 136px 0 48px;
  background-color: #F8FAFC;
}
.home-main-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  grid-row-gap: 70px;
}
.home-main-info {
  padding-right: 60px;
}
.home-main-info-ttl {
  font-weight: 500;
  font-size: 42px;
  line-height: 52px;
  color: #111827;
  margin-bottom: 16px;
}
.home-main-info-subttl {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #374151;
  margin-bottom: 24px;
}
.home-main-buttons {
  display: flex;
  align-items: center;
}
.home-main-registration {
  padding: 12px 32px;
  margin-right: 32px;
}
.home-main-about {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2563EB;
}
.home-main-video {
  position: relative;
  background-color: #ffffff;
  box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding-top: 56%;
  height: min-content;
  overflow: hidden;
}
.home-main-video-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.home-main-video-wrap iframe {
  width: 100%;
  display: block;
  height: 100%;
}
.home-main-bank {
  display: flex;
  align-items: center;
  padding-right: 60px;
}
.home-main-bank-logo {
  width: 110px;
  min-width: 110px;
  margin-right: 30px;
  overflow: hidden;
}
.home-main-bank-logo img {
  display: block;
  width: 100%;
}
.home-main-bank-desc {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b7280;
}
.home-section {
  background-color: #ffffff;
}
.home-section-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.home-section-ttl {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #111827;
}
.home-section-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2563EB;
}
.home-easy {
  padding: 60px 0 80px;
}
.home-easy-wrap {
  display: grid;
  grid-template-columns: repeat(3, 364px);
  grid-column-gap: 23px;
  max-width: 1138px;
  margin: 0 auto;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.home-easy-wrap::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.home-easy-item {
  padding: 32px;
  background-color: #F8FAFC;
  border-radius: 12px;
  overflow: hidden;
}
.home-easy-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 56px;
  height: 56px;
  background-color: #ffffff;
  border-radius: 50%;
  margin-bottom: 24px;
}
.home-easy-item-ttl {
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  margin-bottom: 8px;
}
.home-easy-item-text {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #374151;
}
.home-how {
  padding: 128px 0;
  background-color: #F8FAFC;
}
.home-how-wrap {
  display: flex;
}
.home-how-img {
  width: 61.5%;
  min-width: 61.5%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.08);
  margin-right: 6.85%;
  overflow: hidden;
}
.home-how-img img {
  display: block;
  object-fit: cover;
  object-position: center center;
}
.home-how-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.home-how-info-block-ttl {
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  margin-bottom: 8px;
}
.home-how-info-block-text {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #374151;
}
.home-projects {
  padding: 60px 0 132px;
}
.home-projects-slider-container {
  position: relative;
  max-width: 1138px;
  margin: 0 auto;
  padding-bottom: 1px;
}
.home-projects-slider-container .swiper-slide {
  height: initial;
}
.home-projects-slider-container .swiper-pagination {
  position: absolute;
  left: 0px;
  bottom: -60px;
}
.home-projects-slider-container .home-projects-button-prev {
  position: absolute;
  left: -56px;
  top: 50%;
  transform: translateY(-50%);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.home-projects-slider-container .home-projects-button-next {
  position: absolute;
  right: -56px;
  top: 50%;
  transform: translateY(-50%);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.home-projects-slider-container .swiper-pagination-bullet {
  width: 4px;
  height: 4px;
  margin: 0 2px !important;
  background-color: #D1D5DB;
}
.home-projects-slider-container .swiper-pagination-bullet-active {
  background-color: #16A34A;
}
.home-projects-item {
  border-radius: 8px;
  border: 1px solid #E5E7EB;
  max-width: 364px;
  height: auto;
  display: flex;
  flex-direction: column;
}
.home-projects-item-head {
  display: flex;
  align-items: center;
  padding: 20px;
}
.home-projects-item-head-img {
  width: 36px;
  min-width: 36px;
  height: 36px;
  margin-right: 12px;
  border-radius: 50%;
  overflow: hidden;
}
.home-projects-item-head-img img {
  width: 100%;
}
.home-projects-item-head-ttl {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #111827;
}
.home-projects-item-img {
  margin-bottom: 20px;
}
.home-projects-item-img img {
  width: 100%;
}
.home-projects-item-amount {
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  margin-bottom: 4px;
  padding: 0 20px;
}
.home-projects-item-amount span:last-of-type {
  font-size: 14px;
}
.home-projects-item-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  margin-bottom: 20px;
  padding: 0 20px;
}
.home-projects-item-info {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9CA3AF;
  padding: 0 20px 20px;
}
.home-projects-item-city {
  position: relative;
  padding-left: 22px;
}
.home-projects-item-city-mark {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 0;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
}
.home-interest-inner {
  display: flex;
  justify-content: space-between;
  padding: 40px;
  background: #F8FAFC;
  background-repeat: no-repeat;
  background-position: top right;
  border-radius: 12px;
}
.home-interest-info-ttl {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #111827;
  margin-bottom: 8px;
}
.home-interest-info-subttl {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #374151;
  margin-bottom: 24px;
}
.home-interest-buttons {
  display: flex;
  align-items: center;
}
.home-interest-create {
  padding: 10px 24px;
  margin-right: 32px;
}
.home-interest-about {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2563EB;
}
.home-interest-img {
  width: 288px;
  min-width: 288px;
  margin-left: 40px;
}
.home-interest-img img {
  width: 100%;
}

@media screen and (max-width: 1280px) {
  .home-main-info-ttl {
    font-size: 3.35vw;
  }

  .home-projects-slider-container .home-projects-button-prev, .home-projects-slider-container .home-projects-button-next {
    display: none;
  }

  .home-projects-pagination {
    display: none;
  }

  .home-projects {
    padding: 60px 0;
  }
}
@media screen and (max-width: 1169px) {
  .home-easy-wrap {
    margin: 0;
    padding: 0 16px;
    grid-column-gap: 16px;
  }
  .home-projects-slider {
    padding: 0 16px;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .home-projects-slider::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
@media screen and (max-width: 1000px) {
  .home {
    padding-bottom: 72px;
  }
  .home-main {
    padding: 48px 0 32px;
  }
  .home-main-grid {
    display: block;
    text-align: center;
  }
  .home-main-info {
    margin-bottom: 48px;
    padding-right: 0;
  }
  .home-main-info-ttl {
    font-size: 42px;
  }
  .home-main-buttons {
    justify-content: center;
  }
  .home-main-video {
    margin-bottom: 24px;
  }
  .home-main-bank {
    justify-content: center;
    padding-right: 0;
  }
  .home-how {
    padding: 48px 0 60px;
  }
  .home-how-wrap {
    display: block;
  }
  .home-how-img {
    min-width: 0;
    width: 100%;
    margin-bottom: 48px;
  }
  .home-how-img img {
    width: 100%;
  }
  .home-how-info {
    flex-direction: row;
    gap: 32px;
  }
  .home-interest-inner {
    padding: 32px;
  }
  .home-interest-img {
    width: 200px;
    min-width: 220px;
  }
}
@media screen and (max-width: 767px) {
  .home {
    padding-bottom: 48px;
  }
  .home-main-info-ttl {
    font-size: 32px;
    line-height: 46px;
  }
  .home-main-bank {
    flex-direction: column;
  }
  .home-main-bank-logo {
    margin: 0 0 8px;
  }
  .home-section-head {
    margin-bottom: 32px;
  }
  .home-easy {
    padding: 40px 0 60px;
  }
  .home-how {
    padding: 40px 0;
  }
  .home-how-img {
    margin-bottom: 32px;
  }
  .home-how-info {
    flex-direction: column;
    gap: 16px;
  }
  .home-interest-inner {
    padding: 24px;
  }
  .home-interest-img {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .home {
    padding-bottom: 40px;
  }
  .home-main {
    padding: 24px 0;
  }
  .home-main-info {
    margin-bottom: 28px;
  }
  .home-main-info-ttl {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 12px;
  }
  .home-main-info-subttl {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .home-main-buttons {
    flex-direction: column;
  }
  .home-main-registration {
    width: 100%;
    margin: 0 0 24px;
  }
  .home-main-video {
    margin-bottom: 20px;
  }
  .home-section-head {
    margin-bottom: 32px;
  }
  .home-section-ttl {
    font-size: 24px;
    line-height: 28px;
  }
  .home-easy {
    padding: 40px 0;
  }
  .home-easy-wrap {
    grid-template-columns: repeat(3, 284px);
    grid-column-gap: 8px;
  }
  .home-easy-item {
    padding: 24px;
  }
  .home-easy-item-icon {
    margin-bottom: 16px;
  }
  .home-easy-item-ttl {
    font-size: 18px;
    line-height: 28px;
  }
  .home-how {
    padding: 24px 0;
  }
  .home-how-info-block-ttl {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 4px;
  }
  .home-projects {
    padding: 40px 0;
  }
  .home-projects-item {
    max-width: 287px;
  }
  .home-interest-inner {
    background-image: none;
  }
  .home-interest-buttons {
    width: 100%;
    flex-direction: column;
  }
  .home-interest-create {
    width: 100%;
    margin: 0 0 28px;
    text-align: center;
  }
}
.l-home {
  font-size: 14px;
  line-height: 1.4;
  color: #111827;
  flex-grow: 1;
  overflow: clip;
}

.l-home-intro {
  padding-bottom: 190px;
}
@media screen and (max-width: 1169px) {
  .l-home-intro {
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 1000px) {
  .l-home-intro {
    padding-bottom: 76px;
  }
}
@media screen and (max-width: 767px) {
  .l-home-intro {
    padding-bottom: 40px;
  }
}
.l-home-intro__row {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1169px) {
  .l-home-intro__row {
    flex-wrap: wrap;
  }
}
.l-home-intro__content {
  max-width: 51%;
  padding-top: 90px;
  flex: 0 0 51%;
  align-self: flex-end;
  transition: all 1.4s;
  transition-property: transform, visibility, opacity;
}
@media screen and (max-width: 1000px) {
  .l-home-intro__content {
    max-width: 56.8%;
    padding-top: 40px;
    flex-basis: 56.8%;
    font-size: 12px;
  }
}
@media screen and (max-width: 767px) {
  .l-home-intro__content {
    max-width: 100%;
    flex-basis: 100%;
  }
}
.l-home-intro__content-footer {
  max-width: 488px;
}
@media screen and (max-width: 767px) {
  .l-home-intro__content-footer {
    max-width: 100%;
  }
}
.l-home-intro__content-title {
  max-width: 550px;
  margin: 0 0 36px;
  font-weight: 600;
  font-size: 56px;
  line-height: 1.1071428571;
}
@media screen and (max-width: 1169px) {
  .l-home-intro__content-title {
    font-size: 48px;
  }
}
@media screen and (max-width: 1000px) {
  .l-home-intro__content-title {
    margin-bottom: 32px;
    font-size: 40px;
  }
}
@media screen and (max-width: 767px) {
  .l-home-intro__content-title {
    margin: 0 auto 28px;
    font-size: 32px;
    text-align: center;
  }
}
@media screen and (max-width: 480px) {
  .l-home-intro__content-title {
    margin-bottom: 24px;
    font-size: 28px;
  }
}
.l-home-intro__content-btns {
  margin: -16px;
  padding-bottom: 89px;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 1169px) {
  .l-home-intro__content-btns {
    padding-bottom: 47px;
  }
}
@media screen and (max-width: 1000px) {
  .l-home-intro__content-btns {
    padding-bottom: 43px;
  }
}
@media screen and (max-width: 767px) {
  .l-home-intro__content-btns {
    padding-bottom: 35px;
    justify-content: center;
  }
}
@media screen and (max-width: 480px) {
  .l-home-intro__content-btns {
    margin: -8px;
    padding-bottom: 25px;
    flex-direction: column;
  }
}
.l-home-intro__content-btns-item {
  margin: 16px;
}
@media screen and (max-width: 480px) {
  .l-home-intro__content-btns-item {
    margin: 8px;
  }
}
.l-home-intro__content-btns-item.btn, .l-home-intro__content-btns-item.btn-video {
  font-size: 16px;
}
.l-home-intro__content-btns-item.btn {
  padding-right: 40px;
  padding-left: 40px;
}
.l-home-intro__content-desctiption {
  margin: 0 0 16px;
  line-height: 1.5714285714;
  color: #6b7280;
}
@media screen and (max-width: 767px) {
  .l-home-intro__content-desctiption {
    margin-bottom: 12px;
    text-align: center;
  }
}
.l-home-intro__content-tinkoff {
  padding-top: 16px;
  border-top: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .l-home-intro__content-tinkoff {
    padding-top: 12px;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
  }
}
.l-home-intro__content-tinkoff-text {
  margin-right: 18px;
  line-height: 1.5714285714;
  flex-grow: 1;
  color: #6b7280;
}
@media screen and (max-width: 767px) {
  .l-home-intro__content-tinkoff-text {
    max-width: 100%;
    width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
  }
}
.l-home-intro__content-tinkoff-logo {
  max-width: 144px;
  flex: 0 0 144px;
}
@media screen and (max-width: 1000px) {
  .l-home-intro__content-tinkoff-logo {
    max-width: 110px;
    flex-basis: 110px;
  }
}
@media screen and (min-width: 1200px) {
  .l-home-intro:not(.is-loaded) .l-home-intro__content {
    transform: translateY(14px);
    visibility: hidden;
    opacity: 0;
  }
}
.l-home-intro__media {
  max-width: 580px;
  margin-right: -100px;
  flex-basis: 51%;
}
@media screen and (max-width: 1169px) {
  .l-home-intro__media {
    margin-right: -116px;
  }
}
@media screen and (max-width: 767px) {
  .l-home-intro__media {
    max-width: 100%;
    margin-right: 0;
    flex-basis: 100%;
  }
}
.l-home-intro__picture {
  margin: 0 100px 100px;
  padding-bottom: 94.827586206%;
  border-radius: 0 0 300px 300px;
  display: block;
  box-shadow: 0 0 0 100px #F2F6FA;
  transition: box-shadow 0.4s 0.2s, transform 0.4s 0s, visibility 0.94s 0s, opacity 0.94s 0s;
  position: relative;
  overflow: hidden;
  z-index: -1;
}
@media screen and (max-width: 1169px) {
  .l-home-intro__picture {
    margin: 0 8.594vw 8.594vw;
    box-shadow: 0 0 0 8.594vw #F2F6FA;
  }
}
@media screen and (max-width: 767px) {
  .l-home-intro__picture {
    margin: 0 0 32px;
    padding-bottom: 70%;
    border-radius: initial;
    box-shadow: initial;
  }
}
@media screen and (min-width: 1200px) {
  .l-home-intro:not(.is-loaded) .l-home-intro__picture {
    box-shadow: 0 0 0 0 transparent;
    transform: translateY(-1000px);
    visibility: hidden;
    opacity: 0;
  }
}
@media screen and (max-width: 767px) {
  .l-home-intro__img {
    object-position: center 25%;
  }
}

.l-benefits-card {
  padding: 50px 0;
}
.l-benefits-card--1 {
  padding-top: 100px;
}
@media screen and (max-width: 1169px) {
  .l-benefits-card--1 {
    padding-top: 80px;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 1000px) {
  .l-benefits-card--1 {
    padding-top: 48px;
  }
}
@media screen and (max-width: 480px) {
  .l-benefits-card--1 {
    padding-top: 40px;
  }
}
.l-benefits-card--2 {
  padding-top: 14px;
  padding-bottom: 203px;
}
@media screen and (max-width: 1169px) {
  .l-benefits-card--2 {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 1000px) {
  .l-benefits-card--2 {
    padding-bottom: 70px;
  }
}
.l-benefits-card--3 {
  padding-top: 216px;
}
@media screen and (max-width: 1169px) {
  .l-benefits-card--3 {
    padding-top: 100px;
  }
}
@media screen and (max-width: 1000px) {
  .l-benefits-card--3 {
    padding-top: 112px;
  }
}
@media screen and (max-width: 767px) {
  .l-benefits-card--3 {
    padding-top: 48px;
  }
}
@media screen and (max-width: 480px) {
  .l-benefits-card--3 {
    padding-top: 40px;
  }
}
.l-benefits-card--4 {
  padding-top: 161px;
  padding-bottom: 180px;
}
@media screen and (max-width: 1169px) {
  .l-benefits-card--4 {
    padding-top: 50px;
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 1000px) {
  .l-benefits-card--4 {
    padding-top: 50px;
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 767px) {
  .l-benefits-card--4 {
    padding-top: 0;
    padding-bottom: 48px;
  }
}
.l-benefits-card--5 {
  padding-top: 127px;
  padding-bottom: 127px;
}
@media screen and (max-width: 1169px) {
  .l-benefits-card--5 {
    padding-top: 90px;
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 1000px) {
  .l-benefits-card--5 {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 767px) {
  .l-benefits-card--5 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
.l-benefits-card--theme-gray {
  position: relative;
  z-index: 0;
}
.l-benefits-card--theme-gray:before {
  background: #F8FAFC;
  width: 100vw;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
.l-benefits-card__inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media screen and (max-width: 1000px) {
  .l-benefits-card__inner {
    align-items: center;
  }
}
@media screen and (max-width: 767px) {
  .l-benefits-card__inner {
    flex-direction: column;
  }
}
@media screen and (max-width: 1000px) {
  .l-benefits-card--5 .l-benefits-card__inner {
    flex-direction: column;
  }
}
.l-benefits-card__col--content {
  flex-grow: 1;
  align-self: center;
}
@media screen and (max-width: 1169px) {
  .l-benefits-card__col--content {
    max-width: calc(50% - 18px);
  }
}
@media screen and (max-width: 767px) {
  .l-benefits-card__col--content {
    width: 100%;
    max-width: 100%;
    margin: 0 0 40px;
  }
}
.l-benefits-card__col--picture {
  max-width: 57%;
  flex: 0 0 57%;
}
@media screen and (max-width: 1169px) {
  .l-benefits-card__col--picture {
    max-width: calc(50% - 18px);
  }
}
@media screen and (max-width: 767px) {
  .l-benefits-card__col--picture {
    width: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .l-benefits-card--5 .l-benefits-card__col {
    width: 100%;
    max-width: 100%;
    margin: 0 !important;
    flex: 0 0 100%;
  }
}
.l-benefits-card--1 .l-benefits-card__col--content {
  margin-top: 200px;
  margin-bottom: 156px;
  align-self: flex-end;
}
@media screen and (max-width: 1000px) {
  .l-benefits-card--1 .l-benefits-card__col--content {
    margin-top: 0;
    margin-bottom: 90px;
    align-self: center;
  }
}
@media screen and (max-width: 767px) {
  .l-benefits-card--1 .l-benefits-card__col--content {
    margin-bottom: 20px;
  }
}
.l-benefits-card--3 .l-benefits-card__col--content {
  margin-top: 90px;
}
@media screen and (max-width: 1169px) {
  .l-benefits-card--3 .l-benefits-card__col--content {
    margin-top: 40px;
  }
}
@media screen and (max-width: 767px) {
  .l-benefits-card--3 .l-benefits-card__col--content {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
.l-benefits-card--4 .l-benefits-card__col--content {
  margin-bottom: 42px;
}
.l-benefits-card--direction-normal .l-benefits-card__col--content {
  margin-right: 30px;
}
@media screen and (max-width: 767px) {
  .l-benefits-card--direction-normal .l-benefits-card__col--content {
    margin-right: 0;
  }
}
.l-benefits-card--direction-normal .l-benefits-card__col--picture {
  margin-right: 0;
  margin-left: -10%;
  order: initial;
}
@media screen and (max-width: 767px) {
  .l-benefits-card--direction-normal .l-benefits-card__col--picture {
    margin-left: 0;
  }
}
.l-benefits-card--direction-reverse .l-benefits-card__col--content {
  margin-right: 0;
  margin-left: 30px;
}
@media screen and (max-width: 767px) {
  .l-benefits-card--direction-reverse .l-benefits-card__col--content {
    margin-left: 0;
  }
}
.l-benefits-card--direction-reverse .l-benefits-card__col--picture {
  margin-right: -10%;
  margin-left: 0;
  order: -1;
}
@media screen and (max-width: 767px) {
  .l-benefits-card--direction-reverse .l-benefits-card__col--picture {
    margin-right: 0;
    order: initial;
  }
}
@media screen and (max-width: 767px) {
  .l-benefits-appeal ~ .l-benefits-card .l-benefits-card__col--picture {
    margin-bottom: 30px;
    order: -1;
  }
}
@media screen and (max-width: 767px) {
  .l-benefits-appeal ~ .l-benefits-card .l-benefits-card__picture {
    text-align: center;
  }
}
@media screen and (max-width: 1169px) {
  .l-benefits-card--1 .l-benefits-card__col--picture .l-benefits-card__picture {
    margin-left: -30%;
  }
}
@media screen and (max-width: 767px) {
  .l-benefits-card--1 .l-benefits-card__col--picture .l-benefits-card__picture {
    margin-bottom: -7%;
    margin-left: -28%;
  }
}
@media screen and (max-width: 480px) {
  .l-benefits-card--1 .l-benefits-card__col--picture .l-benefits-card__picture {
    margin-left: -24%;
  }
}
.l-benefits-card__content {
  max-width: 544px;
  position: relative;
  z-index: 0;
}
.l-benefits-card__content-counter {
  font-weight: 600;
  font-size: 240px;
  line-height: 1;
  color: #e5e7eb;
  position: absolute;
  top: -0.48em;
  left: -0.04em;
  pointer-events: none;
  user-select: none;
  z-index: -1;
}
@media screen and (max-width: 1000px) {
  .l-benefits-card__content-counter {
    font-size: 200px;
    top: -0.4em;
  }
}
@media screen and (max-width: 767px) {
  .l-benefits-card__content-counter {
    top: -0.07em;
    right: 0;
    left: initial;
  }
}
.l-benefits-card__content-title {
  margin: 0 0 17px;
  font-weight: 600;
  line-height: 1.1;
}
.l-benefits-card__content-title--size-sm {
  font-size: 32px;
}
@media screen and (max-width: 1000px) {
  .l-benefits-card__content-title--size-sm {
    font-size: 32px;
  }
}
@media screen and (max-width: 767px) {
  .l-benefits-card__content-title--size-sm {
    font-size: 28px;
  }
}
@media screen and (max-width: 480px) {
  .l-benefits-card__content-title--size-sm {
    font-size: 24px;
  }
}
.l-benefits-card__content-title--size-md {
  font-size: 36px;
}
@media screen and (max-width: 1000px) {
  .l-benefits-card__content-title--size-md {
    font-size: 32px;
  }
}
@media screen and (max-width: 767px) {
  .l-benefits-card__content-title--size-md {
    font-size: 28px;
  }
}
@media screen and (max-width: 480px) {
  .l-benefits-card__content-title--size-md {
    font-size: 24px;
  }
}
.l-benefits-card__content-title span {
  color: #16A34A;
}
.l-benefits-card__content-text {
  margin: 0;
  font-size: 16px;
  line-height: 1.75;
}
.l-benefits-card__content-text p {
  margin-bottom: 0.7em;
}
@media screen and (max-width: 1000px) {
  .l-benefits-card--5 .l-benefits-card__content {
    max-width: 100% !important;
  }
}
.l-benefits-card--direction-normal .l-benefits-card__content {
  max-width: 544px;
  margin-left: 0;
}
@media screen and (max-width: 767px) {
  .l-benefits-card--direction-normal .l-benefits-card__content {
    max-width: 100%;
  }
}
.l-benefits-card--direction-reverse .l-benefits-card__content {
  max-width: 454px;
  margin-left: auto;
}
@media screen and (max-width: 767px) {
  .l-benefits-card--direction-reverse .l-benefits-card__content {
    max-width: 100%;
  }
}
.l-benefits-card__picture {
  display: block;
  font-size: 0;
  pointer-events: none;
  user-select: none;
}
@media screen and (max-width: 1000px) {
  .l-benefits-card--5 .l-benefits-card__picture {
    max-width: 504px;
    margin: 0 auto 10px;
  }
}
.l-benefits-card--direction-normal .l-benefits-card__picture {
  text-align: right;
}
.l-benefits-card--direction-reverse .l-benefits-card__picture {
  text-align: left;
}
.l-benefits-card__img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: top;
}

.l-benefits-appeal {
  color: #ffffff;
  position: relative;
  z-index: 0;
}
.l-benefits-appeal:before {
  background: #111827;
  width: 100vw;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
.l-benefits-appeal__inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.l-benefits-appeal__col--content {
  max-width: 640px;
  margin: 0 40px 0 0;
  align-self: flex-start;
  flex-grow: 1;
}
@media screen and (max-width: 1169px) {
  .l-benefits-appeal__col--content {
    max-width: initial;
    margin-right: 0;
    flex-grow: 1;
  }
}
.l-benefits-appeal__col--picture {
  max-width: 380px;
  margin-top: -65px;
  margin-bottom: 65px;
  flex: 0 0 33.5%;
}
@media screen and (max-width: 1169px) {
  .l-benefits-appeal__col--picture {
    display: none;
  }
}
.l-benefits-appeal__content {
  padding: 64px 0 60px;
}
@media screen and (max-width: 1169px) {
  .l-benefits-appeal__content {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}
@media screen and (max-width: 767px) {
  .l-benefits-appeal__content {
    padding-top: 48px;
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 480px) {
  .l-benefits-appeal__content {
    padding-bottom: 48px;
  }
}
.l-benefits-appeal__content-title {
  margin: 0 0 24px;
  padding: 13px 0 0 69px;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.15;
  color: #16A34A;
  position: relative;
}
@media screen and (max-width: 1169px) {
  .l-benefits-appeal__content-title {
    font-size: 36px;
  }
}
@media screen and (max-width: 767px) {
  .l-benefits-appeal__content-title {
    margin-bottom: 16px;
    padding-top: 0;
    padding-left: 0;
    font-size: 32px;
  }
}
@media screen and (max-width: 480px) {
  .l-benefits-appeal__content-title {
    font-size: 28px;
  }
}
.l-benefits-appeal__content-title:before {
  background: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.927 5C3.62668 13.1088 0 22.1844 0 29.6884C0 37.6737 4.23201 43 10.7609 43C16.3227 43 19.8281 39.1272 19.8281 34.2864C19.8281 29.3253 16.2014 25.9369 11.3651 25.6942C12.5747 21.0951 15.9599 16.134 23.8186 11.4136L16.927 5ZM41.1084 5C27.8081 13.1088 24.1814 22.1844 24.1814 29.6884C24.1814 37.6737 28.4134 43 34.9423 43C40.5041 43 44.0105 39.1272 44.0105 34.2864C44.0105 29.3253 40.3828 25.9369 35.5465 25.6942C36.7551 21.0951 40.1413 16.134 48 11.4136L41.1073 5H41.1084Z' fill='%2316A34A'/%3E%3C/svg%3E%0A") 50% no-repeat;
  background-size: contain;
  width: 48px;
  height: 48px;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 1169px) {
  .l-benefits-appeal__content-title:before {
    top: 3px;
  }
}
@media screen and (max-width: 767px) {
  .l-benefits-appeal__content-title:before {
    margin: 0 0 16px;
    position: initial;
  }
}
.l-benefits-appeal__content-title span {
  display: block;
  transform: skewX(-13deg);
}
.l-benefits-appeal__content-text {
  max-width: 580px;
  margin: 0 0 16px;
  font-weight: bold;
  font-size: 28px;
  line-height: 1.4;
}
@media screen and (max-width: 1169px) {
  .l-benefits-appeal__content-text {
    max-width: initial;
  }
}
@media screen and (max-width: 480px) {
  .l-benefits-appeal__content-text {
    font-size: 24px;
  }
}
.l-benefits-appeal__content-text > :first-child {
  margin-top: 0;
}
.l-benefits-appeal__content-text > :last-child {
  margin-bottom: 0;
}
.l-benefits-appeal__content-author {
  margin: 0 0 16px;
  font-size: 16px;
  line-height: 1.55;
  color: #F8FAFC;
}
.l-benefits-appeal__content-moore {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: #FFF8A7;
  transition: all 0.4s;
  transition-property: opacity;
}
.l-benefits-appeal__content-moore:hover {
  text-decoration: none;
}
@media screen and (min-width: 1200px) {
  .l-benefits-appeal__content-moore:hover {
    opacity: 0.8;
  }
}
.l-benefits-appeal__content-moore:after {
  background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.00502 4.50502C7.27839 4.23166 7.72161 4.23166 7.99497 4.50502L12.995 9.50502C13.2683 9.77839 13.2683 10.2216 12.995 10.495L7.99497 15.495C7.72161 15.7673 7.27839 15.7673 7.00502 15.495C6.73166 15.2216 6.73166 14.7784 7.00502 14.505L11.51 10L7.00502 5.49497C6.73166 5.22161 6.73166 4.77839 7.00502 4.50502Z' fill='%23FFF8A7'/%3E%3C/svg%3E%0A") 50% no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  margin: 2px 0 0 2px;
  content: "";
  flex: 0 0 20px;
  transition: all 0.4s;
  transition-property: transform;
}
@media screen and (min-width: 1200px) {
  .l-benefits-appeal__content-moore:hover:after {
    transform: translateX(4px);
  }
}
.l-benefits-appeal__picture {
  display: block;
  text-align: right;
  pointer-events: none;
  user-select: none;
}
.l-benefits-appeal__img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: top;
}

.l-benefits-join {
  padding: 56px 0;
  position: relative;
  z-index: 0;
}
@media screen and (max-width: 1169px) {
  .l-benefits-join {
    padding: 48px 0;
  }
}
@media screen and (max-width: 1000px) {
  .l-benefits-join {
    padding: 40px 0;
  }
}
.l-benefits-join:before {
  background: #F1F5F9;
  width: 100vw;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
.l-benefits-join__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .l-benefits-join__inner {
    flex-wrap: wrap;
  }
}
.l-benefits-join__title {
  margin: 0 25px 0 0;
  flex-grow: 1;
  font-weight: bold;
  font-size: 36px;
  line-height: 1.21;
}
@media screen and (max-width: 1169px) {
  .l-benefits-join__title {
    font-size: 28px;
  }
}
@media screen and (max-width: 767px) {
  .l-benefits-join__title {
    width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 480px) {
  .l-benefits-join__title {
    font-size: 24px;
    text-align: center;
  }
}
.l-benefits-join__btn {
  flex-shrink: 0;
}
@media screen and (max-width: 480px) {
  .l-benefits-join__btn {
    width: 100%;
  }
}

.l-home-main__title {
  max-width: 48%;
  margin: 0 0 -88px;
  font-weight: 600;
  font-size: 42px;
  line-height: 1.25;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 1169px) {
  .l-home-main__title {
    margin-bottom: -43px;
  }
}
@media screen and (max-width: 1000px) {
  .l-home-main__title {
    margin-bottom: 45px;
    font-size: 36px;
  }
}
@media screen and (max-width: 767px) {
  .l-home-main__title {
    margin-bottom: 32px;
    font-size: 32px;
  }
}
@media screen and (max-width: 480px) {
  .l-home-main__title {
    font-size: 28px;
  }
}
.settings {
  flex: 1;
  padding: 40px 0 80px;
}
.settings-wrap {
  display: flex;
  align-items: flex-start;
}
.settings-tabs {
  width: 240px;
  min-width: 240px;
  margin-right: 40px;
  position: sticky;
  top: 42px;
}
.settings-tabs-head {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.settings-tabs-img {
  width: 32px;
  min-width: 32px;
  height: 32px;
  margin-right: 12px;
  border-radius: 50%;
  overflow: hidden;
}
.settings-tabs-img img {
  width: 100%;
}
.settings-tabs-info-top {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #111827;
}
.settings-tabs-info-bottom {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8A9199;
}
.settings-tabs-tab {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 24px;
  color: #6b7280;
  cursor: pointer;
}
.settings-tabs-tab:last-of-type {
  margin-bottom: 0;
}
.settings-tabs-tab.active {
  font-weight: 500;
  color: #166534;
}
@media screen and (max-width: 1000px) {
  .settings-tabs-head-nav-step {
    padding: 0 16px;
  }
}
.settings-inner {
  flex: 1;
}
.settings-inner-ttl {
  margin: 0;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  color: #111827;
  margin-bottom: 8px;
}
.settings-inner-subttl {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #111827;
}
.settings-inner-desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
  margin-bottom: 60px;
}
.settings-inner-form-group {
  margin-bottom: 40px;
}
.settings-inner-form-group-wrap {
  display: none;
}
.settings-inner-form-label {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #111827;
  margin-bottom: 12px;
}
.settings-inner-form-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
  margin: 0 0 12px;
}
.settings-inner-form-email {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  margin: 0 0 12px;
}
.settings-inner-form-changepass {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
}
.settings-inner-form-changepass-link {
  color: #2563EB;
  transition: all 0.4s;
  transition-property: box-shadow;
  cursor: pointer;
}
@media screen and (min-width: 1200px) {
  .settings-inner-form-changepass-link:hover {
    box-shadow: inset 0 -1px 0 currentColor;
  }
}
.settings-inner-form-add {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.settings-inner-form-add img {
  margin-right: 8px;
}
.settings-inner-form-add span {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2563EB;
}
.settings-inner-form-block {
  max-width: 90%;
}
.settings-inner-form-block .settings-inner-form-text {
  margin-bottom: 0;
}
.settings-inner-form-block-accounts {
  max-width: 70%;
}
.settings-inner-form-block-accounts .settings-inner-form-text {
  margin-bottom: 0;
}
.settings-inner-form-buttons {
  display: flex;
  align-items: center;
}
.settings-inner-form-save {
  padding: 10px 24px;
  margin-right: 32px;
}
.settings-inner-form-preview {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2563EB;
}
.settings-inner-form-cancel {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
  cursor: pointer;
}
.settings-inner-form-account {
  width: 142px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #D1D5DB;
  transition: all 0.4s;
  transition-property: background;
}
@media screen and (min-width: 1200px) {
  .settings-inner-form-account:hover {
    background: rgba(229, 231, 235, 0.6);
  }
}
.settings-inner-form-account-logo {
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 4px;
}
.settings-inner-form-account-logo img {
  width: 100%;
}
.settings-inner-form-account span {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
}
.settings-inner-form-account-connected {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.settings-inner-form-account-connected-block {
  text-align: right;
}
.settings-inner-form-account-connected-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #111827;
}
.settings-inner-form-account-connected-cancel {
  margin-left: auto;
  display: inline-flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #2563EB;
  transition: all 0.4s;
  transition-property: box-shadow;
  cursor: pointer;
}
@media screen and (min-width: 1200px) {
  .settings-inner-form-account-connected-cancel:hover {
    box-shadow: inset 0 -1px 0 currentColor;
  }
}
.settings-inner-form-account-connected-logo {
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 12px;
}
.settings-inner-form.notice .settings-inner-subttl {
  margin-bottom: 24px;
}
.settings-inner-form.notice .input-group {
  margin-bottom: 32px;
}
.settings-inner-form-group-btns {
  margin-top: -10px;
}
.settings-inner-card {
  display: flex;
  align-items: flex-end;
}
.settings-inner-card-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #D1D5DB;
  overflow: hidden;
  margin-right: 16px;
}
.settings-inner-card-logo img {
  width: 100%;
}
.settings-inner-card-data {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.settings-inner-card-number {
  color: #111827;
}
.settings-inner-card-date {
  color: #6b7280;
}
.settings-inner-card-close {
  width: 48px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user-verification {
  font-size: 14px;
  line-height: 1.7;
  color: #111827;
}
.user-verification__phone-wrapper {
  padding-top: 40px;
}
.user-verification__paragraph {
  margin: 0 0 1.7em;
}
.user-verification__subtitle {
  margin: 32px 0 8px;
}
.user-verification__block {
  margin: 0 0 48px;
}
.user-verification__radio:not(:checked) ~ .user-verification__block {
  display: none;
}
.user-verification__choose-item {
  margin: 0 0 20px;
}
.user-verification__attach-file + .user-verification__attach-file {
  margin-top: 12px;
}
.user-verification__status-wrap {
  padding-top: 24px;
}
.user-verification__status {
  margin: 0 0 16px;
}
.user-verification__status-description {
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 1.7;
  color: #374151;
}
.user-verification__status-btn {
  margin-bottom: 20px;
}

@media screen and (max-width: 1000px) {
  .settings {
    padding: 0 0 48px;
  }
  .settings-wrap {
    display: block;
  }
  .settings-tabs {
    position: relative;
    width: calc(100% + 32px);
    min-width: 0;
    margin: 0 -16px 32px;
    position: static;
    top: 0;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .settings-tabs::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .settings-tabs-head {
    display: none;
  }
  .settings-tabs-list {
    width: max-content;
    min-width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding: 6px 16px;
  }
  .settings-tabs-list::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 100%;
    height: 1px;
    background-color: #e5e7eb;
  }
  .settings-tabs-tab {
    position: relative;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    margin-right: 48px;
    white-space: nowrap;
  }
  .settings-tabs-tab:last-of-type {
    margin-right: 0;
  }
  .settings-tabs-tab.active::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -6px;
    width: 100%;
    height: 3px;
    background-color: #166534;
    z-index: 2;
  }
  .settings-inner-desc {
    margin-bottom: 48px;
  }
  .settings-inner-form-block-accounts {
    max-width: 55%;
  }
}
@media screen and (max-width: 767px) {
  .settings {
    padding: 0 0 40px;
  }
  .settings-inner-ttl {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 12px;
  }
  .settings-inner-desc {
    margin-bottom: 32px;
  }
}
@media screen and (max-width: 480px) {
  .settings-inner-desc {
    margin-bottom: 24px;
  }
  .settings-inner-form-block {
    max-width: none;
  }
  .settings-inner-form-block-accounts {
    max-width: none;
  }
  .settings-inner-form-block-accounts .settings-inner-form-text {
    margin-bottom: 12px;
  }
  .settings-inner-form-buttons {
    flex-direction: column;
  }
  .settings-inner-form-save {
    width: 100%;
    margin: 0 0 32px;
    text-align: center;
  }
}
.tinkoff-bank {
  display: flex;
  align-items: center;
}
.tinkoff-bank__logo {
  width: 110px;
  height: auto;
  margin: 0 20px 0 0;
  display: block;
  flex: 0 0 110px;
}
.tinkoff-bank__description {
  margin: 0;
  font-size: 12px;
  line-height: 1.3333333333;
  color: #6b7280;
}

.section-title {
  margin: 0;
  font-weight: 600;
  font-size: 42px;
  line-height: 1.15;
  color: #111827;
}

.authors {
  overflow: hidden;
}

.authors-intro {
  background: #F8FAFC;
  padding: 72px 0;
  overflow: hidden;
}
.authors-intro__row {
  align-items: center;
}
.authors-intro__col--media {
  margin-bottom: -72px;
  align-self: center;
}
.authors-intro__content {
  max-width: 500px;
  padding: 52px 0;
}
.authors-intro__title {
  margin: 0 0 20px;
  font-weight: 600;
  font-size: 56px;
  line-height: 1.1071428571;
  color: #111827;
}
.authors-intro__desription {
  margin: 0 0 28px;
  color: #374151;
}
.authors-intro__btns {
  padding-bottom: 66px;
}
.authors-intro__tinkoff-bank {
  padding-top: 32px;
  border-top: 1px solid #e5e7eb;
}
.authors-intro__picture {
  margin-right: -134px;
  padding: 30px 0;
  display: block;
}
.authors-intro__picture img {
  width: 100%;
  height: auto;
  display: block;
}

.a-section-card {
  padding: 130px 0 100px 0;
}
.a-section-card__section-title {
  max-width: 600px;
  margin: 0 auto 30px;
  text-align: center;
}
.a-section-card__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.a-section-card__media {
  max-width: 530px;
  margin-right: 7.5%;
  flex: 0 0 46.6%;
}
.a-section-card__picture {
  width: 100%;
  display: block;
  user-select: none;
}
.a-section-card__picture img {
  width: 100%;
  height: auto;
  display: block;
}
.a-section-card + .a-section-card .a-section-card__media {
  margin-right: 0;
  margin-left: 7.5%;
}
.a-section-card__content {
  padding: 0 0 24px 24px;
  flex-grow: 1;
}
.a-section-card + .a-section-card .a-section-card__content {
  padding-right: 24px;
  padding-left: 0;
  order: -1;
}
.a-section-card__title {
  margin: 0 0 16px;
}
.a-section-card__text {
  font-size: 16px;
  line-height: 1.75;
  color: #374151;
}
.a-section-card__text:not(:last-child) {
  margin-bottom: 24px;
}

.a-benefits-card__header {
  margin: 0 0 40px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 480px) {
  .a-benefits-card__header {
    margin-bottom: 10px;
  }
}
.a-benefits-card__header:after {
  content: "";
  display: block;
  border-top: 2px dotted #D1D5DB;
  flex-grow: 1;
}
.a-benefits-card__picture {
  background: #16A34A;
  width: 24px;
  height: 24px;
  margin-right: 20px;
  flex: 0 0 24px;
  font-size: 0;
  line-height: 24px;
  text-align: center;
  border-radius: 50%;
}
.a-benefits-card__icon {
  display: inline-block;
  vertical-align: middle;
}
.a-benefits-card__title {
  margin: 0 0 12px;
  font-weight: 500;
  font-size: 21px;
}
@media screen and (max-width: 480px) {
  .a-benefits-card__title {
    margin-bottom: 7px;
  }
}
.a-benefits-card__text {
  margin: 0;
  font-size: 15px;
  line-height: 1.6;
}

.a-benefits-section {
  padding: 140px 0 70px;
}
@media screen and (max-width: 1169px) {
  .a-benefits-section {
    padding-top: 70px;
  }
}
@media screen and (max-width: 767px) {
  .a-benefits-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.a-benefits-section__title {
  max-width: 490px;
  margin: 0 0 90px;
}
@media screen and (max-width: 1169px) {
  .a-benefits-section__title {
    max-width: 360px;
    margin-bottom: 60px;
    font-size: 32px;
  }
}
@media screen and (max-width: 767px) {
  .a-benefits-section__title {
    max-width: 280px;
    font-size: 24px;
  }
}
@media screen and (max-width: 480px) {
  .a-benefits-section__title {
    margin-bottom: 40px;
  }
}
.a-benefits-section__list {
  margin: 0 -20px -40px;
  display: flex;
  flex-wrap: wrap;
}
.a-benefits-section__list-col {
  max-width: 25%;
  margin-bottom: 40px;
  padding: 0 20px;
  flex: 0 0 25%;
}
@media screen and (max-width: 767px) {
  .a-benefits-section__list-col {
    max-width: 50%;
    flex-basis: 50%;
  }
}
@media screen and (max-width: 480px) {
  .a-benefits-section__list-col {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.a-section-info {
  padding: 50px 0;
}
.a-section-info__inner {
  background: #F8FAFC;
  margin-top: -76px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 16px;
}
.a-section-info__content {
  padding: 86px 24px 86px 5.7%;
  flex-grow: 1;
}
.a-section-info__title {
  margin: 0 0 16px;
}
.a-section-info__text {
  font-size: 16px;
  line-height: 1.75;
  color: #374151;
}
.a-section-info__text:not(:last-child) {
  margin-bottom: 24px;
}
.a-section-info__media {
  max-width: 381px;
  margin: -44px 114px 0 0;
  flex: 0 0 33.8%;
  align-self: flex-end;
  position: relative;
  z-index: 0;
}
.a-section-info__btn {
  display: inline-block;
  vertical-align: top;
}
.a-section-info__picture {
  display: block;
  user-select: none;
}
.a-section-info__picture img {
  width: 100%;
  height: auto;
  display: block;
}
.a-section-info__decor {
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: contain;
  width: 248px;
  height: 286px;
  content: "";
  position: absolute;
  top: 44px;
  right: -114px;
  pointer-events: none;
  z-index: -1;
}

.why-uppora {
  padding: 62px 0 140px;
}
.why-uppora__title {
  margin: 0 0 83px;
  text-align: center;
}

.why-uppora-card {
  white-space: initial;
}
.why-uppora-card__picture {
  margin: 0 0 48px;
  padding-bottom: 68.965517241%;
  box-shadow: 0 8px 24px rgba(15, 23, 42, 0.06);
  border-radius: 16px;
}
.why-uppora-card__title {
  margin: 0 0 24px;
  font-weight: 600;
  font-size: 21px;
  line-height: 1.4285714286;
  color: #111827;
}
.why-uppora-card__text {
  font-size: 15px;
  line-height: 1.6;
  color: #374151;
}

.appeal-section {
  max-width: 1138px;
  margin: 0 auto 80px;
  padding: 80px 0;
  position: relative;
  z-index: 0;
}
.appeal-section--about {
  margin-bottom: 0 !important;
}
.appeal-section:before {
  background: #F8FAFC;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 16px;
  z-index: -1;
}
.appeal-section--about:before {
  background: #ffffff;
}
.appeal-section__content {
  padding: 0 16px;
  text-align: center;
}
.appeal-section__title {
  max-width: 750px;
  margin: 0 auto 16px;
}
.appeal-section__text {
  max-width: 650px;
  margin: 0 auto 24px;
  font-size: 15px;
  line-height: 1.6;
}
.appeal-section__btns {
  justify-content: center;
}

.mass-media-card {
  transition: all 0.4s;
  transition-property: opacity;
}
.mass-media-card:not(:hover) {
  opacity: 0.2;
}
.mass-media-card__picture {
  display: block;
  padding-bottom: 30%;
  display: block;
  position: relative;
}
.mass-media-card__link:before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}
.mass-media-card__link > *, .mass-media-card__img {
  max-width: calc(100% - 14px);
  max-height: calc(100% - 14px);
  margin: auto;
  fill: #111827;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.mass-media-section {
  background: #F8FAFC;
  padding: 80px 0 100px;
}
@media screen and (max-width: 767px) {
  .mass-media-section {
    padding-top: 60px;
    padding-bottom: 50px;
  }
}
.mass-media-section__title {
  margin: 0 0 70px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .mass-media-section__title {
    margin-bottom: 30px;
    font-size: 24px;
  }
}
.mass-media-section__list {
  margin: 0 -10px -10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .mass-media-section__list {
    flex-wrap: wrap;
  }
}
.mass-media-section__list-col {
  max-width: 25%;
  margin-bottom: 10px;
  padding: 0 10px;
  flex: 0 0 25%;
}
@media screen and (max-width: 767px) {
  .mass-media-section__list-col {
    max-width: 50%;
    flex-basis: 50%;
  }
}

.template-card {
  display: flex;
  align-items: center;
}
.template-card__input {
  flex-grow: 1;
}
.template-card__currency {
  margin-left: 4px;
  font-size: 14px;
  color: #9CA3AF;
  flex-shrink: 0;
  white-space: nowrap;
}

/* 
.label-checkbox {
    display: block;
    cursor: pointer;
    position: relative;

    &__input {
        display: block;
        position: absolute;
        left: -9999px;
    }

    &__inner {
        padding: 0 0 0 32px;
        display: block;
        font-size: 14px;
        line-height: 1.45;

        &:before {
            background: transparent;
            width: 20px;
            height: 20px;
            border: 1px solid #D1D5DB;
            border-radius: 4px;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            transition: all .4s;
            transition-property: background, border-color;
        }
    }

    &__inner-icon {
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        transition: all .4s;
        transition-property: opacity, visibility;
    }

    &__input:checked ~ &__inner:before {
        background: $green3;
        border-color: $green3;
    }

    &__input:not(:checked) ~ &__inner-icon {
        opacity: 0;
        visibility: hidden;
    }
} */
.button-badge-donate {
  background: transparent;
  padding: 8px 16px;
  display: block;
  font-size: 14px;
  line-height: 1;
  color: #374151;
  border: 1px solid #D1D5DB;
  border-radius: 16px;
  outline: none;
  cursor: pointer;
  transition: all 0.4s;
}
.button-badge-donate:hover, .button-badge-donate:focus {
  outline: none;
}
.button-badge-wrap {
  margin: -12px 0 0 -12px;
  display: flex;
  flex-wrap: wrap;
}
.button-badge-wrap__item {
  margin: 12px 0 0 12px;
}

.btn-donate {
  background: transparent;
  width: 100%;
  padding: 14px 28px;
  display: block;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.35;
  color: #374151;
  text-align: center;
  border: 1px solid #D1D5DB;
  border-radius: 8px;
  outline: none;
  user-select: none;
  cursor: pointer;
  transition: all 0.4s;
  transition-property: border-color;
}
.btn-donate__logo {
  width: 162px;
  height: auto;
  margin: 0 auto 6px;
  display: block;
}
.btn-donate__title {
  margin: 0 0 -6px;
  display: block;
}

.link-accent {
  color: #2563EB;
  box-shadow: inset 0 -1px 0 #2563EB;
  transition: 0.4s;
  transition-property: box-shadow;
}

.d-page {
  padding: 32px 0 40px;
  flex-grow: 1;
  font-size: 14px;
  color: #111827;
  overflow: hidden;
}

.prev-link {
  min-height: 24px;
  padding-left: 32px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: #2563EB;
  position: relative;
  transition: all 0.4s;
  transition-property: color;
}
.prev-link__icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.pager-link {
  display: inline-block;
  font-size: 0;
  position: relative;
  transition: all 0.4s;
  transition-property: opacity, visibility;
}
.pager-link__icon {
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  display: block;
}
.pager-link:after {
  content: "";
  position: absolute;
  inset: -6px;
}
.pager-link--next {
  transform: scaleX(-1);
}
.pager-link--is-hidden {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

.site-pager {
  display: flex;
  align-items: center;
}
.site-pager__link {
  display: none;
}
.site-pager__steps {
  margin: 0;
}

.ty-donate {
  font-size: 14px;
  line-height: 1.35;
  color: #111827;
  text-align: center;
}
.ty-donate__media {
  width: 128px;
  height: 128px;
  margin: 0 auto 8px;
  display: block;
  font-size: 0;
  text-align: center;
  user-select: none;
  position: relative;
}
.ty-donate__media > * {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.ty-donate__media.is-loaded .ty-donate__media-gif, .ty-donate__media:not(.is-loaded) .ty-donate__media-img {
  opacity: 0;
  visibility: hidden;
}
.ty-donate__title {
  margin: 0 0 8px;
  font-weight: 600;
  font-size: 24px;
}
.ty-donate__text {
  margin: 0 0 20px;
  font-size: 14px;
  color: #374151;
}
.ty-donate__btn {
  margin: 0 0 24px;
  display: inline-block;
  vertical-align: top;
}
.d-donate__form {
  max-width: 512px;
  margin: 0 auto;
}
.d-donate__thank {
  max-width: 320px;
  margin: 0 auto;
  padding: 50px 0;
}

.donate-form {
  display: block;
}
.donate-form--size-sm {
  max-width: 384px;
  margin-right: auto;
  margin-left: auto;
}
.donate-form__header {
  margin: 0 0 4px;
  display: flex;
  align-items: center;
}
.donate-form__pager-link {
  margin-right: 12px;
  display: none;
}
.donate-form__title {
  margin: 0;
  font-weight: 600;
  font-size: 30px;
  line-height: 1.3333333333;
}
.donate-form__input-donate {
  margin: 30px 0 16px;
}
.donate-form__input-name {
  margin-bottom: 48px;
}
.donate-form__comment {
  margin-bottom: 16px;
}
.donate-form__button-badge-amount-wrap {
  padding-bottom: 48px;
}
.donate-form__button-badge-comment-wrap {
  padding-bottom: 52px;
}
.donate-form__privacy {
  margin-bottom: 32px;
}
.donate-form__btn {
  margin-top: 48px;
  margin-bottom: 24px;
}
.donate-form__tinkoff-bank {
  font-size: 12px;
  line-height: 1.35;
  color: #6b7280;
  text-align: center;
}
.donate-form__tinkoff-bank-text {
  margin: 0 0 12px;
  display: block;
}
.donate-form__tinkoff-bank-logo {
  width: 126px;
  height: auto;
  margin: 0 auto;
  display: block;
}

.project-card {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 1.3;
  color: #111827;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.project-card__info {
  padding: 20px;
  display: flex;
  align-items: center;
}
.project-card__logo {
  width: 36px;
  height: 36px;
  margin-right: 12px;
  border-radius: 50%;
  flex: 0 0 36px;
  overflow: hidden;
}
.project-card__title {
  height: 2.7em;
  margin: 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.35;
  overflow: hidden;
}
.project-card__link {
  display: -webkit-box;
  color: #111827;
  outline: none;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.4s;
  transition-property: color;
  overflow: hidden;
}
.project-card__link:before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.project-card__media {
  padding-bottom: 53.2%;
  display: block;
  overflow: hidden;
}
.project-card__content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.project-card__target {
  display: flex;
  align-items: center;
  font-weight: 500;
}
.project-card__price {
  margin-right: 0.3em;
  font-size: 21px;
}
.project-card__price-title {
  font-size: 14px;
}
.project-card__description {
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 1.7;
  color: #374151;
}
.project-card__footer {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #9CA3AF;
}
.project-card__time {
  display: block;
}
.project-card__city-label {
  flex-shrink: 0;
}

.appeal-alert {
  background: #F8FAFC;
  padding: 24px;
  border-radius: 8px;
  position: relative;
}
.appeal-alert__close {
  position: absolute;
  top: 12px;
  right: 12px;
}
.appeal-alert__inner {
  display: flex;
  justify-content: space-between;
}
.appeal-alert__content {
  margin: 0 28px 0 0;
  font-size: 14px;
  line-height: 1.7;
  color: #374151;
}
.appeal-alert__content a {
  color: #2563EB;
  transition: all 0.4s;
  transition-property: color;
}
.appeal-alert__btn {
  margin-right: 32px;
  display: inline-block;
  flex-shrink: 0;
  align-self: center;
}

.projects-list__row {
  margin: 0 -16px -40px;
}
.projects-list__col {
  margin-bottom: 40px;
  padding-right: 16px;
  padding-left: 16px;
}
.projects-list__card {
  height: 100%;
}

.p-project {
  padding: 40px 0 80px;
  color: #111827;
  overflow: clip;
}
.p-project__title {
  margin: 0 0 32px;
}
.p-project__filter {
  margin: 0 0 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e5e7eb;
}
.p-project__appeal-alert {
  margin: 0 0 32px;
}
.p-project__search-users {
  margin: 0 0 72px;
  padding-top: 16px;
}
.p-project__search-info {
  margin: 0 0 16px;
}
.p-project__bottom-search {
  padding-top: 40px;
  text-align: center;
}
.p-project__bottom-search-btn {
  min-width: 176px;
}
.p-project__search-header {
  display: flex;
  align-items: center;
}
.p-project__search-header-result-counter {
  margin: 6px 0 32px 12px;
  font-weight: normal;
  font-size: 14px;
  color: #6b7280;
}

.nav-step {
  font-weight: normal;
  font-size: 16px;
  line-height: 1.75;
}
.nav-step__list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style-type: none;
}
.nav-step__item {
  margin: 0;
  padding: 0;
}
.nav-step__item:not(:last-child) {
  margin-bottom: 26px;
}
.nav-step__link {
  display: block;
  color: #6b7280;
  outline: none;
  transition: all 0.4s;
  transition-property: color;
}
@media screen and (min-width: 1200px) {
  .nav-step__link:hover {
    color: #166534;
  }
}
.nav-step__link.is-active {
  font-weight: 500;
  color: #166534 !important;
}
.nav-step__link.is-disabled {
  font-weight: normal;
  color: #D1D5DB;
  pointer-events: none;
  cursor: default;
}
.nav-step__link.is-developing:not(:hover) {
  color: #e5e7eb;
}

.u-subtitle {
  font-size: 14px;
  color: #6b7280;
}

.add-link-btn {
  background: transparent;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.3;
  color: #2563EB;
  position: relative;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  position: relative;
}
.add-link-btn:before {
  content: "";
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
}
.add-link-btn__icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  flex: 0 0 20px;
  transition: all 0.4s;
  transition-property: transform;
}
.add-link-btn:hover .add-link-btn__icon {
  transform: rotate(180deg);
}

.label-radio {
  padding: 0 0 0 32px;
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.7;
  user-select: none;
  cursor: pointer;
  position: relative;
}
.label-radio--size-sm {
  font-size: 16px;
  line-height: 1.375;
}
.label-radio__input {
  display: none;
  position: absolute;
  left: -9999px;
}
.label-radio__inner {
  display: block;
}
.label-radio__inner:before {
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  box-shadow: inset 0 0 0 1px #D1D5DB;
  border-radius: 50%;
  transition: all 0.4s;
  transition-property: box-shadow;
}
.label-radio__input:checked ~ .label-radio__inner:before {
  box-shadow: inset 0 0 0 6px #16A34A;
}
.label-radio__input:disabled ~ .label-radio__inner {
  color: #9CA3AF;
}
.label-radio__input:disabled ~ .label-radio__inner:before {
  box-shadow: inset 0 0 0 1px #D1D5DB;
}
.label-radio__input:disabled ~ .label-radio__inner .label-radio__text {
  color: #9CA3AF;
}
.label-radio__title, .label-radio__text {
  margin: 0;
  display: block;
}
.label-radio__title {
  font-weight: 500;
}
.label-radio__title-badge {
  background: #F3F4F6;
  margin-left: 8px;
  padding: 4px 6px;
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: #111827;
  border-radius: 4px;
  white-space: nowrap;
  vertical-align: middle;
}
.label-radio__text {
  color: #6b7280;
}

.n-new-project {
  padding: 32px 0 80px;
  font-size: 14px;
  color: #111827;
  flex-grow: 1;
  overflow: clip;
}
.n-new-project__container {
  display: flex;
  align-items: flex-start;
}
.n-new-project__aside {
  width: 240px;
  margin: -32px 40px 0 0;
  padding: 42px 0;
  flex: 0 0 240px;
  position: sticky;
  top: 0;
  left: 0;
}
.n-new-project__aside-title {
  margin: 0 0 24px;
  font-weight: 600;
  font-size: 20px;
}
.n-new-project__main {
  flex-grow: 1;
}
.n-new-project__main-title {
  margin: 0 0 8px;
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
.n-new-project__main-pager {
  margin: 0 0 24px;
}
.n-new-project__main-alert {
  margin: 0 0 40px;
}
.n-new-project__main-form__area-item {
  max-width: 480px;
  margin: 0 0 48px;
}
.n-new-project__main-form__area-item--category {
  max-width: 280px;
}
.n-new-project__main-form__area-d-all {
  max-width: 100%;
  margin-bottom: 48px;
}
.n-new-project__main-form__area-new {
  margin: 0 0 48px;
}
.n-new-project__main-form__area-logo {
  margin: 0 0 60px;
}
.n-new-project__main-form__area-line {
  margin: 0 0 32px;
}
.n-new-project__main-form__area-link {
  margin: 0 0 60px;
}
.n-new-project__main-form-privacy {
  max-width: 630px;
  margin: 0 0 60px;
}
.n-new-project__main-form-privacy-title {
  margin: 0 0 24px;
  font-weight: 500;
  font-size: 16px;
}
.n-new-project__main-form-privacy-label-radio {
  margin: 0 0 24px;
}
.card-badge-text {
  font-size: 18px;
}
.card-badge-text__title {
  margin: 0 0 8px;
  font-weight: 500;
  display: block;
}
.card-badge-text__contacts {
  display: block;
}
.card-badge-text__contacts a {
  color: #2563EB;
  transition: all 0.4s;
  transition-property: color;
}

.progress {
  background: #DFE1E3;
  height: 6px;
  border-radius: 3px;
  position: relative;
}
.progress span {
  background: #16A34A;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
}

.clients-info {
  padding: 100px 0;
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 0;
}
.clients-info:before {
  background: #F8FAFC;
  content: "";
  position: absolute;
  top: 20px;
  right: -80px;
  bottom: 0;
  left: -80px;
  border-radius: 16px;
  z-index: -1;
}
.clients-info__aside {
  margin: -14px 68px 0 0;
  flex-grow: 1;
}
.clients-info__aside-percent {
  margin: 0 0 6px;
  font-weight: bold;
  font-size: 148px;
  line-height: 1;
  color: #16A34A;
  text-align: center;
}
.clients-info__content {
  flex-grow: 1;
}
.clients-info__title {
  margin: 0 0 32px;
  font-weight: bold;
  font-size: 28px;
  line-height: 1.45;
  color: #111827;
}
.clients-info__text {
  line-height: 1.8;
  color: #111827;
}

.blockquote-card__text {
  margin: 0 0 15px;
  font-style: italic;
}
@media screen and (max-width: 1169px) {
  .blockquote-card__text {
    margin-bottom: 12px;
  }
}
.blockquote-card__footer {
  margin: 0;
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
}
.blockquote-card__author,
.blockquote-card cite {
  font-style: normal;
}

.author-cite__inner {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 480px) {
  .author-cite__inner {
    flex-wrap: wrap;
  }
}
.author-cite__avatar {
  width: 56px;
  height: 56px;
  margin: 4px 20px 0 0;
  flex: 0 0 56px;
  border-radius: 50%;
  overflow: hidden;
}
@media screen and (max-width: 480px) {
  .author-cite__avatar {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 16px;
  }
}
.author-cite__blockquote {
  margin: 0;
  display: block;
  flex-grow: 1;
}

.a-about-info {
  padding: 60px 0 120px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.a-about-info__content {
  max-width: 748px;
  margin-right: 80px;
  flex-grow: 1;
}
.a-about-info__title {
  margin: 0 0 40px;
  font-weight: 600;
  font-size: 42px;
  line-height: 1.3;
}
.a-about-info__text {
  margin-bottom: 32px;
}
.a-about-info__author {
  padding-top: 32px;
  border-top: 1px solid #e5e7eb;
}
.a-about-info__aside {
  padding-top: 100px;
  flex-shrink: 0;
}
.a-about-info__aside-card:not(:last-child) {
  margin-bottom: 26px;
}

.a-about {
  padding: 90px 0;
  flex-grow: 1;
  font-size: 14px;
  color: #111827;
  overflow: clip;
}
.a-about__inner {
  max-width: 820px;
}
.a-about__title {
  margin: 0;
  font-weight: bold;
  font-size: 80px;
  line-height: 1.15;
}
.a-about__title > span {
  height: 1.15em;
  display: block;
  position: relative;
  overflow: hidden;
}
.a-about__title > span span {
  display: block;
  transition: all 0.6s;
  transition-property: opacity, visibility;
}
.a-about__title > span span:not(.is-active) {
  height: 0;
  opacity: 0;
  visibility: hidden;
}
.a-about__description {
  margin: 0 0 40px;
  font-size: 20px;
  color: #111827;
}
.a-about__poster {
  width: 100vw;
  margin: 90px 0 60px calc(-1 * (100vw - 569px));
  padding-bottom: 30vw;
  left: 50vw;
}
.list-info {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 1.8;
  list-style-type: none;
}
.list-info li {
  margin: 0;
  padding: 0;
  position: relative;
}
.list-info li:after {
  background: #e5e7eb;
  width: 100%;
  height: 1px;
  margin: 30px 0;
  content: "";
  display: block;
}
.list-info__icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  margin-right: 36px;
  position: absolute;
  top: 0.2em;
  right: 100%;
}

.link-accent-simple {
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #2563EB;
  white-space: nowrap;
  position: relative;
  transition: all 0.4s;
  transition-property: color;
}
.link-accent-simple:before {
  content: "";
  position: absolute;
  inset: -6px;
}

.a-about-company {
  padding: 120px 0 90px;
  display: flex;
  align-items: flex-start;
}
.a-about-company__title {
  max-width: 320px;
  margin: 0 60px 60px 0;
  flex: 0 0 320px;
}
.a-about-company__content {
  padding-top: 12px;
  flex-grow: 1;
  color: #111827;
}
.a-about-company__list {
  margin: 0 0 40px;
}
.news-card {
  color: #111827;
  white-space: initial;
  position: relative;
}
.news-card__picture {
  margin: 0 0 24px;
  padding-bottom: 55.865921787%;
  overflow: hidden;
}
.news-card__title {
  margin: 0 0 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.55;
}
.news-card__link {
  color: inherit;
  transition: all 0.4s;
  transition-property: color;
}
.news-card__link:before {
  content: "";
  position: absolute;
  inset: 0;
}
.news-card__footer {
  display: block;
}
.news-card__time {
  display: block;
  font-size: 14px;
  line-height: 1;
  color: #9CA3AF;
}

.news-list__row {
  margin: 0 -16px;
}
.news-list__col {
  padding-right: 16px;
  padding-left: 16px;
}
.news-section {
  padding: 120px 0;
  position: relative;
  z-index: 0;
}
.news-section:before {
  background: #F8FAFC;
  width: 100vw;
  height: 100%;
  margin-left: -50vw;
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;
}
.news-section__header {
  margin: 0 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.news-section__title {
  margin: 0 40px 0 0;
}
.news-section__view-all {
  margin-bottom: 12px;
  flex-shrink: 0;
  align-self: flex-end;
}

.team-card {
  background: transparent;
  padding: 20px;
  display: flex;
  align-items: center;
  color: #111827;
  border: 1px solid #e5e7eb;
  border-radius: 16px;
  position: relative;
  transition: all 0.34s;
  transition-property: background;
  overflow: hidden;
  z-index: 0;
}
@media screen and (min-width: 1200px) {
  .team-card:hover {
    background: rgba(229, 231, 235, 0.2);
  }
}
.team-card__picture {
  width: 48px;
  height: 48px;
  margin-right: 16px;
  flex: 0 0 48px;
  border-radius: 50%;
  overflow: hidden;
}
.team-card__content {
  padding-right: 44px;
  line-height: 1.6;
}
.team-card__name, .team-card__position {
  margin: 0;
}
.team-card__name {
  font-weight: 500;
  font-size: 16px;
}
.team-card__link {
  color: #111827;
}
.team-card__link:before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 1;
}
.team-card__position {
  font-size: 14px;
  color: #6b7280;
}
.team-card__arrow {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin: -12px 0 0;
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  transition: all 0.34s;
  transition-property: transform;
}
@media screen and (min-width: 1200px) {
  .team-card:hover .team-card__arrow {
    transform: translateX(44px);
  }
}

.team-list__row {
  margin: 0 -16px -24px;
}
.team-list__col {
  margin-bottom: 24px;
  padding-right: 16px;
  padding-left: 16px;
}
.team-section {
  padding: 100px 0 120px;
}
.team-section__title {
  margin: 0 0 40px;
}

.label-card-line {
  display: block;
}
.label-card-line__title {
  margin: 0 0 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: #4B5563;
}
.label-card-line__date {
  display: flex;
  align-items: center;
}
.label-card-line__date-input {
  width: 60px;
}
.label-card-line__date-divider {
  margin: 0 8px;
  font-size: 14px;
  line-height: 1;
  color: #6b7280;
}

.card-block {
  padding: 32px 0 24px;
}
.card-block__number {
  margin: 0 0 24px;
}
.card-block__info {
  margin: 0 0 32px;
  display: flex;
  justify-content: space-between;
}
.card-block__info-cvv {
  width: 60px;
  flex: 0 0 60px;
}
.card-block__btn {
  width: 100%;
  display: block;
}

.file-attach {
  display: flex;
  align-items: center;
}
.file-attach__text {
  overflow: hidden;
}
.file-attach__name {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2563EB;
}
.file-attach__remove {
  background: transparent;
  width: 24px;
  height: 24px;
  margin-left: 8px;
  flex: 0 0 24px;
  font-size: 0;
  color: #9CA3AF;
  border: none;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  transition: all 0.4s;
  transition-property: color;
}
.file-attach__icon {
  width: 100%;
  height: 100%;
  display: block;
}

.files-hidden,
.input-hidden {
  display: none;
  position: absolute;
  left: -9999px;
}

.support-status {
  font-size: 14px;
  line-height: 1.4;
  white-space: nowrap;
}
.support-status--new {
  color: #16A34A;
}
.support-status--closed {
  color: #9CA3AF;
}

.support-card {
  background: #F8FAFC;
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 14px;
  color: #111827;
  border-radius: 8px;
  position: relative;
}
.support-card__content {
  padding-right: 20px;
}
.support-card__title {
  margin: 0 0 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
}
.support-card__link {
  display: block;
  color: #242B33;
  transition: all 0.4s;
  transition-property: color;
}
.support-card__link:hover {
  color: #16A34A;
  text-decoration: none;
}
.support-card__link:before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
}
.support-card__date {
  margin: 0;
  display: block;
  font-size: 12px;
  color: #9CA3AF;
}
.support-card__status {
  margin-left: auto;
  flex-shrink: 0;
  line-height: 24px;
}

.s-support-list__item:not(:last-child) {
  margin-bottom: 12px;
}

.files-attach-list__item:not(:last-child) {
  margin-bottom: 14px;
}

.s-support {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.35;
  color: #242B33;
  flex-grow: 1;
  overflow: clip;
}
.s-support__inner {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  flex-grow: 1;
}

.support-title {
  margin: 0;
  font-weight: 600;
  font-size: 30px;
}

.s-support-main {
  min-height: 100%;
  overflow: clip;
}
.s-support-main__header {
  margin: 0 0 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.s-support-main__header-btn {
  flex-shrink: 0;
}
.s-support-main__top {
  margin: 0 0 48px;
}
.s-support-main__subtitle {
  margin: 0 0 20px;
  font-weight: 600;
  font-size: 20px;
}
.s-support-main__form-area-item {
  margin: 0 0 40px;
}
.s-support-main__form-area-item--subject, .s-support-main__form-area-item--email {
  max-width: 448px;
}
.s-support-main__form-files-attach-list {
  margin: -20px 0 24px;
}
.s-support-main__form-file-text {
  color: #6b7280;
}
.s-support-main__chat {
  min-height: calc(100vh - 240px);
}
.s-support-main__social {
  margin-top: 60px;
  padding-top: 15px;
  border-top: 1px solid #e5e7eb;
}
@media screen and (max-width: 1000px) {
  .s-support-main__social {
    margin-top: 40px;
  }
}
@media screen and (max-width: 767px) {
  .s-support-main__social {
    padding-top: 0;
    border-top: none;
  }
}

.chat-message {
  display: flex;
  align-items: flex-start;
}
.chat-message__avatar {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 50%;
  flex: 0 0 40px;
  user-select: none;
  position: relative;
  overflow: hidden;
}
.chat-message__avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.chat-message__body {
  flex-grow: 1;
  overflow: hidden;
}
.chat-message__body-header {
  margin: 0 0 6px;
  display: flex;
  align-items: center;
}
.chat-message__body-name {
  font-weight: 500;
  font-size: 14px;
  color: #111827;
}
.chat-message__body-date {
  margin-left: 8px;
  display: block;
  flex-shrink: 0;
  font-size: 12px;
  color: #6b7280;
}
.chat-message__body-content > :last-child {
  margin-bottom: 0;
}
.chat-message__body-text {
  margin: 0 0 4px;
  font-size: 14px;
  line-height: 1.7;
}
.chat-message__body-attach {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.chat-message__body-attach-file {
  padding: 3px 0;
  font-weight: 500;
  color: #2563EB;
  transition: all 0.4s;
  transition-property: color;
}
.chat-message__body-attach-file:hover {
  text-decoration: none;
}
.chat-message__body-attach-file:not(:last-child) {
  margin-bottom: 4px;
}

.s-support-chat {
  display: flex;
  flex-direction: column;
}
.s-support-chat__header {
  margin: 0 0 24px;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e7eb;
}
.s-support-chat__header-prev-link {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  display: none;
  flex: 0 0 24px;
}
.s-support-chat__header-prev-link:hover {
  text-decoration: none;
}
.s-support-chat__body-message:not(:last-child) {
  margin-bottom: 32px;
}
.s-support-chat__footer {
  margin-top: auto;
  padding-top: 24px;
}
.s-support-chat__footer-message {
  min-height: 80px;
  margin: 0 0 24px;
}
.s-support-chat__footer-attach-list {
  margin-bottom: 20px;
}
.s-support-chat__footer-attach-item {
  margin: 0 0 6px;
}
.s-support-chat__form-file-text {
  color: #6b7280;
}

@media screen and (max-width: 1169px) {
  .authors-intro {
    padding: 59px 0;
  }
  .authors-intro__col--media {
    margin-bottom: -59px;
  }
  .authors-intro__content {
    padding: 13px 0;
  }
  .authors-intro__title {
    margin-bottom: 16px;
    font-size: 5.15vw;
  }
  .authors-intro__desription {
    margin-bottom: 24px;
  }
  .authors-intro__btns {
    padding-bottom: 48px;
  }
  .authors-intro__tinkoff-bank {
    padding-top: 0;
    border-top: none;
  }
  .why-uppora {
    padding: 72px 0 80px;
  }
  .why-uppora__title {
    margin-bottom: 40px;
    font-size: 32px;
  }

  .why-uppora-list__row {
    margin: 0 -16px -30px;
    padding: 0 16px 30px;
    display: block;
    font-size: 0;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
  }
  .why-uppora-list__col {
    max-width: 396px;
    padding-right: 0;
    padding-left: 0;
    flex-basis: 396px;
    display: inline-block;
    vertical-align: top;
  }
  .why-uppora-list__col:not(:last-child) {
    margin-right: 48px;
  }
  .a-about-info {
    padding-top: 0;
    padding-bottom: 50px;
  }
  .a-about-info__content {
    margin-right: 60px;
  }
  .a-about-info__title {
    margin-bottom: 16px;
    font-size: 42px;
  }
  .a-about-info__text {
    margin-bottom: 24px;
  }
  .a-about-info__author {
    padding-top: 24px;
  }
  .a-about-info__aside {
    padding-top: 76px;
  }
  .list-info__icon {
    margin-right: 12px;
  }

  .clients-info {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .clients-info:before {
    border-radius: 0;
    top: 0;
  }
  .clients-info__aside {
    margin-right: 40px;
  }
  .clients-info__title {
    margin: 0 0 16px;
  }
  .a-about-company {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .a-about-company__title {
    margin-right: 40px;
  }
  .a-about {
    padding: 32px 0;
  }
  .a-about__title {
    font-size: 60px;
  }
  .a-about__description {
    font-size: 18px;
  }
  .a-about__poster {
    width: auto;
    margin: 48px -16px 60px;
    left: 0;
  }
  .news-section {
    padding: 40px 0 50px;
  }
  .news-section__header {
    margin-top: 0;
  }
  .team-section {
    padding: 50px 0 60px;
  }
  .appeal-section {
    padding: 80px 0;
  }
  .appeal-section:before {
    width: auto;
    right: 16px;
    left: 16px;
  }

  .team-list__row {
    margin: 0 -10px -20px;
  }
  .team-list__col {
    margin-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .news-list__row {
    margin: 0 -16px -30px;
    padding: 0 16px 30px;
    display: block;
    font-size: 0;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
  }
  .news-list__col {
    max-width: 396px;
    padding-right: 0;
    padding-left: 0;
    flex-basis: 396px;
    display: inline-block;
    vertical-align: top;
  }
  .news-list__col:not(:last-child) {
    margin-right: 32px;
  }
  .a-section-card__inner {
    margin: 0 -24px;
  }
  .a-section-card__media {
    max-width: 50%;
    margin-right: 0;
    padding-right: 24px;
    padding-left: 24px;
    flex-basis: 50%;
  }
  .a-section-card + .a-section-card .a-section-card__media {
    margin-left: 0;
  }
  .a-section-card__content {
    max-width: 50%;
    padding-right: 24px;
    padding-left: 24px;
    flex-basis: 50%;
  }
  .a-section-card + .a-section-card .a-section-card__content {
    padding-left: 24px;
  }
  .a-section-card__title {
    margin-bottom: 12px;
    font-size: 32px;
  }
  .a-section-info {
    padding: 30px 0 0;
  }
  .a-section-info__inner {
    margin: 0;
  }
  .a-section-info__content {
    max-width: 50%;
    padding-right: 24px;
    padding-left: 60px;
    flex: 0 0 50%;
  }
  .a-section-info__title {
    margin-bottom: 12px;
    font-size: 32px;
  }
  .a-section-info__media {
    max-width: 50%;
    padding-right: 24px;
    padding-left: 24px;
    flex: 0 0 50%;
  }
  .a-section-info__picture {
    max-width: 381px;
  }
  .a-section-info__decor {
    right: 0;
  }

  .project-card__description {
    margin-bottom: 12px;
  }

  .filter-group__item:not(:last-child) {
    margin-right: 16px;
  }

  .projects-list__row {
    margin-right: -8px;
    margin-bottom: -20px;
    margin-left: -8px;
  }
  .projects-list__col {
    margin-bottom: 20px;
    padding-right: 6px;
    padding-left: 6px;
  }
  .p-project {
    padding: 20px 0;
  }
  .p-project__title {
    margin-bottom: 20px;
    font-size: 24px;
  }
  .p-project__filter {
    margin-right: -16px;
    margin-bottom: 20px;
    margin-left: -16px;
    padding-right: 16px;
    padding-left: 16px;
  }
  .p-project__appeal-alert {
    margin-bottom: 20px;
  }
  .appeal-alert__inner {
    flex-wrap: wrap;
  }
  .appeal-alert__content {
    max-width: 100%;
    flex-basis: 100%;
  }
  .appeal-alert__btn {
    margin: 12px 0 0;
  }

  .s-support-main__header {
    margin-bottom: 32px;
  }
  .s-support-main__top {
    margin-bottom: 32px;
  }
  .s-support-main__form-area-item {
    margin-bottom: 32px;
  }
  .s-support-main__form-files-attach-list {
    margin-top: -12px;
  }
  .s-support-main__chat {
    background: #fff;
    width: 100%;
    min-height: 100vh;
    padding: 0 16px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: clip;
    z-index: 10000;
  }

  .s-support-chat__header {
    background: #fff;
    margin: 0 -16px 26px;
    padding: 14px 16px;
    position: sticky;
    top: 0;
    z-index: 2;
  }
  .s-support-chat__header-prev-link {
    display: block;
  }
  .s-support-chat__title {
    font-size: 20px;
  }
  .s-support-chat__body {
    width: 100%;
    max-width: 670px;
    margin: 0 auto;
  }
  .s-support-chat__footer {
    background: #fff;
    width: 100%;
    max-width: 670px;
    margin: 0 auto;
    padding-bottom: 24px;
    position: sticky;
    bottom: 0;
    z-index: 2;
  }
}
@media screen and (max-width: 1000px) {
  .nav-step {
    font-size: 14px;
  }
  .nav-step--mobile-scroll .nav-step__list {
    margin: 0 -16px;
    padding: 0 16px;
    display: block;
    flex-direction: initial;
    box-shadow: inset 0 -1px #e5e7eb;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-x: auto;
  }
  .nav-step--mobile-scroll .nav-step__list::-webkit-scrollbar {
    display: none;
  }
  .nav-step--mobile-scroll .nav-step__item {
    display: inline-block;
    vertical-align: top;
  }
  .nav-step--mobile-scroll .nav-step__item:not(:last-child) {
    margin-right: 40px;
    margin-bottom: 0;
  }
  .nav-step--mobile-scroll .nav-step__link {
    padding: 7px 0;
    border-bottom: 2px solid transparent;
  }
  .nav-step--mobile-scroll .nav-step__link.is-active {
    border-bottom-color: #166534;
  }

  .authors-intro {
    padding: 32px 0;
  }
  .authors-intro__col {
    max-width: 100%;
    flex-basis: 100%;
  }
  .authors-intro__col--media {
    margin-bottom: -32px;
  }
  .authors-intro__content {
    max-width: 580px;
    margin: 0 auto 32px;
    padding: 0;
    text-align: center;
  }
  .authors-intro__title {
    font-size: 48px;
  }
  .authors-intro__desription {
    max-width: 420px;
    margin-right: auto;
    margin-bottom: 16px;
    margin-left: auto;
    font-size: 16px;
  }
  .authors-intro__btns {
    padding-bottom: 32px;
    justify-content: center;
  }
  .authors-intro__picture {
    max-width: 720px;
    margin: 0 auto;
  }
  .why-uppora {
    padding: 60px 0;
  }
  .why-uppora__title {
    margin-bottom: 32px;
  }

  .why-uppora-list__col:not(:last-child) {
    margin-right: 24px;
  }
  .tinkoff-bank {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .tinkoff-bank__logo {
    margin: 0 0 8px;
    flex-basis: initial;
  }
  .site-pager__link {
    display: block;
  }

  .appeal-alert {
    padding: 16px;
  }

  .n-new-project {
    padding-bottom: 32px;
  }
  .n-new-project__container {
    flex-direction: column;
  }
  .n-new-project__aside {
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    top: 0;
    flex-basis: initial;
  }
  .n-new-project__aside-title {
    margin: 0 0 16px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }
  .n-new-project__aside-nav {
    display: none;
  }
  .n-new-project__main {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .n-new-project__main-title {
    margin-bottom: 16px;
    font-size: 24px;
  }
  .n-new-project__main-pager {
    background: #F8FAFC;
    margin: 0 -16px 32px;
    padding: 14px 16px;
    justify-content: space-between;
    order: -1;
  }
  .n-new-project__main-alert {
    margin-bottom: 32px;
  }
  .n-new-project__main-form__area-link {
    margin-bottom: 48px;
  }
  .n-new-project__main-form-privacy {
    margin-bottom: 48px;
  }
  .a-about-info {
    flex-wrap: wrap;
  }
  .a-about-info__content {
    width: 100%;
    margin: 0 0 32px;
    flex: 0 0 100%;
  }
  .a-about-info__aside {
    width: 100%;
    padding-top: 0;
    flex: 0 0 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .a-about-info__aside-card:not(:last-child) {
    margin-right: 48px;
  }

  .clients-info {
    flex-wrap: wrap;
  }
  .clients-info__aside {
    width: 100%;
    margin: 0 0 24px;
    flex: 0 0 100%;
  }
  .clients-info__content {
    width: 100%;
    flex: 0 0 100%;
  }
  .clients-info__title {
    margin: 0 0 16px;
  }
  .a-about-company {
    flex-wrap: wrap;
  }
  .a-about-company__title {
    width: 100%;
    margin: 0 0 32px;
    flex: 0 0 100%;
  }
  .a-about-company__content {
    width: 100%;
    padding-top: 0;
    flex: 0 0 100%;
  }
  .a-about-company__list {
    margin-left: 36px;
  }
  .team-list__col {
    max-width: 50%;
    flex-basis: 50%;
  }
  .appeal-section {
    margin-bottom: 60px;
    padding: 60px 0;
  }
  .appeal-section__title {
    max-width: 620px;
  }
  .appeal-section__text {
    max-width: 620px;
  }
  .a-about__poster {
    padding-bottom: 44vw;
  }

  .a-section-card {
    padding: 90px 0 60px;
  }
  .a-section-card__section-title {
    font-size: 32px;
  }
  .a-section-card__inner {
    margin: 0 -6px;
  }
  .a-section-card__media {
    padding-right: 6px;
    padding-left: 6px;
  }
  .a-section-card__content {
    padding-right: 6px;
    padding-left: 6px;
  }
  .a-section-card + .a-section-card .a-section-card__content {
    padding-right: 6px;
    padding-left: 6px;
  }
  .a-section-card__group-btns {
    display: none;
  }

  .a-section-info__inner {
    margin: 0 -22px;
    padding: 0 16px;
    border-radius: 0;
  }
  .a-section-info__content {
    max-width: initial;
    padding: 62px 6px;
    flex: initial;
    flex-grow: 1;
  }
  .a-section-info__media {
    max-width: initial;
    margin-right: -10px;
    padding-right: 6px;
    padding-left: 6px;
    flex: initial;
    flex-shrink: 0;
  }
  .a-section-info__picture {
    max-width: 340px;
  }
  .a-section-info__decor {
    display: none;
  }

  .projects-list__col {
    max-width: 50%;
    flex-basis: 50%;
  }
}
@media screen and (max-width: 767px) {
  .authors-intro__content {
    margin-bottom: 28px;
  }
  .authors-intro__title {
    font-size: 6.7vw;
  }
  .authors-intro__btns {
    padding-bottom: 28px;
  }
  .authors-intro__picture {
    padding-top: 0;
  }

  .why-uppora {
    padding-bottom: 48px;
  }
  .why-uppora__title {
    margin-bottom: 24px;
    font-size: 24px;
  }

  .n-new-project__main-alert {
    margin-bottom: 24px;
  }
  .n-new-project__main-form__area-item {
    max-width: initial;
    margin-bottom: 32px;
  }
  .n-new-project__main-form__area-d-all {
    margin-bottom: 32px;
  }
  .n-new-project__main-form__area-new {
    margin-bottom: 32px;
  }
  .n-new-project__main-form__area-link {
    margin-bottom: 40px;
  }
  .n-new-project__main-form-privacy {
    max-width: initial;
    margin-bottom: 32px;
  }
  .n-new-project__main-form-btns .btn {
    width: 100%;
    flex-basis: 100%;
  }

  .a-about__title {
    font-size: 28px;
  }
  .a-about__description {
    margin-bottom: 32px;
    font-size: 16px;
  }
  .a-about__poster {
    margin-top: 32px;
    margin-bottom: 32px;
    padding-bottom: 44vw;
  }

  .a-about-info {
    padding-bottom: 30px;
  }
  .a-about-info__title {
    margin-bottom: 12px;
    font-size: 24px;
  }
  .a-about-info__text {
    font-size: 16px;
  }
  .a-about-info__aside {
    flex-direction: column;
  }
  .a-about-info__aside-card {
    width: 100%;
    margin-right: 0;
  }
  .a-about-info__aside-card:not(:last-child) {
    margin-bottom: 16px;
  }

  .card-badge-text {
    font-size: 16px;
  }

  .clients-info {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .clients-info__title {
    font-size: 18px;
  }
  .clients-info__text {
    font-size: 16px;
  }

  .a-about-company {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .a-about-company__title {
    margin: 0 0 16px;
    font-size: 24px;
  }
  .a-about-company__list {
    margin-bottom: 24px;
    margin-left: 0;
  }

  .list-info {
    font-size: 16px;
  }
  .list-info li:after {
    margin: 16px 0;
  }
  .list-info__icon {
    display: none;
  }

  .news-card__picture {
    margin-bottom: 16px;
  }
  .news-card__title {
    margin-bottom: 4px;
    font-size: 16px;
  }
  .news-list__col {
    max-width: 260px;
    flex-basis: 260px;
  }
  .news-list__col:not(:last-child) {
    margin-right: 20px;
  }
  .news-section {
    padding: 30px 0;
  }
  .news-section__header {
    margin-top: 0;
    margin-bottom: 24px;
  }
  .news-section__title {
    font-size: 24px;
  }
  .news-section__view-all {
    margin-bottom: 0;
  }

  .team-section {
    padding: 40px 0;
  }
  .team-section__title {
    margin-bottom: 24px;
    font-size: 24px;
  }

  .team-list__row {
    margin-bottom: 0;
  }
  .team-list__col {
    max-width: 100%;
    margin-bottom: -1px;
    flex-basis: 100%;
  }
  .team-card {
    padding: 20px 0;
    border-radius: 0;
    border-width: 1px 0;
  }
  .team-card__content {
    padding-right: 34px;
  }
  .team-card__arrow {
    right: 0;
  }

  .appeal-section {
    margin-bottom: 40px;
    padding: 40px 0;
  }
  .appeal-section__title {
    font-size: 24px;
    margin-bottom: 12px;
  }
  .appeal-section__text {
    margin-bottom: 16px;
  }
  .clients-info__aside {
    width: 100%;
    max-width: 100%;
  }
  .clients-info__aside-percent {
    font-size: 80px;
    text-align: left;
  }
  .a-section-card {
    padding: 40px 0;
  }
  .a-section-card__section-title {
    max-width: 450px;
    margin-right: initial;
    margin-bottom: 12px;
    margin-left: initial;
    font-size: 24px;
    text-align: left;
  }
  .a-section-card__inner {
    flex-wrap: wrap;
  }
  .a-section-card__content {
    max-width: 100%;
    margin-bottom: 32px;
    padding-bottom: 0;
    flex-basis: 100%;
    order: -1;
  }
  .a-section-card__title {
    font-size: 24px;
  }
  .a-section-card__media {
    max-width: 100%;
    margin-bottom: -40px;
    flex-basis: 100%;
  }
  .a-section-card__picture {
    max-width: 530px;
    margin: 0 auto;
    position: relative;
    right: -8px;
  }

  .a-section-info__inner {
    flex-wrap: wrap;
  }
  .a-section-info__content {
    max-width: 100%;
    padding: 40px 0 32px;
    flex-basis: 100%;
    text-align: center;
  }
  .a-section-info__title {
    font-size: 24px;
  }
  .a-section-info__media {
    max-width: 100%;
    margin-top: 0;
    flex-basis: 100%;
  }
  .a-section-info__picture {
    max-width: 288px;
    margin: 0 auto;
  }

  .project-card {
    border-radius: 0;
    border-width: 0;
  }
  .project-card__info {
    padding: 16px;
  }
  .project-card__content {
    padding: 20px 16px;
  }

  .projects-list__row {
    margin-right: -24px;
    margin-bottom: 0;
    margin-left: -24px;
  }
  .projects-list__col {
    max-width: 100%;
    margin-bottom: 0;
    flex-basis: 100%;
  }
  .projects-list__col:not(:last-child) {
    border-bottom: 8px solid #e5e7eb;
  }

  .filter-group__item:not(:last-child) {
    margin-right: 13px;
  }

  .d-donate__thank {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .donate-form__title {
    font-size: 20px;
  }
  .donate-form__pager-link {
    display: block;
  }

  .site-pager__steps {
    margin: 0 30px;
  }

  .s-support {
    padding: 20px 0;
  }

  .support-title {
    font-size: 20px;
  }

  .s-support-main__header {
    margin-bottom: 40px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .s-support-main__header-btn {
    margin-top: 24px;
  }
  .s-support-main__top {
    margin-bottom: 24px;
  }
  .s-support-main__subtitle {
    margin-bottom: 16px;
  }
  .s-support-main__form-area-item {
    margin-bottom: 24px;
  }
  .s-support-main__form-files-attach-list {
    margin-top: -4px;
  }
  .s-support-main__form-file-text {
    max-width: 100%;
    flex: 0 0 100%;
    order: -2;
  }
  .chat-message__body-header {
    margin-bottom: 3px;
  }
  .s-support-chat__body-message:not(:last-child) {
    margin-bottom: 22px;
  }
  .s-support-chat__footer-message {
    min-height: 70px;
  }
  .s-support-chat__form-file-text {
    max-width: 100%;
    flex: 0 0 100%;
    order: -2;
  }
}
@media screen and (max-width: 480px) {
  .authors-intro {
    padding: 24px 0;
  }
  .authors-intro__title {
    margin-bottom: 12px;
    font-size: 10vw;
  }
  .authors-intro__desription {
    margin-bottom: 20px;
  }
  .authors-intro__btns {
    margin-bottom: 24px;
  }
  .authors-intro__btns .btn {
    width: 100%;
  }
  .why-uppora {
    padding-bottom: 32px;
  }
  .why-uppora__title {
    margin-bottom: 12px;
  }

  .why-uppora-list__row {
    white-space: initial;
  }
  .why-uppora-list__col {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    display: block;
  }
  .why-uppora-list__col:not(:last-child) {
    margin-right: 0;
    margin-bottom: 56px;
    position: relative;
  }
  .why-uppora-list__col:not(:last-child):after {
    background: #e5e7eb;
    width: 100%;
    height: 1px;
    margin-top: 28px;
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
  }
  .why-uppora-card__picture {
    margin-bottom: 24px;
  }
  .why-uppora-card__title {
    margin-bottom: 8px;
  }
  .input-block {
    line-height: 1.5;
  }
  .input-block__template-cards-item--size-4 {
    max-width: 51%;
    flex-basis: 51%;
  }

  .a-about-company__btns .btn {
    width: 100%;
  }

  .appeal-section {
    margin-bottom: 0;
  }
  .appeal-section:before {
    right: 0;
    left: 0;
    border-radius: 0;
  }
  .appeal-section__content {
    padding-right: 0;
    padding-left: 0;
  }
  .appeal-section__btns .btn {
    width: 100%;
  }

  .s-support-main__header-btn {
    width: 100%;
  }
  .s-support-main__form-btns .btn {
    width: 100%;
    flex-basis: 100%;
  }
  .s-support-main__form-btns .btn-trp {
    order: -1;
  }
  .support-card {
    flex-wrap: wrap;
  }
  .support-card__content {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .support-card__status {
    max-width: 100%;
    margin-top: 12px;
    flex: 0 0 100%;
  }

  .s-support-chat__footer-btns-group .btn {
    width: 100%;
    flex-basis: 100%;
  }
  .s-support-chat__footer-btns-group .btn-trp {
    order: -1;
  }
}
.comment-answer-form__body {
  margin: 0 0 20px;
  display: flex;
  align-items: flex-start;
}
.comment-answer-form__picture {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  flex: 0 0 32px;
  border-radius: 50%;
}
.comment-answer-form__input {
  flex-grow: 1;
}
.comment-answer-form__group-btns {
  justify-content: flex-end;
}
.comment-answer-form__group-btns .btn {
  min-height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.project-tip {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.project-tip__picture {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  border-radius: 50%;
  flex: 0 0 32px;
  overflow: hidden;
}
.project-tip__content {
  line-height: 1;
  flex-grow: 1;
  overflow: hidden;
}
.project-tip__name {
  margin: 0 0 4px;
  font-weight: 500;
}
.project-tip__prev-link {
  font-size: 12px;
  color: #8A9199;
}

.balance-alert {
  background: #F8FAFC;
  border-radius: 8px;
}
.balance-alert__inner {
  padding: 24px;
}
.balance-alert__header {
  margin: 0 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.balance-alert__amount {
  padding-right: 20px;
  overflow: hidden;
}
.balance-alert__amount-current {
  margin: 0 0 10px;
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
}
.balance-alert__amount-all {
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
}
.balance-alert__btn {
  margin-left: auto;
  flex-shrink: 0;
}
.balance-alert__content {
  font-size: 14px;
  line-height: 1.6;
  color: #6b7280;
}
.balance-alert__description {
  margin: 0 0 8px;
}
.balance-alert__card-info a {
  margin-left: 0.5em;
  color: #2563EB;
  transition: all 0.4s;
  transition-property: color;
}

.comment-btn {
  display: flex;
  align-items: center;
  color: #9CA3AF;
  cursor: pointer;
  transition: all 0.4s;
  transition-property: color;
}
.comment-btn:after {
  width: 0;
  height: 0;
  margin: 1px 0 0 2px;
  content: "";
  display: inline-block;
  flex-shrink: 0;
  border-style: solid;
  border-width: 3px 3px 0 3px;
  border-color: currentColor transparent transparent transparent;
  vertical-align: middle;
}
.comment-btn__icon-wrap {
  width: 20px;
  height: 20px;
  display: block;
  position: relative;
}
.comment-btn__icon {
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.btn-reply {
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  color: #6b7280;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  transition: all 0.4s;
  transition-property: color;
}
.btn-reply:before {
  content: "";
  position: absolute;
  top: -6px;
  right: -4px;
  bottom: -6px;
  left: -4px;
}

.transactions-item__body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.transactions-item__body-user {
  margin-right: auto;
  padding-right: 20px;
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.transactions-item__body-user-picture {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 50%;
  flex: 0 0 40px;
}
.transactions-item--answer .transactions-item__body-user-picture {
  width: 32px;
  height: 32px;
  flex-basis: 32px;
}
.transactions-item__body-user-content {
  flex-grow: 1;
}
.transactions-item__body-user-name {
  margin: 0 0 7px;
  display: inline-block;
  line-height: 1.3;
  font-weight: 500;
  font-size: 14px;
  vertical-align: top;
  position: relative;
  z-index: 0;
}
.transactions-item--answer .transactions-item__body-user-name {
  margin-left: 8px;
  color: #fff;
}
.transactions-item--answer .transactions-item__body-user-name:before {
  background: #16A34A;
  content: "";
  position: absolute;
  top: -3px;
  right: -8px;
  bottom: -3px;
  left: -8px;
  border-radius: 20px;
  z-index: -1;
}
.transactions-item__body-user-date {
  display: block;
  line-height: 1;
  font-size: 12px;
  color: #9CA3AF;
}
.transactions-item__body-comment-btn {
  margin: 0 8px 0 0;
  display: none;
  flex-shrink: 0;
  position: relative;
}
.transactions-item__body-comment-btn:before {
  content: "";
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
}
.transactions-item--new-comment .transactions-item__body-comment-btn, .transactions-item--old-comment .transactions-item__body-comment-btn {
  display: flex;
}
.transactions-item--new-comment .transactions-item__body-comment-btn .comment-btn__icon:last-child, .transactions-item--old-comment .transactions-item__body-comment-btn .comment-btn__icon:first-child {
  display: block;
}
.transactions-item__body-amount {
  min-width: 90px;
  margin: 2px 0 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  flex-shrink: 0;
  text-align: right;
}
.transactions-item--balance-plus .transactions-item__body-amount {
  color: #16A34A;
}
.transactions-item--balance-minus .transactions-item__body-amount {
  color: #111827;
}
.transactions-item__dropdown {
  display: none;
}
.transactions-item--show-comment .transactions-item__dropdown {
  display: block;
}
.transactions-item__comment {
  margin-top: 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.7;
  color: #374151;
}
.transactions-item__comment:not(:last-child) {
  margin-bottom: 12px;
}
.transactions-item__comment > * {
  margin: 0;
}
.transactions-item__btn-reply {
  vertical-align: top;
}
.transactions-item__answer {
  margin: 24px 0 0 52px;
  padding-top: 24px;
  border-top: 1px solid #E5E7EB;
}
.transactions-item__comment-answer-form {
  margin: 24px 0 0 52px;
  display: none;
}
.transactions-item--show-form .transactions-item__comment-answer-form {
  display: block;
}

.transactions-block__header {
  padding: 0 0 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E5E7EB;
}
.transactions-block__header-title {
  margin-right: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
}
.transactions-block__list-item {
  padding: 30px 0;
  border-bottom: 1px solid #E5E7EB;
}

.project-settings {
  padding: 32px 0 80px;
  font-size: 14px;
  color: #111827;
  flex-grow: 1;
  overflow: clip;
}
.project-settings__container {
  display: flex;
  align-items: flex-start;
}
.project-settings__aside {
  width: 240px;
  margin: -32px 40px 0 0;
  padding: 42px 0;
  flex: 0 0 240px;
  position: sticky;
  top: 0;
  left: 0;
}
.project-settings__aside-project-tip {
  margin: 0 0 24px;
}
.project-settings__aside-pager {
  display: none;
}
.project-settings__main {
  max-width: calc(100% - 280px);
  flex-grow: 1;
}
.project-settings__main-title {
  margin: 0 0 48px;
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
.project-settings__main-developing {
  margin-top: -15px;
}
.project-settings__main-balance-alert {
  margin: 0 0 40px;
}
.project-settings__main-widgets-item {
  margin-bottom: 40px;
  padding-bottom: 40px;
}
.project-settings__main-widgets-item:not(:last-child) {
  border-bottom: 1px solid #e5e7eb;
}
.project-settings__main-subscribers-subtitle {
  margin: -8px 0 24px;
  padding-bottom: 24px;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.3;
  color: #111827;
  border-bottom: 1px solid #e5e7eb;
}
.project-settings__verification {
  max-width: 660px;
  margin-top: -16px;
  font-size: 14px;
  line-height: 1.7;
}
.project-settings__verification-paragraph {
  margin: 0 0 16px;
}
.project-settings__verification-subtitle {
  margin: 0 0 16px;
  font-weight: 500;
  font-size: 16px;
}
.project-settings__verification-subtitle span {
  color: #6b7280;
}
.project-settings__verification-user-type {
  margin: 48px 0 58px;
}
.project-settings__verification-user-type-title {
  margin: 0 0 16px;
}
.project-settings__verification-user-type-label {
  margin: 0 0 16px;
}
.project-settings__verification-photo {
  margin: 0 0 60px;
}
.project-settings__verification-photo-title {
  margin: 0 0 16px;
}
.project-settings__verification-link .input-block__el {
  max-width: 384px;
}
.project-settings__verification-btns {
  margin-top: 40px;
}
.project-settings__verification-status {
  margin: 0 0 16px;
}
.project-settings__verification-status-description {
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 1.7;
  color: #374151;
}
.project-settings__verification-status-btn {
  margin: 0 0 20px;
}
.project-settings__verification-address {
  margin: 0 0 60px;
}
.project-settings__verification-phone-company {
  max-width: 384px;
  margin: 0 0 60px;
}
.project-settings__verification-attach-file + .project-settings__verification-attach-file {
  margin-top: 12px;
}

@media screen and (max-width: 1280px) {
  .project-settings__main-title {
    margin-bottom: 32px;
  }
  .project-settings__main-balance-alert {
    margin-bottom: 32px;
  }
  .project-settings__main-subscribers-subtitle {
    padding-bottom: 10px;
    font-size: 16px;
  }
}
@media screen and (max-width: 1000px) {
  .project-settings__container {
    background: #ffffff;
    margin-top: -80px;
    flex-wrap: wrap;
    position: relative;
    z-index: 999;
  }
  .project-settings__aside {
    max-width: 100%;
    margin: 0 0 6px;
    padding: 18px 0;
    flex: 0 0 100%;
    position: initial;
  }
  .project-settings__aside-pager {
    margin: 0 0 28px;
    display: flex;
    align-items: center;
    line-height: 1;
  }
  .project-settings__aside-pager-link {
    margin-right: 12px;
    flex: 0 0 24px;
  }
  .project-settings__aside-pager-title {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
  }
  .project-settings__aside-project-tip {
    display: none;
  }
  .project-settings__main {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .project-settings__main-title {
    position: absolute;
    left: -9999px;
    pointer-events: none;
    opacity: 0;
  }
  .project-settings__main-subscribers-subtitle {
    margin-bottom: 20px;
    padding-bottom: 0;
    border-bottom: none;
  }
}
@media screen and (max-width: 767px) {
  .comment-answer-form__picture {
    display: none;
  }

  .transactions-item__body {
    flex-wrap: wrap;
  }
  .transactions-item__body-user {
    max-width: calc(100% - 34px);
    flex-basis: calc(100% - 34px);
  }
  .transactions-item__body-comment-btn {
    margin-right: 0;
  }
  .transactions-item__body-amount {
    max-width: 100%;
    margin-top: 6px;
    padding-left: 52px;
    flex-basis: 100%;
    text-align: left;
  }
  .transactions-item__comment-answer-form {
    margin-top: 16px;
    margin-left: 0;
  }

  .transactions-block__list-item {
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .project-settings__main-title {
    margin-bottom: 24px;
  }
  .project-settings__main-balance-alert {
    margin-bottom: 24px;
  }
  .project-settings__main-widgets-item {
    margin-bottom: 32px;
    padding-bottom: 32px;
  }
}
@media screen and (max-width: 480px) {
  .balance-alert__inner {
    padding: 16px;
  }
  .balance-alert__header {
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  .balance-alert__amount {
    max-width: 100%;
    margin-bottom: 20px;
    padding-right: 0;
    flex: 0 0 100%;
  }
  .balance-alert__btn {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .balance-alert__card-info a {
    display: block;
    margin-left: 0;
  }
}
.faq-nav {
  background: #F8FAFC;
  line-height: 1.75;
  color: #4B5563;
  border-radius: 4px;
}
.faq-nav__inner {
  padding: 24px;
}
.faq-nav__item + .faq-nav__item {
  border-top: 1px solid #e5e7eb;
}
.faq-nav__btn {
  width: 100%;
  margin: 0;
  padding: 20px 0;
  display: block;
  text-align: left;
  cursor: pointer;
  position: relative;
}
.faq-nav__btn:after {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.50503 7.42299C4.77839 7.14963 5.22161 7.14963 5.49497 7.42299L9.99992 11.9279L14.5049 7.42299C14.7782 7.14963 15.2214 7.14963 15.4948 7.42299C15.7672 7.69636 15.7672 8.13958 15.4948 8.41294L10.4949 13.4129C10.2215 13.6862 9.77831 13.6862 9.50494 13.4129L4.50503 8.41294C4.23166 8.13958 4.23166 7.69636 4.50503 7.42299Z'/%3E%3C/svg%3E");
  background-size: contain;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transition: all 0.4s;
  transition-property: transform;
}
.faq-nav__item.is-show .faq-nav__btn:after {
  transform: rotate(-180deg);
}
.faq-nav__title {
  margin: 0;
  padding-right: 28px;
  font-weight: normal;
  font-size: 16px;
}
.faq-nav__list {
  margin: 0 0 20px;
  font-size: 16px;
  color: #2563EB;
}
.faq-nav__item:not(.is-show) .faq-nav__list {
  display: none;
}
.faq-nav__footer {
  padding: 24px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e5e7eb;
}
.faq-nav__content {
  padding-right: 45px;
  display: flex;
  align-items: flex-start;
  font-size: 14px;
}
.faq-nav__content-icon {
  width: 20px;
  height: 20px;
  margin-top: 0.15em;
  margin-right: 8px;
  object-fit: contain;
  flex: 0 0 20px;
  color: #9CA3AF;
}
.faq-nav__content-btn {
  flex-shrink: 0;
}

.p-faq {
  padding: 32px 0;
  overflow: clip;
}
.p-faq__title {
  margin: 0 0 32px;
}
.p-faq__header {
  margin-bottom: 60px;
}
.p-faq__content-item {
  padding: 48px 0;
  font-size: 16px;
  color: #111827;
  position: relative;
  z-index: 0;
}
.p-faq__content-item:not(:last-child) {
  border-bottom: 1px solid #e5e7eb;
}
.p-faq__content-item:before {
  background: #F8FAFC;
  content: "";
  position: absolute;
  top: 0;
  right: -24px;
  bottom: 0;
  left: -24px;
  border-radius: 4px;
  transition: all 0.3s;
  transition-property: opacity, visibility;
  transition-delay: 0.2s;
  z-index: -1;
}
.p-faq__content-item:not(.is-flash):before {
  opacity: 0;
  visibility: hidden;
}

@media screen and (max-width: 1169px) {
  .faq-nav__content {
    padding-right: 24px;
  }

  .p-faq {
    padding: 24px 0;
  }
}
@media screen and (max-width: 767px) {
  .faq-nav__inner {
    padding-right: 16px;
    padding-left: 16px;
  }
  .faq-nav__footer {
    padding-top: 16px;
    flex-direction: column;
    align-items: flex-start;
  }
  .faq-nav__content {
    width: 100%;
    max-width: 100%;
    margin-bottom: 16px;
    padding-right: 0;
  }
  .faq-nav__content-icon {
    display: none;
  }

  .p-faq__container {
    width: initial;
    max-width: initial;
  }
  .p-faq__title {
    margin-bottom: 16px;
    font-size: 20px;
  }
  .p-faq__header {
    margin: 0 -16px;
    border-radius: 0;
  }
  .p-faq__content-item {
    margin: 0 -16px;
    padding: 32px 16px;
  }
  .p-faq__content-item h2 {
    margin-bottom: 1.25em;
    font-size: 1.25em;
  }
  .p-faq__content-item h3 {
    margin: 1.25em 0 0.5em;
    font-size: 1em;
  }
}
@media screen and (max-width: 480px) {
  .faq-nav__content-btn {
    width: 100%;
  }
}
.feed-card {
  font-weight: normal;
  font-size: 14px;
  line-height: 1.2;
  color: #111827;
  border: 1px solid #e5e7eb;
  border-radius: 12px;
  position: relative;
}
.feed-card__header {
  border-bottom: 1px solid #e5e7eb;
}
.feed-card__user {
  padding: 20px 24px 16px;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.4s;
  transition-property: opacity;
}
@media screen and (min-width: 1200px) {
  .feed-card__user:hover {
    opacity: 0.6;
  }
}
.feed-card__user-avatar {
  width: 48px;
  height: 48px;
  margin-right: 12px;
  flex: 0 0 48px;
  border-radius: 50%;
  overflow: hidden;
}
.feed-card__user-content {
  font-size: 14px;
  line-height: 1.3;
}
.feed-card__user-top {
  margin: 0 0 5px;
  display: flex;
  align-items: center;
}
.feed-card__user-top-name {
  margin-right: 6px;
  font-weight: 500;
  -webkit-line-clamp: 1;
}
.feed-card__user-top-name a {
  color: inherit;
}
.feed-card__user-top-name a:before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}
.feed-card__user-top-action {
  color: #6b7280;
  flex-shrink: 0;
  white-space: nowrap;
}
.feed-card__user-bottom {
  display: flex;
  align-items: center;
  line-height: 1.2;
  flex-wrap: wrap;
}
.feed-card__user-bottom-time {
  margin-right: 6px;
  color: #6b7280;
}
.feed-card__user-bottom-donate-amount {
  background: #F3F4F6;
  padding: 4px 6px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: #111827;
  align-self: center;
  border-radius: 4px;
  white-space: nowrap;
}
.feed-card__header-description {
  margin: -8px 0 0;
  padding: 0 24px 20px;
}
.feed-card__body {
  padding: 0 24px;
}
.feed-card__body-info {
  padding: 16px 0;
  display: flex;
  align-items: center;
}
.feed-card__body-user {
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.4s;
  transition-property: opacity;
}
@media screen and (min-width: 1200px) {
  .feed-card__body-user:hover {
    opacity: 0.6;
  }
}
.feed-card__body-info-avatar {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  flex: 0 0 32px;
  border-radius: 50%;
  overflow: hidden;
}
.feed-card__body-info-name {
  margin: 0 10px 0 0;
  font-weight: 500;
  font-size: 16px;
}
.feed-card__body-info-name a {
  color: inherit;
}
.feed-card__body-info-name a:before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}
.feed-card__body-info-btn-action {
  margin-left: auto;
}
.feed-card__body-poster {
  margin: 0 0 24px;
  padding-bottom: 56.25%;
  display: block;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: all 0.4s;
  transition-property: opacity;
}
@media screen and (min-width: 1200px) {
  .feed-card__body-poster:hover {
    opacity: 0.6;
  }
}
.feed-card__body-title {
  margin: 0 0 -16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.55;
  color: #111827;
}
.feed-card__body-title a {
  color: inherit;
  transition: all 0.4s;
  transition-property: opacity;
}
@media screen and (min-width: 1200px) {
  .feed-card__body-title a:hover {
    opacity: 0.6;
  }
}
.feed-card__body-text {
  margin: 24px 0 0;
}
.feed-card__footer {
  margin: 12px 0 0;
  padding: 0 24px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.feed-card__footer-rating {
  margin: 0;
}
.feed-card__footer-link-moore {
  margin-left: auto;
  color: #2563EB;
  flex-shrink: 0;
  box-shadow: inset 0 -1px 0 transparent;
  position: relative;
  transition: all 0.4s;
  transition-property: box-shadow;
}
@media screen and (min-width: 1200px) {
  .feed-card__footer-link-moore:hover {
    box-shadow: inset 0 -1px 0 currentColor;
  }
}
.feed-card__footer-link-moore:before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
}

.project-thumbnail-card {
  color: #111827;
  position: relative;
  transition: all 0.4s;
  transition-property: opacity;
}
@media screen and (min-width: 1200px) {
  .project-thumbnail-card:hover {
    opacity: 0.6;
  }
}
.project-thumbnail-card > :last-child {
  margin-bottom: 0;
}
.project-thumbnail-card__inner {
  display: flex;
  align-items: flex-start;
}
.project-thumbnail-card__avatar {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  flex: 0 0 40px;
  border-radius: 50%;
}
.project-thumbnail-card__title {
  margin: 1px 0 3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.42;
}
.project-thumbnail-card__title a {
  color: inherit;
}
.project-thumbnail-card__title a:before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}
.project-thumbnail-card__subscribe {
  font-size: 12px;
  color: #6b7280;
}

.feed-main__card-list-item:not(:last-child) {
  margin-bottom: 24px;
}

.feed-aside__title {
  margin: 0 0 20px;
  font-weight: 600;
  font-size: 18px;
}
.feed-aside__project-thumbnail-list-card:not(:last-child) {
  margin-bottom: 24px;
}

.feed-layout {
  padding: 32px 0 80px;
  font-size: 14px;
  color: #111827;
  flex-grow: 1;
}
.feed-layout__inner {
  display: flex;
  align-items: flex-start;
}
.feed-layout__main {
  flex-grow: 1;
  order: -1;
}
.feed-layout__aside {
  max-width: 320px;
  min-width: 240px;
  margin: -20px 0 0 3.75vw;
  padding-top: 20px;
  flex: 0 0 29%;
  position: sticky;
  top: 0;
  order: 0;
}

.notification-layout {
  padding: 32px 0 80px;
  font-size: 14px;
  color: #111827;
  flex-grow: 1;
}
.notification-layout__inner {
  max-width: 672px;
  margin: 0 auto;
}
.notification-layout__header {
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e7eb;
}
.notification-layout__header-title {
  margin: 0;
  padding-right: 15px;
  font-weight: 600;
  font-size: 30px;
}
.notification-layout__header-settings {
  margin-left: auto;
  flex-shrink: 0;
  font-size: 14px;
  color: #2563EB;
  box-shadow: inset 0 -1px 0 transparent;
  white-space: nowrap;
  transition: all 0.4s;
  transition-property: box-shadow;
}
@media screen and (min-width: 1200px) {
  .notification-layout__header-settings:hover {
    box-shadow: inset 0 -1px 0 currentColor;
  }
}
.notification-layout__list-card {
  border-bottom: 1px solid #e5e7eb;
}
.p-error {
  padding: 32px 0;
  text-align: center;
  color: #111827;
  overflow: clip;
}
.p-error__inner {
  display: inline-block;
  text-align: center;
  vertical-align: top;
}
.p-error__title {
  margin: 0 0 35px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: 148px;
  line-height: 1;
  color: #D1D5DB;
  animation: 0.5s linear 2s normal forwards running errorTextColor;
  user-select: none;
  pointer-events: none;
}
@media screen and (max-width: 767px) {
  .p-error__title {
    margin-bottom: 5px;
    font-size: 100px;
  }
}
.p-error__title span {
  width: 1.0135135135em;
  height: 0.7297297297em;
  margin: 0 0.1013513514em;
  display: block;
  font-size: inherit;
  color: #D1D5DB;
  border: 0.1554054054em solid currentColor;
  border-radius: 0.3648648649em;
  animation: 0.5s linear 2s normal forwards running errorDotColor;
  position: relative;
}
.p-error__title span:before {
  background: currentColor;
  width: 0.2432432432em;
  height: 0.2432432432em;
  margin-top: -0.1216216216em;
  content: "";
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0.1081081081em;
  animation: 1s ease-in 1s normal forwards running errorDotSlide;
}
.p-error__content {
  max-width: 400px;
  display: inline-block;
  vertical-align: top;
}
.p-error__content-subtitle {
  margin: 0 0 8px;
  font-weight: 600;
  font-size: 24px;
}
.p-error__content-text {
  margin: 0 0 20px;
  font-size: 14px;
}
@keyframes errorTextColor {
  100% {
    color: #111827;
  }
}
@keyframes errorDotColor {
  100% {
    color: #16A34A;
  }
}
@keyframes errorDotSlide {
  100% {
    transform: translateX(0.2432432432em);
  }
}
.p-privacy {
  background: #ffffff;
  padding: 32px 0;
  flex-grow: 1;
  color: #111827;
  position: relative;
  overflow: clip;
  z-index: 4;
}
@media screen and (max-width: 1000px) {
  .p-privacy {
    max-width: initial;
    margin-top: -48px;
    padding-top: 24px;
  }
}
@media screen and (max-width: 480px) {
  .p-privacy {
    padding-top: 16px;
  }
}
.p-privacy__inner {
  max-width: 602px;
  margin: 0 auto;
}
.p-privacy__header {
  margin: 0 0 32px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .p-privacy__header {
    margin-bottom: 24px;
  }
}
.p-privacy__pager-link {
  margin-right: 12px;
  display: none;
  flex: 0 0 24px;
}
@media screen and (max-width: 1000px) {
  .p-privacy__pager-link {
    display: block;
  }
}
.p-privacy__title {
  margin: 0;
  font-weight: 600;
  font-size: 30px;
}
@media screen and (max-width: 1000px) {
  .p-privacy__title {
    font-size: 20px;
  }
}
.p-privacy__list-card:not(:last-child) {
  margin-bottom: 22px;
}
@media screen and (max-width: 1169px) {
  .p-privacy__list-card:not(:last-child) {
    margin-bottom: 20px;
  }
}

.l-login {
  background: #F8FAFC;
  overflow: clip;
}
@media screen and (max-width: 480px) {
  .l-login {
    background: transparent;
  }
}
.l-login__container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 480px) {
  .l-login__container {
    align-items: flex-start;
  }
}
.l-login__inner {
  width: 100%;
  max-width: 448px;
  margin: 0 auto;
  padding: 48px 0;
}
@media screen and (max-width: 767px) {
  .l-login__inner {
    padding: 32px 0;
  }
}
@media screen and (max-width: 480px) {
  .l-login__inner {
    max-width: 100%;
  }
}
.l-login__logo {
  width: 120px;
  margin: 0 auto 24px;
  display: block;
}
@media screen and (min-width: 1200px) {
  .l-login__logo {
    transition: all 0.4s;
    transition-property: opacity;
  }
}
@media screen and (min-width: 1200px) {
  .l-login__logo:hover {
    opacity: 0.6;
  }
}
.l-login__logo img {
  width: 100%;
  height: auto;
  display: block;
}
.l-login__block {
  background: #ffffff;
  margin: 0 0 16px;
  padding: 48px 40px;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 480px) {
  .l-login__block {
    background: transparent;
    margin-bottom: 26px;
    padding: 0;
    box-shadow: initial;
  }
}
.l-login__footer {
  display: block;
  font-size: 12px;
  line-height: 1.35;
  color: #6b7280;
  text-align: center;
}
.l-login__footer a {
  color: #2563EB;
}
@media screen and (min-width: 1200px) {
  .l-login__footer a {
    box-shadow: 0 1px 0 transparent;
    transition: all 0.4s;
    transition-property: box-shadow;
  }
}
@media screen and (min-width: 1200px) {
  .l-login__footer a:hover {
    box-shadow: 0 1px 0 currentColor;
  }
}

* {
  box-sizing: border-box;
  outline: none !important;
}

html {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Inter", arial, sans-serif;
  font-smooth: antialiased;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-display: swap;
  margin: 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

.popup-avatar-img .cropper-face,
.popup-avatar-img .cropper-view-box {
  border-radius: 50%;
}

ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

a,
a:visited {
  text-decoration: none;
}

img {
  display: block;
}

.container, .container-small, .container-medium, .container-large {
  width: 100%;
  max-width: 1072px;
  padding: 0 16px;
  margin: 0 auto;
}

@media screen and (min-width: 769px) {
  a:hover {
    text-decoration: none;
    outline: 0;
  }

  .header-middle:hover .header-middle-edit {
    opacity: 1;
  }
}
.header-top {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  padding: 13px 24px;
  position: relative;
}

.header-top-left,
.header-top-right {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.header-logo {
  width: 122px;
  height: 28px;
  margin-right: 40px;
}

.header-logo img {
  width: 100%;
}

.header-about {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #374151;
  text-decoration: none;
}

.header-new {
  padding: 4px 16px;
  margin-right: 24px;
}

.header-alerts {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 24px;
  cursor: pointer;
}

.header-alerts.header-alerts-mobile {
  display: none;
}

.header-profile {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background-color: #e5e7eb;
}

.header-profile img {
  width: 100%;
}

.header-middle {
  position: relative;
  width: 100%;
  height: 240px;
  background-color: #e5e7eb;
}

.header-middle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-middle-edit {
  position: absolute;
  right: 24px;
  bottom: 16px;
  width: 32px;
  border-radius: 8px;
  height: 32px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #374151;
  transition: 0.3s;
  opacity: 0;
  cursor: pointer;
}

.header-middle-edit img {
  width: initial;
  height: initial;
  object-fit: unset;
}

.header-bottom {
  margin-bottom: 32px;
}

.header-bottom-wrap {
  width: 100%;
  padding-top: 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header-info {
  position: relative;
}

.header-info-photo {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  overflow: hidden;
  background-color: #6b7280;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
}

.header-info-photo img {
  width: 100%;
}

.header-info-block {
  margin-left: 140px;
}

.header-info-name {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #111827;
}

.header-info-subs {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9CA3AF;
  cursor: pointer;
}

.header-subscribe {
  padding: 6px 24px;
  height: -webkit-min-content;
  height: min-content;
}

@media screen and (max-width: 1000px) {
  .header-about,
.header-new,
.header-alerts {
    display: none;
  }

  .header-alerts.header-alerts-mobile {
    display: -ms-flexbox;
    display: flex;
    margin-right: 0;
  }

  .header-top {
    padding: 8px 16px;
  }

  .header-top-right {
    -ms-flex-order: -1;
    order: -1;
  }

  .header-profile {
    width: 28px;
    height: 28px;
  }

  .header-logo {
    width: 104.6px;
    height: 24px;
    margin-right: 0;
  }

  .header-middle {
    height: 144px;
  }

  .header-middle-edit {
    right: 16px;
  }

  .header-bottom-wrap {
    padding-top: 10px;
    -ms-flex-align: center;
    align-items: center;
  }

  .header-info-block {
    margin-left: 124px;
  }

  .header-info-photo {
    width: 112px;
    height: 112px;
  }

  .header-info-name {
    font-size: 20px;
    line-height: 28px;
  }
}
@media screen and (max-width: 768px) {
  .header-info-block {
    margin-left: 0;
  }

  .header-info-photo {
    bottom: auto;
    top: -66px;
  }

  .header-info-name {
    padding-top: 50px;
  }

  .header-middle-edit {
    opacity: 1;
  }

  .header-bottom-wrap {
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
@media screen and (max-width: 480px) {
  .header-middle {
    height: 128px;
  }

  .header-info-photo {
    top: -48px;
    width: 80px;
    height: 80px;
  }

  .header-info-name {
    padding-top: 40px;
    font-size: 20px;
    line-height: 28px;
  }

  .header-subscribe {
    padding: 4px 12px;
  }
}
.footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 12px 24px;
  border-top: 1px solid #e5e7eb;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b7280;
}

.footer-block {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.footer-divider {
  width: 1px;
  height: 16px;
  background-color: #e5e7eb;
  margin: 0 24px;
}

.footer-copyrights {
  color: #9CA3AF;
}

.footer-about,
.footer-docs {
  color: #6b7280;
}

@media screen and (max-width: 1000px) {
  .footer {
    display: none;
  }
}
.popup-profile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 5;
  padding: 62px 24px 0 0;
  display: none;
}

.popup-profile-wrap {
  width: 265px;
  margin-left: auto;
  background: #FFFFFF;
  overflow: auto;
  border-radius: 8px;
  padding: 4px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.popup-profile-section {
  position: relative;
}

.popup-profile-section::after {
  position: absolute;
  left: -4px;
  bottom: 0;
  content: "";
  width: calc(100% + 8px);
  height: 1px;
  background-color: #e5e7eb;
}

.popup-profile-section:nth-of-type(3)::after {
  display: none;
}

.popup-profile-section-bottom {
  display: none;
}

.popup-profile-user {
  display: -ms-flexbox;
  display: flex;
  padding: 8px 14px;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.popup-profile-user-img {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  min-width: 32px;
  border-radius: 50%;
  overflow: hidden;
}

.popup-profile-user-img img {
  width: 100%;
}

.popup-profile-user-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #111827;
}

.popup-profile-user-email {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8A9199;
}

.popup-profile-link,
.popup-profile-copyrights,
.popup-profile-docs,
.popup-profile-lang {
  display: block;
  padding: 6px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #111827;
  transition: 0.3s;
}

.popup-profile-link:hover,
.popup-profile-copyrights:hover,
.popup-profile-docs:hover,
.popup-profile-lang:hover {
  background-color: #F3F4F6;
}

.popup-profile-link {
  border-radius: 4px;
  cursor: pointer;
}

.popup-profile-link.mobile {
  display: none;
}

.popup-profile-link.green {
  color: #166534;
}

@media screen and (max-width: 1000px) {
  .popup-profile {
    position: fixed;
    padding: 0;
    background-color: rgba(17, 24, 39, 0.5);
  }

  .popup-profile-wrap {
    margin-left: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: inherit;
    border-radius: 0;
    width: 320px;
  }

  .popup-profile-section {
    padding: 6px 0;
  }

  .popup-profile-section:nth-of-type(3)::after {
    display: block;
  }

  .popup-profile-section:nth-of-type(4)::after {
    display: none;
  }

  .popup-profile-section-bottom {
    -ms-flex: 1;
    flex: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .popup-profile-link.mobile {
    display: block;
  }

  .popup-profile-docs {
    color: #6b7280;
    font-size: 12px;
    line-height: 16px;
  }

  .popup-profile-copyrights {
    font-size: 12px;
    line-height: 16px;
    color: #9CA3AF;
  }
}
@media screen and (max-width: 480px) {
  .popup-profile-wrap {
    width: 256px;
  }
}
.popup-subs {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(17, 24, 39, 0.5);
  z-index: 5;
  display: none;
}

.popup-subs.active {
  display: -ms-flexbox;
  display: flex;
}

.popup-subs-wrap {
  position: relative;
  width: 100%;
  max-width: 576px;
  height: 75%;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: auto;
  scrollbar-color: #D1D5DB transparent;
  scrollbar-width: thin;
}

.popup-subs-wrap::-webkit-scrollbar {
  width: 4px;
}

.popup-subs-wrap::-webkit-scrollbar-track {
  border: solid 6px transparent;
}

.popup-subs-wrap::-webkit-scrollbar-thumb {
  background-color: #D1D5DB;
  border-radius: 18px;
  border: solid 6px transparent;
}

.popup-subs-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 20px 24px;
  border-bottom: 1px solid #e5e7eb;
  background-color: #fff;
  z-index: 5;
}

.popup-subs-back {
  display: none;
}

.popup-subs-count {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #374151;
}

.popup-subs-close {
  width: 28px;
  height: 28px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
}

.popup-subs-list {
  padding: 24px;
}

.popup-subs-user {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}

.popup-subs-user:last-of-type {
  margin-bottom: 0;
}

.popup-subs-user-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.popup-subs-user-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.popup-subs-user-photo img {
  width: 100%;
}

.popup-subs-user-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #374151;
  margin-left: 16px;
}

.popup-subs-user-btn {
  width: 124px;
  padding: 4px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  margin-left: auto;
}

@media screen and (max-width: 1000px) {
  .popup-subs-wrap {
    height: 85%;
  }
}
@media screen and (max-width: 480px) {
  .popup-subs {
    display: -ms-flexbox;
    display: flex;
    transition: 0.3s;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  .popup-subs.active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .popup-subs-wrap {
    height: 100%;
    border-radius: 0;
  }

  .popup-subs-header {
    padding: 0;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .popup-subs-back {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 52px;
    height: 56px;
  }

  .popup-subs-close {
    display: none;
  }

  .popup-subs-list {
    padding: 16px;
  }

  .popup-subs-user {
    margin-bottom: 16px;
  }

  .popup-subs-user-btn {
    display: none;
  }
}
.popup-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 5;
  display: none;
}

.popup-cover.active {
  display: -ms-flexbox;
  display: flex;
}

.popup-cover-wrap {
  margin: 300px 24px 0 auto;
  position: relative;
  width: 100%;
  max-width: 512px;
  height: 384px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: auto;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  scrollbar-color: #D1D5DB transparent;
  scrollbar-width: thin;
}

.popup-cover-wrap::-webkit-scrollbar {
  width: 4px;
}

.popup-cover-wrap::-webkit-scrollbar-track {
  border: solid 6px transparent;
}

.popup-cover-wrap::-webkit-scrollbar-thumb {
  background-color: #D1D5DB;
  border-radius: 18px;
  border: solid 6px transparent;
}

.popup-cover-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  border-bottom: 1px solid #e5e7eb;
  background-color: #fff;
  z-index: 5;
}

.popup-cover-ttl {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #374151;
}

.popup-cover-buttons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.popup-cover-delete {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  padding: 4px 16px;
  cursor: pointer;
}

.popup-cover-input {
  position: relative;
  width: 103.2px;
  height: 34px;
}

.popup-cover-input input {
  width: 0.01px;
  height: 0;
  opacity: 0;
  visibility: hidden;
}

.popup-cover-input label {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  padding: 4px 16px;
}

.popup-cover-close {
  display: none;
}

.popup-cover-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr [4];
  grid-template-columns: repeat(4, 1fr);
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  padding: 16px 16px 0;
}

.popup-cover-grid-item {
  height: 64px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
}

.popup-cover-grid-item img {
  display: block;
  width: 100%;
  height: 100%;
}

.popup-cover-footer {
  display: none;
}

@media screen and (max-width: 1000px) {
  .popup-cover {
    position: fixed;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: rgba(17, 24, 39, 0.5);
  }

  .popup-cover-wrap {
    margin: 0;
    height: 85%;
  }
}
@media screen and (max-width: 768px) {
  .popup-cover-wrap {
    height: 75%;
  }
}
@media screen and (max-width: 480px) {
  .popup-cover {
    display: -ms-flexbox;
    display: flex;
    transition: 0.3s;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  .popup-cover.active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .popup-cover-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 0;
    height: 100vh;
  }

  .popup-cover-header .popup-cover-buttons {
    display: none;
  }

  .popup-cover-close {
    width: 28px;
    height: 28px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    margin-left: auto;
  }

  .popup-cover-grid {
    padding: 16px;
    -ms-flex: 1;
    flex: 1;
    -ms-grid-rows: none;
    grid-template-rows: none;
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
  }

  .popup-cover-footer {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    left: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
    border-top: 1px solid #e5e7eb;
    background-color: #fff;
    z-index: 5;
  }

  .popup-cover-delete {
    padding: 0;
  }
}
.popup-avatar {
  position: absolute;
  top: -120px;
  right: -50%;
  z-index: 5;
  display: none;
}

.popup-avatar.active {
  display: -ms-flexbox;
  display: flex;
}

.popup-avatar-wrap {
  position: relative;
  width: 208px;
  height: 280px;
  padding: 4px;
  border-radius: 8px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.popup-avatar-wrap::after {
  content: "";
  position: absolute;
  top: 95px;
  left: -10px;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: -1;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
}

.popup-avatar-header {
  display: none;
}

.popup-avatar-close-desctop {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 28px;
  height: 28px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;
}

.popup-avatar-img {
  width: 100%;
  height: 228px;
  border-radius: 8px;
  overflow: hidden;
}

.popup-avatar-img img {
  width: 100%;
}

.popup-avatar-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 12px;
}

.popup-avatar-input {
  position: relative;
}

.popup-avatar-input input {
  width: 0.01px;
  height: 0;
  opacity: 0;
  visibility: hidden;
}

.popup-avatar-input label {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2563EB;
  cursor: pointer;
}

.popup-avatar-btn {
  border: none;
  background-color: transparent;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2563EB;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .popup-avatar {
    top: -115px;
    right: -50px;
  }
}
@media screen and (max-width: 1000px) {
  .popup-avatar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    right: auto;
  }

  .popup-avatar-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;
  }

  .popup-avatar-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
    border-bottom: 1px solid #e5e7eb;
    background-color: #fff;
    z-index: 5;
  }

  .popup-avatar-ttl {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
  }

  .popup-avatar-close-desctop {
    display: none;
  }

  .popup-avatar-img {
    -ms-flex: 1;
    flex: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: auto;
    background-color: #e5e7eb;
    padding: 16px;
  }

  .popup-avatar-img img {
    width: initial;
    max-width: 100%;
    height: initial;
    max-height: 100%;
  }
}
.btn {
  font-size: 14px;
  line-height: 24px;
  border-radius: 8px;
  cursor: pointer;
}

.btn-white {
  background-color: #ffffff;
  font-weight: 400;
  color: #374151;
  border: 1px solid #D1D5DB;
}

.btn-green {
  font-weight: 500;
  color: #ffffff;
  background-color: #15803D;
}

.post {
  width: 100%;
  padding: 24px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  margin-bottom: 24px;
}

.post:last-of-type {
  margin-bottom: 0;
}

.post-header {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
}

.post-header-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
  overflow: hidden;
}

.post-header-img img {
  width: 100%;
}

.post-header-ttl {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #111827;
  margin-bottom: 4px;
}

.post-header-date {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6b7280;
}

.post-header-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: auto;
}

.post-header-btn img {
  width: 100%;
}

.post-img {
  margin-bottom: 24px;
  border-radius: 4px;
  overflow: hidden;
}

.post-img img {
  width: 100%;
}

.post-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  margin-bottom: 24px;
}

.post-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.post-count {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
  margin: 0 8px;
}

.post-like,
.post-dislike {
  background: none;
  border: none;
  width: 32px;
  height: 32px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.post-dislike img {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media screen and (max-width: 768px) {
  .post {
    border: none;
    margin-bottom: 0;
    position: relative;
    padding: 24px 0;
  }

  .post::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: -16px;
    width: calc(100% + 32px);
    height: 1px;
    background-color: #e5e7eb;
  }
}
@media screen and (max-width: 480px) {
  .post {
    padding: 16px 0;
  }

  .post-header,
.post-img,
.post-text {
    margin-bottom: 16px;
  }

  .post-header-ttl {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.profile {
  padding-bottom: 32px;
  -ms-flex: 1;
  flex: 1;
}

.profile-wrap {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 672px auto;
  grid-template-columns: 672px auto;
  grid-column-gap: 48px;
  grid-row-gap: 32px;
}

.profile-tabs {
  position: relative;
  -ms-grid-column-span: 2;
  grid-column: span 2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 12px;
}

.profile-tabs::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #e5e7eb;
}

.profile-tab {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
  margin-right: 40px;
  cursor: pointer;
}

.profile-tab.active {
  position: relative;
  color: #166534;
}

.profile-tab.active::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -13px;
  width: 100%;
  height: 3px;
  background-color: #166534;
  z-index: 2;
}

.profile-posts {
  width: 100%;
  margin-right: 48px;
}

.profile-aside-text,
.profile-aside-adress {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6b7280;
  margin-bottom: 12px;
}

.profile-aside-link {
  display: block;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2563EB;
  margin-bottom: 4px;
}

@media screen and (max-width: 1000px) {
  .profile-wrap {
    -ms-grid-columns: 64.8% auto;
    grid-template-columns: 64.8% auto;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
  }
}
@media screen and (max-width: 768px) {
  .profile-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-row-gap: 0;
    grid-column-gap: 0;
  }

  .profile-aside {
    -ms-flex-order: -2;
    order: -2;
    margin-bottom: 24px;
  }

  .profile-tabs::after {
    left: -16px;
    width: calc(100% + 32px);
    height: 1px;
    background-color: #e5e7eb;
  }

  .profile-posts {
    margin: 0;
  }
}
/*# sourceMappingURL=styles.css.map */
.btn-group {
  padding: 7px;
  position: absolute;
  right: 24px;
  bottom: 16px;
  border-radius: 8px;
  height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #374151;
  transition: 0.3s;
  cursor: pointer;
}

.btn-group button {
  background-color: #374151;
  /* Green background */
  border: 1px solid #374151;
  /* Green border */
  color: white;
  /* White text */
  padding: 5px;
  /* Some padding */
  cursor: pointer;
  /* Pointer/hand icon */
  float: left;
  /* Float the buttons side by side */
}

.btn-group button:not(:last-child) {
  border-right: none;
  /* Prevent double borders */
}

/* Clear floats (clearfix hack) */
.btn-group:after {
  content: "";
  clear: both;
  display: table;
}

/* Add a background color on hover */
.btn-group button:hover {
  background-color: #374151;
}

.profile-posts-content-empty {
  font-size: 14px;
  line-height: 1.55;
  color: #6b7280;
  margin-top: 25px;
}

div.scrollmenu {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.phoneTimer {
  display: none;
  margin-bottom: 10px;
}

.phone_text {
  margin-right: 5px;
}

.button-role {
  background-color: #fff;
  cursor: pointer;
}

.index-post-news {
  display: flex;
  justify-content: end;
}

.qr-code {
  height: 30%;
  width: 30%;
}

/* Класс visually-hidden для скрытия элементов */
.visually-hidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

/* User profile */
.user-profile__content-name {
  line-height: 24px;
  overflow: visible;
}

.user-profile__content-email {
  line-height: 14px;
  overflow: visible;
}

.notyf__toast {
  padding: 0;
  border-radius: 8px;
  background: #334155;
}

.notyf__wrapper {
  padding: 12px 30px 12px 16px;
  background: #334155;
  min-height: 72px;
}

.notyf__ripple {
  background: #334155;
}

.notyf__icon {
  display: none;
}

.notyf__message {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  min-width: 280px;
}

.notyf__dismiss {
  margin-right: 0;
}

.notyf__dismiss-btn {
  opacity: 1;
  background-color: #334155;
}

.notyf__dismiss-btn:before,
.notyf__dismiss-btn:after {
  top: 16px;
}

@media screen and (max-width: 480px) {
  .notyf__message {
    min-width: 254px;
  }
}
/* Dropdown Поделиться */
.ya-share2__link,
.share-el__item-title {
  color: #374151;
  font-family: "Inter";
  font-size: 14px;
  line-height: 24px;
}

.share-el__item--telegram .share-el__item-icon {
  color: #26A5E4;
}

.share-el__item--vk .share-el__item-icon {
  color: #0077FF;
}

@media screen and (max-width: 768px) {
  .share-el {
    position: static;
  }

  .share-el__item {
    padding: 12px;
  }

  .share-el__dropdown {
    top: auto;
    left: 8px;
    right: 8px;
    bottom: 8px;
  }

  .share-el__dropdown-body {
    width: 100%;
  }

  .share-el__dropdown-cancel {
    display: block;
    color: #374151;
  }

  .ya-share2__link,
.share-el__item-title {
    font-size: 16px;
    line-height: 28px;
  }
}
/* Страница проекта - Город */
.project-main-info-city {
  font-size: 14px;
  line-height: 24px;
  color: #9CA3AF;
}

/* Чекбоксы */
.label-checkbox__inner {
  color: #374151;
}

.label-checkbox {
  position: relative;
  display: block;
  color: #374151;
  font-size: 14px;
  line-height: 24px;
  padding-left: 30px;
}

.label-checkbox__input {
  display: none;
}

.label-checkbox__input + label::before {
  content: "";
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 1px solid #D1D5DB;
  border-radius: 4px;
}

.label-checkbox__input:checked + label::before {
  background-color: #16A34A;
  border: none;
}

.label-checkbox__input:checked + label::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 1px;
  width: 18px;
  height: 18px;
  background-image: url("/assets/checked.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
}

/* Демо проект */
.aside-links-ttl {
  font-weight: 500;
  margin-bottom: 16px;
}

/* Демо проект - Управление - Донаты */
.balance-alert__description {
  line-height: 24px;
  margin-bottom: 0;
}

/* Демо проект - Управление - Основное */
.input-block__label-description {
  line-height: 24px;
}

.u-icon.tooltip-donate__icon {
  fill: #9CA3AF;
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 768px) {
  .tooltip-donate {
    position: static;
  }

  .tooltip-donate .tooltip-donate__dropdown {
    max-width: 304px;
    transform: translate(-50%, -55%);
  }

  .tooltip-donate .tooltip-donate__dropdown::before {
    display: none;
  }

  .tooltip-donate:hover .tooltip-donate__dropdown,
.tooltip-donate:active .tooltip-donate__dropdown,
.tooltip-donate.is-active .tooltip-donate__dropdown {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
/* Демо-проект - Управление - Описание */
.n-new-project__main-form {
  max-width: 648px;
}

.input-el--textarea {
  font-family: "Inter", arial, sans-serif;
}

/* Демо проект - Управление - Изображения */
.video-link-block__link-wrap {
  margin-bottom: 34px;
}

/* Блог проекта - Страница поста */
.project-detail-header {
  margin-bottom: 16px;
  cursor: pointer;
}

.project-detail-ttl-wrapper {
  margin-bottom: 24px;
}

.project-detail-ttl-wrapper.add {
  margin-bottom: 40px;
}

.project-detail-ttl-info {
  display: flex;
  justify-content: space-between;
}

.project-detail-ttl {
  line-height: 36px;
  margin-right: 12px;
  margin-bottom: 0;
}

.project-detail-ttl-btn {
  margin: 6px 0 30px;
}

.project-detail-ttl-label {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  padding: 2px 6px;
  color: #374151;
  background-color: #F3F4F6;
  border-radius: 4px;
  margin-top: 8px;
}

.project-detail-text p {
  margin: 0 0 12px;
}

.project-detail-img {
  max-width: 100%;
  margin: 0;
  border-radius: 0;
}

.project-detail-img img {
  max-width: 100%;
  margin-bottom: 24px;
}

.project-detail-header-img {
  border-radius: 50%;
  overflow: hidden;
}

.project-detail-text .media,
.project-detail-text .image,
.project-detail-text figure {
  width: 100%;
  margin: 24px 0;
}

.project-detail-text figure img {
  width: 100%;
}

.project-detail-text h1,
.project-detail-text h2,
.project-detail-text h3 {
  margin: 0;
  margin-top: 32px;
  font-weight: 600px;
  font-size: 20px;
  line-height: 32px;
}

.project-detail-text ul,
.project-detail-text ol {
  list-style: initial;
}

.project-detail-text ul li,
.project-detail-text ol li {
  margin-bottom: 12px;
}

.project-detail ul li::marker {
  color: #000000;
}

.project-add-text-ttl.extended {
  margin-bottom: 4px;
}

@media screen and (max-width: 480px) {
  .project-detail-ttl {
    line-height: 28px;
  }

  .project-detail-ttl-wrapper.add {
    margin-bottom: 32px;
  }
}
/* Пост */
.post-img img {
  height: 378px;
  object-fit: cover;
}

@media screen and (max-width: 1000px) {
  .post-img img {
    height: 414px;
  }
}
@media screen and (max-width: 768px) {
  .post-img img {
    height: 252px;
  }
}
@media screen and (max-width: 480px) {
  .post-img img {
    height: 162px;
  }
}
/* Отчеты в проекте */
.project-blog-noreports {
  display: flex;
  justify-content: flex-start;
  padding: 16px;
}

.u-icon.project-blog-noreports__icon {
  flex-shrink: 0;
  fill: #9CA3AF;
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.project-blog-noreports-msg {
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
}

.project-blog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-blog-header-btn {
  margin-left: 12px;
  width: 20px;
  height: 26px;
}

/* Проект - Управление - Донаты */
.project-settings__main-balance-alert {
  margin: 0 0 32px;
}

.balance-alert__content {
  margin-bottom: 16px;
}

.balance-alert__header {
  margin-bottom: 16px;
}

.balance-alert__description p {
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
}

.balance-alert__description-date {
  color: #111827;
  font-weight: 500;
}

.balance-alert__support {
  display: inline-block;
  margin-left: 0.5em;
  color: #2563EB;
  transition: all 0.4s;
  transition-property: all;
  transition-property: color;
}

/* Проект - Добавить отчет */
.project-add-field-subttl,
.project-add-img-subttl {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  margin-bottom: 12px;
}

/* Инпут города - select2 */
.select2__region {
  color: #9CA3AF;
  display: inline-block;
  margin-left: 4px;
}

.select2-selection__arrow {
  display: none;
}

.select2-dropdown.select2-dropdown--below {
  top: -38px;
}

.select2-container--default .select2-selection--single {
  border: 1px solid #E5E7EB;
}

.select2-search__field {
  border: none;
}

.select2-search.select2-search--dropdown {
  padding: 0;
}

.select2-container--default .select2-results__option.select2-results__option--highlighted.select2-results__option--selectable,
.select2-container--default .select2-results__option.select2-results__option--highlighted,
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #F3F4F6;
  color: #374151;
}

.select2-container--default .select2-results__option {
  font-size: 14px;
  line-height: 24px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  padding: 4px 14px;
  font-size: 14px;
  line-height: 24px;
  color: #374151;
  font-family: "Inter", arial, sans-serif;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: none;
}

.select2-dropdown {
  border: 1px solid #E5E7EB;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single:hover,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single:focus,
.select2-container--default .select2-selection--single:hover, .select2-container--default .select2-selection--single:focus {
  border: 1px solid #E5E7EB;
}

/* Комментарии, сабкомменты */
.subcomments {
  padding-left: 56px;
}

.subcomments .comment-block-item-person {
  margin-right: 12px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  flex: 0 0 24px;
}

.login-form {
  @apply bg-white sm:shadow lg:login-width  md:w-1/2 w-full p-5 sm:p-8 mt-10;
}

.or-text {
  height: 1px;
  @apply w-full bg-gray-300 mt-3;
}

.login-text-p {
  @apply focus:outline-none focus:underline hover:underline text-sm font-medium text-blue-500 cursor-pointer;
}

.login-input {
  @apply border rounded-lg  text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:border-transparent;
}

.apply-test {
  @apply focus:ring-2 rounded-lg focus:ring-offset-2 focus:ring-indigo-700 text-sm font-semibold leading-none text-white focus:outline-none bg-rose-600 border hover:bg-indigo-600 py-4 w-full;
}

.login-form-container {
  height: 100%;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252/var(--tw-bg-opacity));
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (max-width: 320px) {
  .login-form-container {
    height: 100%;
    width: 100%;
    --tw-bg-opacity: 1;
    background-color: white;
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
.form-button {
  @apply focus:ring-2 rounded-lg focus:ring-offset-2 focus:ring-indigo-700 text-sm font-semibold leading-none text-white focus:outline-none border hover:bg-green-700 py-4 w-full;
}

.login-form-row {
  @apply flex flex-col items-center justify-center;
}

.form-p-sign {
  @apply focus:outline-none leading-6 text-3xl font-semibold text-center text-gray-900;
}

.form-p-login {
  @apply focus:outline-none text-gray-500 text-sm mt-4 font-normal text-center leading-none;
}

.form-label {
  @apply text-sm font-normal leading-none text-gray-800;
}

.form-social-container {
  @apply flex flex-row justify-center items-center space-x-3;
}

.form-social-icon {
  @apply w-11 h-11 ml-3 items-center justify-center inline-flex rounded-full font-bold text-lg  text-white hover:shadow-lg cursor-pointer transition ease-in duration-300;
}

.eye-logn-pass {
  @apply absolute right-0 mt-2 mr-3 cursor-pointer;
}

/*
Trix 1.3.1
Copyright © 2020 Basecamp, LLC
http://trix-editor.org/*/
trix-editor {
  border: 1px solid #bbb;
  border-radius: 3px;
  margin: 0;
  padding: 0.4em 0.6em;
  min-height: 5em;
  outline: none;
}

trix-toolbar * {
  box-sizing: border-box;
}

trix-toolbar .trix-button-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow-x: auto;
}

trix-toolbar .trix-button-group {
  display: flex;
  margin-bottom: 10px;
  border: 1px solid #bbb;
  border-top-color: #ccc;
  border-bottom-color: #888;
  border-radius: 3px;
}

trix-toolbar .trix-button-group:not(:first-child) {
  margin-left: 1.5vw;
}

@media (max-device-width: 768px) {
  trix-toolbar .trix-button-group:not(:first-child) {
    margin-left: 0;
  }
}
trix-toolbar .trix-button-group-spacer {
  flex-grow: 1;
}

@media (max-device-width: 768px) {
  trix-toolbar .trix-button-group-spacer {
    display: none;
  }
}
trix-toolbar .trix-button {
  position: relative;
  float: left;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  font-weight: 600;
  white-space: nowrap;
  padding: 0 0.5em;
  margin: 0;
  outline: none;
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  background: transparent;
}

trix-toolbar .trix-button:not(:first-child) {
  border-left: 1px solid #ccc;
}

trix-toolbar .trix-button.trix-active {
  background: #cbeefa;
  color: black;
}

trix-toolbar .trix-button:not(:disabled) {
  cursor: pointer;
}

trix-toolbar .trix-button:disabled {
  color: rgba(0, 0, 0, 0.125);
}

@media (max-device-width: 768px) {
  trix-toolbar .trix-button {
    letter-spacing: -0.01em;
    padding: 0 0.3em;
  }
}
trix-toolbar .trix-button--icon {
  font-size: inherit;
  width: 2.6em;
  height: 1.6em;
  max-width: calc(0.8em + 4vw);
  text-indent: -9999px;
}

@media (max-device-width: 768px) {
  trix-toolbar .trix-button--icon {
    height: 2em;
    max-width: calc(0.8em + 3.5vw);
  }
}
trix-toolbar .trix-button--icon::before {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.6;
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-device-width: 768px) {
  trix-toolbar .trix-button--icon::before {
    right: 6%;
    left: 6%;
  }
}
trix-toolbar .trix-button--icon.trix-active::before {
  opacity: 1;
}

trix-toolbar .trix-button--icon:disabled::before {
  opacity: 0.125;
}

trix-toolbar .trix-button--icon-attach::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M16.5%206v11.5a4%204%200%201%201-8%200V5a2.5%202.5%200%200%201%205%200v10.5a1%201%200%201%201-2%200V6H10v9.5a2.5%202.5%200%200%200%205%200V5a4%204%200%201%200-8%200v12.5a5.5%205.5%200%200%200%2011%200V6h-1.5z%22%2F%3E%3C%2Fsvg%3E);
  top: 8%;
  bottom: 4%;
}

trix-toolbar .trix-button--icon-bold::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M15.6%2011.8c1-.7%201.6-1.8%201.6-2.8a4%204%200%200%200-4-4H7v14h7c2.1%200%203.7-1.7%203.7-3.8%200-1.5-.8-2.8-2.1-3.4zM10%207.5h3a1.5%201.5%200%201%201%200%203h-3v-3zm3.5%209H10v-3h3.5a1.5%201.5%200%201%201%200%203z%22%2F%3E%3C%2Fsvg%3E);
}

trix-toolbar .trix-button--icon-italic::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M10%205v3h2.2l-3.4%208H6v3h8v-3h-2.2l3.4-8H18V5h-8z%22%2F%3E%3C%2Fsvg%3E);
}

trix-toolbar .trix-button--icon-link::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M9.88%2013.7a4.3%204.3%200%200%201%200-6.07l3.37-3.37a4.26%204.26%200%200%201%206.07%200%204.3%204.3%200%200%201%200%206.06l-1.96%201.72a.91.91%200%201%201-1.3-1.3l1.97-1.71a2.46%202.46%200%200%200-3.48-3.48l-3.38%203.37a2.46%202.46%200%200%200%200%203.48.91.91%200%201%201-1.3%201.3z%22%2F%3E%3Cpath%20d%3D%22M4.25%2019.46a4.3%204.3%200%200%201%200-6.07l1.93-1.9a.91.91%200%201%201%201.3%201.3l-1.93%201.9a2.46%202.46%200%200%200%203.48%203.48l3.37-3.38c.96-.96.96-2.52%200-3.48a.91.91%200%201%201%201.3-1.3%204.3%204.3%200%200%201%200%206.07l-3.38%203.38a4.26%204.26%200%200%201-6.07%200z%22%2F%3E%3C%2Fsvg%3E);
}

trix-toolbar .trix-button--icon-strike::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12.73%2014l.28.14c.26.15.45.3.57.44.12.14.18.3.18.5%200%20.3-.15.56-.44.75-.3.2-.76.3-1.39.3A13.52%2013.52%200%200%201%207%2014.95v3.37a10.64%2010.64%200%200%200%204.84.88c1.26%200%202.35-.19%203.28-.56.93-.37%201.64-.9%202.14-1.57s.74-1.45.74-2.32c0-.26-.02-.51-.06-.75h-5.21zm-5.5-4c-.08-.34-.12-.7-.12-1.1%200-1.29.52-2.3%201.58-3.02%201.05-.72%202.5-1.08%204.34-1.08%201.62%200%203.28.34%204.97%201l-1.3%202.93c-1.47-.6-2.73-.9-3.8-.9-.55%200-.96.08-1.2.26-.26.17-.38.38-.38.64%200%20.27.16.52.48.74.17.12.53.3%201.05.53H7.23zM3%2013h18v-2H3v2z%22%2F%3E%3C%2Fsvg%3E);
}

trix-toolbar .trix-button--icon-quote::before {
  background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M6%2017h3l2-4V7H5v6h3zm8%200h3l2-4V7h-6v6h3z%22%2F%3E%3C%2Fsvg%3E);
}

trix-toolbar .trix-button--icon-heading-1::before {
  background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12%209v3H9v7H6v-7H3V9h9zM8%204h14v3h-6v12h-3V7H8V4z%22%2F%3E%3C%2Fsvg%3E);
}

trix-toolbar .trix-button--icon-code::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M18.2%2012L15%2015.2l1.4%201.4L21%2012l-4.6-4.6L15%208.8l3.2%203.2zM5.8%2012L9%208.8%207.6%207.4%203%2012l4.6%204.6L9%2015.2%205.8%2012z%22%2F%3E%3C%2Fsvg%3E);
}

trix-toolbar .trix-button--icon-bullet-list::before {
  background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M4%204a2%202%200%201%200%200%204%202%202%200%200%200%200-4zm0%206a2%202%200%201%200%200%204%202%202%200%200%200%200-4zm0%206a2%202%200%201%200%200%204%202%202%200%200%200%200-4zm4%203h14v-2H8v2zm0-6h14v-2H8v2zm0-8v2h14V5H8z%22%2F%3E%3C%2Fsvg%3E);
}

trix-toolbar .trix-button--icon-number-list::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M2%2017h2v.5H3v1h1v.5H2v1h3v-4H2v1zm1-9h1V4H2v1h1v3zm-1%203h1.8L2%2013.1v.9h3v-1H3.2L5%2010.9V10H2v1zm5-6v2h14V5H7zm0%2014h14v-2H7v2zm0-6h14v-2H7v2z%22%2F%3E%3C%2Fsvg%3E);
}

trix-toolbar .trix-button--icon-undo::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12.5%208c-2.6%200-5%201-6.9%202.6L2%207v9h9l-3.6-3.6A8%208%200%200%201%2020%2016l2.4-.8a10.5%2010.5%200%200%200-10-7.2z%22%2F%3E%3C%2Fsvg%3E);
}

trix-toolbar .trix-button--icon-redo::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M18.4%2010.6a10.5%2010.5%200%200%200-16.9%204.6L4%2016a8%208%200%200%201%2012.7-3.6L13%2016h9V7l-3.6%203.6z%22%2F%3E%3C%2Fsvg%3E);
}

trix-toolbar .trix-button--icon-decrease-nesting-level::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M3%2019h19v-2H3v2zm7-6h12v-2H10v2zm-8.3-.3l2.8%202.9L6%2014.2%204%2012l2-2-1.4-1.5L1%2012l.7.7zM3%205v2h19V5H3z%22%2F%3E%3C%2Fsvg%3E);
}

trix-toolbar .trix-button--icon-increase-nesting-level::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M3%2019h19v-2H3v2zm7-6h12v-2H10v2zm-6.9-1L1%2014.2l1.4%201.4L6%2012l-.7-.7-2.8-2.8L1%209.9%203.1%2012zM3%205v2h19V5H3z%22%2F%3E%3C%2Fsvg%3E);
}

trix-toolbar .trix-dialogs {
  position: relative;
}

trix-toolbar .trix-dialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-size: 0.75em;
  padding: 15px 10px;
  background: #fff;
  box-shadow: 0 0.3em 1em #ccc;
  border-top: 2px solid #888;
  border-radius: 5px;
  z-index: 5;
}

trix-toolbar .trix-input--dialog {
  font-size: inherit;
  font-weight: normal;
  padding: 0.5em 0.8em;
  margin: 0 10px 0 0;
  border-radius: 3px;
  border: 1px solid #bbb;
  background-color: #fff;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

trix-toolbar .trix-input--dialog.validate:invalid {
  box-shadow: #F00 0px 0px 1.5px 1px;
}

trix-toolbar .trix-button--dialog {
  font-size: inherit;
  padding: 0.5em;
  border-bottom: none;
}

trix-toolbar .trix-dialog--link {
  max-width: 600px;
}

trix-toolbar .trix-dialog__link-fields {
  display: flex;
  align-items: baseline;
}

trix-toolbar .trix-dialog__link-fields .trix-input {
  flex: 1;
}

trix-toolbar .trix-dialog__link-fields .trix-button-group {
  flex: 0 0 content;
  margin: 0;
}

trix-editor [data-trix-mutable]:not(.attachment__caption-editor) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

trix-editor [data-trix-mutable]::-moz-selection,
trix-editor [data-trix-cursor-target]::-moz-selection, trix-editor [data-trix-mutable] ::-moz-selection {
  background: none;
}

trix-editor [data-trix-mutable]::selection,
trix-editor [data-trix-cursor-target]::selection, trix-editor [data-trix-mutable] ::selection {
  background: none;
}

trix-editor [data-trix-mutable].attachment__caption-editor:focus::-moz-selection {
  background: highlight;
}

trix-editor [data-trix-mutable].attachment__caption-editor:focus::selection {
  background: highlight;
}

trix-editor [data-trix-mutable].attachment.attachment--file {
  box-shadow: 0 0 0 2px highlight;
  border-color: transparent;
}

trix-editor [data-trix-mutable].attachment img {
  box-shadow: 0 0 0 2px highlight;
}

trix-editor .attachment {
  position: relative;
}

trix-editor .attachment:hover {
  cursor: default;
}

trix-editor .attachment--preview .attachment__caption:hover {
  cursor: text;
}

trix-editor .attachment__progress {
  position: absolute;
  z-index: 1;
  height: 20px;
  top: calc(50% - 10px);
  left: 5%;
  width: 90%;
  opacity: 0.9;
  transition: opacity 200ms ease-in;
}

trix-editor .attachment__progress[value="100"] {
  opacity: 0;
}

trix-editor .attachment__caption-editor {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  color: inherit;
  text-align: center;
  vertical-align: top;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

trix-editor .attachment__toolbar {
  position: absolute;
  z-index: 1;
  top: -0.9em;
  left: 0;
  width: 100%;
  text-align: center;
}

trix-editor .trix-button-group {
  display: inline-flex;
}

trix-editor .trix-button {
  position: relative;
  float: left;
  color: #666;
  white-space: nowrap;
  font-size: 80%;
  padding: 0 0.8em;
  margin: 0;
  outline: none;
  border: none;
  border-radius: 0;
  background: transparent;
}

trix-editor .trix-button:not(:first-child) {
  border-left: 1px solid #ccc;
}

trix-editor .trix-button.trix-active {
  background: #cbeefa;
}

trix-editor .trix-button:not(:disabled) {
  cursor: pointer;
}

trix-editor .trix-button--remove {
  text-indent: -9999px;
  display: inline-block;
  padding: 0;
  outline: none;
  width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid highlight;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
}

trix-editor .trix-button--remove::before {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  content: "";
  background-image: url(data:image/svg+xml,%3Csvg%20height%3D%2224%22%20width%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M19%206.4L17.6%205%2012%2010.6%206.4%205%205%206.4l5.6%205.6L5%2017.6%206.4%2019l5.6-5.6%205.6%205.6%201.4-1.4-5.6-5.6z%22%2F%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3C%2Fsvg%3E);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
}

trix-editor .trix-button--remove:hover {
  border-color: #333;
}

trix-editor .trix-button--remove:hover::before {
  opacity: 1;
}

trix-editor .attachment__metadata-container {
  position: relative;
}

trix-editor .attachment__metadata {
  position: absolute;
  left: 50%;
  top: 2em;
  transform: translate(-50%, 0);
  max-width: 90%;
  padding: 0.1em 0.6em;
  font-size: 0.8em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
}

trix-editor .attachment__metadata .attachment__name {
  display: inline-block;
  max-width: 100%;
  vertical-align: bottom;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

trix-editor .attachment__metadata .attachment__size {
  margin-left: 0.2em;
  white-space: nowrap;
}

.trix-content {
  line-height: 1.5;
}

.trix-content * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.trix-content h1 {
  font-size: 1.2em;
  line-height: 1.2;
}

.trix-content blockquote {
  border: 0 solid #ccc;
  border-left-width: 0.3em;
  margin-left: 0.3em;
  padding-left: 0.6em;
}

.trix-content [dir=rtl] blockquote,
.trix-content blockquote[dir=rtl] {
  border-width: 0;
  border-right-width: 0.3em;
  margin-right: 0.3em;
  padding-right: 0.6em;
}

.trix-content li {
  margin-left: 1em;
}

.trix-content [dir=rtl] li {
  margin-right: 1em;
}

.trix-content pre {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-family: monospace;
  font-size: 0.9em;
  padding: 0.5em;
  white-space: pre;
  background-color: #eee;
  overflow-x: auto;
}

.trix-content img {
  max-width: 100%;
  height: auto;
}

.trix-content .attachment {
  display: inline-block;
  position: relative;
  max-width: 100%;
}

.trix-content .attachment a {
  color: inherit;
  text-decoration: none;
}

.trix-content .attachment a:hover, .trix-content .attachment a:visited:hover {
  color: inherit;
}

.trix-content .attachment__caption {
  text-align: center;
}

.trix-content .attachment__caption .attachment__name + .attachment__size::before {
  content: " · ";
}

.trix-content .attachment--preview {
  width: 100%;
  text-align: center;
}

.trix-content .attachment--preview .attachment__caption {
  color: #666;
  font-size: 0.9em;
  line-height: 1.2;
}

.trix-content .attachment--file {
  color: #333;
  line-height: 1;
  margin: 0 2px 2px 2px;
  padding: 0.4em 1em;
  border: 1px solid #bbb;
  border-radius: 5px;
}

.trix-content .attachment-gallery {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.trix-content .attachment-gallery .attachment {
  flex: 1 0 33%;
  padding: 0 0.5em;
  max-width: 33%;
}

.trix-content .attachment-gallery.attachment-gallery--2 .attachment, .trix-content .attachment-gallery.attachment-gallery--4 .attachment {
  flex-basis: 50%;
  max-width: 50%;
}

.trix-content .attachment-gallery > action-text-attachment,
.trix-content .attachment-gallery > .attachment {
  flex: 1 0 33%;
  padding: 0 0.5em;
  max-width: 33%;
}
.trix-content .attachment-gallery.attachment-gallery--2 > action-text-attachment,
.trix-content .attachment-gallery.attachment-gallery--2 > .attachment, .trix-content .attachment-gallery.attachment-gallery--4 > action-text-attachment,
.trix-content .attachment-gallery.attachment-gallery--4 > .attachment {
  flex-basis: 50%;
  max-width: 50%;
}
.trix-content action-text-attachment .attachment {
  padding: 0 !important;
  max-width: 100% !important;
}

.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*

.select2-container--default .select2-selection--single .select2-selection__arrow{
 top: 10px !important;
}

.select2-container--default .select2-selection--single{
 border: 1px solid #E5E7EB !important;
}

.select2-container--default .select2-search--dropdown .select2-search__field{
 border: 1px solid #E5E7EB !important;

}

.select2-dropdown{
 border: 1px solid #E5E7EB !important;

}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable{
 background-color: #16A34A !important;
}


.select2-container{
 width: 100% !important;
}



.select2-container--default .select2-selection--single .select2-selection__arrow b {
 background-color: transparent;
 border: none !important;
 height: 20px !important;
 width: 20px !important;
 margin: auto !important;
 top: auto !important;
 left: -45% !important;
}

*/