.tinkoff-bank {
    display: flex;
    align-items: center;

    &__logo {
        width: 110px;
        height: auto;
        margin: 0 20px 0 0;
        display: block;
        flex: 0 0 110px;
    }

    &__description {
        margin: 0;
        font-size: 12px;
        line-height: 1.33333333333;
        color: $gray;
    }
}

.section-title {
    margin: 0;
    font-weight: 600;
    font-size: 42px;
    line-height: 1.15;
    color: $darknes;
}

.authors {
    overflow: hidden;
}

.authors-intro {
    background: $blue-light;
    padding: 72px 0;
    overflow: hidden;

    &__container {
    }

    &__row {
        align-items: center;
    }

    &__col {

        &--content {
        }

        &--media {
            margin-bottom: -72px;
            align-self: center;
        }
    }

    &__content {
        max-width: 500px;
        padding: 52px 0;
    }

    &__title {
        margin: 0 0 20px;
        font-weight: 600;
        font-size: 56px;
        line-height: 1.10714285714;
        color: $darknes;
    }

    &__desription {
        margin: 0 0 28px;
        color: $dark-blue;
    }

    &__btns {
        padding-bottom: 66px;
    }

    &__btn {
    }

    &__tinkoff-bank {
        padding-top: 32px;
        border-top: 1px solid $gray-light;
    }

    &__picture {
        margin-right: -134px;
        padding: 30px 0;
        display: block;
        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
}

.a-section-card {
    padding: 130px 0 100px 0;

    &__container {
    }

    &__section-title {
        max-width: 600px;
        margin: 0 auto 30px;
        text-align: center;
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__media {
        max-width: 530px;
        margin-right: 7.5%;
        flex: 0 0 46.6%;
    }

    &__picture {
        width: 100%;
        display: block;
        user-select: none;

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    & + & &__media {
        margin-right: 0;
        margin-left: 7.5%;
    }

    &__content {
        padding: 0 0 24px 24px;
        flex-grow: 1;
    }

    & + & &__content {
        padding-right: 24px;
        padding-left: 0;
        order: -1;
    }

    &__title {
        margin: 0 0 16px;
    }

    &__text {
        font-size: 16px;
        line-height: 1.75;
        color: $dark-blue;
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }
}

.a-benefits-card {
    &__header {
        margin: 0 0 40px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 480px) {
            margin-bottom: 10px;
        }
        &:after {
            content: '';
            display: block;
            border-top: 2px dotted #D1D5DB;
            flex-grow: 1;
        }
    }
    &__picture {
        background: $green3;
        width: 24px;
        height: 24px;
        margin-right: 20px;
        flex: 0 0 24px;
        font-size: 0;
        line-height: 24px;
        text-align: center;
        border-radius: 50%;
    }
    &__icon {
        display: inline-block;
        vertical-align: middle;
    }
    &__content {
    }
    &__title {
        margin: 0 0 12px;
        font-weight: 500;
        font-size: 21px;
        @media screen and (max-width: 480px) {
            margin-bottom: 7px;
        }
    }
    &__text {
        margin: 0;
        font-size: 15px;
        line-height: 1.6;
    }
}

.a-benefits-section {
    padding: 140px 0 70px;
    @media screen and (max-width: 1169px) {
        padding-top: 70px;
    }
    @media screen and (max-width: 767px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    &__container {
    }
    &__title {
        max-width: 490px;
        margin: 0 0 90px;
        @media screen and (max-width: 1169px) {
            max-width: 360px;
            margin-bottom: 60px;
            font-size: 32px;
        }
        @media screen and (max-width: 767px) {
            max-width: 280px;
            font-size: 24px;
        }
        @media screen and (max-width: 480px) {
            margin-bottom: 40px;
        }
    }
    &__list {
        margin: 0 -20px -40px;
        display: flex;
        flex-wrap: wrap;
    }
    &__list-col {
        max-width: 25%;
        margin-bottom: 40px;
        padding: 0 20px;
        flex: 0 0 25%;
        @media screen and (max-width: 767px) {
            max-width: 50%;
            flex-basis: 50%;
        }
        @media screen and (max-width: 480px) {
            max-width: 100%;
            flex-basis: 100%;
        }
    }
}

.a-section-info {
    padding: 50px 0;

    &__container {
    }

    &__inner {
        background: $blue-light;
        margin-top: -76px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-radius: 16px;
    }

    &__content {
        padding: 86px 24px 86px 5.7%;
        flex-grow: 1;
    }

    &__title {
        margin: 0 0 16px;
    }

    &__text {
        font-size: 16px;
        line-height: 1.75;
        color: $dark-blue;

        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }

    &__media {
        max-width: 381px;
        margin: -44px 114px 0 0;
        flex: 0 0 33.8%;
        align-self: flex-end;
        position: relative;
        z-index: 0;
    }

    &__btn {
        display: inline-block;
        vertical-align: top;
    }

    &__picture {
        display: block;
        user-select: none;

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    &__decor {
        background-position: left bottom;
        background-repeat: no-repeat;
        background-size: contain;
        width: 248px;
        height: 286px;
        content: '';
        position: absolute;
        top: 44px;
        right: -114px;
        pointer-events: none;
        z-index: -1;
    }
}

.why-uppora {
    padding: 62px 0 140px;

    &__container {
    }

    &__title {
        margin: 0 0 83px;
        text-align: center;
    }
}

.why-uppora-list {

    &__row {
    }

    &__col {
    }

    &__card {
    }
}

.why-uppora-card {
    white-space: initial;

    &__picture {
        margin: 0 0 48px;
        padding-bottom: 68.965517241%;
        box-shadow: 0 8px 24px rgba(15,23,42,.06);
        border-radius: 16px;
    }

    &__content {
    }

    &__title {
        margin: 0 0 24px;
        font-weight: 600;
        font-size: 21px;
        line-height: 1.42857142857;
        color: $darknes;
    }

    &__text {
        font-size: 15px;
        line-height: 1.6;
        color: $dark-blue;
    }
}

.appeal-section {
    max-width: 1138px;
    margin: 0 auto 80px;
    padding: 80px 0;
    position: relative;
    z-index: 0;

    &--about {
        margin-bottom: 0 !important;
    }

    &:before {
        background: $blue-light;
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 16px;
        z-index: -1;
    }

    &--about:before {
        background: $white;
    }

    &__content {
        padding: 0 16px;
        text-align: center;
    }

    &__title {
        max-width: 750px;
        margin: 0 auto 16px;
    }

    &__text {
        max-width: 650px;
        margin: 0 auto 24px;
        font-size: 15px;
        line-height: 1.6;
    }

    &__btns {
        justify-content: center;
    }
}

.mass-media-card {
    transition: all .4s;
    transition-property: opacity;
    &:not(:hover) {
        opacity: .2;
    }
    &__picture {
        display: block;
        padding-bottom: 30%;
        display: block;
        position: relative;
    }
    &__link {
        &:before {
            width: 100%;
            height: 100%;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &__link > *,
    &__img {
        max-width: calc(100% - 14px);
        max-height: calc(100% - 14px);
        margin: auto;
        fill: #111827;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.mass-media-section {
    background: $blue-light;
    padding: 80px 0 100px;
    @media screen and (max-width: 767px) {
        padding-top: 60px;
        padding-bottom: 50px;
    }
    &__title {
        margin: 0 0 70px;
        text-align: center;
        @media screen and (max-width: 767px) {
            margin-bottom: 30px;
            font-size: 24px;
        }
    }
    &__list {
        margin: 0 -10px -10px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 767px) {
            flex-wrap: wrap;
        }
        &-col {
            max-width: 25%;
            margin-bottom: 10px;
            padding: 0 10px;
            flex: 0 0 25%;
            @media screen and (max-width: 767px) {
                max-width: 50%;
                flex-basis: 50%;
            }
        }
    }
}

.template-card {
    display: flex;
    align-items: center;

    &__input {
        flex-grow: 1;
    }

    &__currency {
        margin-left: 4px;
        font-size: 14px;
        color: #9CA3AF;
        flex-shrink: 0;
        white-space: nowrap;
    }
}
/* 
.label-checkbox {
    display: block;
    cursor: pointer;
    position: relative;

    &__input {
        display: block;
        position: absolute;
        left: -9999px;
    }

    &__inner {
        padding: 0 0 0 32px;
        display: block;
        font-size: 14px;
        line-height: 1.45;

        &:before {
            background: transparent;
            width: 20px;
            height: 20px;
            border: 1px solid #D1D5DB;
            border-radius: 4px;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            transition: all .4s;
            transition-property: background, border-color;
        }
    }

    &__inner-icon {
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        transition: all .4s;
        transition-property: opacity, visibility;
    }

    &__input:checked ~ &__inner:before {
        background: $green3;
        border-color: $green3;
    }

    &__input:not(:checked) ~ &__inner-icon {
        opacity: 0;
        visibility: hidden;
    }
} */

.button-badge-donate {
    background: transparent;
    padding: 8px 16px;
    display: block;
    font-size: 14px;
    line-height: 1;
    color: $dark-blue;
    border: 1px solid #D1D5DB;
    border-radius: 16px;
    outline: none;
    cursor: pointer;
    transition: all .4s;

    &:hover,
    &:focus {
        outline: none;
    }

    &:hover {

    }

    &:focus {
        
    }

    &__amount {
    }

    &__currency {
    }
}

.button-badge-wrap {
    margin: -12px 0 0 -12px;
    display: flex;
    flex-wrap: wrap;

    &__item {
        margin: 12px 0 0 12px;
    }
}

.btn-donate {
    background: transparent;
    width: 100%;
    padding: 14px 28px;
    display: block;
    font-weight: normal;
    font-size: 12px;
    line-height: 1.35;
    color: $dark-blue;
    text-align: center;
    border: 1px solid #D1D5DB;
    border-radius: 8px;
    outline: none;
    user-select: none;
    cursor: pointer;
    transition: all .4s;
    transition-property: border-color;

    &:hover,
    &:focus {

    }

    &:hover {

    }

    &:focus {
        
    }

    &__logo {
        width: 162px;
        height: auto;
        margin: 0 auto 6px;
        display: block;
    }

    &__title {
        margin: 0 0 -6px;
        display: block;
    }
}

.link-accent {
    color: $blue;
    box-shadow: inset 0 -1px 0 $blue;
    transition: .4s;
    transition-property: box-shadow;
}

.d-page {
    padding: 32px 0 40px;
    flex-grow: 1;
    font-size: 14px;
    color: $darknes;
    overflow: hidden;
}

.prev-link {
    min-height: 24px;
    padding-left: 32px;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    color: $blue;
    position: relative;
    transition: all .4s;
    transition-property: color;
    &__icon {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        width: 24px;
        height: 24px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.pager-link {
    display: inline-block;
    font-size: 0;
    position: relative;
    transition: all .4s;
    transition-property: opacity, visibility;

    &__icon {
        background-position: 50%;
        background-size: contain;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        display: block;
    }

    &:after {
        content: '';
        position: absolute;
        inset: -6px;
    }

    &--prev,
    &--next {
        
    }

    &--prev {
    }

    &--next {
        transform: scaleX(-1);
    }

    &--is-hidden {
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
    }
}

.site-pager {
    display: flex;
    align-items: center;

    &__link {
        display: none;
    }

    &__steps {
        margin: 0;
    }
}

.ty-donate {
    font-size: 14px;
    line-height: 1.35;
    color: $darknes;
    text-align: center;
    &__inner {
    }
    &__media {
        width: 128px;
        height: 128px;
        margin: 0 auto 8px;
        display: block;
        font-size: 0;
        text-align: center;
        user-select: none;
        position: relative;
        & > * {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
        }
        &-gif {
        }
        &-img {
        }
    }
    &__media.is-loaded &__media-gif,
    &__media:not(.is-loaded) &__media-img {
        opacity: 0;
        visibility: hidden;
    }
    &__title {
        margin: 0 0 8px;
        font-weight: 600;
        font-size: 24px;
    }
    &__text {
        margin: 0 0 20px;
        font-size: 14px;
        color: $dark-blue;
    }
    &__btn {
        margin: 0 0 24px;
        display: inline-block;
        vertical-align: top;
    }
    &__pev-link {
    }
}

.d-donate {

    &__container {
    }

    &__form {
        max-width: 512px;
        margin: 0 auto;
    }

    &__thank {
        max-width: 320px;
        margin: 0 auto;
        padding: 50px 0;
    }
}

.donate-form {
    display: block;

    &--size-sm {
        max-width: 384px;
        margin-right: auto;
        margin-left: auto;
    }

    &__header {
        margin: 0 0 4px;
        display: flex;
        align-items: center;
    }

    &__pager-link {
        margin-right: 12px;
        display: none;
    }

    &__title {
        margin: 0;
        font-weight: 600;
        font-size: 30px;
        line-height: 1.33333333333;
    }

    &__input-donate {
        margin: 30px 0 16px;
    }

    &__input-name {
        margin-bottom: 48px;
    }

    &__comment {
        margin-bottom: 16px;
    }

    &__button-badge-amount-wrap {
        padding-bottom: 48px;
    }

    &__button-badge-comment-wrap {
        padding-bottom: 52px;
    }

    &__privacy {
        margin-bottom: 32px;
    }

    &__btn {
        margin-top: 48px;
        margin-bottom: 24px;
    }

    &__tinkoff-bank {
        font-size: 12px;
        line-height: 1.35;
        color: $gray;
        text-align: center;
    }

    &__tinkoff-bank-text {
        margin: 0 0 12px;
        display: block;
    }

    &__tinkoff-bank-logo {
        width: 126px;
        height: auto;
        margin: 0 auto;
        display: block;
    }
}

.project-card {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 1.3;
    color: $darknes;
    border: 1px solid $gray-light;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    z-index: 0;

    &__info {
        padding: 20px;
        display: flex;
        align-items: center;
    }
    &__logo {
        width: 36px;
        height: 36px;
        margin-right: 12px;
        border-radius: 50%;
        flex: 0 0 36px;
        overflow: hidden;
    }
    &__title {
        height: 2.7em;
        margin: 0;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.35;
        overflow: hidden;
    }
    &__link {
        display: -webkit-box;
        color: $darknes;
        outline: none;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        transition: all .4s;
        transition-property: color;
        overflow: hidden;

        &:before {
            width: 100%;
            height: 100%;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
    }
    &__media {
        padding-bottom: 53.2%;
        display: block;
        overflow: hidden;
    }
    &__content {
        padding: 20px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    &__target {
        display: flex;
        align-items: center;
        font-weight: 500;
    }
    &__price {
        margin-right: .3em;
        font-size: 21px;
    }
    &__price-title {
        font-size: 14px;
    }
    &__description {
        margin: 0 0 20px;
        font-size: 14px;
        line-height: 1.7;
        color: $dark-blue;
    }
    &__footer {
        margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #9CA3AF;
    }
    &__time {
        display: block;
    }
    &__city-label {
        flex-shrink: 0;
    }
}

.appeal-alert {
    background: $blue-light;
    padding: 24px;
    border-radius: 8px;
    position: relative;

    &__close {
        position: absolute;
        top: 12px;
        right: 12px;
    }
    &__inner {
        display: flex;
        justify-content: space-between;
    }
    &__content {
        margin: 0 28px 0 0;
        font-size: 14px;
        line-height: 1.7;
        color: $dark-blue;

        a {
            color: $blue;
            transition: all .4s;
            transition-property: color;
        }
    }
    &__btn {
        margin-right: 32px;
        display: inline-block;
        flex-shrink: 0;
        align-self: center;
    }
}

.projects-list {

    &__row {
        margin: 0 -16px -40px;
    }

    &__col {
        margin-bottom: 40px;
        padding-right: 16px;
        padding-left: 16px;
    }

    &__card {
        height: 100%;
    }
}

.p-project {
    padding: 40px 0 80px;
    color: $darknes;
    overflow: clip;
    &__container {
    }
    &__title {
        margin: 0 0 32px;
        @extend .title-3;
    }
    &__filter {
        margin: 0 0 32px;
        padding-bottom: 16px;
        border-bottom: 1px solid $gray-light;
    }
    &__appeal-alert {
        margin: 0 0 32px;
    }
    &__projects-list {
    }
    &__search-users {
        margin: 0 0 72px;
        padding-top: 16px;
    }
    &__search-info {
        margin: 0 0 16px;
    }
    &__bottom-search {
        padding-top: 40px;
        text-align: center;
        &-btn {
            min-width: 176px;
        }
    }
    &__search-header {
        display: flex;
        align-items: center;
        &-result-counter {
            margin: 6px 0 32px 12px;
            font-weight: normal;
            font-size: 14px;
            color: $gray;
        }
    }
}

.nav-step {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.75;
    &__list {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        list-style-type: none;
    }
    &__item {
        margin: 0;
        padding: 0;

        &:not(:last-child) {
            margin-bottom: 26px;
        }
    }
    &__link {
        display: block;
        color: $gray;
        outline: none;
        transition: all .4s;
        transition-property: color;

        &:hover {
            @media screen and (min-width: 1200px) {
                color: $green1;
            }
        }

        &.is-active {
            font-weight: 500;
            color: $green1 !important;
        }

        &.is-disabled {
            font-weight: normal;
            color: #D1D5DB;
            pointer-events: none;
            cursor: default;
        }

        &.is-developing:not(:hover) {
            color: $gray-light;
        }
    }
}

.u-subtitle {
    font-size: 14px;
    color: $gray;
}

.add-link-btn {
    background: transparent;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1.3;
    color: $blue;
    position: relative;
    border: none;
    border-radius: 0;
    outline: none;
    cursor: pointer;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: -6px;
        right: -6px;
        bottom: -6px;
        left: -6px;
    }
    &__icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        flex: 0 0 20px;
        transition: all .4s;
        transition-property: transform;
    }
    &:hover &__icon {
        transform: rotate(180deg);
    }
}

.label-radio {
    padding: 0 0 0 32px;
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.7;
    user-select: none;
    cursor: pointer;
    position: relative;
    &--size-sm {
        font-size: 16px;
        line-height: 1.375;
    }
    &__input {
        display: none;
        position: absolute;
        left: -9999px;
    }
    &__inner {
        display: block;
        &:before {
            width: 20px;
            height: 20px;
            content: '';
            position: absolute;
            top: 2px;
            left: 0;
            box-shadow: inset 0 0 0 1px #D1D5DB;
            border-radius: 50%;
            transition: all .4s;
            transition-property: box-shadow;
        }
    }
    &__input:checked ~ &__inner {
        &:before {
            box-shadow: inset 0 0 0 6px $green3;
        }
    }
    &__input:disabled ~ &__inner {
        color: $gray-1;
        &:before {
            box-shadow: inset 0 0 0 1px #D1D5DB;
        }
    }
    &__input:disabled ~ &__inner &__text {
        color: $gray-1;
    }
    &__title,
    &__text {
        margin: 0;
        display: block;
    }
    &__title {
        font-weight: 500;
    }
    &__title-badge {
        background: #F3F4F6;
        margin-left: 8px;
        padding: 4px 6px;
        display: inline-block;
        font-weight: 500;
        font-size: 12px;
        line-height: 1;
        color: $darknes;
        border-radius: 4px;
        white-space: nowrap;
        vertical-align: middle;
    }
    &__text {
        color: $gray;
    }
}

.n-new-project {
    padding: 32px 0 80px;
    font-size: 14px;
    color: $darknes;
    flex-grow: 1;
    overflow: clip;

    &__container {
        display: flex;
        align-items: flex-start;
    }
    &__aside {
        width: 240px;
        margin: -32px 40px 0 0;
        padding: 42px 0;
        flex: 0 0 240px;
        position: sticky;
        top: 0;
        left: 0;
    }
    &__aside-title {
        margin: 0 0 24px;
        font-weight: 600;
        font-size: 20px;
    }
    &__aside-nav {
    }
    &__main {
        flex-grow: 1;
    }
    &__main-title {
        margin: 0 0 8px;
        font-weight: 600;
        font-size: 30px;
        line-height: 1.35;
    }
    &__main-pager {
        margin: 0 0 24px;
    }
    &__main-alert {
        margin: 0 0 40px;
    }
    &__main-form {
    }
    &__main-form__area-item {
        max-width: 480px;
        margin: 0 0 48px;
        &--category {
            max-width: 280px;
        }
    }
    &__main-form__area-d-all {
        max-width: 100%;
        margin-bottom: 48px;
    }
    &__main-form__area-new {
        margin: 0 0 48px;
    }
    &__main-form__area-logo {
        margin: 0 0 60px;
    }
    &__main-form__area-line {
        margin: 0 0 32px;
    }
    &__main-form__area-link {
        margin: 0 0 60px;
    }
    &__main-form-privacy {
        max-width: 630px;
        margin: 0 0 60px;
    }
    &__main-form-privacy-title {
        margin: 0 0 24px;
        font-weight: 500;
        font-size: 16px;
    }
    &__main-form-privacy-label-radio {
        margin: 0 0 24px;
    }
    &__main-form-btns {
    }
}

.card-badge-text {
    font-size: 18px;

    &__title {
        margin: 0 0 8px;
        font-weight: 500;
        display: block;
    }
    &__contacts {
        display: block;

        a {
            color: $blue;
            transition: all .4s;
            transition-property: color;
        }
    }
}

.progress {
    background: #DFE1E3;
    height: 6px;
    border-radius: 3px;
    position: relative;

    span {
        background: $green3;
        width: 0;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: inherit;
    }
}

.clients-info {
    padding: 100px 0;
    display: flex;
    align-items: flex-start;
    position: relative;
    z-index: 0;

    &:before {
        background: $blue-light;
        content: '';
        position: absolute;
        top: 20px;
        right: -80px;
        bottom: 0;
        left: -80px;
        border-radius: 16px;
        z-index: -1;
    }

    &__aside {
        margin: -14px 68px 0 0;
        flex-grow: 1;
    }
    &__aside-percent {
        margin: 0 0 6px;
        font-weight: bold;
        font-size: 148px;
        line-height: 1;
        color: $green3;
        text-align: center;
    }
    &__aside-progress {
    }
    &__content {
        flex-grow: 1;
    }
    &__title {
        margin: 0 0 32px;
        font-weight: bold;
        font-size: 28px;
        line-height: 1.45;
        color: #111827;
    }
    &__text {
        line-height: 1.8;
        color: $darknes;
    }
}

.blockquote-card {
    &__text {
        margin: 0 0 15px;
        font-style: italic;
        @media screen and (max-width: 1169px) {
            margin-bottom: 12px;
        }
    }
    &__footer {
        margin: 0;
        display: block;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;
    }
    &__author,
    cite {
        font-style: normal;
    }
}

.author-cite {
    &__inner {
        display: flex;
        align-items: flex-start;
        @media screen and (max-width: 480px) {
            flex-wrap: wrap;
        }
    }
    &__avatar {
        width: 56px;
        height: 56px;
        margin: 4px 20px 0 0;
        flex: 0 0 56px;
        border-radius: 50%;
        overflow: hidden;
        @media screen and (max-width: 480px) {
            margin-top: 0;
            margin-right: 0;
            margin-bottom: 16px;
        }
    }
    &__blockquote {
        margin: 0;
        display: block;
        flex-grow: 1;
    }
}

.a-about-info {
    padding: 60px 0 120px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__content {
        max-width: 748px;
        margin-right: 80px;
        flex-grow: 1;
    }
    &__title {
        margin: 0 0 40px;
        font-weight: 600;
        font-size: 42px;
        line-height: 1.3;
    }
    &__text {
        margin-bottom: 32px;
    }
    &__author {
        padding-top: 32px;
        border-top: 1px solid $gray-light;
    }
    &__aside {
        padding-top: 100px;
        flex-shrink: 0;
    }
    &__aside-card {
        &:not(:last-child) {
            margin-bottom: 26px;
        }
    }
}

.a-about {
    padding: 90px 0;
    flex-grow: 1;
    font-size: 14px;
    color: $darknes;
    overflow: clip;
    &__container {
    }
    &__inner {
        max-width: 820px;
    }
    &__title {
        margin: 0;
        font-weight: bold;
        font-size: 80px;
        line-height: 1.15;
        & > span {
            height: 1.15em;
            display: block;
            position: relative;
            overflow: hidden;
            span {
                display: block;
                transition: all .6s;
                transition-property: opacity, visibility;
                &.is-active {
                }
                &:not(.is-active) {
                    height: 0;
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }
    &__description {
        margin: 0 0 40px;
        font-size: 20px;
        color: $darknes;
    }
    &__poster {
        width: 100vw;
        margin: 90px 0 60px calc(-1 * (100vw - 569px));
        padding-bottom: 30vw;
        left: 50vw;
    }
    &__info {
    }
    &__clients-info {
    }
}

.list-info {
    margin: 0;
    padding: 0;
    font-size: 18px;
    line-height: 1.8;
    list-style-type: none;

    li {
        margin: 0;
        padding: 0;
        position: relative;

        &:after {
            background: $gray-light;
            width: 100%;
            height: 1px;
            margin: 30px 0;
            content: '';
            display: block;
        }
    }

    &__icon {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        width: 24px;
        height: 24px;
        margin-right: 36px;
        position: absolute;
        top: .2em;
        right: 100%;
    }
}

.link-accent-simple {
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    color: $blue;
    white-space: nowrap;
    position: relative;
    transition: all .4s;
    transition-property: color;

    &:before {
        content: '';
        position: absolute;
        inset: -6px;
    }
}

.a-about-company {
    padding: 120px 0 90px;
    display: flex;
    align-items: flex-start;

    &__title {
        max-width: 320px;
        margin: 0 60px 60px 0;
        flex: 0 0 320px;
    }
    &__content {
        padding-top: 12px;
        flex-grow: 1;
        color: $darknes;
    }
    &__list {
        margin: 0 0 40px;
    }
    &__btns {
    }
}

.news-card {
    color: $darknes;
    white-space: initial;
    position: relative;

    &__picture {
        margin: 0 0 24px;
        padding-bottom: 55.865921787%;
        overflow: hidden;
    }
    &__content {
    }
    &__title {
        margin: 0 0 8px;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.55;
    }
    &__link {
        color: inherit;
        transition: all .4s;
        transition-property: color;

        &:before {
            content: '';
            position: absolute;
            inset: 0;
        }
    }
    &__footer {
        display: block;
    }
    &__time {
        display: block;
        font-size: 14px;
        line-height: 1;
        color: #9CA3AF;
    }
}

.news-list {

    &__row {
        margin: 0 calc(-1 * 16px);
    }
    &__col {
        padding-right: 16px;
        padding-left: 16px;
    }
    &__card {
    }
}

.news-section {
    padding: 120px 0;
    position: relative;
    z-index: 0;

    &:before {
        background: $blue-light;
        width: 100vw;
        height: 100%;
        margin-left: -50vw;
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        z-index: -1;
    }

    &__header {
        margin: 0 0 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        margin: 0 40px 0 0;
    }

    &__view-all {
        margin-bottom: 12px;
        flex-shrink: 0;
        align-self: flex-end;
    }
}

.team-card {
    background: transparent;
    padding: 20px;
    display: flex;
    align-items: center;
    color: $darknes;
    border: 1px solid $gray-light;
    border-radius: 16px;
    position: relative;
    transition: all .34s;
    transition-property: background;
    overflow: hidden;
    z-index: 0;
    &:hover {
        @media screen and (min-width: 1200px) {
            background: rgba($gray-light, .2);
        }
    }
    &__picture {
        width: 48px;
        height: 48px;
        margin-right: 16px;
        flex: 0 0 48px;
        border-radius: 50%;
        overflow: hidden;
    }
    &__content {
        padding-right: 44px;
        line-height: 1.6;
    }
    &__name,
    &__position {
        margin: 0;
    }
    &__name {
        font-weight: 500;
        font-size: 16px;
    }
    &__link {
        color: $darknes;

        &:before {
            content: '';
            position: absolute;
            inset: 0;
            z-index: 1;
        }
    }
    &__position {
        font-size: 14px;
        color: $gray;
    }
    &__arrow {
        background-size: contain;
        background-position: 50%;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        margin: -12px 0 0 ;
        content: '';
        position: absolute;
        top: 50%;
        right: 20px;
        transition: all .34s;
        transition-property: transform;
    }
    &:hover &__arrow {
        @media screen and (min-width: 1200px) {
            transform: translateX(44px);
        }
    }
}

.team-list {

    &__row {
        margin: 0 -16px -24px;
    }
    &__col {
        margin-bottom: 24px;
        padding-right: 16px;
        padding-left: 16px;
    }
    &__card {
    }
}

.team-section {
    padding: 100px 0 120px;

    &__title {
        margin: 0 0 40px;
    }
}

.label-card-line {
    display: block;

    &__title {
        margin: 0 0 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 1;
        color: #4B5563;
    }

    &__input {

    }

    &__date {
        display: flex;
        align-items: center;
    }

    &__date-input {
        width: 60px;
    }

    &__date-divider {
        margin: 0 8px;
        font-size: 14px;
        line-height: 1;
        color: $gray;
    }
}

.card-block {
    padding: 32px 0 24px;

    &__number {
        margin: 0 0 24px;
    }
    &__info {
        margin: 0 0 32px;
        display: flex;
        justify-content: space-between;
    }
    &__info-date {
    }
    &__info-cvv {
        width: 60px;
        flex: 0 0 60px;
    }
    &__btn {
        width: 100%;
        display: block;
    }
}

.file-attach {
    display: flex;
    align-items: center;
    
    &__text {
        overflow: hidden;
    }
    &__name {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $blue;
    }
    &__remove {
        background: transparent;
        width: 24px;
        height: 24px;
        margin-left: 8px;
        flex: 0 0 24px;
        font-size: 0;
        color: #9CA3AF;
        border: none;
        border-radius: 0;
        outline: none;
        cursor: pointer;
        transition: all .4s;
        transition-property: color;
    }
    &__icon {
        width: 100%;
        height: 100%;
        display: block;
    }
}

.files-hidden,
.input-hidden {
    display: none;
    position: absolute;
    left: -9999px;
}

.support-status {
    font-size: 14px;
    line-height: 1.4;
    white-space: nowrap;

    &--new {
        color: $green3;
    }
    &--closed {
        color: #9CA3AF;
    }
}

.support-card {
    background: #F8FAFC;
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 14px;
    color: $darknes;
    border-radius: 8px;
    position: relative;

    &__content {
        padding-right: 20px;
    }
    &__title {
        margin: 0 0 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;
    }
    &__link {
        display: block;
        color: #242B33;
        transition: all .4s;
        transition-property: color;

        &:hover {
            color: $green3;
            text-decoration: none;
        }

        &:before {
            width: 100%;
            height: 100%;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 8px;
        }
    }
    &__date {
        margin: 0;
        display: block;
        font-size: 12px;
        color: #9CA3AF;
    }
    &__status {
        margin-left: auto;
        flex-shrink: 0;
        line-height: 24px;
    }
}

.s-support-list {

    &__item {

        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }
}

.files-attach-list {
    
    &__item {

        &:not(:last-child) {
            margin-bottom: 14px;
        }
    }
}

.s-support {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.35;
    color: #242B33;
    flex-grow: 1;
    overflow: clip;

    &__inner {
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
        flex-grow: 1;
    }
}

.support-title {
    margin: 0;
    font-weight: 600;
    font-size: 30px;
}

.s-support-main {
    min-height: 100%;
    overflow: clip;
    &__container {
    }
    &__header {
        margin: 0 0 56px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__header-btn {
        flex-shrink: 0;
    }
    &__top {
        margin: 0 0 48px;
    }
    &__title {
    }
    &__subtitle {
        margin: 0 0 20px;
        font-weight: 600;
        font-size: 20px;
    }
    &__form {
    }
    &__form-area-item {
        margin: 0 0 40px;
        &--subject,
        &--email {
            max-width: 448px;
        }
    }
    &__form-files-attach-list {
        margin: -20px 0 24px;
    }
    &__form-btns {
    }
    &__form-file-text {
        color: $gray;
    }
    &__chat {
        min-height: calc(100vh - 240px);
    }
    &__social {
        margin-top: 60px;
        padding-top: 15px;
        border-top: 1px solid $gray-light;
        @media screen and (max-width: 1000px) {
            margin-top: 40px;
        }
        @media screen and (max-width: 767px) {
            padding-top: 0;
            border-top: none;
        }
    }
}

.chat-message {
    display: flex;
    align-items: flex-start;

    &__avatar {
        width: 40px;
        height: 40px;
        margin-right: 12px;
        border-radius: 50%;
        flex: 0 0 40px;
        user-select: none;
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &__body {
        flex-grow: 1;
        overflow: hidden;
    }
    &__body-header {
        margin: 0 0 6px;
        display: flex;
        align-items: center;
    }
    &__body-name {
        font-weight: 500;
        font-size: 14px;
        color: $darknes;
    }
    &__body-date {
        margin-left: 8px;
        display: block;
        flex-shrink: 0;
        font-size: 12px;
        color: $gray;
    }
    &__body-content {

        > :last-child {
            margin-bottom: 0;
        }
    }
    &__body-text {
        margin: 0 0 4px;
        font-size: 14px;
        line-height: 1.7;
    }
    &__body-attach {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    &__body-attach-file {
        padding: 3px 0;
        font-weight: 500;
        color: $blue;
        transition: all .4s;
        transition-property: color;

        &:hover {
            text-decoration: none;
        }

        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }
}

.s-support-chat {
    display: flex;
    flex-direction: column;

    &__header {
        margin: 0 0 24px;
        padding-bottom: 24px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $gray-light;
    }
    &__header-prev-link {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        width: 24px;
        height: 24px;
        margin-right: 12px;
        display: none;
        flex: 0 0 24px;

        &:hover {
            text-decoration: none;
        }
    }
    &__title {
    }
    &__body {
    }
    &__body-message {

        &:not(:last-child) {
            margin-bottom: 32px;
        }
    }
    &__footer {
        margin-top: auto;
        padding-top: 24px;
    }
    &__footer-message {
        min-height: 80px;
        margin: 0 0 24px;
    }
    &__footer-attach-list {
        margin-bottom: 20px;
    }
    &__footer-attach-item {
        margin: 0 0 6px;
    }
    &__footer-btns-group {
    }
    &__form-file-text {
        color: $gray;
    }
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1169px) {
    .authors-intro {
        padding: 59px 0;

        &__container {
        }

        &__row {
        }

        &__col {

            &--content {
            }

            &--media {
                margin-bottom: -59px;
            }
        }

        &__content {
            padding: 13px 0;
        }

        &__title {
            margin-bottom: 16px;
            font-size: 5.15vw;
        }

        &__desription {
            margin-bottom: 24px;
        }

        &__btns {
            padding-bottom: 48px;
        }

        &__btn {
        }

        &__tinkoff-bank {
            padding-top: 0;
            border-top: none;
        }

        &__picture {

            img {
            }
        }
    }

    .why-uppora {
        padding: 72px 0 80px;

        &__container {
        }

        &__title {
            margin-bottom: 40px;
            font-size: 32px;
        }
    }

    .why-uppora-list {

        &__row {
            margin: 0 -16px -30px;
            padding: 0 16px 30px;
            display: block;
            font-size: 0;
            flex-wrap: nowrap;
            white-space: nowrap;
            overflow-x: auto;
        }

        &__col {
            max-width: 396px;
            padding-right: 0;
            padding-left: 0;
            flex-basis: 396px;
            display: inline-block;
            vertical-align: top;
            &:not(:last-child) {
                margin-right: 48px;
            }
        }

        &__card {
        }
    }

    .a-about-info {
        padding-top: 0;
        padding-bottom: 50px;

        &__content {
            margin-right: 60px;
        }
        &__title {
            margin-bottom: 16px;
            font-size: 42px;
        }
        &__text {
            margin-bottom: 24px;
        }
        &__author {
            padding-top: 24px;
        }
        &__aside {
            padding-top: 76px;
        }
        &__aside-card {

            &:not(:last-child) {
            }
        }
    }

    .list-info {

        li {

            &:after {
            }
        }

        &__icon {
            margin-right: 12px;
        }
    }

    .clients-info {
        padding-top: 50px;
        padding-bottom: 50px;

        &:before {
            border-radius: 0;
            top: 0;
        }

        &__aside {
            margin-right: 40px;
        }
        &__aside-progress {
        }
        &__content {
        }
        &__title {
            margin: 0 0 16px;
        }
        &__text {
        }
    }

    .a-about-company {
        padding-top: 70px;
        padding-bottom: 70px;

        &__title {
            margin-right: 40px;
        }
        &__content {
        }
        &__list {
        }
        &__btns {
        }
    }

    .a-about {
        padding: 32px 0;

        &__container {
        }
        &__inner {
        }
        &__title {
            font-size: 60px;
        }
        &__description {
            font-size: 18px;
        }
        &__poster {
            width: auto;
            margin: 48px -16px 60px;
            left: 0;
        }
        &__info {
        }
        &__clients-info {
        }
    }

    .news-section {
        padding: 40px 0 50px;

        &__header {
            margin-top: 0;
        }

        &__title {
        }

        &__view-all {
        }
    }

    .team-section {
        padding: 50px 0 60px;

        &__title {
        }
    }

    .appeal-section {
        padding: 80px 0;

        &:before {
            width: auto;
            right: 16px;
            left: 16px;
        }
    }

    .team-list {

        &__row {
            margin: 0 -10px -20px;
        }
        &__col {
            margin-bottom: 20px;
            padding-right: 10px;
            padding-left: 10px;
        }
        &__card {
        }
    }

    .news-list {

        &__row {
            margin: 0 -16px -30px;
            padding: 0 16px 30px;
            display: block;
            font-size: 0;
            flex-wrap: nowrap;
            white-space: nowrap;
            overflow-x: auto;
        }

        &__col {
            max-width: 396px;
            padding-right: 0;
            padding-left: 0;
            flex-basis: 396px;
            display: inline-block;
            vertical-align: top;
            &:not(:last-child) {
                margin-right: 32px;
            }
        }

        &__card {
        }
    }

    .a-section-card {

        &__container {
        }

        &__inner {
            margin: 0 -24px;
        }

        &__media {
            max-width: 50%;
            margin-right: 0;
            padding-right: 24px;
            padding-left: 24px;
            flex-basis: 50%;
        }

        & + & &__media {
            margin-left: 0;
        }

        &__picture {
        }

        &__content {
            max-width: 50%;
            padding-right: 24px;
            padding-left: 24px;
            flex-basis: 50%;
        }

        & + & &__content {
            padding-left: 24px;
        }

        &__title {
            margin-bottom: 12px;
            font-size: 32px;
        }

        &__text {
        }
    }

    .a-section-info {
        padding: 30px 0 0;

        &__container {
        }

        &__inner {
            margin: 0;
        }

        &__content {
            max-width: 50%;
            padding-right: 24px;
            padding-left: 60px;
            flex: 0 0 50%;
        }

        &__title {
            margin-bottom: 12px;
            font-size: 32px;
        }

        &__text {

            &:not(:last-child) {
            }
        }

        &__media {
            max-width: 50%;
            padding-right: 24px;
            padding-left: 24px;
            flex: 0 0 50%;
        }

        &__btn {
        }

        &__picture {
            max-width: 381px;
        }

        &__decor {
            right: 0;
        }
    }

    .project-card {

        &__description {
            margin-bottom: 12px;
        }
    }

    .filter-group {

        &__item {

            &:not(:last-child) {
                margin-right: 16px;
            }
        }
    }

    .projects-list {

        &__row {
            margin-right: -8px;
            margin-bottom: -20px;
            margin-left: -8px;
        }

        &__col {
            margin-bottom: 20px;
            padding-right: 6px;
            padding-left: 6px;
        }

        &__card {
        }
    }

    .p-project {
        padding: 20px 0;

        &__container {
        }
        &__title {
            margin-bottom: 20px;
            font-size: 24px;
        }
        &__filter {
            margin-right: -16px;
            margin-bottom: 20px;
            margin-left: -16px;
            padding-right: 16px;
            padding-left: 16px;
        }
        &__appeal-alert {
            margin-bottom: 20px;
        }
        &__projects-list {
        }
    }

    .appeal-alert {

        &__inner {
            flex-wrap: wrap;
        }

        &__content {
            max-width: 100%;
            flex-basis: 100%;
        }

        &__btn {
            margin: 12px 0 0;
        }
    }

    .s-support-main {

        &__container {
        }
        &__header {
            margin-bottom: 32px;
        }
        &__header-btn {
        }
        &__top {
            margin-bottom: 32px;
        }
        &__title {
        }
        &__subtitle {
        }
        &__form {
        }
        &__form-area-item {
            margin-bottom: 32px;
        }
        &__form-files-attach-list {
            margin-top: -12px;
        }
        &__form-btns {
        }
        &__form-file-text {
        }
        &__chat {
            background: #fff;
            width: 100%;
            min-height: 100vh;
            padding: 0 16px;
            position: absolute;
            top: 0;
            left: 0;
            overflow: clip;
            z-index: 10000;
        }
    }

    .s-support-chat {

        &__header {
            background: #fff;
            margin: 0 -16px 26px;
            padding: 14px 16px;
            position: sticky;
            top: 0;
            z-index: 2;
        }
        &__header-prev-link {
            display: block;
        }
        &__title {
            font-size: 20px;
        }
        &__body {
            width: 100%;
            max-width: 670px;
            margin: 0 auto;
        }
        &__body-message {

            &:not(:last-child) {
            }
        }
        &__footer {
            background: #fff;
            width: 100%;
            max-width: 670px;
            margin: 0 auto;
            padding-bottom: 24px;
            position: sticky;
            bottom: 0;
            z-index: 2;
        }
        &__footer-message {
        }
        &__footer-btns-group {
        }
        &__form-file-text {
        }
    }
}

@media screen and (max-width: 1000px) {
    .nav-step {
        font-size: 14px;
        &--mobile-scroll &__list {
            margin: 0 -16px;
            padding: 0 16px;
            display: block;
            flex-direction: initial;
            box-shadow: inset 0 -1px $gray-light;
            white-space: nowrap;
            -ms-overflow-style: none;
            scrollbar-width: none;
            overflow-x: auto;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        &--mobile-scroll &__item {
            display: inline-block;
            vertical-align: top;
            &:not(:last-child) {
                margin-right: 40px;
                margin-bottom: 0;
            }
        }
        &--mobile-scroll &__link {
            padding: 7px 0;
            border-bottom: 2px solid transparent;
            &.is-active {
                border-bottom-color: $green1;
            }
        }
    }
    .authors-intro {
        padding: 32px 0;

        &__container {
        }

        &__row {
        }

        &__col {
            max-width: 100%;
            flex-basis: 100%;

            &--content {
            }

            &--media {
                margin-bottom: -32px;
            }
        }

        &__content {
            max-width: 580px;
            margin: 0 auto 32px;
            padding: 0;
            text-align: center;
        }

        &__title {
            font-size: 48px;
        }

        &__desription {
            max-width: 420px;
            margin-right: auto;
            margin-bottom: 16px;
            margin-left: auto;
            font-size: 16px;
        }

        &__btns {
            padding-bottom: 32px;
            justify-content: center;
        }

        &__btn {
        }

        &__picture {
            max-width: 720px;
            margin: 0 auto;

            img {
            }
        }
    }

    .why-uppora {
        padding: 60px 0;

        &__container {
        }

        &__title {
            margin-bottom: 32px;
        }
    }

    .why-uppora-list {

        &__row {
        }

        &__col {

            &:not(:last-child) {
                margin-right: 24px;
            }
        }

        &__card {
        }
    }

    .tinkoff-bank {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        &__logo {
            margin: 0 0 8px;
            flex-basis: initial;
        }

        &__description {
        }
    }

    .site-pager {

        &__link {
            display: block;
        }
    }

    .appeal-alert {
        padding: 16px;
    }

    .n-new-project {
        padding-bottom: 32px;

        &__container {
            flex-direction: column;
        }
        &__aside {
            width: 100%;
            margin: 0;
            padding: 0;
            position: relative;
            top: 0;
            flex-basis: initial;
        }
        &__aside-title {
            margin: 0 0 16px;
            font-weight: 600;
            font-size: 16px;
            text-align: center;
        }
        &__aside-nav {
            display: none;
        }
        &__main {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
        &__main-title {
            margin-bottom: 16px;
            font-size: 24px;
        }
        &__main-pager {
            background: $blue-light;
            margin: 0 -16px 32px;
            padding: 14px 16px;
            justify-content: space-between;
            order: -1;
        }
        &__main-alert {
            margin-bottom: 32px;
        }
        &__main-form {
        }
        &__main-form__area-item {
        }
        &__main-form__area-logo {
        }
        &__main-form__area-line {
        }
        &__main-form__area-link {
            margin-bottom: 48px;
        }
        &__main-form-privacy {
            margin-bottom: 48px;
        }
        &__main-form-privacy-title {
        }
        &__main-form-privacy-label-radio {
        }
    }

    .a-about-info {
        flex-wrap: wrap;

        &__content {
            width: 100%;
            margin: 0 0 32px;
            flex: 0 0 100%;
        }
        &__text {
        }
        &__aside {
            width: 100%;
            padding-top: 0;
            flex: 0 0 100%;
            display: flex;
            flex-wrap: wrap;
        }
        &__aside-card {

            &:not(:last-child) {
                margin-right: 48px;
            }
        }
    }

    .clients-info {
        flex-wrap: wrap;

        &__aside {
            width: 100%;
            margin: 0 0 24px;
            flex: 0 0 100%;
        }
        &__aside-progress {
        }
        &__content {
            width: 100%;
            flex: 0 0 100%;
        }
        &__title {
            margin: 0 0 16px;
        }
        &__text {
        }
    }

    .a-about-company {
        flex-wrap: wrap;

        &__title {
            width: 100%;
            margin: 0 0 32px;
            flex: 0 0 100%;
        }
        &__content {
            width: 100%;
            padding-top: 0;
            flex: 0 0 100%;
        }
        &__list {
            margin-left: 36px;
        }
        &__btns {
        }
    }

    .team-list {

        &__row {
        }
        &__col {
            max-width: 50%;
            flex-basis: 50%;
        }
        &__card {
        }
    }

    .appeal-section {
        margin-bottom: 60px;
        padding: 60px 0;

        &__content {
        }

        &__title {
            max-width: 620px;
        }

        &__text {
            max-width: 620px;
        }

        &__btns {
        }
    }

    .a-about {

        &__poster {
            padding-bottom: 44vw;
        }
    }

    .a-section-card {
        padding: 90px 0 60px;
        &__container {
        }
        &__section-title {
            font-size: 32px;
        }
        &__inner {
            margin: 0 -6px;
        }
        &__media {
            padding-right: 6px;
            padding-left: 6px;
        }
        &__picture {
        }
        &__content {
            padding-right: 6px;
            padding-left: 6px;
        }
        & + & &__content {
            padding-right: 6px;
            padding-left: 6px;
        }
        &__group-btns {
            display: none;
        }
    }

    .a-section-info {

        &__container {
        }

        &__inner {
            margin: 0 -22px;
            padding: 0 16px;
            border-radius: 0;
        }

        &__content {
            max-width: initial;
            padding: 62px 6px;
            flex: initial;
            flex-grow: 1;
        }

        &__media {
            max-width: initial;
            margin-right: -10px;
            padding-right: 6px;
            padding-left: 6px;
            flex: initial;
            flex-shrink: 0;
        }

        &__picture {
            max-width: 340px;
        }

        &__decor {
            display: none;
        }
    }

    .projects-list {

        &__row {
        }

        &__col {
            max-width: 50%;
            flex-basis: 50%;
        }
    }
}

@media screen and (max-width: 767px) {
    .authors-intro {

        &__container {
        }

        &__row {
        }

        &__col {

            &--content {
            }

            &--media {
            }
        }

        &__content {
            margin-bottom: 28px;
        }

        &__title {
            font-size: 6.7vw;
        }

        &__desription {
        }

        &__btns {
            padding-bottom: 28px;
        }

        &__btn {
        }

        &__tinkoff-bank {
        }

        &__picture {
            padding-top: 0;
        }
    }

    .why-uppora {
        padding-bottom: 48px;

        &__container {
        }

        &__title {
            margin-bottom: 24px;
            font-size: 24px;
        }
    }

    .why-uppora-list {

        &__row {
        }

        &__col {
        }

        &__card {
        }
    }

    .n-new-project {

        &__main-alert {
            margin-bottom: 24px;
        }
        &__main-form__area-item {
            max-width: initial;
            margin-bottom: 32px;
        }
        &__main-form__area-d-all {
            margin-bottom: 32px;
        }
        &__main-form__area-new {
            margin-bottom: 32px;
        }
        &__main-form__area-link {
            margin-bottom: 40px;
        }
        &__main-form-privacy {
            max-width: initial;
            margin-bottom: 32px;
        }
        &__main-form-btns {
            .btn {
                width: 100%;
                flex-basis: 100%;
            }
        }
    }

    .a-about {

        &__title {
            font-size: 28px;
        }
        &__description {
            margin-bottom: 32px;
            font-size: 16px;
        }
        &__poster {
            margin-top: 32px;
            margin-bottom: 32px;
            padding-bottom: 44vw;
        }
    }

    .a-about-info {
        padding-bottom: 30px;

        &__content {
        }
        &__title {
            margin-bottom: 12px;
            font-size: 24px;
        }
        &__text {
            font-size: 16px;
        }
        &__aside {
            flex-direction: column;
        }
        &__aside-card {
            width: 100%;
            margin-right: 0;

            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }

    .card-badge-text {
        font-size: 16px;
    }

    .clients-info {
        padding-top: 30px;
        padding-bottom: 30px;

        &__aside {
        }
        &__aside-progress {
        }
        &__content {
        }
        &__title {
            font-size: 18px;
        }
        &__text {
            font-size: 16px;
        }
    }

    .a-about-company {
        padding-top: 30px;
        padding-bottom: 30px;

        &__title {
            margin: 0 0 16px;
            font-size: 24px;
        }
        &__list {
            margin-bottom: 24px;
            margin-left: 0;
        }
    }

    .list-info {
        font-size: 16px;

        li {

            &:after {
                margin: 16px 0;
            }
        }

        &__icon {
                display: none;
        }
    }

    .news-card {

        &__picture {
            margin-bottom: 16px;
        }
        &__content {
        }
        &__title {
            margin-bottom: 4px;
            font-size: 16px;
        }
        &__link {

            &:before {
            }
        }
        &__footer {
        }
        &__time {
        }
    }

    .news-list {

        &__row {
        }

        &__col {
            max-width: 260px;
            flex-basis: 260px;

            &:not(:last-child) {
                margin-right: 20px;
            }
        }

        &__card {
        }
    }

    .news-section {
        padding: 30px 0;

        &__header {
            margin-top: 0;
            margin-bottom: 24px;
        }

        &__title {
            font-size: 24px;
        }

        &__view-all {
            margin-bottom: 0;
        }
    }

    .team-section {
        padding: 40px 0;

        &__title {
            margin-bottom: 24px;
            font-size: 24px;
        }
    }

    .team-list {

        &__row {
            margin-bottom: 0;
        }
        &__col {
            max-width: 100%;
            margin-bottom: -1px;
            flex-basis: 100%;
        }
        &__card {
        }
    }

    .team-card {
        padding: 20px 0;
        border-radius: 0;
        border-width: 1px 0;

        &__content {
            padding-right: 34px;
        }

        &__arrow {
            right: 0;
        }
    }

    .appeal-section {
        margin-bottom: 40px;
        padding: 40px 0;

        &__content {
        }

        &__title {
            font-size: 24px;
            margin-bottom: 12px;
        }

        &__text {
            margin-bottom: 16px;
        }

        &__btns {
        }
    }

    .clients-info {

        &__aside {
            width: 100%;
            max-width: 100%;
        }
        &__aside-percent {
            font-size: 80px;
            text-align: left;
        }
        &__aside-progress {
        }
        &__content {
        }
        &__title {
        }
        &__text {
        }
    }

    .a-section-card {
        padding: 40px 0;
        &__container {
        }
        &__section-title {
            max-width: 450px;
            margin-right: initial;
            margin-bottom: 12px;
            margin-left: initial;
            font-size: 24px;
            text-align: left;
        }
        &__inner {
            flex-wrap: wrap;
        }
        &__content {
            max-width: 100%;
            margin-bottom: 32px;
            padding-bottom: 0;
            flex-basis: 100%;
            order: -1;
        }

        &__title {
            font-size: 24px;
        }
        &__media {
            max-width: 100%;
            margin-bottom: -40px;
            flex-basis: 100%;
        }
        &__picture {
            max-width: 530px;
            margin: 0 auto;
            position: relative;
            right: -8px;
        }
    }

    .a-section-info {
        &__container {
        }
        &__inner {
            flex-wrap: wrap;
        }
        &__content {
            max-width: 100%;
            padding: 40px 0 32px;
            flex-basis: 100%;
            text-align: center;
        }
        &__title {
            font-size: 24px;
        }
        &__media {
            max-width: 100%;
            margin-top: 0;
            flex-basis: 100%;
        }
        &__picture {
            max-width: 288px;
            margin: 0 auto;
        }
    }

    .project-card {
        border-radius: 0;
        border-width: 0;

        &__info {
            padding: 16px;
        }

        &__content {
            padding: 20px 16px;
        }
    }

    .projects-list {

        &__row {
            margin-right: -24px;
            margin-bottom: 0;
            margin-left: -24px;
        }

        &__col {
            max-width: 100%;
            margin-bottom: 0;
            flex-basis: 100%;

            &:not(:last-child) {
                border-bottom: 8px solid $gray-light;
            }
        }
    }

    .p-project {

        &__projects-list {
        }
    }

    .filter-group {

        &__item {

            &:not(:last-child) {
                margin-right: 13px;
            }
        }
    }

    .d-donate {

        &__container {
        }

        &__form {
        }

        &__thank {
            padding-top: 20px;
            padding-bottom: 40px;
        }
    }

    .donate-form {

        &__title {
            font-size: 20px;
        }

        &__pager-link {
            display: block;
        }
    }

    .site-pager {

        &__steps {
            margin: 0 30px;
        }
    }

    .s-support {
        padding: 20px 0;
    }

    .support-title {
        font-size: 20px;
    }

    .s-support-main {

        &__container {
        }
        &__header {
            margin-bottom: 40px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }
        &__header-btn {
            margin-top: 24px;
        }
        &__top {
            margin-bottom: 24px;
        }
        &__title {
        }
        &__subtitle {
            margin-bottom: 16px;
        }
        &__form {
        }
        &__form-area-item {
            margin-bottom: 24px;
        }
        &__form-files-attach-list {
            margin-top: -4px;
        }
        &__form-btns {
        }
        &__form-file-text {
            max-width: 100%;
            flex: 0 0 100%;
            order: -2;
        }
        &__chat {
        }
    }

    .chat-message {

        &__avatar {

            img {
            }
        }
        &__body {
        }
        &__body-header {
            margin-bottom: 3px;
        }
        &__body-name {
        }
        &__body-date {
        }
        &__body-content {

            > :last-child {
            }
        }
        &__body-text {
        }
        &__body-attach {
        }
        &__body-attach-file {

            &:hover {
            }

            &:not(:last-child) {
            }
        }
    }

    .s-support-chat {

        &__header {
        }
        &__header-prev-link {
        }
        &__title {
        }
        &__body {
        }
        &__body-message {

            &:not(:last-child) {
                margin-bottom: 22px;
            }
        }
        &__footer {
        }
        &__footer-message {
            min-height: 70px;
        }
        &__footer-btns-group {
        }
        &__form-file-text {
            max-width: 100%;
            flex: 0 0 100%;
            order: -2;
        }
    }
}

@media screen and (max-width: 480px) {
    .authors-intro {
        padding: 24px 0;

        &__container {
        }

        &__row {
        }

        &__col {

            &--content {
            }

            &--media {
            }
        }

        &__content {
        }

        &__title {
            margin-bottom: 12px;
            font-size: 10vw;
        }

        &__desription {
            margin-bottom: 20px;
        }

        &__btns {
            margin-bottom: 24px;

            .btn {
                width: 100%;
            }
        }

        &__tinkoff-bank {
        }

        &__picture {

            img {
            }
        }
    }

    .why-uppora {
        padding-bottom: 32px;
        &__container {
        }
        &__title {
            margin-bottom: 12px;
        }
    }

    .why-uppora-list {

        &__row {
            white-space: initial;
        }

        &__col {
            width: 100%;
            max-width: 100%;
            flex-basis: 100%;
            display: block;

            &:not(:last-child) {
                margin-right: 0;
                margin-bottom: 56px;
                position: relative;
            }

            &:not(:last-child):after {
                background: $gray-light;
                width: 100%;
                height: 1px;
                margin-top: 28px;
                content: '';
                position: absolute;
                top: 100%;
                left: 0;
            }
        }

        &__card {
        }
    }

    .why-uppora-card {

        &__picture {
            margin-bottom: 24px;
        }

        &__content {
        }

        &__title {
            margin-bottom: 8px;
        }

        &__text {
        }
    }

    .input-block {
        line-height: 1.5;

        &__template-cards {

            &-item {

                &--size-4 {
                    max-width: 51%;
                    flex-basis: 51%;
                }
            }
        }
    }

    .a-about-company {

        &__btns {
            .btn {
                width: 100%;
            }
        }
    }

    .appeal-section {
        margin-bottom: 0;

        &:before {
            right: 0;
            left: 0;
            border-radius: 0;
        }

        &__content {
            padding-right: 0;
            padding-left: 0;
        }

        &__title {
        }

        &__text {
        }

        &__btns {
            .btn {
                width: 100%;
            }
        }
    }

    .s-support-main {

        &__container {
        }
        &__header {
        }
        &__header-btn {
            width: 100%;
        }
        &__top {
        }
        &__title {
        }
        &__subtitle {
        }
        &__form {
        }
        &__form-area-item {
        }
        &__form-files-attach-list {
        }
        &__form-btns {
            .btn {
                width: 100%;
                flex-basis: 100%;
            }
            .btn-trp {
                order: -1;
            }
        }
        &__form-file-text {
        }
        &__chat {
        }
    }

    .support-card {
        flex-wrap: wrap;

        &__content {
            max-width: 100%;
            flex: 0 0 100%;
        }
        &__title {
        }
        &__link {
        }
        &__date {
        }
        &__status {
            max-width: 100%;
            margin-top: 12px;
            flex: 0 0 100%;
        }
    }

    .s-support-chat {

        &__header {
        }
        &__header-prev-link {
        }
        &__title {
        }
        &__body {
        }
        &__body-message {

            &:not(:last-child) {
            }
        }
        &__footer {
        }
        &__footer-message {
        }
        &__footer-btns-group {

            .btn {
                width: 100%;
                flex-basis: 100%;
            }

            .btn-trp {
                order: -1;
            }
        }
        &__form-file-text {
        }
    }
}